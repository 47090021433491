import { createPath, parsePath } from 'history';
import { useMemo } from 'react';
import { useLocation } from 'ts/base/hooks/UseLocation';
import { artificialPathToUrl } from 'ts/base/routing/PerspectiveHashHistory';
import { NavigationHash } from 'ts/commons/NavigationHash';

/** Returns the navigation hash of the current view. */
export function useNavigationHash(): NavigationHash {
	const location = useLocation();
	const artificialPath = createPath(location);
	const url = artificialPathToUrl(artificialPath);
	const hash = parsePath(url).hash;
	const normalizedHash = new NavigationHash(hash).toString();
	const memoizedHash = useMemo(() => new NavigationHash(normalizedHash), [normalizedHash]);
	if (normalizedHash !== memoizedHash.toString()) {
		// Happens when the memoized NavigationHash object was altered
		return new NavigationHash(normalizedHash);
	}
	return memoizedHash;
}
