import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import type { QualityReport } from 'typedefs/QualityReport';
import type { ExtendedReportSlide } from './ExtendedReportSlide';
import { wrapAllReportSlides } from './ExtendedReportSlide';

export type ExtendedQualityReport = ExtendTypeWith<
	QualityReport,
	{
		/** Wrapped from base class, with extended type. */
		slides: ExtendedReportSlide[];
	}
>;

export function wrapReport(report: QualityReport): ExtendedQualityReport {
	return Object.assign(report, {
		slides: wrapAllReportSlides(report.slides)
	});
}
