import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { DateUtils } from 'ts/commons/DateUtils';
import type { ParentedCommitDescriptor } from 'typedefs/ParentedCommitDescriptor';
import type { UserResolvedRepositoryLogEntry } from 'typedefs/UserResolvedRepositoryLogEntry';
import { FormattedCommitMessage } from './FormattedCommitMessage';

/**
 * A repo log entry with a full CommitDescriptor as 'commit' field and fields 'project', 'formattedDate', and 'deleted'
 * (default to null or false, need to be set manually) .
 */
export class CommitRepositoryLogEntry {
	/** A regex matching SHA-1 hashes as used by Git (e.g. e6c2530d4784e89d66f1452b5fb931ac9451864a). */
	public static GIT_COMMIT_HASH_REGEX = /[0-9a-f]{40}/i;

	/** The expected number of characters in a git commit hash. */
	public static GIT_COMMIT_HASH_LENGTH = 40;

	/** The original commit descriptor. */
	public readonly commit: ParentedCommitDescriptor;

	/** Here we use the wrapped commit descriptor instead. */
	public readonly unresolvedCommit: UnresolvedCommitDescriptor;

	/** The formatted date for the commit timestamp. */
	public readonly formattedDate: string;

	/** A short commit hash if the revision is too long. */
	public readonly shortRevision: string;

	/** The full commit hash. */
	public readonly fullRevision: string;

	/** The commit message title and details parsed from the message string. */
	public readonly parsedCommitMessage: FormattedCommitMessage;

	/** The project alias or id. */
	public project: string | null = null;

	/** Tracks a corresponding log file entry has change type ERepositoryChangeType.DELETE. */
	public deleted = false;

	/** The number of moved files. */
	public numMovedFiles: number;

	/** The number of copied files. */
	public numCopiedFiles: number;

	/** The type(s) of this commit (see {@link ECommitType}). */
	public commitTypes?: string[];

	/** Link to the commit at a CCP */
	public externalLink: string | null = null;

	public constructor(public readonly repositoryLogEntry: UserResolvedRepositoryLogEntry) {
		this.commit = repositoryLogEntry.commit as ParentedCommitDescriptor;
		this.unresolvedCommit = UnresolvedCommitDescriptor.wrap(this.commit);
		this.formattedDate = DateUtils.formatTimestamp(this.commit.timestamp);
		this.fullRevision = repositoryLogEntry.revision;
		this.shortRevision = CommitRepositoryLogEntry.getShortRevision(repositoryLogEntry.revision);
		this.parsedCommitMessage = FormattedCommitMessage.fromPlainCommitMessage(repositoryLogEntry.message ?? null);
		this.numCopiedFiles = repositoryLogEntry.numCopiedFiles;
		this.numMovedFiles = repositoryLogEntry.numMovedFiles;
		this.commitTypes = repositoryLogEntry.commitTypes;
	}

	/** Returns an UnresolvedCommitDescriptor for the commit before. */
	public getPreviousCommit(): UnresolvedCommitDescriptor {
		return UnresolvedCommitDescriptor.getPreviousCommit(this.unresolvedCommit);
	}

	/**
	 * If the revision of the given log entry is a SHA-1 hash (-> Git), the first 8 characters of the hash will be
	 * attached to the given logEntry as a 'shortedCommitHash'-field.
	 */
	public static getShortRevision(revision: string): string {
		if (
			revision.length !== CommitRepositoryLogEntry.GIT_COMMIT_HASH_LENGTH ||
			!revision.match(CommitRepositoryLogEntry.GIT_COMMIT_HASH_REGEX)
		) {
			return revision;
		}
		return revision.substring(0, 8);
	}

	/**
	 * Wraps the repository log entries into commit repository log entries. If a repository log entry is null, the
	 * returned array will contain null at the position as well.
	 */
	public static wrapRepositoryLogEntries(logEntries: UserResolvedRepositoryLogEntry[]): CommitRepositoryLogEntry[] {
		return logEntries.map(logEntry => new CommitRepositoryLogEntry(logEntry));
	}
}
