import * as PerspectiveBaseTemplate from 'soy/base/scaffolding/PerspectiveBaseTemplate.soy.generated';
import * as forms from 'ts-closure-library/lib/dom/forms';
import type { BrowserEvent } from 'ts-closure-library/lib/events/browserevent';
import * as events from 'ts-closure-library/lib/events/eventhandler';
import { ButtonSet, DefaultButtons, Dialog, EventType } from 'ts-closure-library/lib/ui/dialog';
import * as googUserAgent from 'ts-closure-library/lib/useragent/useragent';
import * as soy from 'ts/base/soy/SoyRenderer';
import { tsdom } from 'ts/commons/tsdom';
import type { TeamscaleServiceClient } from '../client/TeamscaleServiceClient';

/** Dialog for creating a support request. */
export class TeamscaleSupportDialog extends Dialog {
	/** The id of the save request button. */
	private static readonly SAVE_REQUEST_BUTTON_ID = 'save-request';

	/** @param client The client used to look up paths. */
	public constructor(private readonly client: TeamscaleServiceClient) {
		super();
	}

	/** Shows the dialog. */
	public show(): void {
		this.setTitle('Create Support Request');
		this.setDisposeOnHide(true);
		const content = soy.renderAsElement(PerspectiveBaseTemplate.supportDialog);
		this.getContentElement()!.appendChild(content);
		const buttons = new ButtonSet();
		buttons.addButton({ key: TeamscaleSupportDialog.SAVE_REQUEST_BUTTON_ID, caption: 'Save' }, true);
		buttons.addButton(DefaultButtons.CANCEL);
		this.setButtonSet(buttons);
		this.setVisible(true);
		this.reposition();
		events.listen(this, EventType.SELECT, event => this.onButtonPressed(event));
	}

	/** Callback for the dialog buttons */
	public onButtonPressed(event: BrowserEvent): void {
		if (event.key === TeamscaleSupportDialog.SAVE_REQUEST_BUTTON_ID) {
			const problemDescription = forms.getValue(tsdom.getHtmlTextAreaElementById('description-text'))!.toString();
			const userAgent = googUserAgent.getUserAgentString();
			const requestData = {
				problemDescription,
				userAgent
			};
			this.client.generateSupportRequest(requestData).then(() => this.client.downloadSupportRequest());
		}
	}
}
