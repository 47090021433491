import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import * as LinkTemplate from 'soy/commons/LinkTemplate.soy.generated';
import { defaultCompare } from 'ts-closure-library/lib/array/array';
import { assert } from 'ts-closure-library/lib/asserts/asserts';
import { isAlpha } from 'ts-closure-library/lib/string/string';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import { UniformPath } from 'ts/commons/UniformPath';
import type { TrackedFindingWithDiffInfoWithCommits } from 'ts/data/TrackedFindingWithDiffInfoWithCommits';
import { SimulinkUtils } from 'ts/perspectives/metrics/simulink/SimulinkUtils';
import type { CommitDescriptor } from 'typedefs/CommitDescriptor';
import type { ElementLocation } from 'typedefs/ElementLocation';
import { EMetricSchemaSource } from 'typedefs/EMetricSchemaSource';
import type { EResourceTypeEntry } from 'typedefs/EResourceType';
import { EType } from 'typedefs/EType';
import type { ImpactedSpecItemsDelta } from 'typedefs/ImpactedSpecItemsDelta';
import type { QualifiedNameLocation } from 'typedefs/QualifiedNameLocation';
import type { TeamscaleIssueId } from 'typedefs/TeamscaleIssueId';
import type { TextRegionLocation } from 'typedefs/TextRegionLocation';
import type { TrackedFinding } from 'typedefs/TrackedFinding';
import { StringUtils } from './StringUtils';

/** ElementLocation with the additional display path. */
type ExtendedElementLocation = ElementLocation & {
	displayPath?: string;
	qualifiedName?: string;
};

/** Utility methods for dealing with paths and locations */
export class PathUtils {
	/** Special folder prefix for the architecture data. */
	public static ARCHITECTURE_STORAGE_PREFIX = '-architectures-';

	/** The expected separator for packages in paths. */
	public static PACKAGE_SEPARATOR = '/';

	/** Width of an uppercase letter if a lowercase letter has width 1. */
	private static readonly UPPERCASE_CHAR_WIDTH = 1.175;

	/** Number of characters to show for a very short version of a uniform path. */
	public static readonly SHORT_LENGTH = 30;

	/** Default number of chars for the shortened path. */
	private static readonly DEFAULT_MAX_CHARS = 50;

	/** Number of characters to show for a path that should be a bit longer than the default. */
	public static readonly LONGER_LENGTH = 60;

	/** The unicode character for three dots (also called 'horizontal ellipsis'). */
	public static UNICODE_DOTDOTDOT_CHARACTER = '\u2026';

	/** Maximum number of chars for the shortened uniform path. */
	private static readonly UNIFORM_PATH_NORMAL_LENGTH = 120;

	/** Minimum number of chars for the shortened uniform path. */
	public static UNIFORM_PATH_SHORT_LENGTH = 70;

	/** The length of uniform paths formatted for findings churns. */
	private static readonly UNIFORM_PATH_FINDINGS_CHURN_LENGTH = 38;

	/** The separator for the case that the architecture is file-based. */
	public static FILE_BASED_ARCHITECTURE_SEPARATOR = '/';

	/** The separator for the case that the architecture is not file-based. */
	public static TYPE_BASED_ARCHITECTURE_SEPARATOR = '.';

	/** Extension for architecture files. */
	public static ARCHITECTURE_FILE_EXTENSION = '.architecture';

	/** Name prefix for non-code metrics */
	public static NON_CODE_NAME_PREFIX = '[Non-Code]';

	/** Name prefix for test metrics */
	public static TEST_NAME_PREFIX = '[Tests]';

	/** Name prefix for issue metrics */
	public static ISSUE_METRIC_NAME_PREFIX = '[Issues]';

	/** Name prefix for test query metrics */
	public static TEST_QUERY_METRIC_NAME_PREFIX = '[Test Queries]';

	/** Name prefix for spec item metrics */
	public static SPEC_ITEM_METRIC_NAME_PREFIX = '[Spec Items]';

	/** Error message displayed when a project's path is inconsistent with some selected threshold. */
	public static INCONSISTENT_PATH_THRESHOLD_ERROR = "Settings for Path & Metric Threshold Configuration don't match";

	/** Error message displayed when selected threshold has no metrics. */
	public static NO_METRIC_SELECTED_THRESHOLD = 'Selected threshold configuration does not provide any metrics';

	/** Threshold configuration name for the sidebar. */
	public static SIDEBAR_THRESHOLD_CONFIGURATION = 'Sidebar Default';

	/** Threshold configuration name for non code metrics. */
	public static NONCODE_THRESHOLD_CONFIGURATION = 'Non Code Default';

	/** Threshold configuration name for issue metrics. */
	public static ISSUE_THRESHOLD_CONFIGURATION = 'Issue Default';

	/** Threshold configuration name for spec item metrics. */
	public static SPEC_ITEM_THRESHOLD_CONFIGURATION = 'Specification Item Default';

	/** Threshold configuration name for test implementation metrics. */
	public static TEST_IMPLEMENTATION_DEFAULT_THRESHOLD_CONFIGURATION = 'Test Implementation Default';

	/** Threshold configuration name for test execution metrics. */
	public static TEST_EXECUTION_DEFAULT_THRESHOLD_CONFIGURATION = 'Test Execution Default';

	/** Threshold configuration name for test query metrics. */
	public static TEST_QUERY_DEFAULT_THRESHOLD_CONFIGURATION = 'Test Query Default';

	/** Teamscale default threshold configuration. */
	public static TEAMSCALE_DEFAULT_THRESHOLD_CONFIGURATION = 'Teamscale Default';

	/** Project default threshold configuration. */
	public static PROJECT_DEFAULT_THRESHOLD_CONFIGURATION = 'Project Default';

	private static readonly DEFAULT_CONFIGURATIONS = [
		PathUtils.NONCODE_THRESHOLD_CONFIGURATION,
		PathUtils.SPEC_ITEM_THRESHOLD_CONFIGURATION,
		PathUtils.ISSUE_THRESHOLD_CONFIGURATION,
		PathUtils.TEST_QUERY_DEFAULT_THRESHOLD_CONFIGURATION,
		PathUtils.TEST_IMPLEMENTATION_DEFAULT_THRESHOLD_CONFIGURATION,
		PathUtils.TEST_EXECUTION_DEFAULT_THRESHOLD_CONFIGURATION,
		PathUtils.TEAMSCALE_DEFAULT_THRESHOLD_CONFIGURATION,
		PathUtils.PROJECT_DEFAULT_THRESHOLD_CONFIGURATION
	];

	/** Returns the max chars or a the default max chars if not defined or null. */
	private static determineMaxChars(maxChars?: number | null): number {
		if (maxChars != null) {
			return maxChars;
		}
		return PathUtils.UNIFORM_PATH_SHORT_LENGTH;
	}

	/**
	 * Adds a 'displayPath' attribute (in-place) to all findings with a human-readable version of the path.
	 *
	 * @param findings The list of findings to shorten the display paths for
	 */
	public static setDisplayPaths(findings: TrackedFinding[]): void {
		for (const finding of findings) {
			PathUtils.setDisplayPath(finding.location);
		}
	}

	/**
	 * Adds a 'displayPath' attribute (in-place) to the finding and all its sibling locations with a human-readable
	 * version of the path.
	 */
	public static setDisplayPathsForSiblings(finding: TrackedFinding): void {
		PathUtils.setDisplayPath(finding.location);
		if (finding.siblingLocations) {
			for (const siblingLocation of finding.siblingLocations) {
				PathUtils.setDisplayPath(siblingLocation);
			}
		}
	}

	/**
	 * Adds a shortened path to the given location object.
	 *
	 * @param location The Location to which the displayPath will be added.
	 */
	public static setDisplayPath(location: ElementLocation): void {
		(location as ExtendedElementLocation).displayPath = PathUtils.getPath(location);
	}

	/**
	 * Shortens a given path string. Tries to preserve as many outer parts of the path fully. If not possible adds
	 * ellipsis in the middle.
	 *
	 * @deprecated For React code use CollapsingTeamscaleLink instead as this is responsive
	 * @param path The path to shorten.
	 * @param optMaxChars
	 * @returns The shortened path string.
	 */
	public static shortenPathString(path: string, optMaxChars?: number | null): string {
		const maxChars = PathUtils.determineMaxChars(optMaxChars);
		path = StringUtils.unEscape(path);
		const estimatedLength = PathUtils.estimateDisplayedTextLength(path);
		if (estimatedLength <= maxChars) {
			return path;
		}
		const packageSeparator = path.lastIndexOf(PathUtils.PACKAGE_SEPARATOR);
		if (packageSeparator < 0) {
			return PathUtils.ellipsisMiddle(path, maxChars);
		}
		const fileName = path.substring(packageSeparator + 1);
		const fileChars = PathUtils.estimateDisplayedTextLength(fileName);
		const dotsAndSeparatorCharCount = 2 * PathUtils.UPPERCASE_CHAR_WIDTH;
		if (fileChars >= maxChars - dotsAndSeparatorCharCount) {
			return (
				PathUtils.UNICODE_DOTDOTDOT_CHARACTER +
				PathUtils.PACKAGE_SEPARATOR +
				PathUtils.ellipsisMiddle(fileName, maxChars - dotsAndSeparatorCharCount)
			);
		}
		const parentPath = path.substring(0, packageSeparator);
		return (
			PathUtils.ellipsisMiddle(parentPath, maxChars - PathUtils.UPPERCASE_CHAR_WIDTH - fileChars) +
			PathUtils.PACKAGE_SEPARATOR +
			fileName
		);
	}

	/**
	 * Replaces the middle part of a text by an ellipsis, preserving a specified number of characters at the front and
	 * end. May only be called if the estimated length of the text is longer than the given number of max chars, i.e. an
	 * ellipsis is actually needed.
	 *
	 * @param text The text to shorten.
	 * @param maxChars The maximum number of chars allowed.
	 * @returns The shortened text with an ellipse in the middle.
	 */
	private static ellipsisMiddle(text: string, maxChars: number): string {
		// Subtracting the width of the ellipsis because we always add it and it's
		// considered an uppercase letter.
		let currentMaximum = maxChars - PathUtils.UPPERCASE_CHAR_WIDTH;
		let prefix = '';
		let suffix = '';
		let start = 0;
		let end = text.length - 1;
		while (start < end) {
			const nextSuffixChar = text[end]!;
			currentMaximum -= PathUtils.estimateLengthOfCharacter(nextSuffixChar);
			if (currentMaximum >= 0) {
				suffix = nextSuffixChar + suffix;
				end--;
			} else {
				break;
			}
			if (start > end) {
				break;
			}
			const nextPrefixChar = text[start]!;
			currentMaximum -= PathUtils.estimateLengthOfCharacter(nextPrefixChar);
			if (currentMaximum >= 0) {
				prefix = prefix + nextPrefixChar;
				start++;
			} else {
				break;
			}
		}
		return prefix + PathUtils.UNICODE_DOTDOTDOT_CHARACTER + suffix;
	}

	/**
	 * Estimates the displayed length of a given path by weighting each lowercase character by a factor of '1' and each
	 * uppercase character by a factor of 1 + <code>UPPERCASE_CHAR_WIDTH</code>.
	 *
	 * @param path The path whose length to estimate.
	 * @returns The weighted width in characters.
	 */
	private static estimateDisplayedTextLength(path: string): number {
		return Array.from(path).reduce(
			(currentEstimate, nextChar) => currentEstimate + PathUtils.estimateLengthOfCharacter(nextChar),
			0
		);
	}

	/**
	 * Returns the estimated lower case letter length of the character. One lowercase letter has length 1.
	 *
	 * @param character Exactly one character as a string
	 * @returns 1 for lowercase letters and 1 + PathUtils.UPPERCASE_CHAR_WIDTH otherwise.
	 */
	private static estimateLengthOfCharacter(character: string): number {
		assert(StringUtils.unicodeLength(character) === 1);
		if (isAlpha(character) && character.toLowerCase() === character) {
			return 1;
		}
		return PathUtils.UPPERCASE_CHAR_WIDTH;
	}

	/**
	 * Returns the displayPath of the given location, or the location, if the displayPath is not yet set.
	 *
	 * @param location The location from which the path is fetched.
	 * @param prefixToRemove If given makes the uniform path of the location relative to given sub path.
	 * @returns The path of the given location.
	 */
	public static getPath(
		location: ElementLocation | TextRegionLocation | QualifiedNameLocation,
		prefixToRemove?: string
	): string {
		let path = location.uniformPath;
		if (!StringUtils.isEmptyOrWhitespace(prefixToRemove)) {
			if (path === prefixToRemove) {
				// We are at the file level; show file name nevertheless
				path = StringUtils.getLastPart(path, '/');
			} else {
				path = StringUtils.stripPrefix(path, StringUtils.ensureEndsWith(prefixToRemove, '/'));
			}
		}
		if ('qualifiedName' in location && !SimulinkUtils.isLocationForRootFinding(location)) {
			// Location is a QualifiedNameLocation for a block inside the current model (i.e., not for the model itself)
			return StringUtils.transformSimulinkBlockIdentifierCosmetically(path + ':' + location.qualifiedName);
		}

		return path;
	}

	/**
	 * Returns the displayPath of the given location with raw line start/end information, or the location, if the
	 * displayPath is not yet set.
	 *
	 * @param location The location from which the path is fetched.
	 * @param prefixToRemove If given makes the uniform path of the location relative to given sub path.
	 * @returns The path of the given location.
	 */
	public static getPathWithRawLineLocation(
		location: ElementLocation | TextRegionLocation | QualifiedNameLocation,
		prefixToRemove?: string
	): string {
		let readableLocation = PathUtils.getPath(location, prefixToRemove);
		if ('rawStartLine' in location) {
			readableLocation += ':' + location.rawStartLine;
			if (location.rawStartLine !== location.rawEndLine) {
				readableLocation += '-' + location.rawEndLine;
			}
		}
		return readableLocation;
	}

	/**
	 * Shortens paths in a given array of logFileEntries.
	 *
	 * @param logFileEntries The LogFileEntries to work on.
	 * @param uniformPathNormalLength Optional value (character count) to be used for truncating 'normal' uniform paths
	 *   (no move/copy) instead of PathUtils.UNIFORM_PATH_NORMAL_LENGTH
	 */
	public static shortenPathsInLogFileEntries<
		T extends { uniformPath?: string; originPath?: string; changeType: string }
	>(
		logFileEntries: T[],
		uniformPathNormalLength?: number
	): Array<ExtendTypeWith<T, { shortenedUniformPath?: string; shortenedOriginPath?: string }>> {
		let uniformPathShortenedLength = uniformPathNormalLength || PathUtils.UNIFORM_PATH_NORMAL_LENGTH;
		if (PathUtils.hasMoveOrCopyEntry(logFileEntries)) {
			uniformPathShortenedLength = PathUtils.UNIFORM_PATH_SHORT_LENGTH;
		}
		for (const entry of logFileEntries) {
			if (entry.originPath) {
				Object.assign(entry, {
					shortenedOriginPath: PathUtils.shortenPathString(entry.originPath, PathUtils.DEFAULT_MAX_CHARS)
				});
			}
			if (entry.uniformPath) {
				Object.assign(entry, {
					shortenedUniformPath: PathUtils.shortenPathString(entry.uniformPath, uniformPathShortenedLength)
				});
			}
		}
		return logFileEntries as Array<
			ExtendTypeWith<T, { shortenedUniformPath?: string; shortenedOriginPath?: string }>
		>;
	}

	/**
	 * Determines if the entries contain move or copy change type log.
	 *
	 * @param logFileEntries The LogFileEntries to work on.
	 */
	private static hasMoveOrCopyEntry(logFileEntries: Array<{ changeType: string }>): boolean {
		for (let i = 0; i < logFileEntries.length; i++) {
			if (logFileEntries[i]!.changeType === 'MOVE' || logFileEntries[i]!.changeType === 'COPY') {
				return true;
			}
		}
		return false;
	}

	/**
	 * Removes the architecture storage prefix (-architecture-) from the given path. If the prefix is followed by '/',
	 * it is removed as well.
	 *
	 * @param path The path
	 */
	public static removeArchitectureStorage(path: string): string {
		path = path.replace(PathUtils.ARCHITECTURE_STORAGE_PREFIX + PathUtils.PACKAGE_SEPARATOR, '');
		path = path.replace(PathUtils.ARCHITECTURE_STORAGE_PREFIX, '');
		return path;
	}

	/**
	 * Returns the file name of the given path.
	 *
	 * @param path The path
	 */
	public static getFileName(path: string): string {
		return path.substring(path.lastIndexOf('/') + 1);
	}

	/** Constructs the link to the file for the given finding. */
	public static getLinkToFile(
		project: string,
		finding: TrackedFinding | TrackedFindingWithDiffInfoWithCommits,
		commit: UnresolvedCommitDescriptor | CommitDescriptor | null | undefined,
		location = finding.location
	): string {
		return LinkTemplate.file({
			identifier:
				'qualifiedName' in location ? StringUtils.removeLastPart(location.qualifiedName, '/') : undefined,
			highlight: 'qualifiedName' in location ? StringUtils.getLastPart(location.qualifiedName, '/') : undefined,
			selection:
				'rawStartLine' in location
					? { startLine: location.rawStartLine, endLine: location.rawEndLine }
					: undefined,
			uniformPath: location.uniformPath,
			commit:
				!commit && finding.death
					? UnresolvedCommitDescriptor.getPreviousCommit(UnresolvedCommitDescriptor.wrap(finding.death))
					: commit,
			project
		});
	}

	/**
	 * Comparison function for path strings. Can be used to sort files and folders with appended deep links.
	 *
	 * This function assumes that directories end with '/', so a folder named 'foo' must be represented as 'foo/'.
	 *
	 * This function puts folders before files and sorts lexicographically and case-insensitive within these groups.
	 *
	 * This function can be deleted if all relevant tables that use the pathComparatorWithPackageSeparator function are
	 * migrated to React and switch to pathComparator.
	 *
	 * @param a The first path string.
	 * @param b The second path string.
	 * @returns -1, 0 or 1 depending on whether a is less than, equal or greater than b.
	 */
	public static pathComparatorWithPackageSeparator(a: string, b: string): number {
		const aIsDir = a.endsWith(PathUtils.PACKAGE_SEPARATOR);
		const bIsDir = b.endsWith(PathUtils.PACKAGE_SEPARATOR);
		if (aIsDir !== bIsDir) {
			if (aIsDir) {
				return -1;
			}
			return 1;
		}
		return defaultCompare(a.toLowerCase(), b.toLowerCase());
	}

	/**
	 * Comparison function for path strings. Can be used to sort files and folders with appended deep links.
	 *
	 * This function uses the resource types explicitly to differentiate between files and folders.
	 *
	 * This function puts folders before files and sorts lexicographically and case-insensitive within these groups.
	 */
	public static pathComparator(
		pathA: string,
		resourceTypeA: EResourceTypeEntry,
		pathB: string,
		resourceTypeB: EResourceTypeEntry
	): number {
		const aIsDir = resourceTypeA === 'CONTAINER';
		const bIsDir = resourceTypeB === 'CONTAINER';
		if (aIsDir !== bIsDir) {
			if (aIsDir) {
				return -1;
			}
			return 1;
		}
		return defaultCompare(pathA.toLowerCase(), pathB.toLowerCase());
	}

	/** All non code related metric schema sources and their default threshold configurations. */
	private static readonly SCHEMA_AND_DEFAULT_THRESHOLD_CONFIG: Array<[EMetricSchemaSource, string]> = [
		[EMetricSchemaSource.NON_CODE_METRICS, PathUtils.NONCODE_THRESHOLD_CONFIGURATION],
		[EMetricSchemaSource.SPEC_ITEM_METRICS, PathUtils.SPEC_ITEM_THRESHOLD_CONFIGURATION],
		[EMetricSchemaSource.ISSUE_METRICS, PathUtils.ISSUE_THRESHOLD_CONFIGURATION],
		[
			EMetricSchemaSource.TEST_IMPLEMENTATION_METRICS,
			PathUtils.TEST_IMPLEMENTATION_DEFAULT_THRESHOLD_CONFIGURATION
		],
		[EMetricSchemaSource.TEST_EXECUTION_METRICS, PathUtils.TEST_EXECUTION_DEFAULT_THRESHOLD_CONFIGURATION],
		[EMetricSchemaSource.TEST_QUERY_METRICS, PathUtils.TEST_QUERY_DEFAULT_THRESHOLD_CONFIGURATION]
	];

	/**
	 * Returns a default threshold configuration which is consistent with the given path. E.g., 'Test Default' for a
	 * test path.
	 */
	public static getDefaultThresholdConfigForPath(path: string): string {
		const schemaAndThresholdConfig = PathUtils.SCHEMA_AND_DEFAULT_THRESHOLD_CONFIG.find(([schema]) =>
			PathUtils.hasPathPrefix(path, schema)
		);
		if (schemaAndThresholdConfig != null) {
			return schemaAndThresholdConfig[1];
		}
		return PathUtils.PROJECT_DEFAULT_THRESHOLD_CONFIGURATION;
	}

	private static hasPathPrefix(path: string, schema: EMetricSchemaSource) {
		return path.startsWith(schema.pathPrefix);
	}

	/**
	 * Returns true if selected values of project path and threshold are consistent. For instance, a non code path must
	 * always go with the non-code metric threshold config.
	 *
	 * @param projectPath Existing path in analyzed project
	 * @param threshold Selected metric threshold configuration
	 */
	public static arePathAndThresholdConsistent(projectPath: string, threshold: string): boolean {
		const isSpecialPathAndThreshold = PathUtils.SCHEMA_AND_DEFAULT_THRESHOLD_CONFIG.some(
			([schema, defaultThresholdConfiguration]) =>
				PathUtils.hasPathPrefix(projectPath, schema) &&
				PathUtils.isAllowedDefaultOrCustomConfiguration(threshold, defaultThresholdConfiguration)
		);

		if (isSpecialPathAndThreshold) {
			return true;
		}

		const isCodeOrArchitecturePath = !PathUtils.SCHEMA_AND_DEFAULT_THRESHOLD_CONFIG.some(([schema]) =>
			PathUtils.hasPathPrefix(projectPath, schema)
		);
		const isCodeOrArchitectureThresholdConfig = PathUtils.isAllowedDefaultOrCustomConfiguration(
			threshold,
			PathUtils.TEAMSCALE_DEFAULT_THRESHOLD_CONFIGURATION,
			PathUtils.PROJECT_DEFAULT_THRESHOLD_CONFIGURATION
		);

		return isCodeOrArchitecturePath && isCodeOrArchitectureThresholdConfig;
	}

	private static isAllowedDefaultOrCustomConfiguration(
		configuration: string,
		...allowedDefaultConfigurations: string[]
	): boolean {
		return (
			allowedDefaultConfigurations.includes(configuration) ||
			!PathUtils.DEFAULT_CONFIGURATIONS.includes(configuration)
		);
	}

	/** Shortens the paths in the spec item references for display in merge request detail view. */
	public static shortenSpecItemDeltaPaths(impactedSpecItemsDelta: ImpactedSpecItemsDelta): void {
		const specItemReferenceDiffs = impactedSpecItemsDelta.specItemReferenceDiffs;
		specItemReferenceDiffs.forEach(ref =>
			Object.assign(ref, { shortenedPath: PathUtils.shortenPathString(ref.uniformPath) })
		);

		const architectureComponentSpecItemDelta = impactedSpecItemsDelta.architectureComponentImpactedItems;
		architectureComponentSpecItemDelta.forEach(delta => {
			const shortenedPaths = delta.fileUniformPaths.map(PathUtils.shortenPathString);
			Object.assign(delta, { shortenedPaths });
		});
	}

	/**
	 * Strips away the -test-execution-/ prefix of a test execution path and removes escaping so that the result matches
	 * the test id the test runner gave us.
	 */
	public static convertToTestId(testExecutionPath: string | null): string {
		if (testExecutionPath == null) {
			return '';
		}

		return StringUtils.unEscape(StringUtils.stripPrefix(testExecutionPath, EType.TEST_EXECUTION.prefix + '/'));
	}

	/** Converts the given test uniform path to a test ID, appends the duration, and transforms it to be CSV conform. */
	public static convertToCsvConformParetoTestLine(
		testExecutionPath: string | null,
		durationInMs: number | undefined
	) {
		const escapedTestId = PathUtils.convertToCsvConformTestId(testExecutionPath);
		const formattedDuration = durationInMs === undefined ? '' : durationInMs + 'ms';
		return [escapedTestId, formattedDuration].join(',');
	}

	/** Converts the given test uniform path to a test ID and transforms it to be CSV conform. */
	public static convertToCsvConformTestId(testExecutionPath: string | null): string {
		if (testExecutionPath == null) {
			return '';
		}
		const unescapedTestId = this.convertToTestId(testExecutionPath);
		const testIdWithEscapedQuotationMarks = StringUtils.replaceAll(unescapedTestId, '"', '""');

		return '"' + testIdWithEscapedQuotationMarks + '"';
	}

	/** Checks whether a uniform path refers to spec items. */
	public static isSpecItemPath(uniformPath: string): boolean {
		return uniformPath.startsWith(EType.SPEC_ITEM.prefix);
	}

	/** Checks whether the given uniform path segments refer to a single spec item. */
	private static areSpecItemPathSegments(segments: string[]) {
		return segments.length === 3 && segments[0]!.startsWith(EType.SPEC_ITEM.prefix);
	}

	/**
	 * Check whether a uniform path refers to a spec item from a spec item query result (e.g.
	 * -spec-items-/my/nice/query/requirements1|DP-12345)
	 */
	public static isSpecItemPathFromQuery(uniformPath: string): boolean {
		const segments = UniformPath.splitIntoSegments(uniformPath);
		return this.areSpecItemPathSegmentsFromQuery(segments);
	}

	/**
	 * Check whether the uniform path segments refer to a spec item from a spec item query result (e.g.
	 * -spec-items-/my/nice/query/requirements1|DP-12345)
	 */
	private static areSpecItemPathSegmentsFromQuery(segments: string[]) {
		return (
			segments.length > 1 &&
			segments[0]!.startsWith(EType.SPEC_ITEM_QUERY.prefix) &&
			segments[segments.length - 1]!.includes('|')
		);
	}

	/**
	 * Parses a TeamscaleIssueId from the provided uniformPath. The implementation supports two types of uniformPaths:
	 *
	 * 1. SpecItem specific path, like: -spec-item-/requirements1/DP-12345
	 * 2. Spec Item query path, with a matched spec item id, like: -spec-items-/my/nice/query/requirements1|DP-12345
	 *
	 * If the uniformPath does not reference a specific spec item, the result is null.
	 */
	public static parseTeamscaleIssueId(uniformPath: string): TeamscaleIssueId | null {
		const segments = UniformPath.splitIntoSegments(uniformPath);
		if (this.areSpecItemPathSegments(segments)) {
			const connectorId = segments[1]!;
			const externalId = segments[2]!;
			return {
				connectorId,
				externalId,
				internalId: `${connectorId}|${externalId}`
			};
		} else if (this.areSpecItemPathSegmentsFromQuery(segments)) {
			const internalId: string = segments[segments.length - 1]!;
			const separatorIndex = internalId.indexOf('|');
			const connectorId = internalId.slice(0, separatorIndex);
			const externalId = internalId.slice(separatorIndex + 1);
			return {
				connectorId,
				externalId,
				internalId
			};
		}

		return null;
	}
}
