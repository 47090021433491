// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/commons/TruncatedDescriptionTemplate.soy

/**
 * @fileoverview Templates in namespace ts.commons.TruncatedDescriptionTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';


/**
 * @param {!$truncatedDescription.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $truncatedDescription = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $truncatedDescription$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.formattedDescription);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} formattedDescription
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $truncatedDescription$ = function($$areYouAnInternalCaller, $ijData, formattedDescription) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.TruncatedDescriptionTemplate.truncatedDescription']) {
    return soy.$$stubsMap['ts.commons.TruncatedDescriptionTemplate.truncatedDescription']({formattedDescription: formattedDescription}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.TruncatedDescriptionTemplate.truncatedDescription ts/commons/TruncatedDescriptionTemplate.soy:25"' : '') + '><div class="truncated-description-container"><div class="inline-code-container finding-description truncated-description">' + soy.$$escapeHtml(formattedDescription) + '</div><div class="truncated-gradient"></div></div><div class="description-more-box"><a class="description-more-link ui secondary button" href="#">Read more</a><a class="description-less-link ui secondary button" href="#">Show less</a></div></div>');
};
export { $truncatedDescription as truncatedDescription };
export { $truncatedDescription$ as truncatedDescription$ };
/**
 * @typedef {{
 *  formattedDescription: ?,
 * }}
 */
$truncatedDescription.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $truncatedDescription.soyTemplateName = 'ts.commons.TruncatedDescriptionTemplate.truncatedDescription';
}
