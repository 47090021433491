/** Generated from EGlobalPermission.java. Do not modify this file! */


export type EGlobalPermissionEntry = 'ACCESS_ADMINISTRATIVE_SERVICES' | 'ADMIN_DASHBOARDS' | 'BACKUP' | 'CREATE_PROJECTS' | 'CREATE_USERS' | 'CREATE_GROUPS' | 'CREATE_ANALYSIS_PROFILES' | 'CREATE_QUALITY_REPORTS' | 'CREATE_METRIC_THRESHOLD_CONFIGURATIONS' | 'CREATE_EXTERNAL_TOOL_ACCOUNTS' | 'CREATE_EXTERNAL_STORAGE_BACKENDS' | 'CREATE_SUPPORT_REQUEST' | 'EDIT_EXTERNAL_METRICS_SCHEMA' | 'EDIT_EXTERNAL_FINDINGS_SCHEMA' | 'EDIT_GLOBAL_NOTIFICATION_SETTINGS' | 'EDIT_SERVER_OPTIONS' | 'EDIT_GLOBAL_ROLES' | 'EDIT_ROLES' | 'VIEW_SYSTEM_STATUS';

export class EGlobalPermission {
	static readonly ACCESS_ADMINISTRATIVE_SERVICES = new EGlobalPermission(0, 'ACCESS_ADMINISTRATIVE_SERVICES', "Access Administrative Services", "Allow access to administrative services. Mostly affects debug services.");
	static readonly ADMIN_DASHBOARDS = new EGlobalPermission(1, 'ADMIN_DASHBOARDS', "Admin Dashboards", "Allow viewing and editing all dashboards including admin dashboards.");
	static readonly BACKUP = new EGlobalPermission(2, 'BACKUP', "Backup Global Data", "Allow backup of global data like users, groups and permissions.");
	static readonly CREATE_PROJECTS = new EGlobalPermission(3, 'CREATE_PROJECTS', "Create Projects", "Allow creation of new projects. The creator has the project administrator role for the project upon creation.");
	static readonly CREATE_USERS = new EGlobalPermission(4, 'CREATE_USERS', "Create Users", "Allow creation of users. The creator has the owner role for the user upon creation.");
	static readonly CREATE_GROUPS = new EGlobalPermission(5, 'CREATE_GROUPS', "Create Groups", "Allow creation of groups. The creator has the owner role for the group upon creation.");
	static readonly CREATE_ANALYSIS_PROFILES = new EGlobalPermission(6, 'CREATE_ANALYSIS_PROFILES', "Create Analysis Profiles", "Allow creation of analysis profiles. The creator has the owner role for the analysis profile upon creation.");
	static readonly CREATE_QUALITY_REPORTS = new EGlobalPermission(7, 'CREATE_QUALITY_REPORTS', "Create Quality Reports", "Allow creation of quality reports. The creator has the owner role for the quality reports upon creation.");
	static readonly CREATE_METRIC_THRESHOLD_CONFIGURATIONS = new EGlobalPermission(8, 'CREATE_METRIC_THRESHOLD_CONFIGURATIONS', "Create Metric Threshold Configurations", "Allow creation of metric threshold configurations. The creator has the owner role for the metric threshold configuration upon creation.");
	static readonly CREATE_EXTERNAL_TOOL_ACCOUNTS = new EGlobalPermission(9, 'CREATE_EXTERNAL_TOOL_ACCOUNTS', "Create External Accounts", "Allow creation of new external accounts for repository connectors, issue trackers, and so on. The creator has the owner role for the account upon creation.");
	static readonly CREATE_EXTERNAL_STORAGE_BACKENDS = new EGlobalPermission(10, 'CREATE_EXTERNAL_STORAGE_BACKENDS', "Configure External Storage Backends", "Allow configuring new external storage backends, which are used for storing external analysis results uploaded to Teamscale using the REST API. The creator has the owner role for the account upon creation.");
	static readonly CREATE_SUPPORT_REQUEST = new EGlobalPermission(11, 'CREATE_SUPPORT_REQUEST', "Create Support Request", "Allow creating and downloading a support request.");
	static readonly EDIT_EXTERNAL_METRICS_SCHEMA = new EGlobalPermission(12, 'EDIT_EXTERNAL_METRICS_SCHEMA', "Edit External Metrics Schema", "Allow editing the external metrics schema.");
	static readonly EDIT_EXTERNAL_FINDINGS_SCHEMA = new EGlobalPermission(13, 'EDIT_EXTERNAL_FINDINGS_SCHEMA', "Edit External Findings Schema", "Allow editing external findings and finding groups.");
	static readonly EDIT_GLOBAL_NOTIFICATION_SETTINGS = new EGlobalPermission(14, 'EDIT_GLOBAL_NOTIFICATION_SETTINGS', "Edit Global Notification Settings", "Allow editing global notification settings.");
	static readonly EDIT_SERVER_OPTIONS = new EGlobalPermission(15, 'EDIT_SERVER_OPTIONS', "Edit Server Options", "Allow editing server options via the settings page of the admin perspective.");
	static readonly EDIT_GLOBAL_ROLES = new EGlobalPermission(16, 'EDIT_GLOBAL_ROLES', "Assign Global Roles", "Allow assigning global roles to users or groups.");
	static readonly EDIT_ROLES = new EGlobalPermission(17, 'EDIT_ROLES', "Edit Roles", "Allow editing the permissions of global- and project roles. Note that a role edit affects all role assignments of that role.");
	static readonly VIEW_SYSTEM_STATUS = new EGlobalPermission(18, 'VIEW_SYSTEM_STATUS', "View System Status", "Allow viewing the system status via the system perspective.");
	static readonly values = [
		EGlobalPermission.ACCESS_ADMINISTRATIVE_SERVICES,
		EGlobalPermission.ADMIN_DASHBOARDS,
		EGlobalPermission.BACKUP,
		EGlobalPermission.CREATE_PROJECTS,
		EGlobalPermission.CREATE_USERS,
		EGlobalPermission.CREATE_GROUPS,
		EGlobalPermission.CREATE_ANALYSIS_PROFILES,
		EGlobalPermission.CREATE_QUALITY_REPORTS,
		EGlobalPermission.CREATE_METRIC_THRESHOLD_CONFIGURATIONS,
		EGlobalPermission.CREATE_EXTERNAL_TOOL_ACCOUNTS,
		EGlobalPermission.CREATE_EXTERNAL_STORAGE_BACKENDS,
		EGlobalPermission.CREATE_SUPPORT_REQUEST,
		EGlobalPermission.EDIT_EXTERNAL_METRICS_SCHEMA,
		EGlobalPermission.EDIT_EXTERNAL_FINDINGS_SCHEMA,
		EGlobalPermission.EDIT_GLOBAL_NOTIFICATION_SETTINGS,
		EGlobalPermission.EDIT_SERVER_OPTIONS,
		EGlobalPermission.EDIT_GLOBAL_ROLES,
		EGlobalPermission.EDIT_ROLES,
		EGlobalPermission.VIEW_SYSTEM_STATUS
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EGlobalPermissionEntry,
		public readonly readableName: string,
		public readonly description: string
	){
	}

	toString() {
		return this.name;
	}
}

