// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/base/scaffolding/PerspectiveBaseTemplate.soy

/**
 * @fileoverview Templates in namespace ts.base.scaffolding.PerspectiveBaseTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';


/**
 * @param {!$error.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $error = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $error$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.status, opt_data.technicalErrorSummary, opt_data.statusText, opt_data.technicalErrorDescription, opt_data.timeMilliSecs);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} status
 * @param {?=} technicalErrorSummary
 * @param {?=} statusText
 * @param {?=} technicalErrorDescription
 * @param {?=} timeMilliSecs
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $error$ = function($$areYouAnInternalCaller, $ijData, status, technicalErrorSummary, statusText, technicalErrorDescription, timeMilliSecs) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.base.scaffolding.PerspectiveBaseTemplate.error']) {
    return soy.$$stubsMap['ts.base.scaffolding.PerspectiveBaseTemplate.error']({status: status, technicalErrorSummary: technicalErrorSummary, statusText: statusText, technicalErrorDescription: technicalErrorDescription, timeMilliSecs: timeMilliSecs}, $ijData);
  }
  let $tmp;
  if (soy.$$equals(status, 404)) {
    $tmp = $baseErrorTemplate$(soy.$$internalCallMarkerDoNotUse, $ijData, 'the page you requested does not exist.');
  } else if (soy.$$equals(status, 403)) {
    $tmp = $baseErrorTemplate$(soy.$$internalCallMarkerDoNotUse, $ijData, 'you are missing permissions for the requested operation: ' + statusText);
  } else {
    $tmp = '<div class="ui error message" style="margin: 10px 30px 0px 10px; overflow: auto;"><h4>&#9785; ' + soy.$$escapeHtml(technicalErrorSummary) + '</h4><br><a id="toggler-' + soy.$$escapeHtmlAttribute(timeMilliSecs) + '" class="error-toggler" href="#" style="font-weight: bold;"><i id="icon-' + soy.$$escapeHtmlAttribute(timeMilliSecs) + '" class="angle double down icon"></i> Details</a><div id="errorbody-' + soy.$$escapeHtmlAttribute(timeMilliSecs) + '"><br>The service call failed with an error.<br>Error code ' + soy.$$escapeHtml(status) + ': ' + soy.$$escapeHtml(statusText) + '<br><br><h4>' + $supportDialogLink(null, $ijData) + '</h4><h4 style="margin-top: 20px;">Technical Description</h4><pre style="margin-top: 5px;">' + soy.$$escapeHtml(technicalErrorDescription) + '</pre></div></div>';
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div style="margin: 100px"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.base.scaffolding.PerspectiveBaseTemplate.error ts/base/scaffolding/PerspectiveBaseTemplate.soy:26"' : '') + '>' + $tmp + '</div>');
};
export { $error as error };
export { $error$ as error$ };
/**
 * @typedef {{
 *  status: ?,
 *  technicalErrorSummary?: (?|undefined),
 *  statusText?: (?|undefined),
 *  technicalErrorDescription?: (?|undefined),
 *  timeMilliSecs?: (?|undefined),
 * }}
 */
$error.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $error.soyTemplateName = 'ts.base.scaffolding.PerspectiveBaseTemplate.error';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $notImpl = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.base.scaffolding.PerspectiveBaseTemplate.notImpl']) {
    return soy.$$stubsMap['ts.base.scaffolding.PerspectiveBaseTemplate.notImpl'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui segment" style="background: magenta"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.base.scaffolding.PerspectiveBaseTemplate.notImpl ts/base/scaffolding/PerspectiveBaseTemplate.soy:70"' : '') + '>Not implemented yet!</div>');
};
export { $notImpl as notImpl };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $notImpl.soyTemplateName = 'ts.base.scaffolding.PerspectiveBaseTemplate.notImpl';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $supportDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.base.scaffolding.PerspectiveBaseTemplate.supportDialog']) {
    return soy.$$stubsMap['ts.base.scaffolding.PerspectiveBaseTemplate.supportDialog'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div style="max-width:600px"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.base.scaffolding.PerspectiveBaseTemplate.supportDialog ts/base/scaffolding/PerspectiveBaseTemplate.soy:78"' : '') + '><p class="lead">In case you encounter problems with Teamscale, you can create a support request and send it to the Teamscale team. Please describe the problem in the text field below. Data about your Teamscale installation will be gathered automatically and attached to the request. This includes information about your operating system, Java and all Teamscale configuration settings. This helps us to analyze your problem more effectively.</p><div class="ui form"><div class="field"><label>Problem description</label><textarea rows="10" style="width: 98%" id="description-text"></textarea></div></div><p class="lead">Please send the generated support request to <a href="mailto:support@teamscale.com?subject=Teamscale%20Support%20Request">support@teamscale.com</a>.</p></div>');
};
export { $supportDialog as supportDialog };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $supportDialog.soyTemplateName = 'ts.base.scaffolding.PerspectiveBaseTemplate.supportDialog';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $supportDialogLink = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.base.scaffolding.PerspectiveBaseTemplate.supportDialogLink']) {
    return soy.$$stubsMap['ts.base.scaffolding.PerspectiveBaseTemplate.supportDialogLink'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<a href="#" class="support-dialog-launcher" style="font-weight: bold;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.base.scaffolding.PerspectiveBaseTemplate.supportDialogLink ts/base/scaffolding/PerspectiveBaseTemplate.soy:109"' : '') + '>Submit a Support Request.</a>');
};
export { $supportDialogLink as supportDialogLink };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $supportDialogLink.soyTemplateName = 'ts.base.scaffolding.PerspectiveBaseTemplate.supportDialogLink';
}


/**
 * @param {!$aboutDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $aboutDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $aboutDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.versionDetails);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} versionDetails
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $aboutDialog$ = function($$areYouAnInternalCaller, $ijData, versionDetails) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.base.scaffolding.PerspectiveBaseTemplate.aboutDialog']) {
    return soy.$$stubsMap['ts.base.scaffolding.PerspectiveBaseTemplate.aboutDialog']({versionDetails: versionDetails}, $ijData);
  }
  soy.assertParamType(typeof versionDetails === 'string', 'versionDetails', versionDetails, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ts-dialog-about"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.base.scaffolding.PerspectiveBaseTemplate.aboutDialog ts/base/scaffolding/PerspectiveBaseTemplate.soy:121"' : '') + '><div class="ui basic center aligned segment"><img class="teamscale-logo" src="images/teamscale-logo.svg"/><div><div class="ui label">' + soy.$$escapeHtml(versionDetails) + '</div></div><h4>Because Code Quality Matters</h4><p>Teamscale supports your team to analyze, monitor, and improve the quality of your code.</p><p><a href="https://www.cqse.eu/en/support/">Questions? Contact us!</a></p></div></div>');
};
export { $aboutDialog as aboutDialog };
export { $aboutDialog$ as aboutDialog$ };
/**
 * @typedef {{
 *  versionDetails: string,
 * }}
 */
$aboutDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $aboutDialog.soyTemplateName = 'ts.base.scaffolding.PerspectiveBaseTemplate.aboutDialog';
}


/**
 * @param {!$shortcutHelp.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $shortcutHelp = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $shortcutHelp$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.shortcuts);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} shortcuts
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $shortcutHelp$ = function($$areYouAnInternalCaller, $ijData, shortcuts) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.base.scaffolding.PerspectiveBaseTemplate.shortcutHelp']) {
    return soy.$$stubsMap['ts.base.scaffolding.PerspectiveBaseTemplate.shortcutHelp']({shortcuts: shortcuts}, $ijData);
  }
  let $output = '<table class="table"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.base.scaffolding.PerspectiveBaseTemplate.shortcutHelp ts/base/scaffolding/PerspectiveBaseTemplate.soy:147"' : '') + '>';
  const shortcut44771List = shortcuts;
  const shortcut44771ListLen = shortcut44771List.length;
  for (let shortcut44771Index = 0; shortcut44771Index < shortcut44771ListLen; shortcut44771Index++) {
    const shortcut44771Data = shortcut44771List[shortcut44771Index];
    $output += '<tr><td style="text-align: right; padding-right: 12px; font-family: monospace">' + soy.$$escapeHtml(shortcut44771Data.shortcut) + '</td><td>' + soy.$$escapeHtml(shortcut44771Data.name) + '</td></tr>';
  }
  $output += '</table>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $shortcutHelp as shortcutHelp };
export { $shortcutHelp$ as shortcutHelp$ };
/**
 * @typedef {{
 *  shortcuts: ?,
 * }}
 */
$shortcutHelp.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $shortcutHelp.soyTemplateName = 'ts.base.scaffolding.PerspectiveBaseTemplate.shortcutHelp';
}


/**
 * @param {!$baseErrorTemplate.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $baseErrorTemplate = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $baseErrorTemplate$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.message);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} message
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $baseErrorTemplate$ = function($$areYouAnInternalCaller, $ijData, message) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.base.scaffolding.PerspectiveBaseTemplate.baseErrorTemplate']) {
    return soy.$$stubsMap['ts.base.scaffolding.PerspectiveBaseTemplate.baseErrorTemplate']({message: message}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui error message"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.base.scaffolding.PerspectiveBaseTemplate.baseErrorTemplate ts/base/scaffolding/PerspectiveBaseTemplate.soy:168"' : '') + '><p style="font-weight: bold; line-height: 35px; font-size: 16px;">Sorry, ' + soy.$$escapeHtml(message) + ' <br>' + $supportDialogLink(null, $ijData) + '</p></div>');
};
export { $baseErrorTemplate as baseErrorTemplate };
export { $baseErrorTemplate$ as baseErrorTemplate$ };
/**
 * @typedef {{
 *  message: ?,
 * }}
 */
$baseErrorTemplate.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $baseErrorTemplate.soyTemplateName = 'ts.base.scaffolding.PerspectiveBaseTemplate.baseErrorTemplate';
}


/**
 * @param {!$infoText.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $infoText = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $infoText$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.message, opt_data.iconClass, opt_data.severity);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} message
 * @param {?=} iconClass
 * @param {?=} severity
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $infoText$ = function($$areYouAnInternalCaller, $ijData, message, iconClass, severity) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.base.scaffolding.PerspectiveBaseTemplate.infoText']) {
    return soy.$$stubsMap['ts.base.scaffolding.PerspectiveBaseTemplate.infoText']({message: message, iconClass: iconClass, severity: severity}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="no-data-available" class="ui ' + (severity ? soy.$$escapeHtmlAttribute(severity) : 'info') + ' message"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.base.scaffolding.PerspectiveBaseTemplate.infoText ts/base/scaffolding/PerspectiveBaseTemplate.soy:189"' : '') + '>' + (iconClass ? '<i class="' + soy.$$escapeHtmlAttribute(iconClass) + '"></i>' : '') + '<span>' + soy.$$escapeHtml(message) + '</span></div>');
};
export { $infoText as infoText };
export { $infoText$ as infoText$ };
/**
 * @typedef {{
 *  message: ?,
 *  iconClass?: (?|undefined),
 *  severity?: (?|undefined),
 * }}
 */
$infoText.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $infoText.soyTemplateName = 'ts.base.scaffolding.PerspectiveBaseTemplate.infoText';
}
