import type { ServiceCallError } from 'api/ServiceCallError';
import type {
	BasicRequestConfig,
	Method,
	RequestPayload,
	RequestWithBodyConfig
} from 'api/ServiceClientImplementation';
import { ServiceClientImplementation } from 'api/ServiceClientImplementation';
import * as strings from 'ts-closure-library/lib/string/string';
import type { Callback } from 'ts/base/Callback';
import type { URLBuilder } from 'ts/base/client/URLBuilder';
import type { CoverageSourceQueryParameters } from 'typedefs/CoverageSourceQueryParameters';
import { EMimeType } from 'typedefs/EMimeType';
import type { ErrorManager } from '../ErrorManager';

/** Error handler interface. */
export type ErrorHandler = (error: ServiceCallError) => void;

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		/** The number of open XHR requests. Is undefined when no XHR call was made. */
		openRequests?: number;
	}
}

/**
 * The service client provides methods for accessing the service interface in a convenient and consistent way. Note that
 * all methods are executed asynchronously and thus require a callback function.
 */
export class ServiceClient {
	private static readonly APPLICATION_X_WWW_FORM_URLENCODED = 'application/x-www-form-urlencoded';

	/** @param errorManager An object that wraps an error handler and performs error management functions. */
	protected constructor(private readonly errorManager: ErrorManager) {}

	private call<T>(
		method: Method,
		url: string,
		config: Partial<RequestWithBodyConfig> = {},
		payload?: RequestPayload
	): Promise<T> {
		const [contentType, body] = ServiceClient.convertToBody(payload);
		return ServiceClientImplementation.call(method, url, { ...config, contentType }, body);
	}

	private static convertToBody(payload: RequestPayload): [string | undefined, XMLHttpRequestBodyInit | undefined] {
		if (payload === undefined || payload === null) {
			return [undefined, undefined];
		} else if (payload instanceof URLSearchParams) {
			return [ServiceClient.APPLICATION_X_WWW_FORM_URLENCODED, payload.toString()];
		} else if (payload instanceof FormData) {
			// The content type is automatically set to multipart/form-data by XMLHttpRequest
			// We must not specify something here
			return [undefined, payload];
		} else {
			return [EMimeType.JSON.type, JSON.stringify(payload)];
		}
	}

	/**
	 * Performs a HEAD call to a URL and returns a resolved promise in case of a successful response and a rejected
	 * Promise otherwise.
	 */
	protected head<T>(url: URLBuilder, config?: Partial<BasicRequestConfig>): Promise<T> {
		return this.call('HEAD', url.getURL(), config);
	}

	/**
	 * Performs a GET call to a URL.
	 *
	 * @param url The url of the endpoint.
	 * @param config The request configuration options.
	 */
	protected get<T>(url: URLBuilder, config?: Partial<BasicRequestConfig>): Promise<T> {
		return this.call('GET', url.getURL(), config);
	}

	/**
	 * Performs a POST call to a URL.
	 *
	 * @param url The url of the endpoint.
	 * @param payload An optional payload to be sent as request body
	 * @param config The request configuration options.
	 */
	protected post<T>(
		url: URLBuilder,
		payload?: RequestPayload,
		config: Partial<RequestWithBodyConfig> = {}
	): Promise<T> {
		return this.call('POST', url.getURL(), config, payload);
	}

	/**
	 * Performs a PUT call to a URL.
	 *
	 * @param url The url of the endpoint.
	 * @param payload An optional payload to be sent as request body
	 * @param config The request configuration options.
	 */
	protected put<T>(
		url: URLBuilder,
		payload?: RequestPayload,
		config: Partial<RequestWithBodyConfig> = {}
	): Promise<T> {
		return this.call('PUT', url.getURL(), config, payload);
	}

	/**
	 * Performs a DELETE call to a URL.
	 *
	 * @param url The url of the endpoint.
	 * @param config The request configuration options.
	 */
	protected delete<T>(url: URLBuilder, config?: Partial<BasicRequestConfig>): Promise<T> {
		return this.call('DELETE', url.getURL(), config);
	}

	/** Downloads a file as blob. */
	public downloadBlob(relativeUrl: string): Promise<Blob> {
		return this.call('GET', relativeUrl, {
			// For a blob download, we need to accept all (not just JSON)
			acceptType: '*/*',
			responseType: 'blob'
		});
	}

	/**
	 * Converts the given object to a URLSearchParams object by rewriting the object's camel-case keys to kebab-case
	 * query parameters and using their values as query values.
	 */
	public static convertToKebabCaseURLSearchParams(
		optionsObject: Record<string, string | string[] | boolean | undefined> | CoverageSourceQueryParameters
	): URLSearchParams {
		const urlSearchParams = new URLSearchParams();
		for (const key in optionsObject) {
			// @ts-ignore
			const value = optionsObject[key]!;
			ServiceClient.appendParameter(urlSearchParams, key, value);
		}
		return urlSearchParams;
	}

	/** Appends an URL parameter, taking into consideration whether it is a single value parameter or an array. */
	private static appendParameter(
		urlSearchParams: URLSearchParams,
		key: string,
		value: string | string[] | boolean | undefined
	): void {
		if (Array.isArray(value)) {
			for (const singleValue of value) {
				urlSearchParams.append(strings.toSelectorCase(key), singleValue);
			}
		} else if (typeof value === 'boolean') {
			urlSearchParams.append(strings.toSelectorCase(key), String(value));
		} else if (typeof value === 'string') {
			urlSearchParams.append(strings.toSelectorCase(key), value);
		}
	}

	/** Returns the error manager. */
	public getErrorManager(): ErrorManager {
		return this.errorManager;
	}

	/**
	 * Wraps the promise so that the callback is called when the promise has been resolved successfully and calls the
	 * default error handler in case something fails.
	 *
	 * @deprecated Use plain Promises instead
	 */
	protected withCallback<T>(promise: Promise<T>, callback: Callback<T>): void {
		promise.then(callback).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns a callback that can be passed the catch clause of a promise.
	 *
	 * @deprecated Handle the error with a catch in the view instead
	 */
	protected getDefaultErrorHandler<T>(): (error: ServiceCallError) => Promise<T> {
		return (error: ServiceCallError): Promise<T> => {
			this.errorManager.handleError(error);
			// Returns a rejected promise as otherwise the next `then` method in the chain would be called.
			return new Promise<T>(() => 0);
		};
	}
}
