import type { QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery as useReactQuery } from '@tanstack/react-query';
import type { Queries } from './ApiDefinition';
import { urlMapping } from './ApiDefinition';
import type { ServiceCallError } from './ServiceCallError';
import type { OperationInfo, RequestParameters } from './ServiceCallOperationHandlerBase';
import { ServiceCallOperationHandlerBase } from './ServiceCallOperationHandlerBase';

/** Query options that are allowed in normal queries and useQueries. */
type AllowedQueryOptions = 'onSuccess' | 'suspense';
type QueryOptions<TData> = Pick<UseQueryOptions<TData, ServiceCallError, TData>, AllowedQueryOptions>;

/** The query operations API. */
export type QueryOperation<TData> = {
	/** The url including the query parameters that corresponds to this operation. */
	readonly url: string;

	/** The query key under, which the results are stored in the query cache. */
	readonly queryKey: QueryKey;

	/**
	 * Performs the actual request and returns a Promise with the deserialized result or throws a ServiceCallError if
	 * the request fails.
	 *
	 * @param signal An optional abort signal which allows to abort the request.
	 */
	fetch: (signal?: AbortSignal) => Promise<TData>;

	/** Returns the query object i.e. to be used with useQueries. */
	query: (
		options?: QueryOptions<TData>
	) => Pick<UseQueryOptions<TData, ServiceCallError, TData>, 'queryKey' | 'queryFn' | AllowedQueryOptions>;

	/**
	 * Calls the useQuery hook to perform the service call. This hook will NOT suspend, but instead returns the loading
	 * and error states via the result.
	 */
	useQuery: (
		options?: Omit<UseQueryOptions<TData, ServiceCallError>, 'queryKey' | 'queryFn'>
	) => UseQueryResult<TData, ServiceCallError>;

	/**
	 * Calls the useQuery hook to perform the service call. This hook will SUSPEND and use the error boundary in case of
	 * an error.
	 */
	useSuspendingQuery: (options?: Omit<UseQueryOptions<TData, ServiceCallError>, 'queryKey' | 'queryFn'>) => TData;
};

/** Implements a proxy handler for the QUERY object, which implements the QueryOperation API. */
class QueryHandler extends ServiceCallOperationHandlerBase implements ProxyHandler<object> {
	/**
	 * The proxy will call this method when code tries to access QUERY.someOperation and it will return an
	 * implementation for that operation.
	 */
	public get(target: object, operationId: keyof Queries) {
		const operationInfo: OperationInfo = urlMapping[operationId];
		return <TData>(...pathQueryAndBodyParams: unknown[]): QueryOperation<TData> => {
			const parameters = this.deriveRequestParameters(operationInfo, pathQueryAndBodyParams);

			const url = this.resolveUrl(operationInfo.path, parameters);
			const queryKey = this.buildQueryKeyFn(operationInfo, parameters);

			const fetch = (signal?: AbortSignal) => {
				return this.performServiceCall<TData>(operationInfo, parameters, signal);
			};

			function query(
				options?: QueryOptions<TData>
			): Pick<UseQueryOptions<TData, ServiceCallError, TData>, 'queryKey' | 'queryFn' | AllowedQueryOptions> {
				return {
					queryKey,
					queryFn: ({ signal }) => fetch(signal),
					...options
				};
			}

			function useQuery(options?: Omit<UseQueryOptions<TData, ServiceCallError>, 'queryKey' | 'queryFn'>) {
				return useReactQuery(
					query({ ...options, suspense: false }) as UseQueryOptions<TData, ServiceCallError>
				);
			}

			function useSuspendingQuery(
				options?: Omit<UseQueryOptions<TData, ServiceCallError>, 'queryKey' | 'queryFn'>
			) {
				return useReactQuery(query(options)).data!;
			}

			return { url, queryKey, fetch, query, useQuery, useSuspendingQuery };
		};
	}

	/**
	 * To make the API as ergonomic as possible, the path parameters are given as individual values in the method call
	 * followed by an object holding the query parameters (optional) and an object holding the request body, which might
	 * either be an arbitrary object or an object holding the form data. This method constructs a RequestParameters
	 * object from this argument list. The names and count of path parameters are determined from the operation's path.
	 * The existence of the body parameter is determined from the contentType.
	 */
	private deriveRequestParameters(operationInfo: OperationInfo, params: unknown[]): RequestParameters {
		const pathParams = QueryHandler.extractPathParams(operationInfo, params);
		const hasBody = operationInfo.contentType !== undefined;
		let body = undefined;
		if (hasBody) {
			body = params[params.length - 1];
		}
		let queryParams: Record<string, string> | undefined = undefined;
		const pathParamCount = Object.keys(pathParams).length;
		if ((hasBody && params.length === pathParamCount + 2) || (!hasBody && params.length === pathParamCount + 1)) {
			queryParams = params[pathParamCount] as Record<string, string>;
		}

		return {
			pathParams,
			queryParams,
			body
		};
	}

	private static extractPathParams(operationInfo: OperationInfo, params: unknown[]) {
		const pathParams: Record<string, string> = {};
		const regExpMatchArray = operationInfo.path.match(/\{[^}]*}/g);
		regExpMatchArray?.forEach((match, index) => {
			const pathParamName = match.slice(1, match.length - 1);
			pathParams[pathParamName] = params[index] as string;
		});
		return pathParams;
	}

	private buildQueryKeyFn(operationInfo: OperationInfo, parameters: RequestParameters): QueryKey {
		const queryKey: unknown[] = this.resolvePath(operationInfo.path, parameters.pathParams)
			.split('/')
			.filter(Boolean);
		queryKey.push(parameters.queryParams);
		queryKey.push(parameters.body);
		return queryKey;
	}
}

/** Provides access to all service calls either via Promise or useQuery based APIs. This should be used to query data. */
export const QUERY: Queries = new Proxy({}, new QueryHandler()) as Queries;
