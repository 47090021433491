import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { RepositoryLogFileHistoryEntry } from 'typedefs/RepositoryLogFileHistoryEntry';

/** The properties that we want to append/overwrite in RepositoryLogFileHistoryEntry objects. */
type RepositoryLogFileHistoryEntryExtension = {
	commit: UnresolvedCommitDescriptor;
	originCommit?: UnresolvedCommitDescriptor;
	introductionCommit: UnresolvedCommitDescriptor | null;
	originTimestamp: number | null;
};

/** The RepositoryLogFileHistoryEntry extended with the above properties. */
export type CommitRepositoryLogFileHistoryEntry = ExtendTypeWith<
	RepositoryLogFileHistoryEntry,
	RepositoryLogFileHistoryEntryExtension
>;

/** Takes the existing object and extends it with the additional properties */
export function wrapRepositoryLogFileHistoryEntry(
	repositoryLogFileHistoryEntry: RepositoryLogFileHistoryEntry
): CommitRepositoryLogFileHistoryEntry {
	return Object.assign(repositoryLogFileHistoryEntry, {
		introductionCommit: null,
		originTimestamp: null,
		commit: UnresolvedCommitDescriptor.wrap(repositoryLogFileHistoryEntry.commit),
		originCommit: UnresolvedCommitDescriptor.wrap(repositoryLogFileHistoryEntry.originCommit)
	});
}

/** Wraps the repository log entries into commit repository log entries. */
export function wrapRepositoryLogFileHistoryEntries(
	logEntries: RepositoryLogFileHistoryEntry[]
): CommitRepositoryLogFileHistoryEntry[] {
	return logEntries.map(logEntry => wrapRepositoryLogFileHistoryEntry(logEntry));
}
