import type { GetTestCoveragePostBody } from 'api/ApiDefinition';
import { HttpStatus } from 'api/HttpStatus';
import { QUERY } from 'api/Query';
import { ServiceCallError } from 'api/ServiceCallError';
import type { UploadProgress } from 'api/ServiceClientImplementation';
import type { PairList } from 'custom-types/PairList';
import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { UserOptions } from 'custom-types/UserOptions';
import { isEmpty } from 'ts-closure-library/lib/array/array';
import * as asserts from 'ts-closure-library/lib/asserts/asserts';
import * as strings from 'ts-closure-library/lib/string/string';
import type { Callback } from 'ts/base/Callback';
import { ReactUtils } from 'ts/base/ReactUtils';
import type { SortOptions } from 'ts/base/table/SortableTable';
import type { AnalysisStateWithProjectAndBranch } from 'ts/commons/AnalysisStateWarningUtils';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import type { CommitFilterSettings } from 'ts/commons/filters/commits/CommitFilterSettingsProvider';
import type { FindingsFilter } from 'ts/commons/filters/findings/FindingsFilter';
import { NavigationUtils } from 'ts/commons/NavigationUtils';
import { ObjectUtils } from 'ts/commons/ObjectUtils';
import type { Options, OptionValue } from 'ts/commons/option/OptionsComponent';
import { IssueQueryInputHandler } from 'ts/commons/query/IssueQueryInputHandler';
import type { QueryableEntityType } from 'ts/commons/query/QueryableEntityType';
import { SortingUtils } from 'ts/commons/SortingUtils';
import { StringUtils } from 'ts/commons/StringUtils';
import type { TestGapOptions } from 'ts/commons/TestGapOptions';
import { TestGapUtils } from 'ts/commons/TestGapUtils';
import { UIUtils } from 'ts/commons/UIUtils';
import { Zippy } from 'ts/commons/Zippy';
import { CommitArchitectureCommitUploadInfo } from 'ts/data/CommitArchitectureCommitUploadInfo';
import { CommitRepositoryLogEntry } from 'ts/data/CommitRepositoryLogEntry';
import type { CommitRepositoryLogFileHistoryEntry } from 'ts/data/CommitRepositoryLogFileHistoryEntry';
import { wrapRepositoryLogFileHistoryEntries } from 'ts/data/CommitRepositoryLogFileHistoryEntry';
import type { ExtendedEventAnnouncement } from 'ts/data/ExtendedEventAnnouncement';
import type { ExtendedQualityReport } from 'ts/data/ExtendedQualityReport';
import { wrapReport } from 'ts/data/ExtendedQualityReport';
import type { ExtendedReportSlide } from 'ts/data/ExtendedReportSlide';
import { wrapReportSlide } from 'ts/data/ExtendedReportSlide';
import type { ArchitectureTrackedData } from 'ts/perspectives/architecture/editor/ArchitectureTrackedData';
import type { FindingsQueryOptions } from 'ts/perspectives/findings/list/FindingListViewBase';
import type { SimulinkBlockData } from 'ts/perspectives/metrics/simulink/SimulinkBlockData';
import type { VOTING_OPTION_KIND, VotingOptionType } from 'ts/perspectives/project/components/VotingIndicator';
import type { RenderContext } from 'ts/perspectives/quality_control/reports/model/RenderContext';
import type { CodeSearchTreemapOptions } from 'ts/perspectives/search/SearchService';
import type { ELogType } from 'ts/perspectives/system/ELogType';
import type { AbapFileMetadata } from 'typedefs/AbapFileMetadata';
import type { AnalysisGroup } from 'typedefs/AnalysisGroup';
import type { AnalysisProgressDescriptor } from 'typedefs/AnalysisProgressDescriptor';
import type { AnalysisState } from 'typedefs/AnalysisState';
import type { AppInstallationInfo } from 'typedefs/AppInstallationInfo';
import type { ArchitectureAssessmentInfo } from 'typedefs/ArchitectureAssessmentInfo';
import type { ArchitectureCommitUploadInfo } from 'typedefs/ArchitectureCommitUploadInfo';
import type { ArchitectureComponentAssignment } from 'typedefs/ArchitectureComponentAssignment';
import type { ArchitectureInfo } from 'typedefs/ArchitectureInfo';
import type { ArchitectureOverviewInfo } from 'typedefs/ArchitectureOverviewInfo';
import type { ArchitectureWithCommitCount } from 'typedefs/ArchitectureWithCommitCount';
import type { AssessedReviewStatus } from 'typedefs/AssessedReviewStatus';
import type { AuthenticatorMappingReply } from 'typedefs/AuthenticatorMappingReply';
import type { AvatarData } from 'typedefs/AvatarData';
import type { BackupExportStatus } from 'typedefs/BackupExportStatus';
import type { BackupImportStatus } from 'typedefs/BackupImportStatus';
import type { BackupJobSummary } from 'typedefs/BackupJobSummary';
import type { BaselineInfo } from 'typedefs/BaselineInfo';
import type { BenchmarkResult } from 'typedefs/BenchmarkResult';
import type { BlacklistingOption } from 'typedefs/BlacklistingOption';
import type { BranchesInfo } from 'typedefs/BranchesInfo';
import type { CancelTriggerRequestBody } from 'typedefs/CancelTriggerRequestBody';
import type { ChangeRegion } from 'typedefs/ChangeRegion';
import type { CodeCityNode } from 'typedefs/CodeCityNode';
import type { CodeFileInfo } from 'typedefs/CodeFileInfo';
import type { CodeSearchResultsWrapper } from 'typedefs/CodeSearchResultsWrapper';
import type { Comment } from 'typedefs/Comment';
import type { CommitAlerts } from 'typedefs/CommitAlerts';
import type { CommitData } from 'typedefs/CommitData';
import type { CommitDescriptor, CommitDescriptor as DataCommitDescriptor } from 'typedefs/CommitDescriptor';
import type { CommitterDisplayName } from 'typedefs/CommitterDisplayName';
import type { CommitTreeNodeData } from 'typedefs/CommitTreeNodeData';
import type { CommitWithUserName } from 'typedefs/CommitWithUserName';
import type { ConfigurationTemplate } from 'typedefs/ConfigurationTemplate';
import type { ConnectorConfiguration } from 'typedefs/ConnectorConfiguration';
import type { ConnectorDescriptorTransport } from 'typedefs/ConnectorDescriptorTransport';
import type { ContainerInfo } from 'typedefs/ContainerInfo';
import type { CoverageSourceInfo } from 'typedefs/CoverageSourceInfo';
import type { CoverageSourceQueryParameters } from 'typedefs/CoverageSourceQueryParameters';
import type { DashboardDescriptor } from 'typedefs/DashboardDescriptor';
import type { DashboardTemplateDescriptor } from 'typedefs/DashboardTemplateDescriptor';
import type { DependencyWithOccurrenceLocation } from 'typedefs/DependencyWithOccurrenceLocation';
import type { DerivedTestCoverageInfo } from 'typedefs/DerivedTestCoverageInfo';
import type { DetailedWorkerLog } from 'typedefs/DetailedWorkerLog';
import type { DiffDescription } from 'typedefs/DiffDescription';
import type { DotNetVersionInfo } from 'typedefs/DotNetVersionInfo';
import type { EAnalysisTool, EAnalysisToolEntry } from 'typedefs/EAnalysisTool';
import type { EArchitectureUploadType } from 'typedefs/EArchitectureUploadType';
import type { EAuditExportTableEntry } from 'typedefs/EAuditExportTable';
import type { EBasicPermissionEntry } from 'typedefs/EBasicPermission';
import type { EBasicPermissionScope } from 'typedefs/EBasicPermissionScope';
import { EBlacklistingOption } from 'typedefs/EBlacklistingOption';
import type { ECommitAuthorSortingOrder } from 'typedefs/ECommitAuthorSortingOrder';
import type { ECommitType } from 'typedefs/ECommitType';
import type { EFindingBlacklistOperation } from 'typedefs/EFindingBlacklistOperation';
import type { EFindingBlacklistType } from 'typedefs/EFindingBlacklistType';
import type { EFindingsExportFormatEntry } from 'typedefs/EFindingsExportFormat';
import type { EIssuesExportFormatEntry } from 'typedefs/EIssuesExportFormat';
import type { EIssueTgaFilterOptionEntry } from 'typedefs/EIssueTgaFilterOption';
import type { ELanguage, ELanguageEntry } from 'typedefs/ELanguage';
import type { EMergeRequestStatus } from 'typedefs/EMergeRequestStatus';
import { EMimeType } from 'typedefs/EMimeType';
import { EProjectScheduleCommand } from 'typedefs/EProjectScheduleCommand';
import type { EQueryTypeEntry } from 'typedefs/EQueryType';
import type { EReportSlide } from 'typedefs/EReportSlide';
import type { ESearchSuggestionTypeEntry } from 'typedefs/ESearchSuggestionType';
import type { ESortOrder, ESortOrderEntry } from 'typedefs/ESortOrder';
import type { ETaskStatus, ETaskStatusEntry } from 'typedefs/ETaskStatus';
import type { ETestPrioritizationStrategy } from 'typedefs/ETestPrioritizationStrategy';
import type { ETokenClass } from 'typedefs/ETokenClass';
import type { ETypeEntry } from 'typedefs/EType';
import type { EvaluatedMetricThresholdPath } from 'typedefs/EvaluatedMetricThresholdPath';
import type { ExceptionsTree } from 'typedefs/ExceptionsTree';
import type { ExecutionUnit } from 'typedefs/ExecutionUnit';
import type { ExtendedFindingsWithCount } from 'typedefs/ExtendedFindingsWithCount';
import type { ExtendedMergeRequest } from 'typedefs/ExtendedMergeRequest';
import type { ExtendedMergeRequestsInfo } from 'typedefs/ExtendedMergeRequestsInfo';
import type { ExternalAnalysisCommitStatus } from 'typedefs/ExternalAnalysisCommitStatus';
import type { ExternalAnalysisGroup } from 'typedefs/ExternalAnalysisGroup';
import type { ExternalAnalysisPartitionInfo } from 'typedefs/ExternalAnalysisPartitionInfo';
import type { ExternalAnalysisStatusInfo } from 'typedefs/ExternalAnalysisStatusInfo';
import type { ExternalCredentialsData } from 'typedefs/ExternalCredentialsData';
import type { ExternalCredentialsUsageInfo } from 'typedefs/ExternalCredentialsUsageInfo';
import type { ExternalFindingsDescription } from 'typedefs/ExternalFindingsDescription';
import type { ExternalStorageBackendOption } from 'typedefs/ExternalStorageBackendOption';
import type { ExternalToolIssueCustomFieldResult } from 'typedefs/ExternalToolIssueCustomFieldResult';
import type { ExternalXCloneStatus } from 'typedefs/ExternalXCloneStatus';
import type { FileGroup } from 'typedefs/FileGroup';
import type { FileSummaryInfoRecord } from 'typedefs/FileSummaryInfoRecord';
import type { FilteredTreeMapWrapper } from 'typedefs/FilteredTreeMapWrapper';
import type { FindingBlacklistInfo } from 'typedefs/FindingBlacklistInfo';
import type { FindingBlacklistRequestBody } from 'typedefs/FindingBlacklistRequestBody';
import type { FindingChurnList } from 'typedefs/FindingChurnList';
import type { FindingDelta } from 'typedefs/FindingDelta';
import type { FindingsNotificationRules } from 'typedefs/FindingsNotificationRules';
import type { FindingsSummaryInfo } from 'typedefs/FindingsSummaryInfo';
import type { FindingsTreemapWrapper } from 'typedefs/FindingsTreemapWrapper';
import type { FindingTypeDescription } from 'typedefs/FindingTypeDescription';
import type { FormattedTokenElementInfo } from 'typedefs/FormattedTokenElementInfo';
import type { GetLinkRolesResponse } from 'typedefs/GetLinkRolesResponse';
import type { GitHubRepository } from 'typedefs/GitHubRepository';
import type { GitHubRepositorySettingsDescription } from 'typedefs/GitHubRepositorySettingsDescription';
import type { GloballyEnforceDefaultStorageOption } from 'typedefs/GloballyEnforceDefaultStorageOption';
import type { GlobalRole } from 'typedefs/GlobalRole';
import type { GroupAssessment } from 'typedefs/GroupAssessment';
import type { ImportedLinksAndTypeResolvedSpecItem } from 'typedefs/ImportedLinksAndTypeResolvedSpecItem';
import type { IncludeExcludePatterns } from 'typedefs/IncludeExcludePatterns';
import type { IndicatorsAndGroups } from 'typedefs/IndicatorsAndGroups';
import type { InstanceComparisonComputation } from 'typedefs/InstanceComparisonComputation';
import type { InstanceComparisonSnapshotCreation } from 'typedefs/InstanceComparisonSnapshotCreation';
import type { IssueHierarchy } from 'typedefs/IssueHierarchy';
import type { IssueQueryResult } from 'typedefs/IssueQueryResult';
import type { IssueTgaParameters } from 'typedefs/IssueTgaParameters';
import type { JacocoAgentWizardInput } from 'typedefs/JacocoAgentWizardInput';
import type { JavaScriptError } from 'typedefs/JavaScriptError';
import type { JobDescriptor } from 'typedefs/JobDescriptor';
import type { Language } from 'typedefs/Language';
import type { LanguageProcessingInfo } from 'typedefs/LanguageProcessingInfo';
import type { LicenseInfo } from 'typedefs/LicenseInfo';
import type { LicenseInfoElement } from 'typedefs/LicenseInfoElement';
import type { LineBasedMethodInfo } from 'typedefs/LineBasedMethodInfo';
import type { LineCoverageInfo } from 'typedefs/LineCoverageInfo';
import type { LoadProfile } from 'typedefs/LoadProfile';
import type { LogFilteringParameters } from 'typedefs/LogFilteringParameters';
import type { MergeRequestDelta } from 'typedefs/MergeRequestDelta';
import type { MergeRequestParentInfoTransport } from 'typedefs/MergeRequestParentInfoTransport';
import type { MethodHistoryEntriesWrapper } from 'typedefs/MethodHistoryEntriesWrapper';
import type { MethodTreeMapNode } from 'typedefs/MethodTreeMapNode';
import type { MetricDeltaValue } from 'typedefs/MetricDeltaValue';
import type { MetricDirectoryEntry } from 'typedefs/MetricDirectoryEntry';
import type { MetricDirectorySchema } from 'typedefs/MetricDirectorySchema';
import type { MetricDistributionEntry } from 'typedefs/MetricDistributionEntry';
import type { MetricDistributionWithDelta } from 'typedefs/MetricDistributionWithDelta';
import type { MetricNotificationRules } from 'typedefs/MetricNotificationRules';
import type { MetricSchemaChangeEntry } from 'typedefs/MetricSchemaChangeEntry';
import type { MetricsForThresholdProfile } from 'typedefs/MetricsForThresholdProfile';
import type { MetricTableEntry } from 'typedefs/MetricTableEntry';
import type { MetricThresholdConfiguration } from 'typedefs/MetricThresholdConfiguration';
import type { MetricTrendEntry } from 'typedefs/MetricTrendEntry';
import type { OpenIdEndpointInfo } from 'typedefs/OpenIdEndpointInfo';
import type { OptionDescriptor } from 'typedefs/OptionDescriptor';
import type { OutlineElement } from 'typedefs/OutlineElement';
import type { ParetoListDescriptor } from 'typedefs/ParetoListDescriptor';
import type { ParseLogEntry } from 'typedefs/ParseLogEntry';
import type { ParseLogOverviewEntry } from 'typedefs/ParseLogOverviewEntry';
import type { PartitionedTestSet } from 'typedefs/PartitionedTestSet';
import type { PasswordChangeRequest } from 'typedefs/PasswordChangeRequest';
import type { PerformanceMetricsEntry } from 'typedefs/PerformanceMetricsEntry';
import type { PermissionLookup } from 'typedefs/PermissionLookup';
import type { PerspectiveContext } from 'typedefs/PerspectiveContext';
import type { PolarionWorkItemLinkRolesResult } from 'typedefs/PolarionWorkItemLinkRolesResult';
import type { PolarionWorkItemTypeResult } from 'typedefs/PolarionWorkItemTypeResult';
import type { PostponedRollback } from 'typedefs/PostponedRollback';
import type { PostponedRollbackCounts } from 'typedefs/PostponedRollbackCounts';
import type { PreprocessorExpansionsTransport } from 'typedefs/PreprocessorExpansionsTransport';
import type { PreviousNextSiblings } from 'typedefs/PreviousNextSiblings';
import type { PrioritizableTest } from 'typedefs/PrioritizableTest';
import type { PrioritizableTestCluster } from 'typedefs/PrioritizableTestCluster';
import type { ProbeCoverageInfo } from 'typedefs/ProbeCoverageInfo';
import type { ProjectBranchingConfiguration } from 'typedefs/ProjectBranchingConfiguration';
import type { ProjectComparisonResult } from 'typedefs/ProjectComparisonResult';
import type { ProjectConfiguration } from 'typedefs/ProjectConfiguration';
import type { ProjectConnectorStatus } from 'typedefs/ProjectConnectorStatus';
import type { ProjectDescription } from 'typedefs/ProjectDescription';
import type { ProjectIdEntry } from 'typedefs/ProjectIdEntry';
import type { ProjectInfo } from 'typedefs/ProjectInfo';
import type { ProjectLogLevelFrequencies } from 'typedefs/ProjectLogLevelFrequencies';
import type { ProjectNotificationRules } from 'typedefs/ProjectNotificationRules';
import type { ProjectPartitionsInfo } from 'typedefs/ProjectPartitionsInfo';
import type { ProjectPlatformLinks } from 'typedefs/ProjectPlatformLinks';
import type { ProjectRole } from 'typedefs/ProjectRole';
import type { ProjectSchedulingFilter } from 'typedefs/ProjectSchedulingFilter';
import type { ProjectsConnectorState } from 'typedefs/ProjectsConnectorState';
import type { ProjectsState } from 'typedefs/ProjectsState';
import type { ProjectThresholdConfigurationsOption } from 'typedefs/ProjectThresholdConfigurationsOption';
import type { ProjectUpdateResult } from 'typedefs/ProjectUpdateResult';
import type { QualityReport } from 'typedefs/QualityReport';
import type { QueryTrendResult } from 'typedefs/QueryTrendResult';
import type { ReducedInstanceComparisonComputation } from 'typedefs/ReducedInstanceComparisonComputation';
import type { RefactoringSuggestions } from 'typedefs/RefactoringSuggestions';
import type { ReportSlideBase } from 'typedefs/ReportSlideBase';
import type { RepositoryActivitySummary } from 'typedefs/RepositoryActivitySummary';
import type { RepositoryLogEntry } from 'typedefs/RepositoryLogEntry';
import type { RepositoryLogFileHistoryEntry } from 'typedefs/RepositoryLogFileHistoryEntry';
import type { RepositoryLogFileHistoryFileInfoEntry } from 'typedefs/RepositoryLogFileHistoryFileInfoEntry';
import type { RepositorySummary } from 'typedefs/RepositorySummary';
import type { ResolvedTask } from 'typedefs/ResolvedTask';
import type { Retrospective } from 'typedefs/Retrospective';
import type { ReviewComment } from 'typedefs/ReviewComment';
import type { ReviewUploadInfo } from 'typedefs/ReviewUploadInfo';
import type { RoleAssignmentWithGlobalInfo } from 'typedefs/RoleAssignmentWithGlobalInfo';
import type { RoleChange } from 'typedefs/RoleChange';
import type { RoleSchemaData } from 'typedefs/RoleSchemaData';
import type { RulesContainer } from 'typedefs/RulesContainer';
import type { SamlServiceProviderConfiguration } from 'typedefs/SamlServiceProviderConfiguration';
import type { SearchResultContainer } from 'typedefs/SearchResultContainer';
import type { SearchSuggestion } from 'typedefs/SearchSuggestion';
import type { ShortLogResponse } from 'typedefs/ShortLogResponse';
import type { SimpleTask } from 'typedefs/SimpleTask';
import type { SimulinkModelComparisonResult } from 'typedefs/SimulinkModelComparisonResult';
import type { SinglePreprocessorExpansionTransport } from 'typedefs/SinglePreprocessorExpansionTransport';
import type { SlideParametersBase } from 'typedefs/SlideParametersBase';
import type { SlideRenderDataBase } from 'typedefs/SlideRenderDataBase';
import type { SlideRenderRequestContent } from 'typedefs/SlideRenderRequestContent';
import type { SlidesWithPositions } from 'typedefs/SlidesWithPositions';
import type { SlideTypeDescription } from 'typedefs/SlideTypeDescription';
import type { SonarQualityProfileImportSummary } from 'typedefs/SonarQualityProfileImportSummary';
import type { SpecItemCodeMapping } from 'typedefs/SpecItemCodeMapping';
import type { SpecItemCodeReference } from 'typedefs/SpecItemCodeReference';
import type { SpecItemGraph } from 'typedefs/SpecItemGraph';
import type { StoredQueryDescriptor } from 'typedefs/StoredQueryDescriptor';
import type { SubjectRoleAssignments } from 'typedefs/SubjectRoleAssignments';
import type { SupportRequestData } from 'typedefs/SupportRequestData';
import type { SystemProcessInfo } from 'typedefs/SystemProcessInfo';
import type { Task } from 'typedefs/Task';
import type { TasksWithCount } from 'typedefs/TasksWithCount';
import type { TaskWithDetailedFindings } from 'typedefs/TaskWithDetailedFindings';
import type { TeamscaleIssueId } from 'typedefs/TeamscaleIssueId';
import type { TeamscaleUploadWizardInput } from 'typedefs/TeamscaleUploadWizardInput';
import type { TestCoverageOverlayData } from 'typedefs/TestCoverageOverlayData';
import type { TestCoveragePartitionInfo } from 'typedefs/TestCoveragePartitionInfo';
import type { TestExecutionWithPartition } from 'typedefs/TestExecutionWithPartition';
import type { TestGapTreeMapWrapper } from 'typedefs/TestGapTreeMapWrapper';
import type { TestHistoryEntry } from 'typedefs/TestHistoryEntry';
import type { TestHistoryWrapper } from 'typedefs/TestHistoryWrapper';
import type { TestImplementation } from 'typedefs/TestImplementation';
import type { TestMinimizationJobRun } from 'typedefs/TestMinimizationJobRun';
import type { TestMinimizationRequestOptions } from 'typedefs/TestMinimizationRequestOptions';
import type { TestMinimizationResult } from 'typedefs/TestMinimizationResult';
import type { TestPathExecutionWrapper } from 'typedefs/TestPathExecutionWrapper';
import type { TestQueryResult } from 'typedefs/TestQueryResult';
import type { TgaSummary } from 'typedefs/TgaSummary';
import type { TgaTableEntry } from 'typedefs/TgaTableEntry';
import type { TokenElementChurnInfo } from 'typedefs/TokenElementChurnInfo';
import type { TokenElementChurnWithOriginInfo } from 'typedefs/TokenElementChurnWithOriginInfo';
import type { TokenElementInfo } from 'typedefs/TokenElementInfo';
import type { TrackedFinding } from 'typedefs/TrackedFinding';
import type { TrackedFindingWithDiffInfo } from 'typedefs/TrackedFindingWithDiffInfo';
import type { TreeMapNode } from 'typedefs/TreeMapNode';
import type { UnlinkedChangesWrapper } from 'typedefs/UnlinkedChangesWrapper';
import type { UsageDataPreview } from 'typedefs/UsageDataPreview';
import type { UsageDataReportingOption } from 'typedefs/UsageDataReportingOption';
import type { UsageTreeMapWrapper } from 'typedefs/UsageTreeMapWrapper';
import type { User } from 'typedefs/User';
import type { UserActivity } from 'typedefs/UserActivity';
import type { UserBatchOperation } from 'typedefs/UserBatchOperation';
import type { UserData } from 'typedefs/UserData';
import type { UserGroup } from 'typedefs/UserGroup';
import type { UserResolvedDashboardDescriptor } from 'typedefs/UserResolvedDashboardDescriptor';
import type { UserResolvedFindingBlacklistInfo } from 'typedefs/UserResolvedFindingBlacklistInfo';
import type { UserResolvedQualityReport } from 'typedefs/UserResolvedQualityReport';
import type { UserResolvedRepositoryLogEntry } from 'typedefs/UserResolvedRepositoryLogEntry';
import type { UserResolvedRetrospective } from 'typedefs/UserResolvedRetrospective';
import type { UserResolvedSpecItem } from 'typedefs/UserResolvedSpecItem';
import type { UserResolvedTeamscaleIssue } from 'typedefs/UserResolvedTeamscaleIssue';
import type { WidgetContext } from 'typedefs/WidgetContext';
import type { WorkerGroupStatus } from 'typedefs/WorkerGroupStatus';
import { ErrorManager } from '../ErrorManager';
import type { ErrorHandler } from './ServiceClient';
import { ServiceClient } from './ServiceClient';
import type { URLBuilder } from './URLBuilder';
import { url } from './URLBuilder';

export type FilterOptions = {
	/** List of filters. Format: <category>/<group>. */
	filter: string[];
	/** List of assessment filters. */
	'assessment-filters': string[];
	/** List of included-path filters. */
	'included-paths': string[];
	/** List of excluded-path filters. */
	'excluded-paths': string[];
	/** Filterregex for this FilterComponent. */
	regex: string;
	/** Guideline (e.g. AUTOSAR C++14) */
	guideline: string;
	/** Guideline rules (e.g. ['A8-4-2', 'M6-3-1']) */
	'guideline-rules': string[];
	/** Filter blacklist rationale for this FilterComponent. */
	blacklistRationale: string;
	/** Whether regex excludes or includes matching findings. */
	'exclude-regex'?: boolean;
	/** Added to task filter for this FilterComponent. */
	'added-to-task'?: boolean;
	/** If true, the categories/groups filter is inverted. */
	invert?: boolean;
};

/** The optional options for findings summary retrieval. */
export type FindingsSummaryQueryParameters = Partial<{
	/** The name of the current baseline, or its timestamp */
	baseline: string | number | null;
	/** Whether to include findings in changed code */
	includeChangedFindings: boolean | null;
	/** Whether to view findings in changed code only */
	onlyChangedFindings: boolean | null;
	/** The commit */
	commit: UnresolvedCommitDescriptor | null;
	/** The blacklisting option */
	blacklisting: string | null;
	/** Whether to only include spec item findings. If false, we only include code findings */
	onlySpecItemFindings: boolean | null;
	/** To determine if additionally configured quality indicators from analysis profile be included or not */
	reportCategoriesWithoutFindings: boolean | null;
	/** The findings filters to apply to the loaded findings */
	filterOptions: FilterOptions;
}>;

/** Data class for IRoles form the roles schema together with all RoleAssignmentWithGlobalInfos. */
export type RolesWithAssignments<T> = {
	/** The available roles. */
	roles: T[];
	/** The associated assignments. */
	roleAssignments: RoleAssignmentWithGlobalInfo[];
};

/** The Teamscale service client. */
export class TeamscaleServiceClient extends ServiceClient {
	/** Group name used for architecture conformance analysis. */
	private static readonly ARCHITECTURE_CONFORMANCE_GROUP_NAME = 'Architecture Conformance';

	/**
	 * @param errorHandler A function called in case of errors. Parameters are error code, error message and error
	 *   description.
	 */
	public constructor(errorHandler?: ErrorHandler) {
		super(new ErrorManager(errorHandler ?? TeamscaleServiceClient.defaultErrorHandler));
	}

	/** Default error handler which appends the error message directly to the root of the dom. */
	public static defaultErrorHandler(error: ServiceCallError): void {
		Zippy.renderErrorAndPrepareToggler(error, renderedElement => document.body.appendChild(renderedElement));
	}

	/** Queries the perspective context for the current user. */
	public async getPerspectiveContext(): Promise<PerspectiveContext> {
		return this.get(url`api/context/perspective`);
	}

	/**
	 * Creates a base URLBuilder for tree maps and code cities.
	 *
	 * @param uniformPath
	 * @param areaMetric The metric index
	 * @param colorMetric The metric index
	 * @param color Color to use for numerical metrics
	 * @param commit
	 * @param includeFileRegexes Regular expressions for included files.
	 * @param excludeFileRegexes Regular expressions for excluded files.
	 */
	private static createTreemapBaseURLBuilder(
		urlBuilder: URLBuilder,
		areaMetric: number | null,
		colorMetric: number | null,
		color?: number[] | null,
		commit?: number | string | UnresolvedCommitDescriptor | null,
		includeFileRegexes?: string[],
		excludeFileRegexes?: string[] | null
	): URLBuilder {
		urlBuilder.append('area-metric', areaMetric);
		urlBuilder.append('color-metric', colorMetric);
		urlBuilder.appendMultiple('included-files-regexes', includeFileRegexes);
		urlBuilder.appendMultiple('excluded-files-regexes', excludeFileRegexes);
		if (color != null) {
			urlBuilder.append('color', (color[0]! << 16) + (color[1]! << 8) + color[2]!);
		}
		if (commit != null) {
			urlBuilder.append('t', commit);
		}
		return urlBuilder;
	}

	/**
	 * Creates a base URLBuilder for finding retrieval.
	 *
	 * @param uniformPath The uniform path
	 * @param optionalParameters OptionalParameters to retrieve findings
	 */
	private static createFindingsBaseURLBuilder(
		urlBuilder: URLBuilder,
		uniformPath: string,
		optionalParameters?: FindingsSummaryQueryParameters
	): URLBuilder {
		urlBuilder.append('uniform-path', uniformPath);
		if (optionalParameters) {
			urlBuilder.append('baseline', optionalParameters.baseline);
			urlBuilder.append('include-changed-code-findings', optionalParameters.includeChangedFindings);
			urlBuilder.append('only-changed-code-findings', optionalParameters.onlyChangedFindings);
			urlBuilder.append('t', optionalParameters.commit);
			urlBuilder.append('blacklisted', optionalParameters.blacklisting);
			urlBuilder.append('only-spec-item-findings', optionalParameters.onlySpecItemFindings);
			urlBuilder.append('report-categories-without-findings', optionalParameters.reportCategoriesWithoutFindings);
		}
		return urlBuilder;
	}

	/**
	 * Creates a base URLBuilder for recursive finding retrieval with filters.
	 *
	 * @param uniformPath The uniform path
	 * @param findingsFilters
	 * @param commit The timestamp
	 * @param baseline?
	 * @param includeChangedFindings? Whether to include findings in changed code
	 * @param onlyChangedFindings? Whether to view findings in changed code only
	 * @param blacklisting? The blacklisting option
	 * @param onlySpecItemFindings? Whether to only include spec item findings. If false, we only include code findings
	 */
	private static createRecursiveFindingsBaseURLBuilder(
		urlBuilder: URLBuilder,
		uniformPath: string,
		findingsFilters: FindingsFilter,
		commit: UnresolvedCommitDescriptor | null,
		baseline: string | number | null,
		includeChangedFindings?: boolean | null,
		onlyChangedFindings?: boolean | null,
		blacklisting?: string | null,
		onlySpecItemFindings?: boolean | null
	): URLBuilder {
		const optionalParameters: FindingsSummaryQueryParameters = {
			baseline,
			includeChangedFindings,
			onlyChangedFindings,
			commit,
			blacklisting,
			onlySpecItemFindings
		};
		TeamscaleServiceClient.createFindingsBaseURLBuilder(urlBuilder, uniformPath, optionalParameters);
		findingsFilters.appendToUrl(urlBuilder);
		return urlBuilder;
	}

	/**
	 * Retrieves the dashboard with given name.
	 *
	 * @param name The name of the dashboard.
	 */
	public getDashboard(name: string): Promise<UserResolvedDashboardDescriptor | null> {
		return this.get<UserResolvedDashboardDescriptor>(
			url`api/dashboards/${name}`
		).catch<UserResolvedDashboardDescriptor | null>(TeamscaleServiceClient.handle404AsNull);
	}

	/**
	 * Checks whether a dashboard with the given name exists on the server.
	 *
	 * @param name The name of the dashboard.
	 */
	public dashboardExists(name: string): Promise<boolean> {
		return this.get<boolean>(url`api/dashboards/${name}/exist`).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Lists the available dashboards asynchronously.
	 *
	 * @param forProject If set and valid project, only the dashboards for this project will be returned. {@code null}
	 *   or invalid values will return all dashboards.
	 */
	public listDashboardsAsync(forProject?: string | null): Promise<UserResolvedDashboardDescriptor[]> {
		const urlBuilder = url`api/dashboards`;
		urlBuilder.append('project', forProject);
		return this.get(urlBuilder);
	}

	/** Provides search suggestions for the given project. */
	public searchSuggestions(
		query: string,
		projectId: string | undefined
	): Promise<Record<ESearchSuggestionTypeEntry, SearchSuggestion[]>> {
		const autoCompleteUrl = url`api/search/autocomplete`;
		autoCompleteUrl.append('token', query);
		autoCompleteUrl.append('project', projectId);
		return this.get(autoCompleteUrl);
	}

	/**
	 * Search code, commits, and issues within a specific project or within all projects.
	 *
	 * @param page The index of the result page to load
	 * @param resultsPerPage The maximum number of results for the current page (0 = all results)
	 * @param sources The sources to search
	 */
	public search(
		query: string | null,
		project: string | null,
		page: number | string | null,
		sources: EQueryTypeEntry[] | null,
		resultsPerPage?: number,
		path?: string,
		treemapOptions?: CodeSearchTreemapOptions
	): Promise<SearchResultContainer> {
		const urlBuilder = url`api/search`;
		urlBuilder.append('query', query);
		urlBuilder.append('project', project);
		urlBuilder.append('page', page);
		urlBuilder.append('limit-per-page', resultsPerPage);
		urlBuilder.append('path', path);
		urlBuilder.appendMultiple('source', sources);
		urlBuilder.append('area-metric', 1);
		urlBuilder.append('color-metric', 1);
		urlBuilder.append('width', treemapOptions?.treemapWidth);
		urlBuilder.append('height', treemapOptions?.treemapHeight);
		urlBuilder.append('is-color-gradation-active', treemapOptions?.isColorGraduationActive);
		return this.get<SearchResultContainer>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns a download link to a CSV with the search results for the given query.
	 *
	 * @param query The query to pass to the service projects
	 */
	public getSearchResultCsvUrl(
		query: string | null,
		project: string | null,
		source: EQueryTypeEntry,
		path?: string
	): string {
		const urlBuilder = url`api/search/csv`;
		urlBuilder.append('query', query);
		urlBuilder.append('project', project);
		urlBuilder.append('path', path);
		urlBuilder.append('source', source);
		return urlBuilder.getURL();
	}

	/** Stores a dashboard under the given name. */
	public saveDashboard(name: string, dashboardDescriptor: DashboardDescriptor): Promise<void> {
		return this.put<void>(url`api/dashboards/${name}`, dashboardDescriptor)
			.catch(this.getDefaultErrorHandler())
			.then(
				() =>
					void Promise.all([
						ReactUtils.queryClient.invalidateQueries(['dashboards']),
						ReactUtils.queryClient.invalidateQueries(['dashboard', name])
					])
			);
	}

	/** Sets/unsets a dashboard as user favorite. */
	public setDashboardFavorite(dashboardName: string, setFavorite: boolean): Promise<void> {
		const urlBuilder = url`api/dashboards/${dashboardName}/favorite`;
		urlBuilder.append('setFavorite', setFavorite);
		return this.post<void>(urlBuilder);
	}

	/** Creates a new dashboard. */
	public createDashboard(dashboardDescriptor: DashboardDescriptor): Promise<void> {
		return this.post<void>(url`api/dashboards`, dashboardDescriptor).catch(this.getDefaultErrorHandler());
	}

	/** Deletes the dashboard with the given name. */
	public deleteDashboard(name: string): Promise<void> {
		return this.delete<void>(url`api/dashboards/${name}`).catch(this.getDefaultErrorHandler());
	}

	/** Changes the owner of the dashboard with the given name. */
	public async changeDashboardOwner(name: string, dashboardDescriptor: DashboardDescriptor): Promise<void> {
		await this.delete(url`api/dashboards/${name}`);
		await this.createDashboard(dashboardDescriptor);
	}

	/** Starts a download for the JSON data of the dashboard with the given name. */
	public exportDashboard(name: string, type?: 'dashboard' | 'template'): void {
		if (type === 'template') {
			window.location.href = url`api/dashboards/templates/${name}/export`.getURL();
		} else {
			window.location.href = url`api/dashboards/${name}/export`.getURL();
		}
	}

	/** Saves the given dashboard template to an index. */
	public saveDashboardTemplate(
		name: string,
		dashboardTemplateDescriptor: DashboardTemplateDescriptor
	): Promise<void> {
		return this.put<void>(url`api/dashboards/templates/${name}`, dashboardTemplateDescriptor)
			.catch(this.getDefaultErrorHandler())
			.then(() => ReactUtils.queryClient.invalidateQueries(['dashboards']));
	}

	/** Creates the given dashboard template. */
	public createDashboardTemplate(dashboardTemplateDescriptor: DashboardTemplateDescriptor): Promise<void> {
		return this.post<void>(url`api/dashboards/templates`, dashboardTemplateDescriptor).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Checks whether a dashboard template with the given name exists on the server. */
	public dashboardTemplateExists(qualifiedName: string): Promise<boolean> {
		return this.head(url`api/dashboards/templates/${qualifiedName}`)
			.then(() => true)
			.catch(() => false);
	}

	/** Get all template descriptors as List<DashboardTemplateDescriptor> */
	public listDashboardTemplates(): Promise<DashboardTemplateDescriptor[]> {
		return this.get<DashboardTemplateDescriptor[]>(url`api/dashboards/templates`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Deletes the dashboard template with the given name. */
	public deleteTemplate(name: string): Promise<void> {
		return this.delete<void>(url`api/dashboards/templates/${name}`).catch(this.getDefaultErrorHandler());
	}

	/** Uploads the given form data to the metrics threshold configuration import service. */
	public importMetricThresholdConfiguration(metricThresholdConfigurations: File[]): Promise<void> {
		const formData = new FormData();
		for (const config of metricThresholdConfigurations) {
			formData.append('metric-threshold-configuration-data', config);
		}
		return this.post(url`api/metric-thresholds/import`, formData);
	}

	/** Starts a download for the project configuration with the given name. */
	public exportProjectConfiguration(name: string): void {
		window.location.href = url`api/projects/${name}/configuration/export`.getURL();
	}

	/** Uploads the given form data to the project configuration import service. */
	public importProjectConfiguration(
		projectConfigurations: File[],
		uploadProgressCallback?: (event: ProgressEvent) => void
	): Promise<void> {
		const formData = new FormData();
		for (const config of projectConfigurations) {
			formData.append('project-configuration', config);
		}
		return this.post(url`api/projects/import`, formData, {
			uploadProgressCallback
		});
	}

	/**
	 * Exports a project backup for the given project.
	 *
	 * @returns The ID of the backup export, which can be used to query the backup export progress.
	 */
	public exportProjectBackup(projectId: string): Promise<string> {
		const urlSearchParams = new URLSearchParams();
		urlSearchParams.set('include-project', projectId);
		return this.post<string>(url`api/backups/export`, urlSearchParams).catch(this.getDefaultErrorHandler());
	}

	/** Downloads the source code for the given uniform path in the given project at the given time as a file. */
	public downloadSourceCode(
		project: string,
		path: string,
		commit: UnresolvedCommitDescriptor | null,
		representation = 'TEXT'
	): void {
		const urlBuilder = url`api/projects/${project}/source-code-download/${path}`;
		if (commit != null) {
			urlBuilder.append('t', commit);
		}
		urlBuilder.append('representation', representation);
		window.location.href = urlBuilder.getURL();
	}

	/**
	 * Retrieves the finding with the given id.
	 *
	 * @param project
	 * @param id
	 * @param commit The commit for which to show the code.
	 */
	public getFinding(
		project: string,
		id: string | number,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<TrackedFinding | null> {
		const urlBuilder = url`api/projects/${project}/findings/${String(id)}`;
		if (commit != null) {
			urlBuilder.append('t', commit);
		}
		return this.get<TrackedFinding>(urlBuilder).catch(() => null);
	}

	/** Retrieves the finding with the given id. */
	public getFindingWithDiffInfo(
		project: string,
		id: string | number,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<TrackedFindingWithDiffInfo> {
		const urlBuilder = url`api/projects/${project}/findings/${String(id)}/with-diff-info`;
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/** Retrieves the issue with the given id. */
	public getIssue(project: string, id: string | number): Promise<UserResolvedTeamscaleIssue> {
		return this.get(url`api/projects/${project}/issues/${String(id)}`);
	}

	/** Retrieves the issue details of the given issue IDs. */
	public getIssuesDetails(
		project: string,
		ids: TeamscaleIssueId[]
	): Promise<Array<UserResolvedTeamscaleIssue | null>> {
		const urlBuilder = url`api/projects/${project}/issues/details`;
		urlBuilder.appendMultiple(
			'issue-ids',
			ids.map(id => id.internalId)
		);
		return this.get(urlBuilder);
	}

	/**
	 * Retrieves the overall number of issues in the given project.
	 *
	 * @param project The project
	 */
	public getIssueCount(project: string): Promise<number> {
		return this.get<number>(url`api/projects/${project}/issues/count`).catch(this.getDefaultErrorHandler());
	}

	/** Retrieves the overall number of spec items in the given project. */
	public getSpecItemCount(project: string, commit: UnresolvedCommitDescriptor | null | undefined): Promise<number> {
		const urlBuilder = url`api/projects/${project}/spec-items/count`;
		urlBuilder.append('t', commit);
		return this.get<number>(urlBuilder);
	}

	/** Returns the columns available for spec items in the given project. */
	public getKnownSpecItemColumns(projectId: string, commit?: UnresolvedCommitDescriptor): Promise<string[]> {
		const urlBuilder = url`api/projects/${projectId}/spec-item-query/columns`;
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/** Performs a query for spec items. */
	public performSpecItemQuery(
		project: string,
		query: string,
		startIndex: number,
		maxResult?: number,
		sortOptions?: SortOptions,
		commit?: UnresolvedCommitDescriptor
	): Promise<IssueQueryResult> {
		const urlBuilder = url`api/projects/${project}/spec-item-query`;
		urlBuilder.append('t', commit);
		return this.performQuery(urlBuilder, query, startIndex, maxResult, sortOptions);
	}

	/** Performs query to the issue/spec item query endpoint. */
	private performQuery<T>(
		urlBuilder: URLBuilder,
		query: string,
		startIndex: number,
		maxResult?: number,
		sortOptions?: SortOptions
	): Promise<T> {
		urlBuilder.append('query', query);
		urlBuilder.append('start', startIndex);
		urlBuilder.append('max', maxResult);
		urlBuilder.append('sort-by', sortOptions?.sortByField);
		urlBuilder.append('sort-order', sortOptions?.sortOrder.name);
		return this.get(urlBuilder);
	}

	/** Retrieves the issue finding churn for the issue. */
	public getIssueFindingChurn(
		project: string,
		issueId: string,
		excludeResolvedFindings: boolean
	): Promise<FindingChurnList> {
		const urlBuilder = url`api/projects/${project}/issues/${issueId}/finding-churn`;
		urlBuilder.append('exclude-resolved-findings', excludeResolvedFindings);
		return this.get<FindingChurnList>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Retrieves the hierarchy of a given issue.
	 *
	 * @param project Project to which the issue belongs.
	 * @param issueId ID of the issue for which to retrieve the hierarchy.
	 */
	public getIssueHierarchy(project: string, issueId: string): Promise<IssueHierarchy> {
		return this.get<IssueHierarchy>(url`api/projects/${project}/issues/${issueId}/hierarchy`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Lists the merge requests of the project.
	 *
	 * @param project
	 * @param start Start Return tasks starting from this index (0 based), i.e. the first start tasks in the list (for
	 *   current sorting) will be skipped.
	 * @param max Limits the number of tasks returned to the given number.
	 * @param status Status of the merge requests (e.g. open or others).
	 * @param sortBy Specifies by which column in the table to sort (e.g. id, title or source branch etc.).
	 * @param sortOrder Specifies whether the list should be sorted in ascending order, descending order or not at all.
	 * @param filter The filter value by which the merge request list should be filtered.
	 */
	public listMergeRequests(
		project: string,
		status: EMergeRequestStatus,
		sortBy?: string,
		sortOrder?: ESortOrder,
		start?: number,
		max?: number,
		filter?: string
	): Promise<ExtendedMergeRequestsInfo> {
		const urlBuilder = url`api/projects/${project}/merge-requests`;
		urlBuilder.append('start', start);
		urlBuilder.append('max', max);
		urlBuilder.append('filter', filter);
		urlBuilder.append('status', status.name);
		urlBuilder.append('sort-by', sortBy);
		urlBuilder.append('sort-order', sortOrder?.name);
		return this.get<ExtendedMergeRequestsInfo>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Gets a merge request of the project. Returns null if the merge request is not found. */
	public getMergeRequest(project: string, mergeRequestId: string): Promise<ExtendedMergeRequest | null> {
		return this.get<ExtendedMergeRequest | null>(
			url`api/projects/${project}/merge-requests/${mergeRequestId}`
		).catch(this.getDefaultErrorHandler());
	}

	/** Gets the merge request delta. Null in case the delta is not calculated yet or merge request does not exist. */
	public getMergeRequestDelta(project: string, mergeRequestId: string): Promise<MergeRequestDelta | null> {
		return this.get<MergeRequestDelta | null>(
			url`api/projects/${project}/merge-requests/${mergeRequestId}/delta`
		).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Lists the tasks of the project with additional count information (wrapped in a TasksWithCount object).
	 *
	 * @param branch The branch for which the findings removal status should be checked.
	 * @param statuses Array of statuses. See ETaskStatus.
	 * @param assignees Array of assignees.
	 * @param authors Array of authors.
	 * @param tags Array of tags.
	 * @param start Return tasks starting from this index (0 based), i.e. the first start tasks in the list (for current
	 *   sorting) will be skipped.
	 * @param max Limits the number of tasks returned to the given number.
	 * @param sortBy One of id, subject, author, assignee, created, updated, status or resolution.
	 * @param sortOrder One of ascending or descending
	 */
	public listTasksWithCountAsync(
		project: string,
		branch: string,
		statuses: ETaskStatus[],
		assignees?: string[],
		authors?: string[],
		tags?: string[] | null,
		start?: number,
		max?: number,
		sortBy?: string,
		sortOrder?: ESortOrder
	): Promise<TasksWithCount> {
		const urlBuilder = TeamscaleServiceClient.assembleTaskFilters(
			url`api/projects/${project}/tasks/with-count`,
			branch,
			[],
			statuses,
			assignees,
			authors,
			tags,
			false,
			start,
			max,
			sortBy,
			sortOrder
		);
		return this.get<TasksWithCount>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Lists the tasks of the project.
	 *
	 * @param project
	 * @param taskIds Array of task ids.
	 * @param statuses Array of statuses. See ETaskStatus.
	 * @param assignees Array of assignees.
	 * @param authors Array of authors.
	 * @param tags Array of tags.
	 * @param details Controls whether the tasks contain information about findings and comments. Defaults to false.
	 * @param start Start Return tasks starting from this index (0 based), i.e. the first start tasks in the list (for
	 *   current sorting) will be skipped.
	 * @param max Limits the number of tasks returned to the given number.
	 * @param sortBy One of id, subject, author, assignee, created, updated, status or resolution.
	 * @param sortOrder One of ascending or descending
	 */
	public listTasks(
		project: string,
		taskIds?: number[] | null,
		statuses?: ETaskStatus[] | null,
		assignees?: string[] | null,
		authors?: string[] | null,
		tags?: string[] | null,
		details?: boolean,
		start?: number,
		max?: number,
		sortBy?: string,
		sortOrder?: ESortOrder
	): Promise<ResolvedTask[]> {
		const urlBuilder = TeamscaleServiceClient.assembleTaskFilters(
			url`api/projects/${project}/tasks`,
			null,
			taskIds,
			statuses,
			assignees,
			authors,
			tags,
			details,
			start,
			max,
			sortBy,
			sortOrder
		);
		return this.get<ResolvedTask[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Assembles the task filter with an urlBuilder and returns the urlBuilder.
	 *
	 * @param baseURL BaseURL for the request, may be null.
	 * @param taskIds? Array of task ids. Has no effect if taskId is set.
	 * @param statuses? Array of statuses. See ETaskStatus.
	 * @param assignees? Array of assignees.
	 * @param authors? Array of authors.
	 * @param tags? Array of tags.
	 * @param details? Controls whether the tasks contain information about findings and comments. Defaults to false.
	 * @param start? Start Return tasks starting from this index (0 based), i.e. the first start tasks in the list (for
	 *   current sorting) will be skipped.
	 * @param max? Limits the number of tasks returned to the given number.
	 * @param sortBy? One of id, subject, author, assignee, created, updated, status or resolution.
	 * @param sortOrder? One of ascending or descending
	 */
	private static assembleTaskFilters(
		urlBuilder: URLBuilder,
		branch: string | null,
		taskIds?: number[] | null,
		statuses?: ETaskStatus[] | null,
		assignees?: string[] | null,
		authors?: string[] | null,
		tags?: string[] | null,
		details?: boolean,
		start?: number,
		max?: number,
		sortBy?: string | null,
		sortOrder?: ESortOrder
	): URLBuilder {
		urlBuilder.append('branch', branch);
		urlBuilder.appendMultiple('ids', taskIds);
		urlBuilder.appendMultiple(
			'status',
			statuses?.map(status => status.name)
		);
		urlBuilder.appendMultiple('assignee', assignees);
		urlBuilder.appendMultiple('author', authors);
		urlBuilder.appendMultiple('tag', tags);
		urlBuilder.append('details', details);
		urlBuilder.append('start', start);
		urlBuilder.append('max', max);
		if (sortBy != null) {
			sortBy = sortBy.toUpperCase();
			if (sortBy === 'UPDATEDBY') {
				sortBy = 'UPDATED_BY';
			}
		}
		urlBuilder.append('sort-by', sortBy);
		urlBuilder.append('sort-order', sortOrder?.name);
		return urlBuilder;
	}

	/**
	 * Updates a task.
	 *
	 * @param id The id of the task, use 0 if a new task should be created by the service
	 * @param keepFindings Whether to preserve the findings of the original task (if overwriting an existing one).
	 * @returns The new/updated task
	 */
	public saveTask(project: string, id: number, task: Task, keepFindings?: boolean): Promise<Task> {
		const urlBuilder = url`api/projects/${project}/tasks/${id.toString()}`;
		urlBuilder.append('keep-findings', keepFindings);
		return this.put<Task>(urlBuilder, task).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Updates the status of a task.
	 *
	 * @param project
	 * @param id The id of the already existing task
	 * @param status The new task status
	 * @returns The updated task
	 */
	public updateTaskStatus(project: string, id: number, status: string): Promise<Task> {
		const urlBuilder = url`api/projects/${project}/tasks/${id.toString()}/status`;
		return this.put<Task>(urlBuilder, status).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Updates the resolution of a task.
	 *
	 * @param project
	 * @param id The id of the already existing task
	 * @param resolution The new resolution of the task
	 * @returns The updated task
	 */
	public updateTaskResolution(project: string, id: number, resolution: string): Promise<Task> {
		const urlBuilder = url`api/projects/${project}/tasks/${id.toString()}/resolution`;
		return this.put<Task>(urlBuilder, resolution);
	}

	/** Creates a task. */
	public createTask(project: string, task: Task): Promise<Task> {
		return this.post<Task>(url`api/projects/${project}/tasks`, task).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Adds a comment to a task.
	 *
	 * @param project
	 * @param id The id of the task
	 * @param comment The comment text
	 * @returns Promise containing the created comment.
	 */
	public commentTask(project: string, id: number, comment: string): Promise<Comment> {
		return this.post<Comment>(url`api/projects/${project}/tasks/${String(id)}/comments`, comment).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Retrieves the task with the given id. */
	public getTask(project: string, id: number, branch: string | null): Promise<TaskWithDetailedFindings> {
		const urlBuilder = url`api/projects/${project}/tasks/${id.toString()}`;
		urlBuilder.append('branch', branch);
		return this.get<TaskWithDetailedFindings>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Retrieves the tasks that match the specified parameters.
	 *
	 * @param project The name of the project to load the task summary for.
	 * @param endCommit The end commit after which all tasks are ignored.
	 * @param baselineTimestamp The base timestamp which tasks which don't have status open are counted.
	 * @param assignees Array of assignees.
	 * @param authors Array of authors.
	 * @param tags Array of tags.
	 */
	public getTaskSummary(
		project: string,
		endCommit?: UnresolvedCommitDescriptor | null,
		baselineTimestamp?: number | null,
		assignees?: string[] | null,
		authors?: string[] | null,
		tags?: string[] | null
	): Promise<Record<keyof typeof ETaskStatus, number>> {
		const urlBuilder = url`api/projects/${project}/tasks/summary`;
		urlBuilder.append('t', endCommit);
		urlBuilder.append('baseline', baselineTimestamp);
		urlBuilder.appendMultiple('assignee', assignees);
		urlBuilder.appendMultiple('author', authors);
		urlBuilder.appendMultiple('tag', tags);
		return this.get<Record<keyof typeof ETaskStatus, number>>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Retrieves the previous and next task as {@link PreviousNextSiblings} for the task with the specified id.
	 *
	 * @param project
	 * @param id
	 * @param statuses Array of statuses. See ETaskStatus.
	 * @param assignees Array of assignees.
	 * @param authors Array of authors.
	 * @param tags Array of tags.
	 * @returns Promise containing an array with the previous and next task id.
	 */
	public getTaskSiblings(
		project: string,
		id: number,
		statuses?: ETaskStatus[],
		assignees?: string[],
		authors?: string[],
		tags?: string[]
	): Promise<PreviousNextSiblings> {
		const urlBuilder = TeamscaleServiceClient.assembleTaskFilters(
			url`api/projects/${project}/tasks/${String(id)}/siblings`,
			null,
			[],
			statuses,
			assignees,
			authors,
			tags
		);
		return this.get<PreviousNextSiblings>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Retrieves a set of all used tags for all tasks.
	 *
	 * @returns Array of all existing task tags
	 */
	public getAllTaskTags(project: string): Promise<string[]> {
		return this.get<string[]>(url`api/projects/${project}/tasks/tags`).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Retrieves the tasks of which the given findings are a part.
	 *
	 * @param project
	 * @param findingIds
	 * @param taskStatuses
	 * @returns The promise is resolved with an array of maps that map {@link ETaskStatus}.name to a {@link SimpleTask}
	 *   that references the given findings. Each array index is in the same order as requested by the parameter
	 *   findingIds. Example: <code>[{OPEN -> [(1, 'Subject of 1'], [2, 'Subject for 2'], DISCARDED -> [42, 'Subject of
	 *   42]}]</code>
	 */
	public getTasksForFindings(
		project: string,
		findingIds: string[],
		taskStatuses?: string[] | null
	): Promise<Array<Partial<Record<ETaskStatusEntry, SimpleTask[]>>>> {
		const urlBuilder = url`api/projects/${project}/findings/tasks`;
		const urlSearchParams = new URLSearchParams();
		findingIds.forEach(id => urlSearchParams.append('finding-id', id));
		if (taskStatuses != null) {
			taskStatuses.forEach(status => urlSearchParams.append('task-status', status));
		}
		return this.post<Array<Partial<Record<ETaskStatusEntry, SimpleTask[]>>>>(urlBuilder, urlSearchParams).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Return the implementation for a given test. */
	public getTestImplementation(
		project: string,
		uniformPathToTest: string,
		endCommit?: UnresolvedCommitDescriptor | null
	): Promise<TestImplementation> {
		const urlBuilder = url`api/projects/${project}/test-implementations/${uniformPathToTest}`;
		urlBuilder.append('t', endCommit);
		return this.get(urlBuilder);
	}

	/** Return the test implementation path for a given test execution path. */
	public getTestImplementationPath(
		project: string,
		testExecutionPath: string,
		endCommit?: UnresolvedCommitDescriptor | null
	): Promise<string | null> {
		const urlBuilder = url`api/projects/${project}/test-executions/${testExecutionPath}/implementation`;
		urlBuilder.append('t', endCommit);
		return this.get(urlBuilder);
	}

	/** Return a list of test paths for a given file. */
	public getTestPathsAndExecResults(
		project: string,
		uniformPathToFile: string,
		endCommit?: UnresolvedCommitDescriptor | null
	): Promise<TestPathExecutionWrapper[] | null> {
		const urlBuilder = url`api/projects/${project}/code/${uniformPathToFile}/tests`;
		urlBuilder.append('t', endCommit);
		return this.get(urlBuilder);
	}

	/** Return the execution unit with the given path. */
	public getExecutionUnit(
		project: string,
		partition: string,
		executionUnitPath: string,
		endCommit?: UnresolvedCommitDescriptor | null
	): Promise<ExecutionUnit> {
		const urlBuilder = url`api/projects/${project}/execution-units/${executionUnitPath}/partitions/${partition}`;
		urlBuilder.append('t', endCommit);
		return this.get(urlBuilder);
	}

	/** Return the execution unit with the given path. */
	public getTest(
		project: string,
		partition: string,
		executionUnitPath: string,
		endCommit?: UnresolvedCommitDescriptor | null
	): Promise<ExecutionUnit> {
		const urlBuilder = url`api/projects/${project}/execution-units/${executionUnitPath}/partitions/${partition}`;
		urlBuilder.append('t', endCommit);
		return this.get(urlBuilder);
	}

	/**
	 * Return the partitions in which a specified test exists.
	 *
	 * @param project The project.
	 * @param testExecutionPath The uniform path to the test for which the test history should be retrieved.
	 * @param endCommit The commit until which test executions should be respected.
	 * @returns List of partition names.
	 */
	public getTestExecutionPartitions(
		project: string,
		testExecutionPath: string,
		endCommit?: UnresolvedCommitDescriptor | null
	): Promise<string[]> {
		const urlBuilder = url`api/projects/${project}/test-executions/${testExecutionPath}/partitions`;
		urlBuilder.append('t', endCommit);
		return this.get(urlBuilder);
	}

	/**
	 * Return the execution unit in which a specified test exists.
	 *
	 * @param project The project.
	 * @param testExecution The uniform path to the test for which the test history should be retrieved.
	 * @param endCommit The commit until which test executions should be respected.
	 */
	public getTestExecutionUnit(
		project: string,
		partition: string,
		testExecution: string,
		endCommit?: UnresolvedCommitDescriptor | null
	): Promise<string | null> {
		const urlBuilder = url`api/projects/${project}/test-executions/${testExecution}/partitions/${partition}/execution-unit`;
		urlBuilder.append('t', endCommit);
		return this.get(urlBuilder);
	}

	/**
	 * Returns the test executions for a test implementation path. Returns empty list if there are no known test
	 * executions for the implementation.
	 *
	 * @param project The project.
	 * @param testImplementationPath The uniform path to the test implementation for which the test executions should be
	 *   retrieved.
	 * @param endCommit The commit until which test executions should be respected.
	 */
	public getTestExecutions(
		project: string,
		testImplementationPath: string,
		endCommit: UnresolvedCommitDescriptor | null
	): Promise<string[]> {
		const urlBuilder = url`api/projects/${project}/test-implementations/${testImplementationPath}/executions`;
		urlBuilder.append('t', endCommit);
		return this.get(urlBuilder);
	}

	/**
	 * Return the history of executions of a specified test used in the test detail view.
	 *
	 * @param project The project.
	 * @param testExecution The uniform path to the test for which the test history should be retrieved.
	 * @param partition The partition of the test.
	 * @param endCommit The commit until which test executions should be respected.
	 * @returns Test history and meta information about e.g. successful or failed test executions.
	 */
	public getTestHistory(
		project: string,
		testExecution: string,
		partition: string,
		endCommit?: UnresolvedCommitDescriptor | null
	): Promise<TestHistoryWrapper> {
		const urlBuilder = url`api/projects/${project}/test-executions/${testExecution}/history`;
		urlBuilder.append('partition', partition);
		urlBuilder.append('t', endCommit);
		return this.get(urlBuilder);
	}

	/**
	 * Retrieves the commits belonging to the given issue.
	 *
	 * @returns The commits done in the context of the given issue id.
	 */
	public async getRepositoryLogEntriesByIssue(
		project: string,
		issueId: string | number
	): Promise<UnresolvedCommitDescriptor[]> {
		const commits = await this.get<DataCommitDescriptor[]>(
			url`api/projects/${project}/issues/${String(issueId)}/commits`
		);
		return commits.map(commit => UnresolvedCommitDescriptor.wrap(commit));
	}

	/**
	 * Retrieves all commits associated with the given task.
	 *
	 * @param project The regarded project.
	 * @param id Task ID for which all associated commits should be loaded.
	 */
	public async getRepositoryLogEntriesByTask(
		project: string,
		id: string | number
	): Promise<UnresolvedCommitDescriptor[]> {
		return this.get<DataCommitDescriptor[]>(url`api/projects/${project}/tasks/${String(id)}/commits`)
			.then(commits => commits.map(commit => UnresolvedCommitDescriptor.wrap(commit)))
			.catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the affected repository log file entries for a given issue in a given project.
	 *
	 * @param project
	 * @param id The id of the issue
	 */
	public getRepositoryLogFileEntriesByIssue(
		project: string,
		id: string | number
	): Promise<RepositoryLogFileHistoryFileInfoEntry[]> {
		return this.get<RepositoryLogFileHistoryFileInfoEntry[]>(
			url`api/projects/${project}/issues/${String(id)}/affected-files`
		).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the impacted tests for the given issue with the corresponding commit. The commit is required to be able
	 * to navigate to the test view.
	 */
	public getImpactedTestsByIssue(
		project: string,
		id: string | number,
		includeChildIssues: boolean,
		branch: string,
		partitions?: string[]
	): Promise<PrioritizableTest[]> {
		const urlBuilder = url`api/projects/${project}/issues/${String(id)}/impacted-tests`;
		urlBuilder.append('include-child-issues', includeChildIssues);
		urlBuilder.append('branch', branch);
		urlBuilder.appendMultiple('partition', partitions);
		return this.get(urlBuilder);
	}

	/**
	 * Returns the line coverage information for the given element.
	 *
	 * @param project
	 * @param uniformPath
	 * @param pretty Whether the line numbers are adjusted for pretty-printed code
	 * @param partitions Which should be used to gather the coverage data. Omitting it or an empty array means all will
	 *   be taken into consideration.
	 * @param commit
	 * @returns Promise with line-based test coverage
	 */
	public getLineCoverage(
		project: string,
		uniformPath: string,
		body: GetTestCoveragePostBody
	): Promise<LineCoverageInfo | null> {
		return QUERY.getTestCoveragePost(project, uniformPath, body).fetch().catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the line coverage partition information for the given element.
	 *
	 * @returns Promise with test coverage partitions (java type TestCoveragePartitionInfo)
	 */
	public getLineCoveragePartitions(
		project: string,
		uniformPath: string,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<TestCoveragePartitionInfo[]> {
		const urlBuilder = url`api/projects/${project}/test-coverage-partitions/${uniformPath}`;
		urlBuilder.append('t', commit);
		return this.get<TestCoveragePartitionInfo[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the probe-based coverage information for the given element.
	 *
	 * @param project
	 * @param uniformPath
	 * @param commit The commit for which to retrieve the data. Latest if not given.
	 * @returns Promise with probe coverage info.
	 */
	public getProbeCoverage(
		project: string,
		uniformPath: string,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<ProbeCoverageInfo | null> {
		const urlBuilder = url`api/projects/${project}/probe-coverage/${uniformPath}`;
		urlBuilder.append('t', commit);
		return this.get<ProbeCoverageInfo | null>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns whether a given language is supported by our shallow parsers.
	 *
	 * @param language
	 * @returns Promise with the language processing information.
	 */
	public getLanguageProcessingInfo(language: string): Promise<LanguageProcessingInfo> {
		const urlBuilder = url`api/language-info/${language}`;
		return this.get<LanguageProcessingInfo>(urlBuilder);
	}

	/**
	 * Returns the methods of the file.
	 *
	 * @param project
	 * @param uniformPath
	 * @param pretty Whether the line numbers are adjusted for pretty-printed code
	 * @param commit Commit at which to return the methods from
	 * @param partitions Which should be used to gather the coverage data. Omitting it or an empty array means all will
	 * @returns A promise with a list of methods
	 */
	public getMethodsForFile(
		project: string,
		uniformPath: string,
		pretty: boolean,
		commit?: UnresolvedCommitDescriptor | null,
		partitions?: string[]
	): Promise<LineBasedMethodInfo[]> {
		const urlBuilder = url`api/projects/${project}/code/${uniformPath}/methods`;
		urlBuilder.append('pretty', pretty);
		urlBuilder.append('t', commit);
		urlBuilder.appendMultiple('partitions', partitions);
		return this.get(urlBuilder);
	}

	/**
	 * Returns the test coverage information for the given element.
	 *
	 * @param uniformPath The uniform path of the file, which contains the method
	 * @param startOffset The character-based offset in file on which the method starts (at the specified time)
	 * @param endOffset The character-based offset in file on which the method ends (at the specified time)
	 * @returns A list of tests executing the given method grouped by partition.
	 */
	public getTestsForMethod(
		project: string,
		uniformPath: string,
		startOffset: number,
		endOffset: number,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<PartitionedTestSet | null> {
		const urlBuilder = url`api/projects/${project}/code/${uniformPath}/methods/${String(startOffset)}-${String(
			endOffset
		)}/tests`;
		urlBuilder.append('t', commit);
		return this.get<PartitionedTestSet>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the impacted tests for the given baseline and end commits. Will return null if no testwise coverage has
	 * been uploaded yet for the project.
	 */
	public getImpactedTests(
		project: string,
		baseline: UnresolvedCommitDescriptor | null,
		end: UnresolvedCommitDescriptor,
		prioritizationStrategy?: ETestPrioritizationStrategy
	): Promise<PrioritizableTest[] | null> {
		const urlBuilder = url`api/projects/${project}/impacted-tests`;
		urlBuilder.append('baseline', baseline);
		urlBuilder.append('end', end);
		if (prioritizationStrategy !== undefined) {
			urlBuilder.append('prioritization-strategy', prioritizationStrategy.name);
		}
		return this.get(urlBuilder);
	}

	/** Returns a ranked list of tests. */
	public getMinimizedTests(
		project: string,
		end: UnresolvedCommitDescriptor,
		partitions: string[],
		testQuery = '',
		maxExecTime?: number,
		clusteringRegEx?: string
	): Promise<TestMinimizationResult | null> {
		const urlBuilder = url`api/projects/${project}/minimized-tests`;
		urlBuilder.append('end', end);
		urlBuilder.append('query', IssueQueryInputHandler.escapeIssueQuery(testQuery));
		urlBuilder.appendMultiple('partitions', partitions);
		urlBuilder.append('clustering-regex', clusteringRegEx ?? '.*');
		urlBuilder.append('max-exec-time', maxExecTime);

		return this.get<TestMinimizationResult | null>(urlBuilder);
	}

	/** Returns the download URL of the impacted tests csv for the given baseline and end commits. */
	public exportImpactedTestsUrl(
		project: string,
		baseline: UnresolvedCommitDescriptor,
		end: UnresolvedCommitDescriptor
	): string {
		const urlBuilder = url`api/projects/${project}/impacted-tests/csv`;
		urlBuilder.append('baseline', baseline);
		urlBuilder.append('end', end);
		urlBuilder.append('ensure-processed', false);
		return urlBuilder.getURL();
	}

	/** Returns the test coverage information for the given Simulink element. */
	public getSimulinkTestCoverage(
		project: string,
		uniformPath: string,
		partitions: string[],
		commit?: UnresolvedCommitDescriptor | null
	): Promise<DerivedTestCoverageInfo | null> {
		const urlBuilder = url`api/projects/${project}/simulink/test-coverage/${uniformPath}`;
		urlBuilder.append('t', commit);
		urlBuilder.appendMultiple('partitions', partitions);
		return this.get<DerivedTestCoverageInfo | null>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Retrieves the findings churn list for a given commit.
	 *
	 * @param project The project
	 * @param commit The commit
	 * @returns FindingsChurnList loaded from backend
	 */
	public getFindingChurnList(project: string, commit: UnresolvedCommitDescriptor): Promise<FindingChurnList> {
		const urlBuilder = url`api/projects/${project}/finding-churn/list`;
		urlBuilder.append('t', commit);
		return this.get<FindingChurnList>(urlBuilder);
	}

	/**
	 * Retrieves the findings delta for a given uniform path and commit range.
	 *
	 * @param project
	 * @param uniformPath
	 * @param commit1
	 * @param commit2
	 * @param findingsFilter The filter to apply to the delta
	 * @param numericDeltaOnly Whether to only return a numeric delta
	 * @param findingBlacklistingOption Which types of findings should be considered.
	 */
	public getFindingDelta(
		project: string,
		uniformPath: string,
		commit1: UnresolvedCommitDescriptor | number,
		commit2: UnresolvedCommitDescriptor | null | number,
		findingsFilter: FindingsFilter,
		isSpecItemDelta?: boolean,
		numericDeltaOnly?: boolean,
		findingBlacklistingOption?: string | null,
		group?: string | null
	): Promise<FindingDelta> {
		const urlBuilder = url`api/projects/${project}/findings/delta`;
		urlBuilder.append('t1', commit1);
		urlBuilder.append('t2', commit2);
		urlBuilder.append('uniform-path', uniformPath);
		findingsFilter.appendToUrl(urlBuilder);
		urlBuilder.append('numeric-delta-only', numericDeltaOnly);
		if (findingBlacklistingOption != null) {
			urlBuilder.append('blacklisted', findingBlacklistingOption);
		} else {
			urlBuilder.append('blacklisted', EBlacklistingOption.ALL.name);
		}
		if (group != null) {
			urlBuilder.append('group', group);
		}
		if (isSpecItemDelta) {
			urlBuilder.append('only-spec-item-findings', isSpecItemDelta);
		}
		return this.get<FindingDelta>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Retrieves the findings delta for a given merge request.
	 *
	 * @param project
	 * @param source The source commit of the merge request
	 * @param target The target commit of the merge request
	 * @param cacheKey Optional key for the merge-base info cache on the server
	 * @param findingsFilter The filter to apply to the delta
	 * @param callback
	 */
	public getMergeRequestFindingChurn(
		project: string,
		source: UnresolvedCommitDescriptor,
		target: UnresolvedCommitDescriptor,
		cacheKey: string | null,
		findingsFilter: FindingsFilter
	): Promise<FindingDelta> {
		const urlBuilder = url`api/projects/${project}/merge-requests/finding-churn`;
		urlBuilder.append('source', source);
		urlBuilder.append('target', target);
		urlBuilder.append('merge-base-cache-key', cacheKey);
		findingsFilter.appendToUrl(urlBuilder);
		urlBuilder.append('blacklisted', EBlacklistingOption.ALL.name);
		return this.get<FindingDelta>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Retrieves the merge-base information for a merge request.
	 *
	 * @param project
	 * @param source The source commit of the merge request
	 * @param target The target commit of the merge request
	 * @param callback The callback is called with an object of Java type
	 *   MergeRequestParentInfoTransport.MergeRequestParentInfoTransport.
	 */
	public getMergeBase(
		project: string,
		source: UnresolvedCommitDescriptor,
		target: UnresolvedCommitDescriptor
	): Promise<MergeRequestParentInfoTransport> {
		const urlBuilder = url`api/projects/${project}/merge-requests/parent-info`;
		urlBuilder.append('source', source);
		urlBuilder.append('target', target);
		urlBuilder.append('merge-base-only', true);
		return this.get(urlBuilder);
	}

	/**
	 * Retrieves the path- or type dependencies for a given project, uniform path and commit. Can also return inverse
	 * path dependencies but not inverse type dependencies.
	 *
	 * @param project
	 * @param uniformPath
	 * @param commit
	 * @param inverse Determines if inverse dependencies are used.
	 * @param callback
	 */
	private getDependencies(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor | null,
		inverse: boolean,
		callback: Callback<DependencyWithOccurrenceLocation[] | null>
	): void {
		const urlBuilder = url`api/projects/${project}/dependencies/${uniformPath}`;
		urlBuilder.append('t', commit);
		urlBuilder.append('inverse', inverse);
		this.withCallback(this.get<DependencyWithOccurrenceLocation[] | null>(urlBuilder), callback);
	}

	/** Retrieves the path dependencies for a given project, uniform path and commit. */
	public getPathDependencies(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor | null,
		callback: Callback<DependencyWithOccurrenceLocation[] | null>
	): void {
		this.getDependencies(project, uniformPath, commit, false, callback);
	}

	/**
	 * Retrieves the inverse path dependencies for a given project, uniform path and commit.
	 *
	 * @param project
	 * @param uniformPath
	 * @param commit
	 * @param callback The callback function with one argument of type Array.<string>.
	 */
	public getInversePathDependencies(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor | null,
		callback: Callback<DependencyWithOccurrenceLocation[] | null>
	): void {
		this.getDependencies(project, uniformPath, commit, true, callback);
	}

	/** Retrieves the component assignments for the given uniform path in all available architectures in the project. */
	public getArchitectureComponentAssignments(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<ArchitectureComponentAssignment[]> {
		const urlBuilder = url`api/projects/${project}/architectures/components`;
		urlBuilder.append('t', commit);
		urlBuilder.append('uniform-path', uniformPath);
		return this.get<ArchitectureComponentAssignment[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns a record of methods and a list of covering test cases, that are expected to cover the corresponding
	 * methods if they are rerun.
	 */
	public getOverlayDataForTestGapTreemap(testGapOptions: TestGapOptions): Promise<Record<string, string[]>> {
		const urlBuilder = TeamscaleServiceClient.createTgaUrlBuilder(
			url`api/projects/${testGapOptions.project}/overlay-tests`,
			testGapOptions
		);
		urlBuilder.appendAll(testGapOptions.toURLSearchParams());
		return this.get<TestCoverageOverlayData>(urlBuilder).then(overlayData => {
			const methodLocations = overlayData.methodLocations;
			const coveringTests = overlayData.testsCoveringMethods;

			const methodsToTest: Record<string, string[]> = {};
			for (let i = 0; i < methodLocations.length; ++i) {
				const methodLocation = methodLocations[i];
				const coveringTestSet = Object.values(coveringTests[i]?.tests ?? []).flat();
				if (methodLocation != null && !ArrayUtils.isEmptyOrUndefined(coveringTestSet)) {
					methodsToTest[
						TestGapUtils.createKeyFromLocation(methodLocation.uniformPath, methodLocation.region)
					] = coveringTestSet;
				}
			}
			return methodsToTest;
		});
	}

	/** Returns all partitions of the given project that contain testwise coverage. */
	public getTestwiseCoveragePartitions(
		project: string,
		commit: UnresolvedCommitDescriptor | null,
		issueId?: string
	): Promise<string[]> {
		const urlBuilder = url`api/projects/${project}/test-coverage/testwise/partitions`;
		urlBuilder.append('t', commit);
		urlBuilder.append('issue-id', issueId);
		return this.get(urlBuilder);
	}

	/**
	 * Returns the changes treemap for the given uniform path and the given commits.
	 *
	 * @param project The project
	 * @param uniformPath
	 * @param commit1
	 * @param commit2
	 * @param areaMetric The index of the area metric in the schema
	 * @param width Width to render tree map
	 * @param height Height to render tree map
	 */
	public getChangeTreeMap(
		project: string,
		uniformPath: string,
		commit1: UnresolvedCommitDescriptor,
		commit2: UnresolvedCommitDescriptor,
		areaMetric: number,
		width: number,
		height: number
	): Promise<TreeMapNode> {
		const urlBuilder = url`api/projects/${project}/change-treemap`;
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('t1', commit1);
		urlBuilder.append('t2', commit2);
		urlBuilder.append('area-metric', areaMetric);
		urlBuilder.append('width', width);
		urlBuilder.append('height', height);
		return this.get<TreeMapNode>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Appends the given array of commits to a newly created URLSearchParams object. */
	private static getCommitsAsURLSearchParams(commits: UnresolvedCommitDescriptor[]): URLSearchParams {
		const searchParams = new URLSearchParams();
		for (const commit of commits) {
			searchParams.append('commit', commit.toString());
		}
		return searchParams;
	}

	/**
	 * Returns the commit alerts for the given commits in a given project.
	 *
	 * @param project The project
	 * @param commits The commits for which the commit alerts should be returned
	 */
	public getCommitAlerts(
		project: string,
		commits: UnresolvedCommitDescriptor[]
	): Promise<Array<CommitAlerts | null>> {
		return QUERY.postCommitAlerts(project, { commit: commits }).fetch().catch(this.getDefaultErrorHandler());
	}

	/**
	 * Retrieves the list of elements changed in a merge request (in the commits that would be merged in the merge
	 * request).
	 *
	 * @param project
	 * @param sourceCommit
	 * @param targetCommit
	 * @param cacheKey Optional key for the merge-base info cache on the server
	 * @param callback The callback function
	 */
	public getMergeRequestElementChurn(
		project: string,
		sourceCommit: UnresolvedCommitDescriptor,
		targetCommit: UnresolvedCommitDescriptor,
		cacheKey: string | null
	): Promise<TokenElementChurnInfo[]> {
		const urlBuilder = url`api/projects/${project}/merge-requests/token-element-churn`;
		urlBuilder.append('source', sourceCommit);
		urlBuilder.append('target', targetCommit);
		urlBuilder.append('merge-base-cache-key', cacheKey);
		return this.get<TokenElementChurnInfo[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the diffs for the two elements. The full path contains the project and uniform path (separated by slash)
	 * and optionally the commit separated by '@' sign.
	 *
	 * @param leftPath The full path for the left element in the diffs.
	 * @param rightPath The full path for the left element in the diffs.
	 * @param regions Regions to focus on in the diff (e.g. a clone region)
	 * @param normalize Decides whether or not the comparison should be done between the normalized codes
	 */
	public getDiffs(
		leftPath: string,
		rightPath: string,
		regions: string | null,
		normalize = false
	): Promise<DiffDescription[]> {
		const urlBuilder = url`api/compare-elements`;
		urlBuilder.append('left', leftPath);
		urlBuilder.append('right', rightPath);
		urlBuilder.append('region', regions);
		urlBuilder.append('normalize', normalize);
		return this.get<DiffDescription[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the treemap for the given uniform path using the specified metric and assessment.
	 *
	 * @param project The project
	 * @param uniformPath
	 * @param areaMetric The metric index to determine the area of displayed nodes
	 * @param colorMetric The metric index to determine color of displayed nodes
	 * @param width Width to render tree map
	 * @param height Height to render tree map
	 * @param color Color to use for numerical metrics
	 * @param includeFileRegexes Regular expressions for included files.
	 * @param excludeFileRegexes Regular expressions for excluded files.
	 * @param endCommit
	 * @param callback The callback function with one argument of Java type TreeMapNode
	 * @param endCommit
	 * @param minValue Minimum value for the color range
	 * @param maxValue Maximum value for the color range
	 */
	public getTreeMap(
		project: string,
		uniformPath: string,
		areaMetric: number | null,
		colorMetric: number | null,
		width: number,
		height: number,
		color: number[] | null,
		includeFileRegexes: string[] | undefined,
		excludeFileRegexes: string[] | undefined,
		endCommit?: UnresolvedCommitDescriptor | null,
		minValue?: number | null,
		maxValue?: number | null,
		partitions?: string[],
		enableColorBlindMode?: boolean
	): Promise<TreeMapNode> {
		const urlBuilder = TeamscaleServiceClient.createTreemapBaseURLBuilder(
			url`api/projects/${project}/metrics/treemap`,
			areaMetric,
			colorMetric,
			color,
			endCommit,
			includeFileRegexes,
			excludeFileRegexes
		);
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('width', width);
		urlBuilder.append('height', height);
		urlBuilder.append('min-value-for-color', minValue);
		urlBuilder.append('max-value-for-color', maxValue);
		urlBuilder.appendMultiple('partition', partitions);
		urlBuilder.append('all-partitions', partitions === undefined);
		urlBuilder.append('color-blind-mode', enableColorBlindMode);

		return this.get<TreeMapNode>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the license treemap.
	 *
	 * @param project The project
	 * @param path The sub-path
	 * @param customerLicenses String with comma-separated names for customer licenses
	 * @param areaMetric The metric index to determine the area of displayed nodes
	 * @param width Width to render tree map
	 * @param height Height to render tree map
	 */
	public getLicenseTreeMap(
		project: string,
		path: string,
		customerLicenses: string[],
		areaMetric: number,
		width: number,
		height: number
	): Promise<FilteredTreeMapWrapper> {
		const urlBuilder = TeamscaleServiceClient.getTreemapURLBuilder(
			url`api/projects/${project}/audit/copyright-licenses/treemap`,
			path,
			areaMetric,
			areaMetric
		);
		urlBuilder.append('width', width);
		urlBuilder.append('height', height);
		urlBuilder.appendMultiple('customer-license', customerLicenses);
		return this.get<FilteredTreeMapWrapper>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	private static getTreemapURLBuilder(
		urlBuilder: URLBuilder,
		uniformPath: string,
		areaMetric: number,
		colorMetric: number
	): URLBuilder {
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('area-metric', areaMetric);
		urlBuilder.append('color-metric', colorMetric);
		return urlBuilder;
	}

	/**
	 * Returns the dependency treemap for the technology scan.
	 *
	 * @param project The project
	 * @param path The sub-path
	 * @param packageDepth Filters dependencies based on depth
	 * @param isColorGradationActive To determine if the treemap should be shaded gradually
	 * @param dependency String with selected dependency for which the treemap should be displayed
	 * @param areaMetric The metric index to determine the area of displayed nodesc
	 * @param width Width to render tree map
	 * @param height Height to render tree map
	 */
	public getTechnologyTreeMapWrapper(
		project: string,
		path: string,
		packageDepth: number,
		isColorGradationActive: boolean,
		dependency: string,
		areaMetric: number,
		width: number,
		height: number
	): Promise<FilteredTreeMapWrapper> {
		const urlBuilder = TeamscaleServiceClient.getTreemapURLBuilder(
			url`api/projects/${project}/audit/technology/treemap`,
			path,
			areaMetric,
			areaMetric
		);
		urlBuilder.append('width', width);
		urlBuilder.append('height', height);
		urlBuilder.append('dependency', dependency);
		urlBuilder.append('package-depth', packageDepth);
		urlBuilder.append('is-color-gradation-active', isColorGradationActive);

		return this.get<FilteredTreeMapWrapper>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the file summary as list indicating file types annoated with lines of code.
	 *
	 * @param path String with path to folder for which file summary is requested
	 * @param includes
	 * @param excludes
	 */
	public getFileSummaryList(
		path: string,
		includes: string[],
		excludes: string[]
	): Promise<PairList<string, FileSummaryInfoRecord>> {
		const urlBuilder = url`api/file-summary`;
		urlBuilder.append('path', path);
		urlBuilder.appendMultiple('includes', includes);
		urlBuilder.appendMultiple('excludes', excludes);
		return this.get<PairList<string, FileSummaryInfoRecord>>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns all third-party dependencies along with the percentage of affected files for each dependency.
	 * Dependencies are cropped based on the given maximum package depth.
	 *
	 * @param project The project
	 * @param path The sub-path
	 * @param packageDepth Maximum package depth for dependencies to be displayed
	 */
	public getTechnologyDependencies(
		project: string,
		path: string,
		packageDepth: number
	): Promise<Record<string, number>> {
		const urlBuilder = url`api/projects/${project}/audit/technology/dependencies/`;
		urlBuilder.append('uniform-path', path);
		urlBuilder.append('package-depth', packageDepth);
		return this.get<Record<string, number>>(urlBuilder);
	}

	/**
	 * Returns the TGA assessment trend for the given project and uniform path. Allows specifying baseline and end
	 * timestamp as well as partitions to use.
	 */
	public getTestGapTrend(testGapOptions: TestGapOptions): Promise<MetricTrendEntry[]> {
		const urlBuilder = TeamscaleServiceClient.createTgaUrlBuilder(
			url`api/projects/${testGapOptions.project}/test-gaps/trend`,
			testGapOptions
		);
		urlBuilder.appendAll(testGapOptions.toURLSearchParams());
		return this.get<MetricTrendEntry[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Retrieves the usage treemap wrapper for the given uniform path.
	 *
	 * @param project The project.
	 * @param uniformPath The uniform path to focus on.
	 * @param baselineTimestamp Timestamp of the baseline to use.
	 * @param headTimestamp Timestamp of the revision until which to consider trace and change information.
	 * @param width Width to render tree map.
	 * @param height Height to render tree map.
	 */
	public getUsageTreeMap(
		project: string,
		uniformPath: string,
		baselineTimestamp: UnresolvedCommitDescriptor | null,
		headTimestamp: UnresolvedCommitDescriptor | null,
		width: number,
		height: number
	): Promise<UsageTreeMapWrapper> {
		const urlBuilder = url`api/projects/${project}/code-usage/treemap`;
		urlBuilder.append('baseline', baselineTimestamp);
		urlBuilder.append('end', headTimestamp);
		urlBuilder.append('width', width);
		urlBuilder.append('height', height);
		urlBuilder.append('uniform-path', uniformPath);
		return this.get<UsageTreeMapWrapper>(urlBuilder);
	}

	/**
	 * Downloads a CSV file summarizing the current usage state for the given uniform path.
	 *
	 * @param project The project.
	 * @param uniformPath The uniform path to focus on.
	 * @param baselineTimestamp Timestamp of the baseline to use.
	 * @param headTimestamp Timestamp of the revision until which to consider trace and change information.
	 */
	public downloadUsageResultsAsCSV(
		project: string,
		uniformPath: string,
		baselineTimestamp: UnresolvedCommitDescriptor | null,
		headTimestamp: UnresolvedCommitDescriptor | null
	): void {
		const urlBuilder = url`api/projects/${project}/code-usage/csv`;
		urlBuilder.append('baseline', baselineTimestamp);
		urlBuilder.append('end', headTimestamp);
		urlBuilder.append('uniform-path', uniformPath);
		window.location.href = urlBuilder.getURL();
	}

	/**
	 * Retrieves the TGA treemap wrapper for the given uniform path.
	 *
	 * @param testGapOptions The general TGA options
	 * @param width Width to render tree map.
	 * @param height Height to render tree map.
	 * @param excludeUnchangedMethods Whether to hide unchanged methods.
	 * @param handleErrorsWithPromise
	 */
	public async getTGATreeMap(
		testGapOptions: TestGapOptions,
		width: number,
		height: number,
		excludeUnchangedMethods: boolean,
		handleErrorsWithPromise?: boolean
	): Promise<TestGapTreeMapWrapper> {
		if (width === 0 || height === 0) {
			throw new Error('Attempted to render treemap with size 0.');
		}
		const urlBuilder = TeamscaleServiceClient.createTgaUrlBuilder(
			url`api/projects/${testGapOptions.project}/test-gaps/treemap`,
			testGapOptions
		);
		urlBuilder.append('width', width);
		urlBuilder.append('height', height);
		urlBuilder.append('exclude-unchanged-methods', excludeUnchangedMethods);
		urlBuilder.appendAll(testGapOptions.toURLSearchParams());
		const promise = this.get<TestGapTreeMapWrapper>(urlBuilder);
		if (handleErrorsWithPromise) {
			return promise;
		} else {
			return promise.catch(this.getDefaultErrorHandler());
		}
	}

	/** Returns the TGA percentage value for the given parameters. */
	public getTGAPercentage(testGapOptions: TestGapOptions): Promise<number> {
		const urlBuilder = TeamscaleServiceClient.createTgaUrlBuilder(
			url`api/projects/${testGapOptions.project}/test-gaps/percentage`,
			testGapOptions
		);
		urlBuilder.appendAll(testGapOptions.toURLSearchParams());
		return this.get<number>(urlBuilder);
	}

	/** Returns the TGA summary for all non-issue changes. */
	public getNonIssueTgaCommits(
		project: string,
		width: number,
		height: number,
		baselineCommit: UnresolvedCommitDescriptor,
		endCommit: UnresolvedCommitDescriptor | null,
		coverageSourceParameters: CoverageSourceQueryParameters
	): Promise<UnlinkedChangesWrapper> {
		const urlBuilder = url`api/projects/${project}/unlinked-changes/treemap`;
		urlBuilder.append('end', endCommit);
		urlBuilder.append('baseline', baselineCommit);
		urlBuilder.append('width', width);
		urlBuilder.append('height', height);
		urlBuilder.appendAll(ServiceClient.convertToKebabCaseURLSearchParams(coverageSourceParameters));
		return this.get(urlBuilder);
	}

	/** Checks if unlinked changes exist since a given baseline. */
	public unlinkedChangesExist(
		project: string,
		baselineCommit: UnresolvedCommitDescriptor,
		coverageSourceParameters: CoverageSourceQueryParameters
	): Promise<boolean> {
		const urlBuilder = url`api/projects/${project}/unlinked-changes`;
		urlBuilder.append('baseline', baselineCommit);
		urlBuilder.appendAll(ServiceClient.convertToKebabCaseURLSearchParams(coverageSourceParameters));
		return this.get(urlBuilder);
	}

	/**
	 * Returns the aggregated TGA summary for all issues matching the given issue query.
	 *
	 * If no branch is explicitly given, a branch is auto-selected for each issue based on its last commit.
	 */
	public getIssueQueryTgaSummary(
		project: string,
		issueQuery: string,
		issueTgaParameters: IssueTgaParameters,
		coverageSourceParameters: CoverageSourceQueryParameters
	): Promise<TgaSummary> {
		const urlBuilder = url`api/projects/${project}/issue-query/tga-summary`;
		urlBuilder.append('query', IssueQueryInputHandler.escapeIssueQuery(issueQuery));
		urlBuilder.append('branch-name', issueTgaParameters.branchName);
		urlBuilder.append('auto-select-branch', issueTgaParameters.autoSelectBranch);
		urlBuilder.append('include-child-issues', issueTgaParameters.includeChildIssues);
		urlBuilder.appendAll(ServiceClient.convertToKebabCaseURLSearchParams(coverageSourceParameters));
		return this.get(urlBuilder);
	}

	/**
	 * Returns the TGA overview information for the given uniform path.
	 *
	 * @param testGapOptions The options for the test gap service call. Does ignore any TgaRequestQueryOptions objects.
	 */
	public getTGAOverviewInformation(testGapOptions: TestGapOptions): Promise<CoverageSourceInfo[]> {
		const urlBuilder = TeamscaleServiceClient.createTgaUrlBuilder(
			url`api/projects/${testGapOptions.project}/test-gaps/overview`,
			testGapOptions
		);
		urlBuilder.appendAll(testGapOptions.toURLSearchParams());
		return this.get<CoverageSourceInfo[]>(urlBuilder);
	}

	/**
	 * Creates a URLBuilder with the given Test Gap information already filled in for a service call.
	 *
	 * @param tgaOptions The options for the test gap service call.
	 */
	private static createTgaUrlBuilder(urlBuilder: URLBuilder, tgaOptions: TestGapOptions): URLBuilder {
		urlBuilder.append('uniform-path', tgaOptions.uniformPath);
		urlBuilder.append('baseline', tgaOptions.baselineCommit);
		urlBuilder.append('end', tgaOptions.headTimestamp);
		if (tgaOptions.mergeRequestMode) {
			urlBuilder.append('merge-request-mode', true);
			urlBuilder.append('merge-base-cache-key', tgaOptions.mergeBaseCacheKey);
			urlBuilder.append('merge-request-identifier', tgaOptions.mergeRequestIdentifier);
		}
		urlBuilder.append('auto-select-branch', tgaOptions.autoSelectBranchForIssues);
		urlBuilder.append('branch-name', tgaOptions.selectedIssueBranch);
		urlBuilder.append('issue-id', tgaOptions.issueId);
		urlBuilder.append('include-child-issues', tgaOptions.includeChildIssues);
		urlBuilder.append('test-uniform-path', tgaOptions.testUniformPath);
		urlBuilder.append('test-query', tgaOptions.testQuery);
		urlBuilder.append('only-executed-methods', tgaOptions.onlyExecutedMethods);
		return urlBuilder;
	}

	/**
	 * Return a delta map showing the difference of the methods tested by the test between the two commits
	 *
	 * @param project The project
	 * @param testExecution The path to the test. Starting with -test-execution-
	 * @param partition The partition of the test
	 * @param width The width of the tree map to be rendered
	 * @param height The height of the tree map to be rendered
	 * @param commit The first commit
	 * @param commit2 The second (later) commit
	 * @param excludeUnchangedMethods If only methods that have changed should be shown
	 */
	public getTestSpecificMethodsChangedTreeMap(
		project: string,
		testExecution: string,
		partition: string,
		width: number,
		height: number,
		commit: UnresolvedCommitDescriptor,
		commit2: UnresolvedCommitDescriptor,
		excludeUnchangedMethods: boolean
	): Promise<MethodTreeMapNode> {
		const urlBuilder = url`api/projects/${project}/test-executions/${testExecution}/related-changes/treemap`;
		urlBuilder.append('width', width);
		urlBuilder.append('height', height);
		urlBuilder.append('baseline', commit);
		urlBuilder.append('end', commit2);
		urlBuilder.append('partitions', partition);
		urlBuilder.append('exclude-unchanged-methods', excludeUnchangedMethods);
		return this.get<MethodTreeMapNode>(urlBuilder);
	}

	/** Downloads a CSV file summarizing the current test state for the given uniform path. */
	public async downloadTGAResultsAsCSV(
		testGapOptions: TestGapOptions,
		excludeUnchangedMethods: boolean
	): Promise<void> {
		const urlBuilder = TeamscaleServiceClient.createTgaUrlBuilder(
			url`api/projects/${testGapOptions.project}/test-gaps.csv`,
			testGapOptions
		);
		urlBuilder.append('exclude-unchanged-methods', excludeUnchangedMethods);
		urlBuilder.appendAll(testGapOptions.toURLSearchParams());
		try {
			const blob = await this.get<Blob>(urlBuilder, {
				acceptType: EMimeType.CSV.type,
				responseType: 'blob'
			});
			const FileSaver = (await import('file-saver')).default;
			FileSaver.saveAs(blob, 'TgaData.csv');
		} catch (e) {
			await this.getDefaultErrorHandler()(e);
		}
	}

	/** Returns all partitions for all visible projects that can be used for a TGA. */
	public getGlobalTestCoveragePartitions(): Promise<ProjectPartitionsInfo[]> {
		return this.get<ProjectPartitionsInfo[]>(url`api/test-coverage/line-based/partitions`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Returns all partitions for a project that can be used for a TGA at the specified commit. */
	public getTestCoveragePartitions(project: string, commit?: UnresolvedCommitDescriptor | null): Promise<string[]> {
		const urlBuilder = url`api/projects/${project}/test-coverage/line-based/partitions`;
		urlBuilder.append('t', commit);
		return this.get<string[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns all partitions for a project that can be used for a TGA. */
	public getAllTestCoveragePartitions(project: string): Promise<string[]> {
		return this.get<string[]>(url`api/projects/${project}/test-coverage/line-based/partitions/all`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Returns all partitions of a project for which test executions have been uploaded. */
	public getAllTestExecutionPartitions(project: string, commit: UnresolvedCommitDescriptor): Promise<string[]> {
		const urlBuilder = url`api/projects/${project}/test-executions/partitions/all`;
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/**
	 * Returns the list of CodeCityElement items for the given uniform path using the specified metric and assessment.
	 *
	 * @param areaMetric The metric index
	 * @param heightMetric The metric index
	 * @param colorMetric The metric index
	 * @param color Color to use for numerical metrics
	 * @param includeFileRegexes Regular expressions for included files.
	 * @param excludeFileRegexes Regular expressions for excluded files.
	 */
	public getCodeCity(
		project: string,
		uniformPath: string,
		areaMetric: number | null,
		heightMetric: number,
		colorMetric: number | null,
		color: number[],
		includeFileRegexes: string[],
		excludeFileRegexes: string[] | null,
		enableColorBlindMode: boolean,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<CodeCityNode> {
		const urlBuilder = TeamscaleServiceClient.createTreemapBaseURLBuilder(
			url`api/projects/${project}/code-city`,
			areaMetric,
			colorMetric,
			color,
			commit,
			includeFileRegexes,
			excludeFileRegexes
		);
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('height', heightMetric);
		urlBuilder.append('color-blind-mode', enableColorBlindMode);
		return this.get<CodeCityNode>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns list of metrics for each file.
	 *
	 * @param metricIndices The indices of the metrics used to display the scatter plot
	 */
	public getScatterPlot(
		project: string,
		uniformPath: string,
		metricIndices: number[],
		commit?: UnresolvedCommitDescriptor | null
	): Promise<MetricDirectoryEntry[]> {
		const urlBuilder = url`api/projects/${project}/metrics-scatter-plot`;
		urlBuilder.appendMultiple('metric-indices', metricIndices);
		urlBuilder.append('t', commit);
		urlBuilder.append('uniform-path', uniformPath);
		return this.get<MetricDirectoryEntry[]>(urlBuilder);
	}

	/** Returns all baselines in the given project. */
	public getAllBaselines(project: string): Promise<BaselineInfo[]> {
		return this.get(url`api/projects/${project}/baselines`);
	}

	/** Returns all baselines for the given project list. */
	public getAllBaselinesByProjects(projectIds: string[]): Promise<Record<string, BaselineInfo[]>> {
		const urlBuilder = url`api/baselines`;
		urlBuilder.appendMultiple('project', projectIds);
		return this.get(urlBuilder);
	}

	/**
	 * Lists the known versions of the software system represented by the given project. Currently only works for .NET
	 * projects.
	 */
	public listSystemVersions(project: string): Promise<DotNetVersionInfo[]> {
		return this.get<DotNetVersionInfo[]>(url`api/projects/${project}/dot-net-versions`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Lists the known versions of the software system represented by the given project list. Currently only works for
	 * .NET projects.
	 */
	public getAllSystemVersionsByProjects(projectIds: string[]): Promise<Record<string, DotNetVersionInfo[]>> {
		const urlBuilder = url`api/dot-net-versions`;
		urlBuilder.appendMultiple('project', projectIds);
		return this.get(urlBuilder);
	}

	/** Retrieves a single system version info. Throws a 404 if the version does not exist. */
	public getSystemVersionInfo(project: string, versionName: string): Promise<DotNetVersionInfo> {
		return this.get(url`api/projects/${project}/dot-net-versions/${versionName}`);
	}

	/**
	 * Retrieves the system performance metrics.
	 *
	 * @param maxSeconds Max number of seconds of trend data to be returned.
	 */
	public getSystemPerformanceMetricsTrend(maxSeconds: number): Promise<PerformanceMetricsEntry[]> {
		const urlBuilder = url`api/performance-metrics-trend`;
		urlBuilder.append('max-seconds', maxSeconds);
		return this.get<PerformanceMetricsEntry[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Lists a baseline's detailed info.
	 *
	 * @param project The name of the project
	 * @param baseline The name of the baseline
	 */
	public getBaselineInfo(project: string, baseline: string): Promise<BaselineInfo> {
		return this.get(url`api/projects/${project}/baselines/${baseline}`);
	}

	/** Sets a baseline for a project. */
	public setBaseline(project: string, baseline: BaselineInfo, oldName?: string): Promise<void> {
		const urlBuilder = url`api/projects/${project}/baselines/${baseline.name}`;
		urlBuilder.append('old-name', oldName);
		return this.put<void>(urlBuilder, baseline).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Deletes a baseline for a project.
	 *
	 * @returns 'success' if the request went through
	 */
	public deleteBaseline(project: string, baseline: string): Promise<string> {
		return this.delete<string>(url`api/projects/${project}/baselines/${baseline}`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Fetches the (line-based) change regions for a file.
	 *
	 * @param project The project to get the changes for.
	 * @param uniformPath The uniform path of the element to get the changes for.
	 * @param baseline The name of a baseline or a baseline timestamp; if provided, only changes after the baseline are
	 *   returned.
	 * @param commit If provided, returns the changes as seen from a historic version identified via this timestamp.
	 */
	public getCodeChangeRegions(
		project: string,
		uniformPath: string,
		baseline?: string | number | null,
		commit?: number | string | UnresolvedCommitDescriptor | null
	): Promise<ChangeRegion[]> {
		const urlBuilder = url`api/projects/${project}/code-changes/${uniformPath}`;
		urlBuilder.append('baseline', baseline);
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/**
	 * Retrieves a flagged finding. Can return null in case the finding is not marked as tolerated.
	 *
	 * @param project The name of the project
	 * @param findingId The ID of the finding
	 * @param commit The branch/timestamp where the finding should be retrieved, or null for latest finding on default
	 *   branch
	 * @param handleErrorsWithPromise If true and the service call fails, the reject method of the promise is called
	 *   with the response data. When set to <code>true</code>, attach an error handler using '.catch(serviceError =>
	 *   ...)' directly to the method call. The error object passed to catch will be of type {@link ServiceCallError}. If
	 *   this is set to false and the service call fails, a red error page will be shown.
	 */
	public getFlaggedFindingInfo(
		project: string,
		findingId: string,
		commit: UnresolvedCommitDescriptor | null,
		handleErrorsWithPromise?: boolean
	): Promise<UserResolvedFindingBlacklistInfo | null> {
		const promise = QUERY.getFlaggedFindingInfo(project, findingId, { t: commit ?? undefined }).fetch();
		if (handleErrorsWithPromise) {
			return promise;
		} else {
			return promise.catch(this.getDefaultErrorHandler());
		}
	}

	/**
	 * Retrieves the flagging information for a list of findings.
	 *
	 * @param project The name of the project
	 * @param findingIds The IDs of the findings
	 * @param commit The branch/timestamp until where the findings should be retrieved, or null for latest findings on
	 *   default branch
	 */
	public getFlaggingInformationForFindings(
		project: string,
		findingIds: string[],
		commit: UnresolvedCommitDescriptor | null
	): Promise<FindingBlacklistInfo[]> {
		return QUERY.getFlaggedFindingsInfos(project, { t: commit ?? undefined }, findingIds).fetch();
	}

	/**
	 * Retrieves all blacklisted findings with the provided type.
	 *
	 * @param project The name of the project
	 * @param toCommit The branch/timestamp until where the findings should be retrieved, or the target commit of a
	 *   merge request.
	 * @param fromCommit The branch/timestamp starting from where the findings should be retrieved, or the source commit
	 *   of a merge request.
	 * @param mergeBaseCacheKey The cacheStorageKey of the MergeRequestParentInfoTransport in case the commits are part
	 *   of a merge request.
	 * @param handleErrorsWithPromise If true and the service call fails, the reject method of the promise is called
	 *   with the response data. When set to <code>true</code>, attach an error handler using '.catch(serviceError =>
	 *   ...)' directly to the method call. The error object passed to catch will be of type {@link ServiceCallError}. If
	 *   this is set to false and the service call fails, a red error page will be shown.
	 */
	public getAllFlaggedFindingInfos(
		project: string,
		toCommit: UnresolvedCommitDescriptor,
		fromCommit?: UnresolvedCommitDescriptor,
		mergeBaseCacheKey?: string,
		handleErrorsWithPromise?: boolean
	): Promise<FindingBlacklistInfo[]> {
		const promise = QUERY.getFlaggedFindings(project, {
			'merge-base-cache-key': mergeBaseCacheKey ?? undefined,
			from: fromCommit ?? undefined,
			to: toCommit
		}).fetch();
		if (handleErrorsWithPromise) {
			return promise;
		} else {
			return promise.catch(this.getDefaultErrorHandler());
		}
	}

	/**
	 * Flags/unflags multiple findings.
	 *
	 * @param commit The branch/timestamp where the finding should be marked, or null for HEAD on default branch
	 * @param handleErrorsWithPromise If true and the service call fails, the reject method of the promise is called
	 *   with the response data. When set to <code>true</code>, attach an error handler using '.catch(serviceError =>
	 *   ...)' directly to the method call. The error object passed to catch will be of type {@link ServiceCallError}. If
	 *   this is set to false and the service call fails, a red error page will be shown.
	 */
	public flagFindings(
		blacklistType: EFindingBlacklistType,
		blacklistOperation: EFindingBlacklistOperation,
		project: string,
		findingIds: string[],
		findingBlacklistInfo: FindingBlacklistInfo | null,
		commit: UnresolvedCommitDescriptor | null,
		handleErrorsWithPromise?: boolean
	): Promise<void> {
		const promise = QUERY.flagFindings(
			project,
			{ t: commit ?? undefined, operation: blacklistOperation.name, type: blacklistType.name },
			{
				blacklistInfo: findingBlacklistInfo,
				findingIds
			} as FindingBlacklistRequestBody
		).fetch();

		if (handleErrorsWithPromise) {
			return promise;
		} else {
			return promise.catch(this.getDefaultErrorHandler());
		}
	}

	/**
	 * Returns the resource findings.
	 *
	 * @param project
	 * @param uniformPath
	 * @param commit
	 * @param pretty Whether to adjust finding locations for pretty printed code
	 * @param isFile If true, then we filter the findings for the exact uniform path. This prevents findings to show up
	 *   files where the given path is a prefix of, for example `foo.h` any `foo.hpp`
	 */
	public getResourceFindings(
		project: string,
		uniformPath: string,
		commit?: number | string | UnresolvedCommitDescriptor | null,
		pretty?: boolean,
		onlySpecItemFindings?: boolean,
		isFile?: boolean
	): Promise<TrackedFinding[]> {
		const urlBuilder = TeamscaleServiceClient.createFindingsBaseURLBuilder(
			url`api/projects/${project}/findings/list`,
			uniformPath,
			{
				onlySpecItemFindings
			}
		);
		urlBuilder.append('t', commit);
		urlBuilder.append('pretty', pretty);
		if (isFile) {
			urlBuilder.append('included-paths', uniformPath);
		}
		return this.get<TrackedFinding[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns all findings recursively for the given path using the given category and group as a filter (both may be
	 * null).
	 *
	 * @param project The project
	 * @param uniformPath The uniform path
	 * @param findingsFilter
	 * @param commit The timestamp
	 * @param baseline? The name of the current baseline, or its timestamp
	 * @param includeChangedFindings? Whether to include findings in changed code
	 * @param onlyChangedFindings? Whether to view findings in changed code only
	 * @param blacklisting? The blacklisting option
	 * @param sortBy? One of message, location, group or a finding property
	 * @param sortOrder? One of ascending or descending
	 * @param pretty? Whether to adjust finding locations for pretty printed code
	 * @param start? Start index of findings to return
	 * @param all? If this is true, all findings are returned, regardless of the other given parameters.
	 * @param qualifiedName? If given, the qualified name filter will be applied.
	 * @param max? Limits the number of findings that are returned
	 * @param isFile If true, then we filter the findings for the exact uniform path. This prevents findings to show up
	 *   files where the given path is a prefix of, for example `foo.h` any `foo.hpp`
	 */
	public getResourceFindingsList(
		project: string,
		uniformPath: string,
		findingsFilter: FindingsFilter,
		commit: UnresolvedCommitDescriptor | null,
		baseline: string | number | null,
		includeChangedFindings?: boolean | null,
		onlyChangedFindings?: boolean | null,
		blacklisting?: string | null,
		sortBy?: string | null,
		sortOrder?: ESortOrderEntry | null,
		pretty?: boolean,
		start?: number,
		all?: boolean,
		qualifiedName?: string | null,
		max?: number | null,
		isFile?: boolean | null
	): Promise<TrackedFinding[]> {
		if (isFile) {
			findingsFilter.addIncludedPath(uniformPath);
		}

		const urlBuilder = this.getResourceFindingsListUrlBuilder(
			url`api/projects/${project}/findings/list`,
			uniformPath,
			findingsFilter,
			commit,
			baseline,
			includeChangedFindings,
			onlyChangedFindings,
			blacklisting,
			sortBy,
			sortOrder,
			false,
			pretty,
			start,
			all,
			qualifiedName,
			max
		);
		return this.get<TrackedFinding[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Retrieves findings for a file by the indicated ids.
	 *
	 * @param project Teamscale project
	 * @param findingIds List of Teamscale finding ids.
	 * @param commit Timestamp and branch info
	 * @param handleErrorWithPromise
	 */
	public getResourceFindingsByIds(
		project: string,
		findingIds: string[],
		commit?: UnresolvedCommitDescriptor | null,
		handleErrorWithPromise?: boolean
	): Promise<Array<TrackedFinding | null>> {
		const urlBuilder = url`api/projects/${project}/findings/list/with-ids`;
		urlBuilder.append('t', commit);
		const promise = this.post<Array<TrackedFinding | null>>(urlBuilder, findingIds);
		if (handleErrorWithPromise) {
			return promise;
		} else {
			return promise.catch(this.getDefaultErrorHandler());
		}
	}

	/**
	 * Returns all findings recursively for the given path using the given category and group as a filter (both may be
	 * null).
	 *
	 * @param project The project
	 * @param uniformPath The uniform path
	 * @param findingsFilter
	 * @param commit The timestamp
	 * @param baseline? The name of the current baseline, or its timestamp
	 * @param includeChangedFindings? Whether to include findings in changed code
	 * @param onlyChangedFindings? Whether to view findings in changed code only
	 * @param blacklisting? The blacklisting option
	 * @param sortBy? One of message, location, group or a finding property
	 * @param sortOrder? One of ascending or descending
	 * @param onlySpecItemFindings? Whether to only include spec item findings. If false, we only include code findings
	 * @param pretty? Whether to adjust finding locations for pretty printed code
	 * @param start? Start index of findings to return
	 * @param all? If this is true, all findings are returned, regardless of the other given parameters.
	 * @param qualifiedName? If given, the qualified name filter will be applied.
	 */
	public getResourceFindingsListWithCount(
		findingsQueryOptions: FindingsQueryOptions,
		start?: number
	): Promise<ExtendedFindingsWithCount> {
		const urlBuilder = this.getResourceFindingsListUrlBuilder(
			url`api/projects/${findingsQueryOptions.project}/findings/list/with-count`,
			findingsQueryOptions.uniformPath,
			findingsQueryOptions.findingsFilter,
			findingsQueryOptions.commit,
			findingsQueryOptions.baseline,
			findingsQueryOptions.includeFindingsInChangedCode,
			findingsQueryOptions.onlyFindingsInChangedCode,
			findingsQueryOptions.blacklistingOption.name,
			findingsQueryOptions.sortOptions.sortByField,
			findingsQueryOptions.sortOptions.sortOrder.name,
			findingsQueryOptions.onlySpecItemFindings,
			false,
			start
		);
		return this.get<ExtendedFindingsWithCount>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the url builder for the resource findings list service calls.
	 *
	 * @param uniformPath The uniform path
	 * @param findingsFilter
	 * @param commit The timestamp
	 * @param baseline? The name of the current baseline, or its timestamp
	 * @param includeChangedFindings? Whether to include findings changed code
	 * @param onlyChangedFindings? Whether to view include findings changed code only
	 * @param blacklisting? The blacklisting option
	 * @param sortBy? One of message, location, group or a finding property
	 * @param sortOrder? One of ascending or descending
	 * @param onlySpecItemFindings? Whether to only include spec item findings. If false, we only include code findings
	 * @param pretty? Whether to adjust finding locations for pretty printed code
	 * @param start? Start index of findings to return
	 * @param all? If this is true, all findings are returned, regardless of the other given parameters.
	 * @param qualifiedName? If given, the qualified name filter will be applied.
	 * @param max? Limits the number of findings that are returned
	 */
	private getResourceFindingsListUrlBuilder(
		urlBuilder: URLBuilder,
		uniformPath: string,
		findingsFilter: FindingsFilter,
		commit: UnresolvedCommitDescriptor | null,
		baseline: string | number | null,
		includeChangedFindings?: boolean | null,
		onlyChangedFindings?: boolean | null,
		blacklisting?: string | null,
		sortBy?: string | null,
		sortOrder?: ESortOrderEntry | null,
		onlySpecItemFindings?: boolean,
		pretty?: boolean,
		start?: number,
		all?: boolean,
		qualifiedName?: string | null,
		max?: number | null
	): URLBuilder {
		TeamscaleServiceClient.createRecursiveFindingsBaseURLBuilder(
			urlBuilder,
			uniformPath,
			findingsFilter,
			commit,
			baseline,
			includeChangedFindings,
			onlyChangedFindings,
			blacklisting,
			onlySpecItemFindings
		);
		urlBuilder.append('sort-by', sortBy);
		urlBuilder.append('sort-order', sortOrder);
		urlBuilder.append('pretty', pretty);
		urlBuilder.append('start', start);
		urlBuilder.append('all', all);
		urlBuilder.append('qualified-name', qualifiedName);
		urlBuilder.append('max', max);
		return urlBuilder;
	}

	/**
	 * Returns the siblings of the given finding respecting filter and sort order.
	 *
	 * @param project The project
	 * @param id
	 * @param uniformPath The uniform path
	 * @param findingsFilter
	 * @param commit The timestamp
	 * @param baseline? The name of the current baseline, or its timestamp
	 * @param includeChangedFindings? Whether to include findings in changed code
	 * @param onlyChangedFindings? Whether to view findings in changed code only
	 * @param blacklisting? The blacklisting option
	 * @param sortBy? One of message, location, group or a finding property
	 * @param sortOrder? One of ascending or descending
	 * @param qualifiedName? If given, the qualified name filter will be applied.
	 */
	public getFindingsListPreviousNextSiblings(
		project: string,
		id: string,
		uniformPath: string,
		findingsFilter: FindingsFilter,
		commit: UnresolvedCommitDescriptor | null,
		baseline: string | number | null,
		includeChangedFindings?: boolean | null,
		onlyChangedFindings?: boolean | null,
		blacklisting?: string | null,
		sortBy?: string | null,
		sortOrder?: string | null,
		qualifiedName?: string | null
	): Promise<PreviousNextSiblings> {
		const urlBuilder = TeamscaleServiceClient.createRecursiveFindingsBaseURLBuilder(
			url`api/projects/${project}/findings/previousNextSiblings/${id}`,
			uniformPath,
			findingsFilter,
			commit,
			baseline,
			includeChangedFindings,
			onlyChangedFindings,
			blacklisting
		);
		urlBuilder.append('sort-by', sortBy);
		urlBuilder.append('sort-order', sortOrder);
		urlBuilder.append('qualified-name', qualifiedName);
		return this.get<PreviousNextSiblings>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Triggers the download of the findings list.
	 *
	 * @param format The export format used
	 * @param project The project
	 * @param uniformPath The uniform path
	 * @param findingsFilter Its timestamp changed code
	 * @param commit The timestamp property
	 * @param baseline? The name of the current baseline, or
	 * @param includeChangedFindings? Whether to include findings in changed code
	 * @param onlyChangedFindings? Whether to view findings in changed code only
	 * @param blacklisting? The blacklisting option
	 * @param sortBy? One of message, location, group or a finding
	 * @param sortOrder? One of ascending or descending
	 */
	public downloadFindingsList(format: EFindingsExportFormatEntry, findingQueryOptions: FindingsQueryOptions): void {
		const urlBuilder = TeamscaleServiceClient.createRecursiveFindingsBaseURLBuilder(
			url`api/projects/${findingQueryOptions.project}/findings/list/export/${format}`,
			findingQueryOptions.uniformPath,
			findingQueryOptions.findingsFilter,
			findingQueryOptions.commit,
			findingQueryOptions.baseline,
			findingQueryOptions.includeFindingsInChangedCode,
			findingQueryOptions.onlyFindingsInChangedCode,
			findingQueryOptions.blacklistingOption.name
		);
		urlBuilder.append('sort-by', findingQueryOptions.sortOptions.sortByField);
		urlBuilder.append('sort-order', findingQueryOptions.sortOptions.sortOrder.name);
		window.location.href = urlBuilder.getURL();
	}

	/** Returns the findings summary. */
	public getFindingsSummaryAsync(
		project: string,
		uniformPath: string,
		parameters?: FindingsSummaryQueryParameters
	): Promise<FindingsSummaryInfo> {
		const urlBuilder = TeamscaleServiceClient.createFindingsBaseURLBuilder(
			url`api/projects/${project}/findings/summary`,
			uniformPath,
			parameters
		);
		if (parameters && 'filterOptions' in parameters) {
			for (const key in parameters.filterOptions) {
				// @ts-ignore
				const value = parameters.filterOptions[key]!;
				if (Array.isArray(value)) {
					urlBuilder.appendMultiple(key, value);
				} else {
					urlBuilder.append(key, value);
				}
			}
		}
		return this.get<FindingsSummaryInfo>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns the available quality indicator and analysis group names from all configured projects. */
	public getGlobalIndicatorsAndGroups(): Promise<IndicatorsAndGroups> {
		return this.get<IndicatorsAndGroups>(url`api/global-indicators-and-groups`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Returns the finding type descriptions for the given project and finding type IDs.
	 *
	 * @param project The project
	 * @param findingTypeIds The finding type ids
	 */
	public getFindingTypeDescriptions(project: string, findingTypeIds: string[]): Promise<FindingTypeDescription[]> {
		const urlBuilder = url`api/projects/${project}/finding-type-descriptors`;
		return this.post(urlBuilder, findingTypeIds);
	}

	/**
	 * Returns the architecture assessment for the specified architecture file.
	 *
	 * @param project The project
	 * @param architecturePath The uniform path of the architecture file
	 * @param timestamp An optional timestamp for time-traveling
	 */
	public getArchitectureAssessment(
		project: string,
		architecturePath: string,
		timestamp?: number | string | UnresolvedCommitDescriptor | null
	): Promise<ArchitectureAssessmentInfo> {
		const urlBuilder = url`api/projects/${project}/architectures/assessments/${architecturePath}`;
		urlBuilder.append('t', timestamp);
		return this.get<ArchitectureAssessmentInfo>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the lookup mapping from types to file for the given project.
	 *
	 * @param project The project
	 * @param timestamp An optional timestamp for time-traveling
	 */
	public getTypeToFileLookup(
		project: string,
		timestamp?: number | string | UnresolvedCommitDescriptor | null
	): Promise<Map<string, string>> {
		const urlBuilder = url`api/projects/${project}/architectures/assessments/type-to-file-lookup`;
		urlBuilder.append('t', timestamp);
		return this.get<Record<string, string>>(urlBuilder).then(typeToFileLookup =>
			ObjectUtils.toMap(typeToFileLookup)
		);
	}

	/**
	 * Validates the given regex.
	 *
	 * @param regEx The regex to be checked for validity
	 * @returns Returns null (valid) or an error message.
	 */
	public validateRegEx(regEx: string): Promise<string | null> {
		const urlBuilder = url`api/validate-regex`;
		urlBuilder.append('regex', regEx);
		return this.get(urlBuilder);
	}

	/**
	 * Returns the analysed languages in the given project or analysis profile, or all available languages if project
	 * and analysis profile are null.
	 */
	public getConfigLanguages(project?: string): Promise<Language[]> {
		const urlBuilder = url`api/config-languages`;
		if (project != null) {
			urlBuilder.appendToPath(project);
		}
		return this.get<Language[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns an URLBuilder that append server type and name to the url.
	 *
	 * @param serverType The type of authentication server
	 * @param serverName The name of the authentication server
	 */
	private static startAuthenticationServiceUrl(
		urlBuilder: URLBuilder,
		serverType: string,
		serverName: string
	): URLBuilder {
		urlBuilder.append('server-type', serverType);
		urlBuilder.append('server-name', serverName);
		return urlBuilder;
	}

	/**
	 * Imports a user from an authentication server.
	 *
	 * @param userName The name of the user to import
	 * @param serverType The type of authentication server
	 * @param serverName The name of the authentication server
	 * @param callback
	 */
	public importUser(userName: string, serverType: string, serverName: string, callback: Callback<string>): void {
		this.importUserOrGroup('user', userName, serverType, serverName, callback);
	}

	/**
	 * Imports a group from an authentication server.
	 *
	 * @param groupName The name of the group to import
	 * @param serverType The type of authentication server
	 * @param serverName The name of the authentication server
	 * @param callback
	 */
	public importGroup(groupName: string, serverType: string, serverName: string, callback: Callback<string[]>): void {
		this.importUserOrGroup('group', groupName, serverType, serverName, callback);
	}

	/**
	 * Imports a user or a group from an authentication server.
	 *
	 * @param userOrGroup Either "user" or "group"
	 * @param userOrGroupName The name of the user or group to import
	 * @param serverType The type of authentication server
	 * @param serverName The name of the authentication server
	 * @param callback
	 */
	private importUserOrGroup<T>(
		userOrGroup: string,
		userOrGroupName: string,
		serverType: string,
		serverName: string,
		callback: Callback<T>
	): void {
		const urlBuilder = url`api/auth/import/${this.checkUserOrGroupAndPluralize(userOrGroup)}/${userOrGroupName}`;
		TeamscaleServiceClient.startAuthenticationServiceUrl(urlBuilder, serverType, serverName);
		urlBuilder.append(userOrGroup, userOrGroupName);
		this.withCallback(this.post<T>(urlBuilder), callback);
	}

	/**
	 * Checks that the input string is either 'user' or 'group' and converts to plural, as this is expected during the
	 * service call.
	 */
	private checkUserOrGroupAndPluralize(userOrGroup: string): string {
		switch (userOrGroup) {
			case 'user':
			case 'users':
				return 'users';
			case 'group':
			case 'groups':
				return 'groups';
			default:
				asserts.fail('Expected user or group as input but got ' + userOrGroup);
				return '';
		}
	}

	/**
	 * Synchronizes all users with the given authentication server.
	 *
	 * @param serverType The type of authentication server
	 * @param serverName The name of the authentication server
	 */
	public synchronizeUsers(serverType: string, serverName: string): Promise<string[]> {
		const urlBuilder = TeamscaleServiceClient.startAuthenticationServiceUrl(
			url`api/auth/synchronization/users`,
			serverType,
			serverName
		);
		return this.post<string[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Synchronizes all groups with the given authentication server.
	 *
	 * @param serverType The type of authentication server
	 * @param serverName The name of the authentication server
	 */
	public synchronizeGroups(serverType: string, serverName: string): Promise<string[]> {
		return this.synchronizeGroup(null, serverType, serverName);
	}

	/**
	 * Synchronizes one or all groups with the given authentication server.
	 *
	 * @param groupName The name of the group to synchronize or null to synchronize all groups
	 * @param serverType The type of authentication server
	 * @param serverName The name of the authentication server
	 */
	public synchronizeGroup(groupName: string | null, serverType: string, serverName: string): Promise<string[]> {
		const urlBuilder = url`api/auth/synchronization/groups`;
		if (groupName) {
			urlBuilder.appendToPath(groupName);
		}
		TeamscaleServiceClient.startAuthenticationServiceUrl(urlBuilder, serverType, serverName);
		return this.post<string[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Lists all configured authentication servers. */
	public listAllAuthenticationServers(): Promise<Record<string, string[]>> {
		return this.get<Record<string, string[]>>(url`api/auth/servers`).catch(this.getDefaultErrorHandler());
	}

	/** Returns the available tools for configuration. */
	public getConfigTools(languages: string[], projectId?: string): Promise<EAnalysisToolEntry[]> {
		const urlBuilder = url`api/configuration-tools`;
		if (projectId != null) {
			urlBuilder.appendToPath(projectId);
		}
		urlBuilder.appendMultiple('language', languages);
		return this.get<EAnalysisToolEntry[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the available tools for configuration.
	 *
	 * @param languages The selected languages (ELanguage).
	 * @param tools The selected tools (EAnalysisTool).
	 * @param profile An optional name of an existing analysis profile to obtain default values from.
	 */
	public getConfigTemplate(
		languages: string[],
		tools: string[],
		profile: string | null
	): Promise<ConfigurationTemplate> {
		const urlBuilder = url`api/configuration-template`;
		urlBuilder.appendMultiple('language', languages);
		urlBuilder.appendMultiple('tool', tools);
		urlBuilder.append('profile', profile);
		return this.get<ConfigurationTemplate>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns the available connectors. */
	public getConnectorDescriptors(): Promise<ConnectorDescriptorTransport[]> {
		return this.get<ConnectorDescriptorTransport[]>(url`api/connector-descriptors`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Returns the available external metrics. The callback receives a list of MetricSchemaChangeEntry. */
	public getExternalMetrics(): Promise<MetricSchemaChangeEntry[]> {
		return this.get<MetricSchemaChangeEntry[]>(url`api/external-metrics`).catch(this.getDefaultErrorHandler());
	}

	/** Adds an external metric definition. */
	public postExternalMetric(metric: MetricSchemaChangeEntry): Promise<void> {
		return this.post(url`api/external-metrics`, [metric]);
	}

	/** Updates an external metric definition. */
	public putExternalMetric(metric: MetricSchemaChangeEntry): Promise<void> {
		return this.put(url`api/external-metrics`, [metric]);
	}

	/** Deletes an external metric. */
	public deleteExternalMetric(metricName: string): Promise<void> {
		return this.delete<void>(url`api/external-metrics/${metricName}`);
	}

	/** Returns the configured external findings groups. */
	public getExternalFindingsGroups(): Promise<ExternalAnalysisGroup[]> {
		return this.get<ExternalAnalysisGroup[]>(url`api/external-findings/groups`);
	}

	/** Updates an external findings group. */
	public putExternalFindingsGroup(group: ExternalAnalysisGroup): Promise<void> {
		return this.put(url`api/external-findings/groups/${group.groupName}`, group);
	}

	/** Adds an external findings group. */
	public postExternalFindingsGroup(group: ExternalAnalysisGroup): Promise<void> {
		return this.post(url`api/external-findings/groups`, group);
	}

	/**
	 * Deletes an external findings group. Name is prefixed with 'group:' from caller
	 *
	 * @param name The name identifying some group
	 */
	public deleteExternalFindingsGroup(name: string): Promise<void> {
		return this.delete<void>(url`api/external-findings/groups/${name}`).catch(this.getDefaultErrorHandler());
	}

	/** Returns the configured external findings descriptions. */
	public getExternalFindingsDescriptions(): Promise<ExternalFindingsDescription[]> {
		return this.get<ExternalFindingsDescription[]>(url`api/external-findings/descriptions`);
	}

	/** Adds an external external findings description. */
	public postExternalFindingsDescription(description: ExternalFindingsDescription): Promise<void> {
		return this.post(url`api/external-findings/descriptions`, description);
	}

	/** Updates an external external findings description. */
	public putExternalFindingsDescription(description: ExternalFindingsDescription): Promise<void> {
		return this.put(url`api/external-findings/descriptions/${description.typeId}`, description);
	}

	/**
	 * Deletes an external findings description.
	 *
	 * @param typeId A string identifier for the description
	 */
	public deleteExternalFindingsDescription(typeId: string): Promise<'success'> {
		return this.delete<'success'>(url`api/external-findings/descriptions/${typeId}`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Imports external findings groups and/or descriptions. */
	public importExternalFindings(externalFindings: File): Promise<void> {
		const formData = new FormData();
		formData.append('external-findings', externalFindings);
		return this.post(url`api/external-findings/import`, formData);
	}

	/** Imports external metric descriptions. */
	public importExternalMetrics(externalMetrics: File): Promise<void> {
		const formData = new FormData();
		formData.append('metrics-descriptions-import-file', externalMetrics);
		return this.post(url`api/external-metrics/import`, formData);
	}

	/** Returns a mapping of analysis profile names to the file extensions used in the project */
	public getAnalysisProfileNamesToIncludeExcludePatternsMap(): Promise<Record<string, IncludeExcludePatterns>> {
		return this.get<Record<string, IncludeExcludePatterns>>(url`api/connectors/default-patterns`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Returns the quality indicators configured in the given project. */
	public getQualityIndicators(project: string): Promise<string[]> {
		return this.get<string[]>(url`api/projects/${project}/configuration/quality-indicators`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Returns the merged metric schema of all analysis profiles. This returns an array of schemas (one for each path).
	 *
	 * @param uniformPaths If given, the schemas for these paths are returned, otherwise only the root schema.
	 */
	public getGlobalMetricSchema(uniformPaths?: string[]): Promise<MetricDirectorySchema[]> {
		const urlBuilder = url`api/metric-schema`;
		urlBuilder.appendMultiple('uniform-path', uniformPaths || ['']);
		return this.get<MetricDirectorySchema[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns the names of available metric threshold configurations. */
	public getMetricThresholdConfigurationNames(includeDefaultConfigurations: boolean): Promise<string[]> {
		const urlBuilder = url`api/metric-threshold-configurations/names`;
		urlBuilder.append('include-default-configurations', includeDefaultConfigurations);
		return this.get<string[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns the names of available metric threshold configurations. */
	public getMetricThresholdConfigurations(
		includeDefaultConfigurations: boolean
	): Promise<MetricThresholdConfiguration[]> {
		const urlBuilder = url`api/metric-threshold-configurations`;
		urlBuilder.append('include-default-configurations', includeDefaultConfigurations);
		return this.get<MetricThresholdConfiguration[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns the metric threshold configuration promise for the given name. */
	public getMetricThresholdConfiguration(
		name: string,
		loadWithBaseConfigurations: boolean,
		projectName?: string
	): Promise<MetricThresholdConfiguration> {
		const urlBuilder = url`api/metric-threshold-configurations/${name}`;
		urlBuilder.append('load-with-base-configurations', loadWithBaseConfigurations);
		urlBuilder.append('project-name', projectName);
		return this.get<MetricThresholdConfiguration>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Creates a metric threshold configuration. */
	public createMetricThresholdConfiguration(
		metricThresholdConfiguration: MetricThresholdConfiguration
	): Promise<void> {
		return this.post(url`api/metric-threshold-configurations`, metricThresholdConfiguration);
	}

	/** Saves a metric threshold configuration. */
	public saveMetricThresholdConfiguration(metricThresholdConfiguration: MetricThresholdConfiguration): Promise<void> {
		return this.put(url`api/metric-threshold-configurations`, metricThresholdConfiguration);
	}

	/** Deletes a metric threshold configuration. */
	public deleteMetricThresholdConfiguration(name: string): Promise<void> {
		return this.delete<void>(url`api/metric-threshold-configurations/${name}`).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Get deriving metric threshold configurations. Necessary to check if metric threshold configuration can be
	 * deleted. A configuration cannot be deleted if configurations exist that inherit it.
	 */
	public getDerivingMetricThresholdConfigurations(name: string): Promise<string[]> {
		return this.get<string[]>(url`api/metric-threshold-configurations/${name}/deriving-configuration-names`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Returns the project configuration for a project.
	 *
	 * @param unEncodedProjectId The ID of the project to obtain the config for. Will be url-encoded by this method.
	 */
	public getProjectConfiguration(unEncodedProjectId: string): Promise<ProjectConfiguration> {
		const urlBuilder = url`api/projects/${unEncodedProjectId}/configuration`;
		return this.get<ProjectConfiguration>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Gets the connectors configuration of projects: for all projects or the specified project ids only */
	public getProjectsConnectorsConfig(): Promise<Map<string, ConnectorConfiguration[]>> {
		return this.get<Record<string, ConnectorConfiguration[]>>(url`api/project-connectors`)
			.then(projectsConnectorsConfig => ObjectUtils.toMap(projectsConnectorsConfig))
			.catch(this.getDefaultErrorHandler());
	}

	/**
	 * Performs a re-analysis of the specified project.
	 *
	 * @param projectId The ID of the project re-analyze
	 * @param onlyFindingsSchemaUpdate Whether to only update the findings and metrics schema without re-analysis
	 */
	public reanalyzeProject(projectId: string, onlyFindingsSchemaUpdate?: boolean | null): Promise<void> {
		const urlBuilder = url`api/projects/${projectId}/reanalysis`;
		urlBuilder.append('only-findings-schema-update', onlyFindingsSchemaUpdate);
		return this.post<void>(urlBuilder, true);
	}

	/**
	 * Creates a project based on a project configuration. Request errors result in a rejected promise and must be
	 * handled there.
	 *
	 * @param projectConfiguration Object describing the configuration.
	 * @param copyDataFromProject An optional project id to copy data from
	 * @param handleErrorsWithPromise If true and the service call fails, the reject method of the promise is called
	 *   with the response data. When set to <code>true</code>, attach an error handler using '.catch(serviceError =>
	 *   ...)' directly to the method call. The error object passed to catch will be of type {@link ServiceCallError}. If
	 *   this is set to false and the service call to create the project fails, a red error page will be shown.
	 */
	public createProject(
		projectConfiguration: ProjectConfiguration,
		copyDataFromProject?: string | null,
		handleErrorsWithPromise?: boolean
	): Promise<void> {
		const urlBuilder = url`api/projects`;
		urlBuilder.append('copy-data-from-project', copyDataFromProject);
		const promise = this.post<void>(urlBuilder, projectConfiguration);
		if (handleErrorsWithPromise) {
			return promise;
		} else {
			return promise.catch(this.getDefaultErrorHandler());
		}
	}

	/**
	 * Lists the projects to which the user has access.
	 *
	 * @param includeDeleting Whether to include projects marked as deleted or not (default is false)
	 * @param includeReanalyzing Whether to include reanalyzing projects as deleted or not (default is false)
	 */
	public getProjectInfos(includeDeleting?: boolean, includeReanalyzing?: boolean): Promise<ProjectInfo[]> {
		const urlBuilder = url`api/projects`;
		urlBuilder.append('include-deleting', includeDeleting);
		urlBuilder.append('include-reanalyzing', includeReanalyzing);
		return this.get<ProjectInfo[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Retrieve all GitHub repositories the current user has access to. */
	public getNotOwnedAdminGitHubRepositories(): Promise<GitHubRepository[]> {
		return this.get<GitHubRepository[]>(url`api/repositories/github/admin-access`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Retrieve all GitHub repositories the current user has access to. */
	public getOwnedGithubRepositories(): Promise<GitHubRepository[]> {
		return this.get<GitHubRepository[]>(url`api/repositories/github/owned`).catch(this.getDefaultErrorHandler());
	}

	/** Retrieve all programming languages of a GitHub repository. */
	public getProgrammingLanguages(project: string): Promise<string[]> {
		return this.get<string[]>(url`api/repositories/github/languages?project=${project}`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Returns all app installations the current user has access to. */
	public getAppInstallations(): Promise<AppInstallationInfo> {
		return this.get<AppInstallationInfo>(url`api/github/app-installations`).catch(this.getDefaultErrorHandler());
	}

	/** Returns all app installations the current user has access to. */
	public getGitHubInstances(): Promise<string[]> {
		return this.get<string[]>(url`api/github/urls`).catch(this.getDefaultErrorHandler());
	}

	/** Returns all app installations the current user has access to. */
	public getGitHubRepositoriesforInstallation(appId: string): Promise<string[]> {
		return this.get<string[]>(url`api/github/repositories?appId=${appId}`).catch(this.getDefaultErrorHandler());
	}

	/** Creates a project based on a GitHub repository. */
	public createGitHubProject(
		owner: string,
		name: string,
		settings: GitHubRepositorySettingsDescription
	): Promise<string> {
		const urlBuilder = url`api/repositories/github`;
		urlBuilder.append('owner', owner);
		urlBuilder.append('name', name);
		return this.post(urlBuilder, settings);
	}

	/** Retrieves the voting settings for a project based on a GitHub repository. */
	public getRepositorySettings(project: string): Promise<GitHubRepositorySettingsDescription> {
		return this.get<GitHubRepositorySettingsDescription>(url`api/repositories/github/${project}/settings`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Updates the voting settings for a project based on a GitHub repository. */
	public updateRepositorySettings(
		project: string,
		settings: GitHubRepositorySettingsDescription
	): Promise<GitHubRepositorySettingsDescription> {
		return this.put(url`api/repositories/github/${project}/settings`, settings);
	}

	/**
	 * Retrieves credentials used by the project with the given project ids.
	 *
	 * @param projectsIds The IDs of the projects.
	 */
	public getProjectCredentialsUsage(projectsIds: string[]): Promise<ExternalCredentialsUsageInfo> {
		const urlBuilder = url`api/projects/credentials`;
		urlBuilder.appendMultiple('project', projectsIds);
		return this.get<ExternalCredentialsUsageInfo>(urlBuilder);
	}

	/**
	 * Saves a project configuration.
	 *
	 * @param projectConfiguration Object describing the configuration.
	 * @param forceReanalyze Whether to perform a re-analyze of the project if required after changing certain
	 *   parameters.
	 * @param skipProjectValidation Whether to skip the project validation.
	 */
	public saveProjectConfiguration(
		projectConfiguration: ProjectConfiguration,
		forceReanalyze?: boolean,
		skipProjectValidation?: boolean
	): Promise<boolean> {
		const urlBuilder = url`api/projects/${asserts.assertString(projectConfiguration.internalId)}/configuration`;
		urlBuilder.append('reanalyze-if-required', forceReanalyze);
		urlBuilder.append('skip-project-validation', skipProjectValidation);
		return this.put(urlBuilder, projectConfiguration);
	}

	/** Adds the given review comment. */
	public addReviewComment(
		project: string,
		reviewComment: ReviewComment,
		commit: UnresolvedCommitDescriptor | null
	): Promise<void> {
		const urlBuilder = url`api/projects/${project}/findings/review-findings`;
		urlBuilder.append('t', commit);
		return this.post<void>(urlBuilder, [reviewComment]).catch(this.getDefaultErrorHandler());
	}

	/** Resolves the given review finding. */
	public resolveReviewFinding(
		project: string,
		findingId: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<void> {
		const urlBuilder = url`api/projects/${project}/review-findings/${findingId}/resolution`;
		urlBuilder.append('t', commit);
		return this.post<void>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Changes the message of the given review finding. */
	public changeReviewFindingMessage(
		project: string,
		findingId: string,
		newMessage: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<void> {
		const urlBuilder = url`api/projects/${project}/review-findings/${findingId}/message`;
		urlBuilder.append('t', commit);
		urlBuilder.append('message', newMessage);
		return this.post<void>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Checks whether review findings are enabled for the given project. */
	public areReviewFindingsEnabled(project: string): Promise<boolean> {
		return this.get(url`api/projects/${project}/feature/review-finding`);
	}

	/** Checks whether merge requests are enabled for the given project. */
	public areMergeRequestsEnabled(project: string): Promise<boolean> {
		return this.get(url`api/projects/${project}/feature/merge-request`);
	}

	/** Obtains the currently set access key for the given user. */
	public getAccessKey(username: string): Promise<string> {
		return this.get<string>(url`api/users/${username}/access-key`).catch(this.getDefaultErrorHandler());
	}

	/** Obtains the findings notification rules for the given user. */
	public getFindingsNotificationRules(): Promise<FindingsNotificationRules | null> {
		return this.get<FindingsNotificationRules | null>(url`api/notification-rules/findings`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Stores the findings notification rules for the given user.
	 *
	 * @returns 'success' or the error message
	 */
	public setFindingsNotificationRules(notificationRules: FindingsNotificationRules): Promise<string> {
		return this.put<string>(url`api/notification-rules/findings`, notificationRules).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Obtains the metric notification rules for the given user. */
	public getMetricNotificationRules(): Promise<MetricNotificationRules | null> {
		return this.get<MetricNotificationRules | null>(url`api/notification-rules/metric`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Stores the metric notification rules for the given user. */
	public setMetricNotificationRules(notificationRules: MetricNotificationRules): Promise<void> {
		return this.put<void>(url`api/notification-rules/metric`, notificationRules).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Obtains the project notification rules. */
	public getProjectNotificationRules(): Promise<ProjectNotificationRules | null> {
		return this.get<ProjectNotificationRules>(url`api/notification-rules/project`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Stores the project notification rules. */
	public setProjectNotificationRules(notificationRules: ProjectNotificationRules): Promise<void> {
		return this.put<void>(url`api/notification-rules/project`, notificationRules);
	}

	/** Removes the access key for the given user. */
	public removeAccessKey(username: string): Promise<void> {
		return this.delete<void>(url`api/users/${username}/access-key`);
	}

	/** Creates a new (random) access key for the given user. */
	public createNewAccessKey(username: string): Promise<string> {
		return this.post<string>(url`api/users/${username}/access-key`);
	}

	/**
	 * Returns a metric file distribution for the given metric.
	 *
	 * @param project The projects name
	 * @param uniformPath The uniform path to calculate metrics for
	 * @param metricName The name of the desired metric
	 * @param fileRegexes An array of regular expression strings, that is used to group files according to their uniform
	 *   path
	 * @param callback The callback function for handling retrieved results
	 * @param commit An optional commit for time-travelling
	 */
	public getMetricFileDistribution(
		project: string,
		uniformPath: string,
		metricName: string,
		fileRegexes: string[],
		commit?: UnresolvedCommitDescriptor | null
	): Promise<number[]> {
		const urlBuilder = url`api/projects/${project}/metrics/file-distribution`;
		urlBuilder.append('t', commit);
		urlBuilder.append('metric-name', metricName);
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.appendMultiple('file-regexps', fileRegexes);
		return this.get<number[]>(urlBuilder);
	}

	/** Returns the overview information containing in which projects have been parser or scanner problems. */
	public getParseLogOverview(): Promise<ParseLogOverviewEntry[]> {
		return this.get<ParseLogOverviewEntry[]>(url`api/system/parse-log/overview`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Returns the parse log for the specified project.
	 *
	 * @param project The project's name
	 * @param startIndex Start number for the index of the parse log problems.
	 * @param maxResults The maximum number of results.
	 */
	public getParseLog(project: string, startIndex: number, maxResults: number): Promise<ParseLogEntry[]> {
		const urlBuilder = url`api/projects/${project}/parse-log/all`;
		urlBuilder.append('start', startIndex);
		urlBuilder.append('max', maxResults);
		return this.get<ParseLogEntry[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns the parse log for a single file. */
	public getElementParseLog(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<ParseLogEntry[]> {
		const urlBuilder = url`api/projects/${project}/parse-log/element`;
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('t', commit);
		return this.get<ParseLogEntry[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Retrieves an external link for the given uniform path (or null). This only works for ABAP files imported from an
	 * SAP system and will return null for non ABAP files.
	 */
	public getAbapExternalLink(
		project: string,
		uniformPath: string,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<string | null> {
		const urlBuilder = url`api/projects/${project}/external-link/${uniformPath}`;
		urlBuilder.append('t', commit);
		return this.get<string | null>(urlBuilder)
			.catch<string | null>(TeamscaleServiceClient.handle404AsNull)
			.catch(this.getDefaultErrorHandler());
	}

	/** Fetches the project option schema. */
	public getProjectOptionSchema(project: string): Promise<OptionDescriptor[]> {
		return this.get<OptionDescriptor[]>(url`api/projects/${project}/options/schema`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Fetches all set project options. */
	public getProjectOptions(project: string): Promise<Record<string, OptionValue>> {
		return this.get<Record<string, OptionValue>>(url`api/projects/${project}/options`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Fetches the ProjectThresholdConfigurationsOption. */
	public getProjectThresholdConfigurationOption(project: string): Promise<ProjectThresholdConfigurationsOption> {
		const urlBuilder = url`api/projects/${project}/options/threshold.configurations`;
		return this.get<ProjectThresholdConfigurationsOption>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Sets a specific project option. */
	public setProjectOption(project: string, optionName: string, optionValue: Record<string, unknown>): Promise<void> {
		return this.put(url`api/projects/${project}/options/${optionName}`, optionValue);
	}

	/** Deletes a specific project option. */
	public deleteProjectOption(project: string, optionName: string): Promise<void> {
		const urlBuilder = url`api/projects/${project}/options/${optionName}`;
		return this.delete(urlBuilder);
	}

	/** Generates a support request. */
	public generateSupportRequest(requestData: SupportRequestData): Promise<string> {
		return this.post<string>(url`api/support-request`, requestData).catch(this.getDefaultErrorHandler());
	}

	/** Downloads a previously generated support request. */
	public downloadSupportRequest(): void {
		NavigationUtils.updateLocation('api/support-request');
	}

	/**
	 * Lists the Simulink model corresponding to the given uniformPath
	 *
	 * @param project Current project
	 * @param uniformPath UniformPath of the current simulink file
	 * @param commit The commit descriptor for which to do the assessment
	 * @param subsystem Qualified name of the subsystem. If null only the topmost layer of the simulink model will be
	 *   fetched.
	 */
	public getSimulinkModel(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor,
		subsystem: string | null
	): Promise<SimulinkBlockData> {
		const urlBuilder = url`api/projects/${project}/simulink/model/${uniformPath}`;
		urlBuilder.append('t', commit);
		if (subsystem) {
			urlBuilder.append('sub-system', subsystem);
		}
		return this.get<SimulinkBlockData>(urlBuilder);
	}

	/**
	 * Lists the entries of the Simulink data dictionary corresponding to the given uniformPath
	 *
	 * @param project Current project
	 * @param uniformPath UniformPath of the current simulink dictionary file
	 * @param commit The commit descriptor for which to do the assessment
	 */
	public getSimulinkDataDictionaryEntries(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor
	): Promise<PairList<string, string>> {
		const urlBuilder = url`api/projects/${project}/simulink/dictionary/${uniformPath}`;
		urlBuilder.append('t', commit);
		return this.get<PairList<string, string>>(urlBuilder);
	}

	/**
	 * Returns the result of a simulink model comparison.
	 *
	 * @param project Current project
	 * @param leftUniformPath UniformPath of the left simulink file
	 * @param leftLocation Qualified name of the left subsystem
	 * @param rightUniformPath UniformPath of the right simulink file
	 * @param rightLocation Qualified name of the right subsystem
	 * @param commit The commit descriptor for which to do the comparison
	 */
	public getSimulinkModelComparison(
		project: string,
		leftUniformPath: string,
		leftLocation: string,
		rightUniformPath: string,
		rightLocation: string,
		commit: UnresolvedCommitDescriptor
	): Promise<SimulinkModelComparisonResult> {
		const urlBuilder = url`api/projects/${project}/simulink/comparison`;
		urlBuilder.append('t', commit);
		urlBuilder.append('left-path', leftUniformPath);
		urlBuilder.append('right-path', rightUniformPath);
		urlBuilder.append('left-location', leftLocation);
		urlBuilder.append('right-location', rightLocation);
		return this.get<SimulinkModelComparisonResult>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns an architecture analysis group if it is enabled for the current project. Otherwise, throws an error. */
	public getArchitectureAnalysisGroup(project: string): Promise<AnalysisGroup | ServiceCallError> {
		return this.get(
			url`api/projects/${project}/configuration/analysis-groups/${TeamscaleServiceClient.ARCHITECTURE_CONFORMANCE_GROUP_NAME}`
		);
	}

	/**
	 * Returns the assessment of the provided architecture.
	 *
	 * @param project Current project.
	 * @param architecture Data object of the architecture, which should be assessed.
	 * @param commit The commit descriptor for which to do the assessment
	 */
	public getArchitectureAssessmentForArchitecture(
		project: string,
		architecture: ArchitectureInfo,
		commit: UnresolvedCommitDescriptor | null,
		typeSearchQuery?: string
	): Promise<ArchitectureAssessmentInfo> {
		const urlBuilder = url`api/projects/${project}/architectures/assessments`;
		urlBuilder.append('t', commit);
		urlBuilder.append('type-search', typeSearchQuery);
		return this.post<ArchitectureAssessmentInfo>(urlBuilder, architecture);
	}

	/**
	 * Uploads the given architecture to the given uniform path.
	 *
	 * @param project The project of the architecture
	 * @param uniformPath The uniform path for the new architecture
	 * @param architecture The JSON data of the architecture
	 * @param commit The commit descriptor for which to upload the architecture.
	 * @param commitMessage The message which should be uploaded
	 */
	public uploadArchitecture(
		project: string,
		uniformPath: string,
		architecture: ArchitectureTrackedData,
		commit: UnresolvedCommitDescriptor | null,
		commitMessage: string
	): Promise<void> {
		const payload = { uniformPath, architecture };
		const urlBuilder = url`api/projects/${project}/architectures`;
		urlBuilder.append('t', commit);
		urlBuilder.append('message', commitMessage);
		return this.post(urlBuilder, payload);
	}

	/** Determines the architecture upload info with the actual architecture commit before or at the given commit. */
	public getArchitectureCommitUploadInfo(
		project: string,
		commit: UnresolvedCommitDescriptor
	): Promise<CommitArchitectureCommitUploadInfo | null> {
		return this.get<ArchitectureCommitUploadInfo | null>(
			url`api/projects/${project}/external-uploads/architectures/${commit.toString()}`
		).then(architectureCommitUploadInfo => {
			if (architectureCommitUploadInfo == null) {
				return null;
			}
			return new CommitArchitectureCommitUploadInfo(architectureCommitUploadInfo);
		});
	}

	/**
	 * Deletes all commits of the given architecture (identified by uniform path). This means that the full history of
	 * creating/editing/deleting this architecture is deleted.
	 */
	public deleteAllArchitectureCommits(project: string, architectureUniformPath: string): Promise<void> {
		const urlBuilder = url`api/projects/${project}/architectures/${architectureUniformPath}/all-commits`;
		urlBuilder.append('message', 'Deleted all commits of ' + architectureUniformPath);
		return this.delete(urlBuilder);
	}

	/**
	 * Creates a deletion commit of the given architecture (identified by uniform path) if the given commit descriptor
	 * does not refer to an existing add/change/delete commit of the architecture. Deletes the architecture commit if
	 * the given commit descriptor refers to an existing add/change/delete commit of the architecture.
	 *
	 * @param project The project of the architecture
	 * @param architectureUniformPath The uniformPath of the architecture
	 * @param commit The commit descriptor of the change to be discarded.
	 */
	public deleteArchitecture(
		project: string,
		architectureUniformPath: string,
		commit: UnresolvedCommitDescriptor
	): Promise<void> {
		const urlBuilder = url`api/projects/${project}/architectures/${architectureUniformPath}`;
		urlBuilder.append('t', commit);
		urlBuilder.append('message', 'Deleted ' + architectureUniformPath);
		return this.delete(urlBuilder);
	}

	/**
	 * Obtains refactoring suggestions for the given finding.
	 *
	 * @param project
	 * @param finding
	 * @param commit The commit for which suggest a refactoring. May be null or undefined to get the latest
	 *   default-branch revision.
	 */
	public getExtractMethodSuggestions(
		project: string,
		findingId: string,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<RefactoringSuggestions | null> {
		const urlBuilder = url`api/projects/${project}/findings/${findingId}/extract-method-suggestions`;
		urlBuilder.append('t', commit);
		return this.get<RefactoringSuggestions | null>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Sends a test mail to check if all mail server settings are correct. */
	public sendTestMail(): Promise<void> {
		return this.post(url`api/mail-settings/test`);
	}

	/**
	 * Calls the service to randomly select files for the file picker.
	 *
	 * @param project The project name.
	 * @param path The sub-path.
	 * @param minFileSize Minimum file size.
	 * @param maxFileSize Maximum file size.
	 * @param regexSearch Filter results
	 */
	public getRandomFilesFromPicker(
		project: string,
		path: string,
		minFileSize: number,
		maxFileSize: number,
		regexSearch: string
	): Promise<CodeFileInfo[]> {
		const urlBuilder = url`api/projects/${project}/random-files`;
		urlBuilder.append('min', minFileSize);
		urlBuilder.append('max', maxFileSize);
		urlBuilder.append('regex', regexSearch);
		urlBuilder.append('uniform-path', path);
		return this.get<CodeFileInfo[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Calls the service to fetch identical file names.
	 *
	 * @param project The project name.
	 * @param path The sub-path.
	 * @param regexFilter The filter.
	 * @param callback
	 */
	public getFilesWithIdenticalName(
		project: string,
		path: string,
		regexFilter: string,
		callback: Callback<FileGroup[]>
	): void {
		const urlBuilder = url`api/projects/${project}/audit/files-with-identical-name/${path}`;
		urlBuilder.append('regex', regexFilter);
		this.withCallback(this.get<FileGroup[]>(urlBuilder), callback);
	}

	/**
	 * Calls the service to fetch identical files with identical content.
	 *
	 * @param project The project name.
	 * @param path The sub-path.
	 * @param regexFilter The filter.
	 * @param callback
	 */
	public getFilesWithIdenticalContent(
		project: string,
		path: string,
		regexFilter: string,
		callback: Callback<FileGroup[]>
	): void {
		const urlBuilder = url`api/projects/${project}/audit/files-with-identical-content/${path}`;
		urlBuilder.append('regex', regexFilter);
		urlBuilder.append('isIgnoreWhitespaces', true);
		this.withCallback(this.get<FileGroup[]>(urlBuilder), callback);
	}

	/**
	 * Calls the service to fetch included source files from solution files.
	 *
	 * @param project The project name.
	 */
	public extractDotNetSourcePaths(project: string): Promise<string[]> {
		return this.get<string[]>(url`api/projects/${project}/audit/dotnet-source-paths-extractor`);
	}

	/**
	 * Calls the service to analyze the copyright licenses of a project.
	 *
	 * @param customerLicenses String of customer license names that should be excluded from the results
	 * @param uniformPath The path to which results should be filtered to.
	 */
	public getLicenses(
		project: string,
		customerLicenses: string[],
		uniformPath: string
	): Promise<LicenseInfoElement[]> {
		const urlBuilder = url`api/projects/${project}/licenses`;
		urlBuilder.appendMultiple('customer-license', customerLicenses);
		urlBuilder.append('uniform-path', uniformPath);
		return this.get<LicenseInfoElement[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Calls the service to export audit result data.
	 *
	 * @param project Project name.
	 * @param uniformPath The (architecture) path.
	 */
	public getAuditResultData(project: string, uniformPath: string): string {
		const urlBuilder = url`api/projects/${project}/audit/data-export/${uniformPath}`;
		return urlBuilder.getURL();
	}

	/**
	 * Calls the service to get all project files as ZIP.
	 *
	 * @param project Project name.
	 * @param uniformPath The (architecture) path.
	 * @param asZip Whether ZIP with all files or CSV with all file paths
	 */
	public getAllProjectFiles(project: string, uniformPath: string, asZip: boolean): string {
		let endpoint;
		if (asZip) {
			endpoint = 'snapshot.zip';
		} else {
			endpoint = 'file-paths.csv';
		}
		const urlBuilder = url`api/projects/${project}/project-files/${endpoint}`;
		urlBuilder.append('uniform-path', uniformPath);
		return urlBuilder.getURL();
	}

	/**
	 * Returns code search match list results.
	 *
	 * @param project The project
	 * @param path The sub-path
	 * @param searchTerm The search term in regex format
	 * @param tokenClasses The token classes as list of comma separated strings
	 * @param isColorGradationActive To determine if the treemap should be shaded gradually
	 * @param areaMetric The metric index to determine the area of displayed nodes
	 * @param width Width to render tree map
	 * @param height Height to render tree map
	 * @param previewSize The size of the result.
	 */
	public getCodeSearchMatchListAndTreemap(
		project: string,
		path: string,
		searchTerm: string,
		tokenClasses: ETokenClass[],
		isColorGradationActive: boolean,
		areaMetric: number,
		width: number,
		height: number,
		previewSize: number
	): Promise<CodeSearchResultsWrapper | null> {
		if (StringUtils.isEmptyOrWhitespace(project)) {
			return Promise.resolve(null);
		}
		const urlBuilder = TeamscaleServiceClient.getTreemapURLBuilder(
			url`api/projects/${project}/audit/code-search/match-list-and-treemap`,
			path,
			areaMetric,
			areaMetric
		);
		urlBuilder.append('width', width);
		urlBuilder.append('height', height);
		urlBuilder.append('search-term', searchTerm);
		urlBuilder.appendMultiple(
			'token-classes',
			tokenClasses.map(tokenType => tokenType.name)
		);
		urlBuilder.append('is-color-gradation-active', isColorGradationActive);
		urlBuilder.append('preview-size', previewSize);
		return this.get<CodeSearchResultsWrapper>(urlBuilder);
	}

	/**
	 * Exports code search match list as a downloadable CSV file.
	 *
	 * @param project The project
	 * @param path The uniform path
	 * @param searchTerm The search term in regex format
	 * @param tokenClasses The token classes as list of comma separated strings
	 */
	public getExportCodeSearchMatchListLink(
		project: string,
		path: string,
		searchTerm: string,
		tokenClasses: ETokenClass[]
	): string {
		const urlBuilder = url`api/projects/${project}/audit/code-search/export`;
		urlBuilder.append('uniform-path', path);
		urlBuilder.append('search-term', searchTerm);
		urlBuilder.appendMultiple(
			'token-classes',
			tokenClasses.map(tokenClass => tokenClass.name)
		);
		return urlBuilder.getURL();
	}

	/**
	 * Returns the treemapWrapper as the result of the code search.
	 *
	 * @param project The project
	 * @param path The sub-path
	 * @param highlightColor Color hex value for highlighting components
	 * @param highlightTerm The highlight term in regex format
	 * @param isShowComponents Whether the top-level components should be shown or whether the language distribution
	 *   should be shown
	 * @param areaMetric The metric index to determine the area of displayed nodes
	 * @param width Width to render tree map
	 * @param height Height to render tree map
	 */
	public getCodeComponentsTreeMapWrapper(
		project: string,
		path: string,
		highlightColor: string,
		highlightTerm: string,
		isShowComponents: boolean,
		areaMetric: number,
		width: number,
		height: number
	): Promise<FilteredTreeMapWrapper> {
		const urlBuilder = TeamscaleServiceClient.createTreemapBaseURLBuilder(
			url`api/projects/${project}/audit/code-components-treemap/${path}`,
			areaMetric,
			areaMetric
		);
		urlBuilder.append('width', width);
		urlBuilder.append('height', height);
		urlBuilder.append('highlight-color', highlightColor);
		urlBuilder.append('highlight-term', highlightTerm);
		urlBuilder.append('is-show-components', isShowComponents);
		return this.get<FilteredTreeMapWrapper>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns all commits per author for the given parameters.
	 *
	 * @param project The project which should be tracked
	 * @param path The path which should be tracked
	 * @param numberOfCommits The minimum number of commits an author needs to be tracked
	 * @param sortingOrder The order in which the authors should be sorted
	 * @param startCommit From this commit on the commits will be tracked.
	 * @param endCommit Until this commit the commits will be tracked.
	 */
	public getCommitTrackingData(
		project: string,
		path: string,
		numberOfCommits: number,
		sortingOrder: ECommitAuthorSortingOrder,
		startCommit: UnresolvedCommitDescriptor | null,
		endCommit: UnresolvedCommitDescriptor | null
	): Promise<CommitData> {
		const urlBuilder = url`api/projects/${project}/commit-chart`;
		urlBuilder.append('uniform-path', path);
		urlBuilder.append('t1', startCommit);
		urlBuilder.append('t2', endCommit);
		urlBuilder.append('commits', numberOfCommits);
		urlBuilder.append('order', sortingOrder.name);
		return this.get<CommitData>(urlBuilder);
	}

	/**
	 * Returns a metric assessment for the given parameters.
	 *
	 * @param project The currently selected project.
	 * @param uniformPath The path for which to receive data
	 * @param commit The commit for which to retrieve data. Can be <code>null</code>.
	 * @param configurationName Name of the threshold configuration that should be used. Should not be
	 *   <code>null</null>.
	 * @param baseline The commit to which the delta and trends should be calculated. Can be <code>null</code>.
	 */
	public getMetricAssessment(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor | null,
		configurationName: string | null,
		baseline: UnresolvedCommitDescriptor | null | number
	): Promise<GroupAssessment[]> {
		const urlBuilder = url`api/projects/${project}/metric-assessments`;
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('t', commit);
		urlBuilder.append('configuration-name', configurationName);
		urlBuilder.append('baseline', baseline);
		return this.get<GroupAssessment[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns a CSV export of the metric assessment for the given parameters.
	 *
	 * @param project The currently selected project.
	 * @param uniformPath The path for which to receive data
	 * @param commit The commit for which to retrieve data. Can be <code>null</code>.
	 * @param configurationName Name of the assessment profile that should be used. Can be <code>null</null>.
	 * @param baseline The commit to which the delta and trends should be calculated. Can be <code>null</code>.
	 */
	public getMetricAssessmentExport(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor | null,
		configurationName: string,
		baseline: UnresolvedCommitDescriptor | null
	): void {
		const urlBuilder = url`api/projects/${project}/export-metric-assessment`;
		urlBuilder.append('t', commit);
		urlBuilder.append('configuration-name', configurationName);
		urlBuilder.append('uniform-path', uniformPath);
		if (baseline != null) {
			urlBuilder.append('baseline', baseline.getTimestamp());
		}
		window.location.href = urlBuilder.getURL();
	}

	/** Calls the project-specific service to download the benchmark results. */
	public getBenchmarkProjects(): Promise<ProjectDescription[]> {
		return this.get<ProjectDescription[]>(url`api/benchmark-projects`);
	}

	/**
	 * Calls the project-specific service to retrieve the benchmark results.
	 *
	 * @param metricName The name of the metric to retrieve the benchmark results.
	 */
	public getBenchmarkResults(
		metricName: string,
		notAnonymizedProjectIds: string[],
		selectedAnonymizedProjectIds: string[]
	): Promise<BenchmarkResult[] | null> {
		const urlBuilder = url`api/audit/metric-benchmark`;
		urlBuilder.append('metric-name', metricName);
		urlBuilder.appendMultiple('projects', notAnonymizedProjectIds);
		urlBuilder.appendMultiple('anonymized-projects', selectedAnonymizedProjectIds);
		return this.get<BenchmarkResult[] | null>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Calls the project-specific service to download the benchmark results. */
	public getBenchmarkResultsAsCsv(
		metricName: string,
		notAnonymizedProjectIds: string[],
		selectedAnonymizedProjectIds: string[]
	): string {
		const urlBuilder = url`api/audit/metric-benchmark.csv`;
		urlBuilder.append('metric-name', metricName);
		urlBuilder.appendMultiple('projects', notAnonymizedProjectIds);
		urlBuilder.appendMultiple('anonymized-projects', selectedAnonymizedProjectIds);
		return urlBuilder.getURL();
	}

	/**
	 * Calls the service to retrieve the Latex table.
	 *
	 * @param project The application project.
	 * @param path The path.
	 * @param tableTypes Types of the tables to export.
	 * @param language Language to use.
	 * @param testProject The test project for the application project.
	 * @param allProject Project containing both application and test.
	 */
	public getAuditLatexTable(
		project: string,
		path: string,
		tableTypes: EAuditExportTableEntry[],
		language: string,
		testProject: string,
		allProject: string
	): Promise<string> {
		let urlBuilder = url`api/projects/${project}/audit/latex-table`;
		urlBuilder.appendMultiple('table-type', tableTypes);
		urlBuilder.append('language', language);
		urlBuilder.append('uniform-path', path);
		if (testProject === 'None' || allProject === 'None' || testProject === '' || allProject === '') {
			return this.get<string>(urlBuilder).catch(this.getDefaultErrorHandler());
		}
		urlBuilder = url`api/audit/latex-table`;
		urlBuilder.appendMultiple('table-type', tableTypes);
		urlBuilder.append('language', language);
		urlBuilder.append('application', project);
		urlBuilder.append('test', testProject);
		urlBuilder.append('all', allProject);
		return this.get<string>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the review status for a file.
	 *
	 * @returns Can return <code>null</code> if the review feature isn't active at all.
	 */
	public getReviewStatus(
		project: string,
		uniformPath: string,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<AssessedReviewStatus | null> {
		const urlBuilder = url`api/projects/${project}/${uniformPath}/review-status`;
		urlBuilder.append('t', commit);
		return this.get<AssessedReviewStatus | null>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns the review status for a file. */
	public setReviewStatus(
		project: string,
		uniformPath: string,
		reviewStatus: ReviewUploadInfo,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<void> {
		const urlBuilder = url`api/projects/${project}/${uniformPath}/review-status`;
		urlBuilder.append('t', commit);
		return this.post(urlBuilder, reviewStatus);
	}

	/** Updates the metric schema for external metrics in the project */
	public updateExternalMetricSchema(project: string): Promise<void> {
		return this.post<void>(url`api/projects/${project}/metric-update`);
	}

	/**
	 * Fetches the trend of unique users per day for a given list of projects.
	 *
	 * @param projectIds List of project names from which to fetch the trend of unique users per day.
	 * @param startTimestamp The timestamp from which on to consider usage data.
	 * @param endTimestamp The timestamp until which to consider usage data.
	 * @param excludedUsers The users to be excluded from the statistics.
	 */
	public getProjectUserActivityTrend(
		projectIds: string[] | null,
		startTimestamp: number,
		endTimestamp?: number,
		excludedUsers: string[] = []
	): Promise<Array<[number, number]>> {
		const urlBuilder = url`api/unique-project-users/trend`;
		urlBuilder.appendMultiple('project-ids', projectIds);
		urlBuilder.append('baseline', startTimestamp);
		urlBuilder.append('end', endTimestamp);
		urlBuilder.appendMultiple('excluded-users', excludedUsers);
		return this.get<Array<[number, number]>>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Validates a connector
	 *
	 * @param connector Java type ConnectorConfiguration
	 * @param projectId The ID of the project to validate
	 * @returns 'success' or the error message
	 */
	public validateConnector(connector: ConnectorConfiguration, projectId: string): Promise<string | null> {
		const urlBuilder = url`api/validate-connector`;
		urlBuilder.append('project-id', projectId);
		return this.put<string>(urlBuilder, connector);
	}

	/** Validates branching configuration settings. */
	public validateBranchingConfiguration(
		branchingConfiguration: ProjectBranchingConfiguration
	): Promise<string | null> {
		return this.put<string>(url`api/branching-configuration/validation`, branchingConfiguration);
	}

	/** Fetches the custom fields provided by an external tool for issues. */
	public fetchExternalToolIssueCustomFields(
		connectorConfiguration: ConnectorConfiguration
	): Promise<ExternalToolIssueCustomFieldResult> {
		const urlBuilder = url`api/connectors/custom-fields`;
		return this.put<ExternalToolIssueCustomFieldResult>(urlBuilder, connectorConfiguration);
	}

	/**
	 * Returns auto-completion suggestions for either users or groups from the given server for the given input.
	 *
	 * @param userOrGroup Either "user" or "group"
	 * @param serverType The server type
	 * @param serverName The server name
	 * @param limit The maximum number of matches
	 * @param input The current input
	 * @param callback
	 */
	public autoCompleteUserOrGroup(
		userOrGroup: string,
		serverType: string,
		serverName: string,
		limit: number,
		input: string,
		callback: Callback<string[]>
	): void {
		const urlBuilder = url`api/auth/import/${this.checkUserOrGroupAndPluralize(
			userOrGroup
		)}/auto-completion-suggestions/${input}`;
		urlBuilder.append('server-type', serverType);
		urlBuilder.append('server-name', serverName);
		urlBuilder.append('limit', limit);
		this.withCallback(this.get<string[]>(urlBuilder), callback);
	}

	/** Retrieve the analysis-completed projects and projects currently being analyzed. */
	public getProjectsState(): Promise<ProjectsState> {
		return this.get<ProjectsState>(url`api/project-analysis-states`).catch(this.getDefaultErrorHandler());
	}

	/** Retrieve the projects connector states. */
	public getProjectsConnectorState(): Promise<ProjectsConnectorState> {
		return this.get(url`api/project-analysis-states/connectors`);
	}

	/** Retrieve the projects' postponed rollback counts. */
	public getPostponedRollbackCounts(): Promise<PostponedRollbackCounts> {
		return this.get(url`api/project-analysis-states/postponed-rollbacks`);
	}

	/** Retrieves the list of partitions for the given project. */
	public getExternalAnalysisPartitionInfos(project: string): Promise<ExternalAnalysisPartitionInfo[]> {
		return this.get<ExternalAnalysisPartitionInfo[]>(
			url`api/projects/${project}/external-analysis/status/partitions`
		).catch(this.getDefaultErrorHandler());
	}

	/** Retrieves the status of the connectors for a project */
	public getProjectConnectorStatuses(projectId: string): Promise<ProjectConnectorStatus[]> {
		return this.get(url`api/projects/${projectId}/connectors/statuses`);
	}

	/** Retrieves the postponed rollbacks for a project */
	public getPostponedRollbacks(projectId: string): Promise<PostponedRollback[]> {
		return this.get(url`api/projects/${projectId}/postponed-rollbacks`);
	}

	/** Executes a postponed rollback. */
	public executePostponedRollback(projectId: string, rollbackId: string): Promise<void> {
		return this.post(url`api/projects/${projectId}/postponed-rollbacks/execute/${rollbackId}`);
	}

	/** Retrieves the external analysis commits stored for a single partition. */
	public getExternalAnalysisCommitInfosForPartition(
		project: string,
		partition: string
	): Promise<ExternalAnalysisCommitStatus[]> {
		return this.get<ExternalAnalysisCommitStatus[]>(
			url`api/projects/${project}/external-analysis/status/partitions/${partition}`
		).catch(this.getDefaultErrorHandler());
	}

	/** Retrieves the external analysis commits stored for a list of partition in the given timeframe. */
	public getExternalAnalysisCommitInfos(
		project: string,
		partitions: string[],
		baselineTimestamp: number,
		endCommit: CommitDescriptor
	): Promise<Record<string, ExternalAnalysisCommitStatus[]>> {
		const urlBuilder = url`api/projects/${project}/external-analysis/status/commit-infos`;
		urlBuilder.appendMultiple('partitions', partitions);
		// We wrap baseline and end to also support the value LATEST for both
		urlBuilder.append(
			'baseline',
			UnresolvedCommitDescriptor.wrap(new UnresolvedCommitDescriptor(baselineTimestamp))
		);
		urlBuilder.append('end', UnresolvedCommitDescriptor.wrap(endCommit));
		return this.get(urlBuilder);
	}

	/** Retrieves details for a single external analysis commit. */
	public getExternalAnalysisCommitDetails(
		project: string,
		branchName: string,
		timestamp: number | string
	): Promise<ExternalAnalysisStatusInfo> {
		return this.get<ExternalAnalysisStatusInfo>(
			url`api/projects/${project}/external-analysis/status/commits/${branchName + ':' + timestamp}`
		).catch(this.getDefaultErrorHandler());
	}

	/** Retrieves external architecture uploads. */
	public getExternalArchitectureUploadsForProject(projectId: string): Promise<ArchitectureWithCommitCount[]> {
		return this.get<ArchitectureWithCommitCount[]>(
			url`api/projects/${projectId}/external-uploads/architectures`
		).catch(this.getDefaultErrorHandler());
	}

	/** Retrieves external architecture commit infos for an architecture. */
	public getExternalArchitectureUploadCommits(project: string, architecture: string): Promise<CommitWithUserName[]> {
		return this.get<CommitWithUserName[]>(
			url`api/projects/${project}/external-uploads/architectures/${architecture}/commits`
		).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Deletes the external analysis result upload at a given timestamp/branch in a given project.
	 *
	 * @param project Project.
	 * @param branch The branchname of the upload to be deleted.
	 * @param timestamp The timestamp of the upload to be deleted.
	 */
	public deleteExternalAnalysisResultUploadsForProject(
		project: string,
		branch: string,
		timestamp: string
	): Promise<void> {
		const commit = branch + ':' + timestamp;
		return this.delete(url`api/projects/${project}/external-analysis/commits/${commit}`);
	}

	/** Deletes all external analysis result uploads for a given partition. */
	public deleteExternalAnalysisResultUploadsForProjectByPartition(project: string, partition: string): Promise<void> {
		return this.delete(url`api/projects/${project}/external-analysis/partitions/${partition}`);
	}

	/**
	 * Gets the TokenElementInfo for a given uniform path.
	 *
	 * @param project Project.
	 * @param uniformPath The uniform path
	 * @param commit The commit for which to retrieve data.
	 */
	public getTokenElementInfo(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<TokenElementInfo | null> {
		const urlBuilder = url`api/projects/${project}/content`;
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('t', commit);
		return this.get<TokenElementInfo>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the preprocessorExpansions of an element.
	 *
	 * @param project Project.
	 * @param uniformPath The uniform path
	 * @param commit The commit for which to retrieve data.
	 * @returns Promise returning the PreprocessorExpansionsTransport
	 */
	public getPreprocessorExpansions(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<PreprocessorExpansionsTransport> {
		const urlBuilder = url`api/projects/${project}/preprocessor-expansions/${uniformPath}`;
		if (commit) {
			urlBuilder.append('t', commit);
		}
		return this.get<PreprocessorExpansionsTransport>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns a specific Preprocessor expansion group of an file. This is a group of tokens that are expanded together
	 * by the preprocessor (e.g., one macro call including the parameters and parentheses).
	 *
	 * @param project Project.
	 * @param uniformPath The uniform path
	 * @param commit The commit for which to retrieve data.
	 * @param expansionGroupId The number of the expansion id (determine via #getPreprocessorExpansions)
	 * @returns Promise returning the SinglePreprocessorExpansionTransport
	 */
	public getPreprocessorExpansionGroup(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor,
		expansionGroupId: number
	): Promise<SinglePreprocessorExpansionTransport> {
		const urlBuilder = url`api/projects/${project}/preprocessor-expansions/${uniformPath}/expansions/${
			'' + expansionGroupId
		}`;
		urlBuilder.append('t', commit);
		return this.get<SinglePreprocessorExpansionTransport>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the ownership treemap for the given uniform path using the specified area metric and color.
	 *
	 * @param excludeMergeCommits Exclude merge commits from ownership
	 * @param excludeImportCommits Exclude import commits from ownership
	 * @param areaMetric The metric index to determine the area of displayed nodes
	 * @param width Width to render tree map
	 * @param height Height to render tree map
	 * @param color Color to use for ownership
	 */
	public getCodeOwnershipTreeMap(
		project: string,
		uniformPath: string,
		excludeMergeCommits: boolean,
		excludeImportCommits: boolean,
		areaMetric: number,
		width: number,
		height: number,
		color: number[],
		commit?: UnresolvedCommitDescriptor | null,
		baseline?: UnresolvedCommitDescriptor | null
	): Promise<TreeMapNode> {
		const urlBuilder = url`api/projects/${project}/code-ownership/treemap`;
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('exclude-merge-commits', excludeMergeCommits);
		urlBuilder.append('exclude-import-commits', excludeImportCommits);
		urlBuilder.append('area-metric', areaMetric);
		urlBuilder.append('color', (color[0]! << 16) + (color[1]! << 8) + color[2]!);
		urlBuilder.append('t', commit);
		urlBuilder.append('baseline', baseline);
		urlBuilder.append('width', width);
		urlBuilder.append('height', height);
		return this.get(urlBuilder);
	}

	/**
	 * Loads the contents of the metrics table
	 *
	 * @param project The project
	 * @param uniformPath The uniform path
	 * @param thresholdConfigName The name of the active threshold profile.
	 * @param commit The commit
	 * @param metrics The metrics to evaluate. If nothing is passed, all metrics are evaluated.
	 * @param limitToThresholdProfile If this is true, only metrics from the profile will be returned.
	 */
	public getMetricsTable(
		project: string,
		uniformPath: string,
		thresholdConfigName: string,
		commit: UnresolvedCommitDescriptor | null,
		metrics?: string[] | null,
		limitToThresholdProfile?: boolean,
		selectedPartitions?: string[]
	): Promise<MetricTableEntry[]> {
		const urlBuilder = url`api/projects/${project}/metrics/table`;

		urlBuilder.append('t', commit);
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('limit-to-profile', String(limitToThresholdProfile));
		urlBuilder.appendMultiple('metrics', metrics);
		urlBuilder.append('configuration-name', thresholdConfigName);
		urlBuilder.appendMultiple('partition', selectedPartitions);
		urlBuilder.append('all-partitions', String(selectedPartitions === undefined));

		return this.get<MetricTableEntry[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Loads the contents of the metrics table
	 *
	 * @param project The project
	 * @param commit The commit
	 */
	public getMetricsForThresholdProfiles(
		project: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<MetricsForThresholdProfile[]> {
		const urlBuilder = url`api/metric-threshold-configurations/metrics`;
		urlBuilder.append('project', project);
		urlBuilder.append('t', commit);

		return this.get<MetricsForThresholdProfile[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Loads the metric names that should be hidden per default.
	 *
	 * @param project The project
	 * @param uniformPath The uniform path
	 * @param optionName The option name for which the default hidden metrics should be returned.
	 * @returns The hidden metrics by name.
	 */
	public getDefaultHiddenMetrics(
		project: string,
		uniformPathType: ETypeEntry,
		optionName: string
	): Promise<string[]> {
		const urlBuilder = url`api/projects/${project}/default-hidden-metrics/${uniformPathType}`;
		urlBuilder.append('option-name', optionName);
		return this.get<string[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the repository log entries for the given array of commits.
	 *
	 * @param project The project
	 * @param commits The commits
	 * @param privacyAware Whether only commits by the current user shall be returned based on the privacy server
	 *   option.
	 */
	public getRepositoryLogEntries(
		project: string,
		commits: UnresolvedCommitDescriptor[],
		privacyAware?: boolean,
		split?: boolean
	): Promise<CommitRepositoryLogEntry[]> {
		const urlBuilder = url`api/projects/${project}/repository-logs`;
		if (split) {
			urlBuilder.appendToPath('split');
		}
		urlBuilder.append('privacy-aware', privacyAware);
		const searchParams = TeamscaleServiceClient.getCommitsAsURLSearchParams(commits);
		return this.post<UserResolvedRepositoryLogEntry[]>(urlBuilder, searchParams)
			.then(logEntries => CommitRepositoryLogEntry.wrapRepositoryLogEntries(logEntries))
			.catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the log entry for the latest (HEAD) commit for a given project and branch.
	 *
	 * @param project
	 * @param branch
	 * @param privacyAware Whether only a commit by the current user shall be returned based on the privacy server
	 *   option.
	 */
	public getLatestCommitRepositoryLogEntry(
		project: string,
		branch: string,
		privacyAware?: boolean
	): Promise<CommitRepositoryLogEntry | null> {
		return QUERY.findLogEntriesInRange(project, {
			t: UnresolvedCommitDescriptor.latestOnBranch(branch),
			'entry-count': 1,
			'privacy-aware': privacyAware
		})
			.fetch()
			.then(logEntries => {
				if (isEmpty(logEntries)) {
					return null;
				}
				return new CommitRepositoryLogEntry(logEntries[0]!);
			})
			.catch(this.getDefaultErrorHandler());
	}

	/**
	 * Performs a query for tests.
	 *
	 * @param sortField Field to sort tests: test-id, subject
	 * @param sortOrder Ascending or descending
	 */
	public performTestQuery(
		project: string,
		query: string,
		startIndex: number,
		maxResult?: number,
		sortOptions?: SortOptions,
		commit?: UnresolvedCommitDescriptor
	): Promise<TestQueryResult> {
		const urlBuilder = url`api/projects/${project}/test-query`;
		urlBuilder.append('t', commit);
		return this.performQuery(
			urlBuilder,
			IssueQueryInputHandler.escapeIssueQuery(query),
			startIndex,
			maxResult,
			sortOptions
		);
	}

	/**
	 * Performs a query for issues.
	 *
	 * @param sortField Field to sort issues: issue-id, subject
	 * @param sortOrder Ascending or descending
	 */
	public performIssueQuery(
		project: string,
		query: string,
		startIndex: number,
		maxResult?: number,
		sortOptions?: SortOptions
	): Promise<IssueQueryResult> {
		return this.performQuery(
			url`api/projects/${project}/issue-query`,
			IssueQueryInputHandler.escapeIssueQuery(query),
			startIndex,
			maxResult,
			sortOptions
		);
	}

	/**
	 * Performs a query for issues with TGA information attached.
	 *
	 * @param sortField Field to sort issues: 'issue-id', 'subject', 'changed-methods-count', 'test-gap-ratio',
	 *   'number-of-test-gaps'
	 * @param sortOrder Ascending or descending
	 * @param tgaFilter 'All issues', 'Only issues with changes', 'Only issues with Test Gaps'
	 */
	public performIssueQueryWithTga(
		project: string,
		query: string,
		startIndex: number,
		maxResult: number,
		sortOptions: SortOptions,
		tgaFilter: EIssueTgaFilterOptionEntry,
		issueTgaParameters: IssueTgaParameters,
		coverageSourceParameters: CoverageSourceQueryParameters
	): Promise<IssueQueryResult> {
		const urlBuilder = url`api/projects/${project}/issue-query/with-tga`;
		urlBuilder.append('tga-filter', tgaFilter);
		urlBuilder.appendAll(ServiceClient.convertToKebabCaseURLSearchParams(coverageSourceParameters));
		urlBuilder.appendAll(ServiceClient.convertToKebabCaseURLSearchParams(issueTgaParameters));
		return this.performQuery(
			urlBuilder,
			IssueQueryInputHandler.escapeIssueQuery(query),
			startIndex,
			maxResult,
			sortOptions
		);
	}

	/** Performs a validation for a query. */
	public performQueryValidation(
		project: string,
		query: string,
		queryableEntityType: QueryableEntityType
	): Promise<boolean> {
		const urlBuilder = url`api/projects/${project}`;
		urlBuilder.appendToPath(...queryableEntityType.validationEndpoint.split('/'));
		urlBuilder.append('query', IssueQueryInputHandler.escapeIssueQuery(query));
		return this.get<boolean>(urlBuilder);
	}

	/** Returns the issue trend. */
	public getIssueTrend(project: string, query: string): Promise<QueryTrendResult> {
		const urlBuilder = url`api/projects/${project}/issue-query/trend`;
		urlBuilder.append('query', IssueQueryInputHandler.escapeIssueQuery(query));
		urlBuilder.append('max', 0);
		return this.get(urlBuilder);
	}

	/** Returns the columns available for issues in the given project. */
	public getKnownIssueColumns(projectId: string): Promise<string[]> {
		return this.get(url`api/projects/${projectId}/issue-query/columns`);
	}

	/**
	 * Returns the issue treemap.
	 *
	 * @param project
	 * @param uniformPath
	 * @param areaMetric The metric index to determine the area of displayed nodes
	 * @param width Width to render tree map
	 * @param height Height to render tree map
	 * @param includeFileRegexes Regular expressions for included files.
	 * @param excludeFileRegexes Regular expressions for excluded files.
	 * @param query Query for the issues for which to show the treemap.
	 * @param callback The callback function with one argument of Java type TreeMapNode
	 */
	public getIssueTreeMap(
		project: string,
		uniformPath: string,
		areaMetric: number,
		width: number,
		height: number,
		includeFileRegexes: string[],
		excludeFileRegexes: string[],
		query: string,
		callback: Callback<TreeMapNode>
	): void {
		const urlBuilder = TeamscaleServiceClient.createTreemapBaseURLBuilder(
			url`api/projects/${project}/issues/treemap`,
			areaMetric,
			areaMetric,
			undefined,
			null,
			includeFileRegexes,
			excludeFileRegexes
		);
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('width', width);
		urlBuilder.append('height', height);
		urlBuilder.append('query', IssueQueryInputHandler.escapeIssueQuery(query));
		this.withCallback(this.get<TreeMapNode>(urlBuilder), callback);
	}

	/** Downloads the CSV file for the issue trend. */
	public downloadIssueTrendAsCSV(project: string, query: string): string {
		const urlBuilder = url`api/projects/${project}/issue-query/trend/download`;
		urlBuilder.append('query', IssueQueryInputHandler.escapeIssueQuery(query));
		return urlBuilder.getURL();
	}

	/** Returns all configured issue queries. */
	public getIssueQueries(project: string): Promise<StoredQueryDescriptor[]> {
		return this.get<StoredQueryDescriptor[]>(url`api/projects/${project}/issues/queries`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Creates/overrides a stored issue query. */
	public createStoredIssueQuery(project: string, issueQueryDescriptor: StoredQueryDescriptor): Promise<void> {
		return this.post(url`api/projects/${project}/issues/queries`, issueQueryDescriptor);
	}

	/** Deletes a stored issue query. */
	public deleteStoredIssueQuery(project: string, issueQueryName: string): Promise<void> {
		return this.delete(url`api/projects/${project}/issues/queries/${issueQueryName}`);
	}

	/** Get TGA metrics. */
	public getTestGapTable(testGapOptions: TestGapOptions): Promise<TgaTableEntry[]> {
		const urlBuilder = TeamscaleServiceClient.createTgaUrlBuilder(
			url`api/projects/${testGapOptions.project}/test-gaps/metrics`,
			testGapOptions
		);
		urlBuilder.appendAll(testGapOptions.toURLSearchParams());
		return this.get<TgaTableEntry[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Searches for siblings of the finding with the given ID and returns the sibling findings. */
	public getSiblingFindings(
		project: string,
		findingId: string,
		commit: UnresolvedCommitDescriptor | null,
		handleErrorsWithPromise?: boolean
	): Promise<TrackedFinding[]> {
		const urlBuilder = url`api/projects/${project}/findings/${findingId}/siblings`;
		urlBuilder.append('t', commit);
		const promise = this.get<TrackedFinding[]>(urlBuilder);
		if (handleErrorsWithPromise) {
			return promise;
		} else {
			return promise.catch(this.getDefaultErrorHandler());
		}
	}

	/**
	 * Returns the outline of a file.
	 *
	 * @param project The project.
	 * @param uniformPath The uniform path of the file for which to get the outline.
	 * @param callback The callback that receives the outline.
	 * @param commit
	 */
	public getCodeOutline(
		project: string,
		uniformPath: string,
		callback: Callback<OutlineElement[]>,
		commit: UnresolvedCommitDescriptor | null
	): void {
		const urlBuilder = url`api/projects/${project}/code-outline/${uniformPath}`;
		urlBuilder.append('t', commit);
		this.withCallback(this.get<OutlineElement[]>(urlBuilder), callback);
	}

	/** Resolves the given usernames to Web-UI strings. */
	public resolveUserNames(usernames: string[]): Promise<string[]> {
		const queryParams = new URLSearchParams();
		usernames.forEach(username => queryParams.append('alias', username));
		return this.post<string[]>(url`api/users/names/resolution`, queryParams).catch(this.getDefaultErrorHandler());
	}

	/** Returns whether storage snapshot support is possible. */
	public isStorageSnapshotBackupSupported(): Promise<boolean> {
		return this.get<boolean>(url`api/storage-snapshot`).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the backup status from the backup import performed at the given id.
	 *
	 * @param id The id for which to retrieve the backup status
	 * @returns Callback
	 */
	public getBackupImportStatus(id: string): Promise<BackupImportStatus> {
		return this.get<BackupImportStatus>(url`api/backups/import/${id}/status`).catch(this.getDefaultErrorHandler());
	}

	/** Returns the list or recent backup import summaries. */
	public getBackupImportSummaries(): Promise<BackupJobSummary[]> {
		return this.get<BackupJobSummary[]>(url`api/backups/import/summary`).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Imports a backup with the given form data.
	 *
	 * @param formData
	 * @param uploadProgressCallback Is called with the progress of the upload.
	 * @returns The id of the backup import process, which can be used to query the backup import state.
	 */
	public importBackup(formData: FormData, uploadProgressCallback?: (event: ProgressEvent) => void): Promise<string> {
		return this.post(url`api/backups/import`, formData, {
			uploadProgressCallback
		});
	}

	/** Imports a Sonar Quality Profile with the given form data. */
	public importSonarProfile(
		formData: FormData,
		uploadProgressCallback?: (event: ProgressEvent) => void
	): Promise<SonarQualityProfileImportSummary> {
		return this.post(url`api/import-sonar-profile`, formData, { uploadProgressCallback });
	}

	/**
	 * Exports a backup with the given form data.
	 *
	 * @param formData
	 * @param uploadProgressCallback
	 * @returns The id of the backup export, which can be used to query the backup export progress.
	 */
	public exportBackup(
		formData: URLSearchParams,
		uploadProgressCallback?: (event: ProgressEvent) => void
	): Promise<string> {
		return this.post<string>(url`api/backups/export`, formData, { uploadProgressCallback });
	}

	/**
	 * Returns the backup status from the backup export performed at the given id.
	 *
	 * @param id The id for which to retrieve the backup status
	 * @returns Callback
	 */
	public getBackupExportStatus(id: string): Promise<BackupExportStatus> {
		return this.get<BackupExportStatus>(url`api/backups/export/${id}/status`).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Gets the current analysis state for the given branch name. If the branch name is null, this method returns the
	 * analysis state of the project.
	 *
	 * @param projectId
	 * @param branchName
	 * @param callback
	 * @param suppressForbiddenErrors Whether to suppress the automatic redirect to the login page on a 403 Forbidden
	 *   error (true) or whether to allow it (false, the default). Not redirecting makes it possible to selectively
	 *   handle permission errors that affect only some components of the page instead of aborting wholesale by
	 *   switching to the login page.
	 */
	public async getBranchAnalysisState(
		projectId: string,
		branchName: string | null,
		suppressForbiddenErrors: boolean | null = false
	): Promise<AnalysisStateWithProjectAndBranch> {
		return this.get<AnalysisState>(url`api/projects/${projectId}/branch-analysis-state/${branchName ?? ''}`)
			.catch(error => {
				if (!suppressForbiddenErrors || error.statusCode !== HttpStatus.FORBIDDEN) {
					this.getErrorManager().handleError(error);
				}
				return new Promise<AnalysisState>(() => 0);
			})
			.then(analysisState => ({ ...analysisState, branchName, projectId }));
	}

	/** Loads the project roles and role assignments for the project ID. */
	public getProjectRoles(projectId: string): Promise<RolesWithAssignments<ProjectRole>> {
		return this.get<RolesWithAssignments<ProjectRole>>(url`api/roles/project-role-assignments/${projectId}`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Sends the role change for the project id to the server. If projectId is null, the role assignment is applied
	 * globally to all projects.
	 */
	public changeProjectRolesAsync(projectId: string | null, roleChange: RoleChange): Promise<void> {
		if (projectId !== null) {
			return this.post<void>(url`api/roles/project-role-assignments/${projectId}`, roleChange).catch(
				this.getDefaultErrorHandler()
			);
		}
		return this.post<void>(url`api/roles/project-role-assignments`, roleChange).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Loads the basic roles and role assignments for the instance in the permission scope. */
	public getBasicRoles(
		permissionScope: EBasicPermissionScope,
		instanceId: string
	): Promise<RolesWithAssignments<string>> {
		const urlBuilder = url`api/roles/basic-role-assignments/${instanceId}`;
		urlBuilder.append('permission-scope', permissionScope.name);
		return this.get<RolesWithAssignments<string>>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Sends the role change for the instance in the permission scope. */
	public changeBasicRolesAsync(
		permissionScope: EBasicPermissionScope,
		instanceId: string | null,
		roleChange: RoleChange
	): Promise<void> {
		const urlBuilder = url`api/roles/basic-role-assignments`;
		if (instanceId != null) {
			urlBuilder.appendToPath(instanceId);
		}
		urlBuilder.append('permission-scope', permissionScope.name);
		return this.post<void>(urlBuilder, roleChange).catch(this.getDefaultErrorHandler());
	}

	/** Loads the global roles assigned to the subject. */
	public changeGlobalRolesAsync(globalRoleChanges: RoleChange): Promise<void> {
		return this.post<void>(url`api/roles/global-role-assignments`, globalRoleChanges).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Loads the permission lookup for the permission scope.
	 *
	 * @param permissionScope
	 */
	public getPermissions(permissionScope: EBasicPermissionScope): Promise<PermissionLookup> {
		const urlBuilder = url`api/basic-permissions`;
		urlBuilder.append('permission-scope', permissionScope.name);
		return this.get<PermissionLookup>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Loads the permissions for a single instance for the permission scope.
	 *
	 * @param permissionScope
	 * @param instance A single instance in the scope to load the permissions for.
	 * @returns An array of EBasicPermission names
	 */
	public getPermissionsForInstance(
		permissionScope: EBasicPermissionScope,
		instance: string
	): Promise<EBasicPermissionEntry[]> {
		const urlBuilder = url`api/basic-permissions/${instance}`;
		urlBuilder.append('permission-scope', permissionScope.name);
		return this.get<EBasicPermissionEntry[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Loads the permission lookup or permissions for a single instance for the permission scope. */
	public getRoleSchemaAsync(): Promise<RoleSchemaData> {
		return this.get<RoleSchemaData>(url`api/roles`).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Stores the given global role as new element.
	 *
	 * @param role The new global role
	 * @returns The promise of the service call
	 */
	public createGlobalRole(role: GlobalRole): Promise<void> {
		return this.post<void>(url`api/roles/global-roles`, role).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Stores the given project role as new element.
	 *
	 * @param role The new project role
	 * @returns The promise of the service call
	 */
	public createProjectRole(role: ProjectRole): Promise<void> {
		return this.post<void>(url`api/roles/project-roles`, role).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Creates a new or updates an existing global role.
	 *
	 * @param oldName The name of the rule, before it was eventually renamed
	 * @param role Role object
	 * @returns The promise of the service call
	 */
	public updateGlobalRole(oldName: string, role: GlobalRole): Promise<void> {
		return this.put<void>(url`api/roles/global-roles/${oldName}`, role).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Creates a new or updates an existing project role.
	 *
	 * @param oldName The name of the rule, before it was eventually renamed
	 * @param role Role object
	 * @returns The promise of the service call
	 */
	public updateProjectRole(oldName: string, role: ProjectRole): Promise<void> {
		return this.put<void>(url`api/roles/project-roles/${oldName}`, role).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Deletes a global role.
	 *
	 * @param roleName The name of the rule
	 * @returns The promise of the service call
	 */
	public deleteGlobalRole(roleName: string): Promise<void> {
		return this.delete<void>(url`api/roles/global-roles/${roleName}`).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Deletes a project role.
	 *
	 * @param roleName The name of the rule
	 * @returns The promise of the service call
	 */
	public deleteProjectRole(roleName: string): Promise<void> {
		return this.delete<void>(url`api/roles/project-roles/${roleName}`).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Retrieves the TGA treemap wrapper for the given uniform path.
	 *
	 * @param project
	 * @param uniformPath
	 * @param findingsFilter
	 * @param width Width to render tree map.
	 * @param height Height to render tree map.
	 * @param baselineCommit The baseline commit. Can be null to indicate no baseline.
	 * @param includeChangedFindings Whether to include findings in changed code
	 * @param onlyChangedFindings Whether to view findings in changed code only
	 * @param endCommit The end commit, which also determines the branch. Can be null to indicate no HEAD and default
	 *   branch.
	 * @param mainColor For the treemap. If not specified, the server default will be used.
	 * @param blacklistFilter The blacklist findingsFilter can be null to use all non blacklisted findings.
	 */
	public getFindingsTreemap(
		project: string,
		uniformPath: string,
		findingsFilter: FindingsFilter,
		width: number,
		height: number,
		baselineCommit: UnresolvedCommitDescriptor | null | undefined,
		includeChangedFindings: boolean | null | undefined,
		onlyChangedFindings: boolean | null | undefined,
		endCommit: UnresolvedCommitDescriptor | null | undefined,
		mainColor: string | null,
		blacklistFilter: EBlacklistingOption
	): Promise<FindingsTreemapWrapper> {
		const urlBuilder = url`api/projects/${project}/findings/treemap`;
		findingsFilter.appendToUrl(urlBuilder);
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('baseline', baselineCommit);
		urlBuilder.append('include-changed-code-findings', includeChangedFindings);
		urlBuilder.append('only-changed-code-findings', onlyChangedFindings);
		urlBuilder.append('t', endCommit);
		urlBuilder.append('width', width);
		urlBuilder.append('height', height);
		urlBuilder.append('color', mainColor);
		urlBuilder.append('blacklisted', blacklistFilter.name);
		return this.get<FindingsTreemapWrapper>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns a promise for all accounts the current user may see. */
	public getExternalAccounts(): Promise<ExternalCredentialsData[]> {
		return this.get<ExternalCredentialsData[]>(url`api/external-accounts`);
	}

	/**
	 * Determines if the external credentials identified by given name exist in the system. Returns a promise with the
	 * corresponding status text.
	 */
	public externalCredentialsExist(credentialsName: string): Promise<boolean> {
		return this.head(url`api/external-accounts/${credentialsName}`)
			.then(() => true)
			.catch(() => false);
	}

	/** Creates the external credentials on the server. */
	public createExternalCredentials(externalCredentials: ExternalCredentialsData): Promise<void> {
		return this.post(url`api/external-accounts`, externalCredentials);
	}

	/** Updates the external credentials on the server. */
	public updateExternalCredentials(
		externalCredentials: ExternalCredentialsData,
		skipConnectorValidation: boolean,
		previousExternalCredentialName: string
	): Promise<void> {
		const urlBuilder = url`api/external-accounts/${previousExternalCredentialName}`;
		urlBuilder.append('skip-connector-validation', skipConnectorValidation);
		return this.put(urlBuilder, externalCredentials);
	}

	/** Deletes the external credentials with the given name. */
	public deleteExternalCredentials(
		externalCredentialsName: string,
		skipConnectorValidation: boolean
	): Promise<string> {
		const urlBuilder = url`api/external-accounts/${externalCredentialsName}`;
		urlBuilder.append('skip-connector-validation', skipConnectorValidation);
		return this.delete(urlBuilder);
	}

	/** Queries the subjectRoleAssignments of the user. */
	public getSubjectRolesAssignments(subjectId: string, subjectType: string): Promise<SubjectRoleAssignments[]> {
		return this.get<SubjectRoleAssignments[]>(url`api/subject-role-assignments/${subjectType}/${subjectId}`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Queries the subjectRoleAssignments for all groups and users (and assigned groups). */
	public getAllSubjectRolesAssignments(): Promise<SubjectRoleAssignments[][]> {
		return this.get<SubjectRoleAssignments[][]>(url`api/subject-role-assignments`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Downloads the complete worker log as a file.
	 *
	 * @param logFilteringParameters The parameters used to filter the log entries included in the result file
	 * @param serviceName The name of the log service to call
	 * @param project The name of the project for which to download the log file
	 * @param minLogLevel If this is not null, only log messages of at least the provided value are returned.
	 */
	public downloadLog(
		logFilteringParameters: LogFilteringParameters,
		logType: ELogType,
		project: string | null,
		minLogLevel?: string | null
	): void {
		const urlBuilder = TeamscaleServiceClient.logsUrl(project, logType);
		urlBuilder.appendToPath('download');
		this.getLogQuery(urlBuilder, logFilteringParameters, minLogLevel);
		window.location.href = urlBuilder.getURL();
	}

	/** Returns the current cross clone detection status. */
	public getXCloneDetectionStatus(project: string): Promise<ExternalXCloneStatus> {
		return this.get<ExternalXCloneStatus>(url`api/projects/${project}/audit/external-x-clones/status`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Runs the X clone detection by calling the corresponding service, which then schedules the trigger. */
	public runXCloneDetection(
		project: string,
		uniformPath: string,
		externalPath: string,
		include: string,
		exclude: string,
		minLength: number
	): Promise<string> {
		return this.post<string>(url`api/projects/${project}/audit/external-x-clones`, {
			uniformPath,
			externalPath,
			include,
			exclude,
			minLength
		}).catch(this.getDefaultErrorHandler());
	}

	/** Returns the currently detected cross clone classes. */
	public getDetectedXCloneClasses(project: string): Promise<ExternalXCloneStatus[]> {
		return this.get<ExternalXCloneStatus[]>(url`api/projects/${project}/audit/external-x-clones/classes`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Returns local file content. */
	public getLocalFileContent(path: string): Promise<FormattedTokenElementInfo> {
		return this.get(url`api/audit/external-x-clones/file/${path}`);
	}

	/**
	 * Returns a preview of the usage info.
	 *
	 * @param option The usage info option to use for the preview.
	 */
	public getUsageDataPreview(option: UsageDataReportingOption): Promise<UsageDataPreview> {
		return this.post<UsageDataPreview>(url`api/usage-data/preview`, option).catch(this.getDefaultErrorHandler());
	}

	/** Returns a preview of the monitoring info. */
	public getMonitoringDataPreview(): Promise<string> {
		return this.get<string>(url`api/monitoring-info`).catch(this.getDefaultErrorHandler());
	}

	/** Returns the SAML metadata for a configured option. */
	public getSamlServiceProviderConfiguration(
		name: string,
		option: unknown
	): Promise<SamlServiceProviderConfiguration> {
		const urlBuilder = url`api/auth/saml/sp-configuration`;
		urlBuilder.append('name', name);
		return this.post<SamlServiceProviderConfiguration>(urlBuilder, option).catch(this.getDefaultErrorHandler());
	}

	/** Returns the SAML metadata from an external identity provider. */
	public getSamlIdentityProviderMetadata(idpUrl: string): Promise<string> {
		const urlBuilder = url`api/auth/saml/idp-metadata`;
		urlBuilder.append('idp-url', idpUrl);
		return this.get<string>(urlBuilder);
	}

	/** Returns the OpenID Connect endpoints based on the issuer URL. */
	public getOpenIdEndpoints(issuer: string): Promise<OpenIdEndpointInfo> {
		const urlBuilder = url`api/auth/openid/issuer-endpoints`;
		urlBuilder.append('issuer', issuer);
		return this.get(urlBuilder);
	}

	/**
	 * Fetches the branches of some Version Controlled System (VCS) repository
	 *
	 * @param connectorConfig Connector configuration with infos for retrieving branches
	 * @param projectId The id of the project to which the connector configuration belongs
	 */
	public getRepositoryBranches(connectorConfig: ConnectorConfiguration, projectId: string | null): Promise<string[]> {
		const urlBuilder = url`api/branches-retriever`;
		urlBuilder.append('projectId', projectId);
		return this.post(urlBuilder, connectorConfig);
	}

	/**
	 * Fetches the files of some Version Controlled System (VCS) repository
	 *
	 * @param connectorConfig Connector configuration with infos for retrieving files
	 * @param projectId The id of the project to which the connector configuration belongs
	 */
	public getRepositoryFiles(connectorConfig: ConnectorConfiguration, projectId: string): Promise<string[]> {
		const urlBuilder = url`api/files-retriever`;
		urlBuilder.append('projectId', projectId);
		return this.post(urlBuilder, connectorConfig);
	}

	/**
	 * Fetches all known snapshots IDs of this system for instance comparison.
	 *
	 * @param onlyCurrentUser Whether only the snapshots created by the currently logged in user should be reported
	 */
	public async getInstanceComparisonSnapshotIds(onlyCurrentUser: boolean): Promise<string[]> {
		const urlBuilder = url`api/instance-comparison/snapshots/ids`;
		urlBuilder.append('only-current-user', onlyCurrentUser);
		return this.get<string[]>(urlBuilder);
	}

	/**
	 * Fetches the snapshot of this system with the given ID for instance comparison.
	 *
	 * @param id The ID of the requested snapshot
	 */
	public async getInstanceComparisonSnapshot(id: string): Promise<InstanceComparisonSnapshotCreation> {
		const urlBuilder = url`api/instance-comparison/snapshots/${id}`;
		urlBuilder.append('reduced', true);
		return this.get<InstanceComparisonSnapshotCreation>(urlBuilder);
	}

	/**
	 * Fetches all comparisons associated with the given snapshot.
	 *
	 * @param id The snapshot ID
	 */
	public async getAssociatedInstanceComparisons(id: string): Promise<InstanceComparisonComputation[]> {
		return this.get<InstanceComparisonComputation[]>(url`api/instance-comparison/snapshots/${id}/comparisons`);
	}

	/**
	 * Fetches the instance comparison with the given ID.
	 *
	 * @param id The ID of the requested comparison
	 */
	public async getInstanceComparisonComputation(id: string): Promise<ReducedInstanceComparisonComputation> {
		return this.get(url`api/instance-comparison/comparisons/${id}`);
	}

	/** Fetches the project comparison with the given ID, project name and contributor. */
	public async getProjectComparisonResultsForContributor(
		comparisonId: string,
		project: string,
		contributor: string
	): Promise<ProjectComparisonResult> {
		const urlBuilder = url`api/instance-comparison/comparisons/${comparisonId}/${project}`;
		urlBuilder.append('contributor', contributor);
		return this.get<ProjectComparisonResult>(urlBuilder);
	}

	/** Creates a new snapshot for the given request. */
	public async createInstanceComparisonSnapshot(snapshotRequestData: URLSearchParams): Promise<string> {
		return this.post(url`api/instance-comparison/snapshots`, snapshotRequestData);
	}

	/**
	 * Triggers a comparison with a remote instance.
	 *
	 * @param remoteInstanceCredentials The credentials to access the remote instance.
	 */
	public async createInstanceComparisonComputation(remoteInstanceCredentials: URLSearchParams): Promise<string> {
		return this.post(url`api/instance-comparison/comparisons`, remoteInstanceCredentials);
	}

	/** Deletes the snapshot with the given ID. */
	public async deleteInstanceComparisonSnapshot(id: string): Promise<void> {
		return this.delete(url`api/instance-comparison/snapshots/${id}`);
	}

	/** Deletes the comparison with the given ID. */
	public async deleteInstanceComparisonComputation(id: string): Promise<void> {
		return this.delete(url`api/instance-comparison/comparisons/${id}`);
	}

	/** Returns the health state of the system, plus critical status messages (if applicable). */
	public getSystemHealthState(): Promise<{ healthy: boolean; statusMessage: string }> {
		return this.get<string>(url`api/health-check?critical-only=true`).then(statusLine => {
			// The health check service guarantees that the output contains a
			// numerical return code (RC) which reflects the health state of the
			// system
			const match = statusLine.match('RC: (\\d)\\)[\\n\\r]*(.+)');
			if (match !== null && match.length === 3) {
				// A return code of "2" indicates a critical health state
				return { healthy: match[1] !== '2', statusMessage: match[2]! };
			}
			return { healthy: true, statusMessage: '' };
		});
	}

	/** Calls the service to analyze the exceptions hierarchies of a project. */
	public getExceptionsHierarchy(project: string): Promise<ExceptionsTree[][]> {
		return this.get<ExceptionsTree[][]>(url`api/projects/${project}/exceptions-hierarchy/object`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Calls the service to analyze the exceptions hierarchies of a project. */
	public getExceptionsHierarchySVG(project: string): Promise<string[]> {
		const urlBuilder = url`api/projects/${project}/exceptions-hierarchy/graph`;
		urlBuilder.append('isdot', false);
		return this.get<string[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Checks whether a connection to the Teamscale server is possible (without actually querying any data). Potential
	 * exceptions are caught by this method and handled as "no connection possible".
	 *
	 * @returns <code>true</code> if the server can be reached, <code>false</code> in case of connection errors or
	 *   exceptions.
	 */
	public canReachServer(): Promise<boolean> {
		return new Promise(resolve => {
			const httpRequest = new XMLHttpRequest();
			httpRequest.onload = (): void => resolve(true);
			httpRequest.onerror = (): void => resolve(false);
			try {
				httpRequest.open('GET', '', true);
				httpRequest.setRequestHeader('Content-type', 'application/json; charset=utf-8');
				httpRequest.send();
			} catch (e) {
				resolve(false);
			}
		});
	}

	/** Returns all stored quality reports. */
	public getQualityReports(): Promise<UserResolvedQualityReport[]> {
		return this.get(url`api/quality-reports`);
	}

	/**
	 * Deletes the report with the given report id. In case the current user may not access the report, a server error
	 * will be thrown.
	 */
	public deleteQualityReport(reportId: string): Promise<void> {
		return this.delete(url`api/quality-reports/${reportId}`);
	}

	/** Returns the quality report with the given id. Will lead to a 404 error in case the report does not exist. */
	public async getQualityReport(reportId: string): Promise<ExtendedQualityReport | null> {
		const report = await this.get<QualityReport | null>(url`api/quality-reports/${reportId}`);
		if (report === null) {
			return null;
		}
		return wrapReport(report);
	}

	/**
	 * Store the new quality report.
	 *
	 * @param report The new quality report
	 * @returns The id of the new quality report
	 */
	public saveQualityReport(report: QualityReport): Promise<string> {
		return this.post(url`api/quality-reports`, report);
	}

	/** Updates the given report slide on the server, i.e. overrides the slide and stores the report. */
	public addReportSlides(reportId: string, slidesAndPosition: SlidesWithPositions): Promise<void> {
		return this.post(url`api/quality-reports/${reportId}/add-slides/`, slidesAndPosition);
	}

	/** Updates the given report slide on the server, i.e. overrides the slide and stores the report. */
	public updateReportSlides(reportId: string, slides: ExtendedReportSlide[]): Promise<void> {
		return this.put(url`api/quality-reports/${reportId}/update-slides/`, slides);
	}

	/** Updates the given report slide on the server, i.e. overrides the slide and stores the report. */
	public removeReportSlides(reportId: string, slideIds: string[]): Promise<void> {
		return this.post(url`api/quality-reports/${reportId}/remove-slides/`, slideIds);
	}

	/** Update an existing quality report */
	public updateQualityReport(updatedReport: QualityReport): Promise<void> {
		return this.put(url`api/quality-reports/${String(updatedReport.metaInfo.id)}`, updatedReport);
	}

	/**
	 * Provides a 'Save as' functionality for quality reports. Can either override an existing report with the given new
	 * configuration, or create a copy with the given id on the server using the given new report profile and meta
	 * info.
	 *
	 * The current user needs to have VIEW rights on all slides of the original report, as well as the default project
	 * of the new one.
	 *
	 * @param report The new quality report
	 * @param idOfExistingReport The id of the existing report to be used as a template or to be overridden
	 * @param overwriteExistingReport Specifies whether to create a new report or override the existing one.
	 * @returns The id of the newly created report
	 */
	public saveQualityReportAs(
		report: QualityReport,
		idOfExistingReport: string,
		overwriteExistingReport: boolean
	): Promise<string> {
		const urlBuilder = url`api/quality-reports`;
		urlBuilder.append('from-template', idOfExistingReport);
		urlBuilder.append('overwrite', overwriteExistingReport);
		return this.post(urlBuilder, report);
	}

	/** Returns all available report slides. */
	public listReportSlideTypes(): Promise<SlideTypeDescription[]> {
		return this.get(url`api/slides/slide-types`);
	}

	/**
	 * Returns the report slide for the given service id.
	 *
	 * @param serviceId Service id (e.g. 'title-slide')
	 * @param reportId Report id
	 */
	public async getReportSlideByServiceId(serviceId: string, reportId: string): Promise<ExtendedReportSlide> {
		const slide = await this.get<ReportSlideBase>(url`api/reports/${reportId}/slides/${serviceId}`);
		return wrapReportSlide(slide);
	}

	/**
	 * Loads slide data.
	 *
	 * @param endpoint Optional endpoint suffix for slide types with multiple endpoints.
	 */
	public loadSlideRenderData(
		slideType: EReportSlide,
		parameters: SlideParametersBase,
		renderContext: RenderContext,
		endpoint?: string
	): Promise<SlideRenderDataBase> {
		const renderRequest = {
			parameters,
			qualityArtifactProfile: renderContext.reportProfile
		} as SlideRenderRequestContent;
		const urlBuilder = url`api/reports/${renderContext.reportMetaInfo.id!}/slides/${slideType.serviceId}`;

		if (endpoint !== undefined) {
			urlBuilder.appendToPath(endpoint);
		}

		return this.post(urlBuilder, renderRequest);
	}

	/**
	 * Loads the currently enabled rules for the given language and for the given project or analysis profile, or all
	 * available rules for the given language if project and analysis profile are null.
	 */
	public getRules(
		languages: ELanguage[],
		tools: EAnalysisTool[],
		project?: string,
		analysisProfile?: string
	): Promise<RulesContainer> {
		const urlBuilder = url`api/language-rules`;
		if (project != null) {
			urlBuilder.appendToPath(project);
		} else if (analysisProfile != null) {
			urlBuilder.appendToPath('analysis-profile', analysisProfile);
			urlBuilder.append('includeDisabledRules', true);
		}
		urlBuilder.appendMultiple(
			'languages',
			languages.map(language => language.name)
		);
		urlBuilder.appendMultiple(
			'tools',
			tools.map(tool => tool.name)
		);
		return this.get<RulesContainer>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Post image for image slide as multipart/form-data
	 *
	 * @param reportId
	 * @param imageFile Image to upload
	 */
	public postImageSlideImage(reportId: string, imageFile: File): Promise<string> {
		const formData = new FormData();
		formData.append('slide-image', imageFile);
		return this.post(url`api/reports/${reportId}/images`, formData);
	}

	/** Returns the input for the Jacoco Agent configuration wizard. */
	public getJacocoAgentConfigurationWizardInput(project: string): Promise<JacocoAgentWizardInput> {
		return this.get<JacocoAgentWizardInput>(url`api/projects/${project}/jacoco-wizard`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Downloads a Jacoco Agent config file with the given configuration.
	 *
	 * @param project The project for which to set up coverage uploads.
	 * @param user The user under which to perform the uploads.
	 * @param partition The partition to upload to.
	 * @param includedPackages The packages to profile.
	 */
	public downloadJacocoAgentConfig(
		project: string,
		user: string,
		partition: string,
		includedPackages: string[]
	): void {
		const urlBuilder = url`api/projects/${project}/jacoco-wizard/jacocoagent.properties`;
		urlBuilder.append('user', user);
		urlBuilder.append('partition', partition);
		for (const includedPackage of includedPackages) {
			urlBuilder.append('include', includedPackage);
		}
		NavigationUtils.updateLocation(urlBuilder.getURL());
	}

	/** Creates a session for uploading external analysis results. */
	public createExternalAnalysisSession(
		project: string,
		message: string,
		partition: string,
		commit: UnresolvedCommitDescriptor
	): Promise<string> {
		const urlBuilder = url`api/projects/${project}/external-analysis/session`;
		urlBuilder.append('message', message);
		urlBuilder.append('partition', partition);
		urlBuilder.append('t', commit);
		return this.post(urlBuilder);
	}

	/** Creates a session for uploading external analysis results. */
	public closeExternalAnalysisSession(project: string, sessionId: string): Promise<string> {
		return this.post(url`api/projects/${project}/external-analysis/session/${sessionId}`);
	}

	/** Creates a session for uploading external analysis results. */
	public uploadExternalAnalysisReportToSession(
		project: string,
		sessionId: string,
		format: string,
		report: File,
		uploadProgressCallback?: UploadProgress
	): Promise<string> {
		const urlBuilder = url`api/projects/${project}/external-analysis/session/${sessionId}/report`;
		urlBuilder.append('format', format);
		const formData = new FormData();
		formData.append('report', report);
		return this.post(urlBuilder, formData, { uploadProgressCallback });
	}

	/** Sends an architecture file to the server. */
	public uploadArchitectureFile(
		project: string,
		commit: number | string | UnresolvedCommitDescriptor | null,
		architectureFile: File,
		architecturePath: string,
		uploadProgressCallback?: (event: ProgressEvent) => void
	): Promise<undefined> {
		const urlBuilder = url`api/projects/${project}/architectures`;
		urlBuilder.append('t', commit);
		const formData = new FormData();
		formData.append(architecturePath, architectureFile);
		return this.post(urlBuilder, formData, {
			uploadProgressCallback
		});
	}

	/**
	 * Uploads dashboards/dashboard templates.
	 *
	 * @returns Names of uploaded dashboards/templates
	 */
	public submitDashboardAndTemplateFiles(dashboardFiles: FileList): Promise<string> {
		const urlBuilder = url`api/dashboards`;
		const formData = new FormData();
		for (const dashboardFile of dashboardFiles) {
			formData.append('dashboardDescriptor', dashboardFile);
		}
		return this.post<string>(urlBuilder, formData).then(result => {
			ReactUtils.queryClient.invalidateQueries(['dashboards']);
			return result;
		});
	}

	/**
	 * Deletes a project.
	 *
	 * @param projectName The name of the project to be deleted.
	 * @param deleteAllAssignments Deletes additional project information like role assignments
	 * @param deleteAllDashboards Deletes additional project information like dashboards
	 * @param callback Callback provides string "success"
	 */
	public deleteProject(
		projectName: string,
		deleteAllAssignments: boolean,
		deleteAllDashboards: boolean,
		callback: Callback<void>
	): void {
		const urlBuilder = url`api/projects/${projectName}`;
		urlBuilder.append('delete-all-assignments', deleteAllAssignments);
		urlBuilder.append('delete-all-dashboards', deleteAllDashboards);
		this.withCallback(this.delete<void>(urlBuilder), callback);
	}

	/** Updates a project. The project must already exist. */
	public updateProject(projectInfo: ProjectInfo): Promise<ProjectUpdateResult> {
		return this.put(url`api/projects/${projectInfo.internalId!}`, projectInfo);
	}

	/** Lists the workers of the execution engine and their status. */
	public getExecutionWorkerStatus(): Promise<WorkerGroupStatus[]> {
		return this.get<WorkerGroupStatus[]>(url`api/execution-status/workers`).catch(this.getDefaultErrorHandler());
	}

	/** Queries the mode of the currently used scheduling filter. */
	public getSchedulingFilter(): Promise<ProjectSchedulingFilter> {
		return this.get<ProjectSchedulingFilter>(url`api/scheduler/mode`).catch(this.getDefaultErrorHandler());
	}

	/** Sets the mode for the currently used scheduling filter. */
	public setSchedulingFilter(mode: string): Promise<void> {
		const urlBuilder = url`api/scheduler/mode`;
		return this.put(urlBuilder, mode);
	}

	/**
	 * Used to (un)pause a project .
	 *
	 * @param projectId The id of the project
	 * @param pause Whether to pause (true) or unpause (false)
	 */
	public setProjectSchedulePausing(projectId: string, pause: boolean): Promise<void> {
		const urlBuilder = url`api/scheduler/projects/${projectId}`;
		let command;
		if (pause) {
			command = EProjectScheduleCommand.PAUSE;
		} else {
			command = EProjectScheduleCommand.UNPAUSE;
		}
		return this.put(urlBuilder, command.name);
	}

	/** Queries the queue of the index execution engine. */
	public getExecutionQueueStatus(): Promise<JobDescriptor[]> {
		return this.get<JobDescriptor[]>(url`api/execution-status/queue`).catch(this.getDefaultErrorHandler());
	}

	/** Returns the content of an element. */
	public getFormattedContent(
		project: string,
		uniformPath: string,
		commit?: string | null | UnresolvedCommitDescriptor,
		pretty?: boolean
	): Promise<FormattedTokenElementInfo | null> {
		const urlBuilder = url`api/projects/${project}/content/formatted`;
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('t', commit);
		urlBuilder.append('pretty', pretty);
		return this.get<FormattedTokenElementInfo>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns the resource metrics. */
	public getResourceMetrics(
		project: string,
		uniformPath: string,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<MetricDirectoryEntry> {
		const urlBuilder = url`api/projects/${project}/metrics`;
		urlBuilder.append('t', commit);
		urlBuilder.append('uniform-path', uniformPath);
		return this.get<MetricDirectoryEntry>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the resource metric distribution. The callback function is called with a list of Java type
	 * MetricDistributionEntry.
	 *
	 * @param project The name of the project
	 * @param uniformPath The uniform path for which to calculate the metric distribution.
	 * @param principalMetricIndex The 'selected' metric index with respect to which the metric distribution is
	 *   calculated.
	 * @param metricIndexes The list of metric indexes to calculate the distribution.
	 * @param boundaries The list of boundaries from which consecutive intervals are calculated and used to calculate
	 *   the distribution.
	 * @param commit An optional commit to retrieve the data.
	 */
	public getResourceMetricDistribution(
		project: string,
		uniformPath: string,
		principalMetricIndex: number,
		metricIndexes: number[],
		boundaries: number[],
		commit?: UnresolvedCommitDescriptor | null
	): Promise<MetricDistributionEntry[]> {
		const urlBuilder = url`api/projects/${project}/metric-distribution`;
		urlBuilder.append('t', commit);
		urlBuilder.append('uniform-path', uniformPath);
		TeamscaleServiceClient.appendMetricDistributionParameters(
			urlBuilder,
			principalMetricIndex,
			metricIndexes,
			boundaries
		);
		return this.get<MetricDistributionEntry[]>(urlBuilder);
	}

	/**
	 * Returns the resource metric distribution with delta. The callback function is called with an object of Java type
	 * MetricDistributionWithDelta. The delta is the difference between end and start metric distribution values for the
	 * provided commits. If no end commit is provided the time is set to now.
	 *
	 * @param project The name of the project.
	 * @param uniformPath The uniform path to calculate the metric distribution for.
	 * @param principalMetricIndex The 'selected' metric index with respect to which the metric distribution is
	 *   calculated.
	 * @param metricIndexes The list of metric indexes to calculate the distribution.
	 * @param boundaries The list of boundaries from which consecutive intervals are calculated and used to calculate
	 *   the distribution.
	 * @param startCommit The commit for the start metric distribution.
	 * @param endCommit Optional commit for the end metric distribution. The current time is used if not provided.
	 */
	public getResourceMetricDistributionWithDelta(
		project: string,
		uniformPath: string,
		principalMetricIndex: number,
		metricIndexes: number[],
		boundaries: number[],
		startCommit: UnresolvedCommitDescriptor,
		endCommit?: UnresolvedCommitDescriptor | null
	): Promise<MetricDistributionWithDelta> {
		const urlBuilder = url`api/projects/${project}/metric-distribution/delta`;
		TeamscaleServiceClient.appendMetricDistributionParameters(
			urlBuilder,
			principalMetricIndex,
			metricIndexes,
			boundaries
		);
		urlBuilder.append('t1', startCommit);
		urlBuilder.append('t2', endCommit);
		urlBuilder.append('uniform-path', uniformPath);
		return this.get<MetricDistributionWithDelta>(urlBuilder);
	}

	/**
	 * Returns metric hotspots for the given resource.
	 *
	 * @param project The name of the project
	 * @param uniformPath The uniform path for which to calculate the metric distribution.
	 * @param metricIndexes The list of metric indexes to calculate the distribution.
	 * @param numResults The number of results to return
	 * @param scoreCutoff The score at which to perform a cutoff (i.e. files with this score or higher will no longer be
	 *   displayed)
	 * @param commit An optional commit to retrieve the data.
	 */
	public getResourceMetricHotspots(
		project: string,
		uniformPath: string,
		metricIndexes: number[],
		numResults: number,
		scoreCutoff: number,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<MetricDirectoryEntry[]> {
		const urlBuilder = url`api/projects/${project}/metrics/hotspots`;
		urlBuilder.append('t', commit);
		urlBuilder.appendMultiple('metric-indexes', metricIndexes);
		urlBuilder.append('num-results', numResults);
		urlBuilder.append('score-cutoff', scoreCutoff);
		urlBuilder.append('uniform-path', uniformPath);
		return this.get<MetricDirectoryEntry[]>(urlBuilder);
	}

	/**
	 * Returns the metrics schema for the project.
	 *
	 * @param uniformPathType Optional, can be <code>null</code> for 'CODE'
	 */
	public getMetricsSchema(project: string, uniformPathType?: ETypeEntry | null): Promise<MetricDirectorySchema> {
		return this.get<MetricDirectorySchema>(
			url`api/projects/${project}/metric-schema/${uniformPathType ?? 'CODE'}`
		).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Fetches and returns all metric schemas of the given project. The returned type is a record that maps the
	 * respective EType#name of each metric schema to the schema itself.
	 */
	public getMetricsSchemas(project: string): Promise<Record<ETypeEntry, MetricDirectorySchema>> {
		return this.get<Record<string, MetricDirectorySchema>>(url`api/projects/${project}/metric-schemas`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Returns commits along a history trend for given timestamps.
	 *
	 * @param branch The branch on which the history shall be retrieved
	 */
	public getCommitsOnHistoryTrend(
		project: string,
		branch: string | null,
		timestamps: number[]
	): Promise<UnresolvedCommitDescriptor[]> {
		const urlBuilder = url`api/projects/${project}/metrics/history/commits`;
		urlBuilder.append('branch', branch);
		urlBuilder.appendMultiple('timestamp', timestamps);
		return this.get<UnresolvedCommitDescriptor[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns the threshold path for a given threshold in the defined time span. */
	public getMetricThresholdPathForThreshold(
		project: string,
		configuration: string,
		thresholdGroupName: string,
		thresholdDisplayName: string,
		startTimestamp: number | null,
		endTimestamp: number | null,
		commit: UnresolvedCommitDescriptor | null,
		callback: Callback<EvaluatedMetricThresholdPath>
	): void {
		const urlBuilder = url`api/projects/${project}/metric-threshold-path-for-threshold`;
		urlBuilder.append('configuration', configuration);
		urlBuilder.append('thresholdGroupName', thresholdGroupName);
		urlBuilder.append('thresholdDisplayName', thresholdDisplayName);
		urlBuilder.append('start', startTimestamp);
		urlBuilder.append('end', endTimestamp);
		urlBuilder.append('t', commit);
		this.withCallback(this.get<EvaluatedMetricThresholdPath>(urlBuilder), callback);
	}

	/**
	 * Returns the threshold path for a metric in a threshold configuration. A heuristic tries to find the best matching
	 * threshold within the configuration.
	 *
	 * @param project Name of the project
	 * @param configurationName Name of the threshold configuration
	 * @param metricName Name of the metric
	 * @param path Path without project prefix
	 * @param callback Callback
	 * @param startTimestamp
	 * @param endTimestamp
	 */
	public getMetricThresholdPathForMetric(
		project: string,
		configurationName: string,
		metricName: string,
		path: string,
		startTimestamp: number | null,
		endTimestamp: number | null
	): Promise<EvaluatedMetricThresholdPath> {
		const urlBuilder = url`api/projects/${project}/metric-threshold-path-for-metric`;
		urlBuilder.append('configuration', configurationName);
		urlBuilder.append('metricName', metricName);
		urlBuilder.append('path', path);
		urlBuilder.append('start', startTimestamp);
		urlBuilder.append('end', endTimestamp);
		return this.get<EvaluatedMetricThresholdPath>(urlBuilder);
	}

	/**
	 * Returns the resource metrics history until a given commit.
	 *
	 * @param project
	 * @param uniformPath
	 * @param callback
	 * @param endCommit The end commit or null
	 * @param startCommit Commit from which the history should start
	 */
	public getResourceMetricsHistoryUntil(
		project: string,
		uniformPath: string,
		endCommit: UnresolvedCommitDescriptor | null,
		startCommit?: UnresolvedCommitDescriptor | null,
		partitions?: string[]
	): Promise<MetricTrendEntry[]> {
		const urlBuilder = url`api/projects/${project}/metrics/history`;
		if (startCommit != null) {
			urlBuilder.append('start', startCommit.toString());
		}
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('end', endCommit?.toString());
		urlBuilder.append('t', endCommit?.toString());
		urlBuilder.appendMultiple('partition', partitions);
		urlBuilder.append('all-partitions', partitions === undefined);
		return this.get(urlBuilder);
	}

	/**
	 * Returns the repository activity summary for the given project.
	 *
	 * @param project The project name
	 * @returns The requested repository activity summary
	 */
	public getRepositoryActivitySummary(project: string): Promise<RepositoryActivitySummary> {
		return this.get<RepositoryActivitySummary>(url`api/projects/${project}/repository-summary`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Returns the repository activity summary for the given project and only considers code commits. */
	public getRepositoryActivitySummaryForCodeCommits(project: string): Promise<RepositoryActivitySummary> {
		const urlBuilder = url`api/projects/${project}/repository-summary`;
		urlBuilder.append('code-commits-only', true);
		return this.get<RepositoryActivitySummary>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns a simple the repository summary for the given project.
	 *
	 * @param project The project name
	 * @returns The requested repository summary
	 */
	public getSimpleRepositorySummary(project: string): Promise<RepositorySummary> {
		const urlBuilder = url`api/projects/${project}/repository-summary`;
		urlBuilder.append('only-first-and-last', true);
		return this.get<RepositorySummary>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns the repository log file entries for the given commits in a given project. */
	public async getRepositoryLogFileEntries(
		project: string,
		commits: UnresolvedCommitDescriptor[]
	): Promise<CommitRepositoryLogFileHistoryEntry[]> {
		const urlBuilder = url`api/projects/${project}/commits/affected-files`;
		urlBuilder.appendAll(TeamscaleServiceClient.getCommitsAsURLSearchParams(commits));
		return this.get<RepositoryLogFileHistoryEntry[]>(urlBuilder)
			.then(logFileEntries => wrapRepositoryLogFileHistoryEntries(logFileEntries))
			.catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the repository log file entries for a given uniform path in a given project.
	 *
	 * @param project The project
	 * @param uniformPath The uniform path
	 * @param commit The commit
	 * @param commitFilterSettings
	 * @param numberOfEntries
	 */
	public async getResourceHistoryEntries(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor | null,
		commitFilterSettings: CommitFilterSettings | null,
		numberOfEntries = 0
	): Promise<CommitRepositoryLogFileHistoryEntry[]> {
		return QUERY.getResourceHistory(project, uniformPath, {
			t: commit ?? undefined,
			...commitFilterSettings,
			'number-of-entries': numberOfEntries
		})
			.fetch()
			.then(logFileEntries => wrapRepositoryLogFileHistoryEntries(logFileEntries))
			.catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the latest repository log entry for a given element in a given project at or before the given commit.
	 *
	 * @param project The project
	 * @param uniformPath The uniform path
	 * @param endCommit The commit; if not given, return latest on default branch
	 * @param excludeExternalUploads Whether external uploads should be regarded for the last change
	 */
	public getLastChangeEntryForResource(
		project: string,
		uniformPath: string,
		endCommit: UnresolvedCommitDescriptor | null | undefined,
		excludeExternalUploads: boolean
	): Promise<RepositoryLogEntry | null> {
		const urlBuilder = url`api/projects/${project}/repository/last-change/${uniformPath}`;
		urlBuilder.append('t', endCommit);
		urlBuilder.append('exclude-external-uploads', excludeExternalUploads);
		return this.get<RepositoryLogEntry | null>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the latest commit starting from `backwardsFrom` backwards. Finds a commit with the given type
	 * `commitType` in the given project.
	 *
	 * @param projectId The project ID to search for the commit.
	 * @param commitType The type of commit to find.
	 * @param endCommit Starts searching backwards from (including this commit as a result candidate).
	 */
	public getLastCommitOfType(
		project: string,
		commitType: ECommitType,
		endCommit: UnresolvedCommitDescriptor | null | undefined
	): Promise<RepositoryLogEntry | null> {
		const urlBuilder = url`api/projects/${project}/repository/last-commit/`;
		urlBuilder.append('t', endCommit);
		urlBuilder.append('commit-type', commitType.name);
		return this.get<RepositoryLogEntry | null>(urlBuilder);
	}

	/** Retrieves the list of elements changed between two commits. */
	public getElementChurn(
		project: string,
		uniformPath: string,
		commit1: UnresolvedCommitDescriptor,
		commit2: UnresolvedCommitDescriptor,
		groupName?: string | null
	): Promise<Array<TokenElementChurnInfo | TokenElementChurnWithOriginInfo>> {
		const urlBuilder = url`api/projects/${project}/delta/affected-files`;
		urlBuilder.append('t1', commit1);
		urlBuilder.append('t2', commit2);
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('group', groupName);
		return this.get<Array<TokenElementChurnInfo | TokenElementChurnWithOriginInfo>>(urlBuilder).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Compares the metrics for the given uniform path for the given commits. */
	public getMetricDeltas(
		project: string,
		uniformPath: string,
		commit1: UnresolvedCommitDescriptor | string | number,
		commit2: UnresolvedCommitDescriptor | string | number | null
	): Promise<MetricDeltaValue[]> {
		const urlBuilder = url`api/projects/${project}/metrics/delta`;
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('t1', commit1);
		urlBuilder.append('t2', commit2);
		return this.get<MetricDeltaValue[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns the container info for a project and uniform path. */
	public getContainerInfo(
		project: string,
		uniformPath: string,
		timestamp?: UnresolvedCommitDescriptor | null
	): Promise<ContainerInfo> {
		const urlBuilder = url`api/projects/${project}/directory`;
		urlBuilder.append('t', timestamp);
		urlBuilder.append('uniform-path', uniformPath);
		return this.get(urlBuilder);
	}

	/** Returns the container info for a uniform path. */
	public getGlobalContainerInfo(uniformPath: string): Promise<ContainerInfo> {
		const urlBuilder = url`api/directories`;
		urlBuilder.append('uniform-path', uniformPath);
		return this.get<ContainerInfo>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the extended types of the given uniform path.
	 *
	 * @param project The project of the uniform path.
	 * @param uniformPath The uniform path to look up.
	 * @param commit The commit for which to retrieve the data. Latest if not given.
	 */
	public getExtendedResourceTypes(
		project: string,
		uniformPath: string,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<string[]> {
		const urlBuilder = url`api/projects/${project}/ext-resource-type/${uniformPath}`;
		urlBuilder.append('t', commit);
		return this.get<string[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * @param project The project of the uniform path.
	 * @param uniformPath The uniform path to look up.
	 * @param timestamp An optional timestamp.
	 * @param children If this is true, the callback will be called with a map from the name of the children of the
	 *   given uniform path to their type. The type of a container child will be the type of its
	 *   deepestRelativePathWithMoreThanOneChild. If the parameter is false, returns the type of the given uniform
	 *   path.
	 * @param checkDefaultBranch Determines if the default branch is also checked for the resource if it was not found
	 *   on the current branch
	 * @returns The type of the given uniform path.
	 */
	public getResourceType(
		project: string,
		uniformPath: string,
		timestamp: number | string | UnresolvedCommitDescriptor | null,
		children: true,
		checkDefaultBranch?: boolean
	): Promise<Record<string, string>>;
	public getResourceType(
		project: string,
		uniformPath: string,
		timestamp: number | string | UnresolvedCommitDescriptor | null,
		children?: false | null,
		checkDefaultBranch?: boolean
	): Promise<string | null>;
	public getResourceType(
		project: string,
		uniformPath: string,
		timestamp?: number | string | UnresolvedCommitDescriptor | null,
		children?: boolean | null,
		checkDefaultBranch = true
	): Promise<string | Record<string, string> | null> {
		let urlBuilder = url`api/projects/${project}/resource-type`;
		if (children) {
			urlBuilder = url`api/projects/${project}/resource-type/children`;
		}
		urlBuilder.append('t', timestamp);
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('check-default-branch', checkDefaultBranch);
		return this.get<string | null>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the language of the given element (or null).
	 *
	 * @returns The language (string value of ELanguage)
	 */
	public getElementLanguage(
		project: string,
		uniformPath: string,
		timestamp?: number | string | UnresolvedCommitDescriptor | null
	): Promise<string | null> {
		const urlBuilder = url`api/projects/${project}/elements/${uniformPath}/language`;
		urlBuilder.append('t', timestamp);
		return this.get<string>(urlBuilder)
			.catch<string | null>(TeamscaleServiceClient.handle404AsNull)
			.catch(this.getDefaultErrorHandler());
	}

	/** Adds error handling to convert 404 not found responses to a null value. */
	private static handle404AsNull<T>(error: unknown): Promise<T | null> {
		if (error instanceof ServiceCallError && error.statusCode === HttpStatus.NOT_FOUND) {
			return Promise.resolve(null);
		}
		return Promise.reject(error);
	}

	/**
	 * Lists the identifiers of available architecture assessments.
	 *
	 * @param project
	 * @param endCommit An optional timestamp for time-traveling
	 * @returns Called with Java type List<ArchitectureOverviewInfo>
	 */
	public listArchitectureAssessmentIdentifiers(
		project: string,
		endCommit?: number | string | UnresolvedCommitDescriptor | null
	): Promise<ArchitectureOverviewInfo[]> {
		const urlBuilder = url`api/projects/${project}/architectures/assessments`;
		urlBuilder.append('t', endCommit);
		return this.get(urlBuilder);
	}

	/**
	 * Returns any pending architecture uploads as a map from path to EArchitectureUploadType.
	 *
	 * @param project
	 * @param commit An optional timestamp for time-traveling
	 */
	public getArchitectureUploadProgress(
		project: string,
		commit?: number | string | UnresolvedCommitDescriptor | null
	): Promise<Record<string, keyof typeof EArchitectureUploadType>> {
		const urlBuilder = url`api/projects/${project}/architecture-analysis-progress`;
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/**
	 * Returns the query that handles the sorting of results for log entries.
	 *
	 * @param logFilteringParameters The parameters used to filter the log entries
	 * @param minLogLevel If this is not null, only log messages of at least the provided value are returned.
	 * @returns Url builder with the created url
	 */
	public getLogQuery(
		urlBuilder: URLBuilder,
		logFilteringParameters: LogFilteringParameters,
		minLogLevel?: string | null
	): URLBuilder {
		if (minLogLevel != null) {
			if (strings.caseInsensitiveEquals(minLogLevel, 'warn')) {
				urlBuilder.appendToPath('warnings');
			} else if (strings.caseInsensitiveEquals(minLogLevel, 'error')) {
				urlBuilder.appendToPath('errors');
			}
		}
		urlBuilder.append('maxResults', logFilteringParameters.maxResults);
		urlBuilder.append('startTimestamp', logFilteringParameters.startTimestamp);
		urlBuilder.append('endTimestamp', logFilteringParameters.endTimestamp);
		urlBuilder.append('filterRegex', logFilteringParameters.filterRegex);
		urlBuilder.append('includeMatches', logFilteringParameters.includeMatches);
		urlBuilder.append('searchDetailedLogs', logFilteringParameters.searchDetailedLogs);
		urlBuilder.append('collapseRepeatedEntries', logFilteringParameters.collapseRepeatedEntries);
		return urlBuilder;
	}

	/**
	 * Finds the commits that belong to a certain revision. Calls the RepositoryTimestampByRevisionService
	 *
	 * @param project The project.
	 * @param revision The revision.
	 */
	public getCommitsForRevision(project: string, revision: string): Promise<DataCommitDescriptor[]> {
		return this.get<DataCommitDescriptor[]>(url`api/projects/${project}/revision/${revision}/commits`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Enables or disables Voting or Detailed Line Commenting for all connectors of the given project.
	 *
	 * @param projectId The project id.
	 * @param votingOption The kind of voting option (Voting or Line Commenting).
	 * @param enable Whether to enable (<code>true</code>) or disable (<code>false</code>) the option.
	 */
	public enableVotingOption(
		projectId: string,
		votingOption: typeof VOTING_OPTION_KIND[VotingOptionType],
		enable: boolean
	): Promise<void> {
		const urlBuilder = url`api/projects/${projectId}/connectors/voting-options/${votingOption.id}`;
		urlBuilder.append('enable', enable);
		return this.put<void>(urlBuilder);
	}

	/** Looks up all user names. */
	public listUsers(): Promise<User[]> {
		return this.get<User[]>(url`api/users`).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Looks up all available authentication methods + the possibility of changing passwords for each.
	 *
	 * @param username The username for which to query the data
	 */
	public getAuthenticators(username: string): Promise<AuthenticatorMappingReply> {
		const urlBuilder = url`api/authentication/${username}`;
		return this.get<AuthenticatorMappingReply>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Looks up if the user authentication allows password changes.
	 *
	 * @param username The username for which to query the data
	 */
	public canChangePassword(username: string): Promise<boolean> {
		return this.get<boolean>(url`api/authentication/passwords/${username}`).catch(this.getDefaultErrorHandler());
	}

	/** Creates a new user or overwrites an existing one. */
	public updatePassword(passwordChangeRequest: PasswordChangeRequest): Promise<void> {
		return this.put(url`api/authentication/${passwordChangeRequest.username}`, passwordChangeRequest);
	}

	/**
	 * Looks up information about a specific user.
	 *
	 * @param userName The name of the user for which details should be fetched.
	 * @param handleErrorsWithPromise If true and the service call fails, the reject method of the promise is called
	 *   with the response data. When set to <code>true</code>, attach an error handler using '.catch(serviceError =>
	 *   ...)' directly to the method call. The error object passed to catch will be of type {@link ServiceCallError}. If
	 *   this is set to false and the service call fails, a red error page will be shown.
	 * @returns The user information. Will be <code>null</code> if the user was not found or is not accessible by the
	 *   current user.
	 */
	public getUserDetails(userName: string, handleErrorsWithPromise?: boolean): Promise<User | null> {
		const promise = this.get<User | null>(url`api/users/${userName}`);
		if (handleErrorsWithPromise) {
			return promise;
		} else {
			return promise.catch(this.getDefaultErrorHandler());
		}
	}

	/** Looks up information about all users visible to the currently logged-in user. */
	public getDetailsForAllVisibleUsers(): Promise<User[]> {
		return this.listUsers();
	}

	/**
	 * Looks up information about a specific user's avatar.
	 *
	 * @param userName The name of the user for whom avatar data should be fetched.
	 */
	public getAvatarData(userName: string): Promise<AvatarData> {
		return this.get<AvatarData>(url`api/avatars/${userName}`).catch(this.getDefaultErrorHandler());
	}

	/** Creates a new user or overwrites an existing one. */
	public updateUser(userData: UserData): Promise<void> {
		return this.put<void>(url`api/users/${userData.username}`, userData).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Changes the crop dimensions of an avatar.
	 *
	 * @param username
	 * @param avatarOffsetX The offset of the avatar from the left in pixels.
	 * @param avatarOffsetY The offset of the avatar from the top in pixels.
	 * @param avatarSize The size of the avatar in pixels.
	 * @returns String "success" if creation succeeded.
	 */
	public changeAvatarCropDimensions(
		username: string,
		avatarOffsetX: number,
		avatarOffsetY: number,
		avatarSize: number
	): Promise<string> {
		const urlBuilder = url`api/avatars/${username}`;
		urlBuilder.append('avatarOffsetX', avatarOffsetX);
		urlBuilder.append('avatarOffsetY', avatarOffsetY);
		urlBuilder.append('avatarSize', avatarSize);
		return this.put<string>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Deletes a user. */
	public deleteUser(username: string): Promise<string | string[]> {
		return this.delete<string>(url`api/users/${username}`).catch(this.getDefaultErrorHandler());
	}

	/** Deletes the list of given users. */
	public deleteUsers(usernames: string[]): Promise<string> {
		const userBatchOperation: UserBatchOperation = {
			usersToDelete: usernames
		};
		return this.post(url`api/users`, userBatchOperation);
	}

	/** Fetches all set server options. */
	public getServerOptions(): Promise<Options> {
		return this.get<Options>(url`api/options/server`).catch(this.getDefaultErrorHandler());
	}

	/** Fetches current default external storage backend option. */
	public getDefaultExternalStorageBackendOption(): Promise<ExternalStorageBackendOption> {
		return this.get<ExternalStorageBackendOption>(url`api/options/server/default-external-storage-backend`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Sets current default external storage backend option. */
	public setDefaultExternalStorageBackendOption(newValue: ExternalStorageBackendOption): Promise<void> {
		return this.setServerOption('default-external-storage-backend', newValue);
	}

	/** Fetches globally enforce default storage option. */
	public getGloballyEnforceDefaultStorageOption(): Promise<GloballyEnforceDefaultStorageOption> {
		return this.get<GloballyEnforceDefaultStorageOption>(
			url`api/options/server/enforce-global-default-storage`
		).catch(this.getDefaultErrorHandler());
	}

	/** Sets globally enforce default storage option. */
	public setGloballyEnforceDefaultStorageOption(newValue: GloballyEnforceDefaultStorageOption): Promise<void> {
		return this.setServerOption('enforce-global-default-storage', newValue);
	}

	/** Fetches and returns the blacklisting option. */
	public getBlacklistingOption(): Promise<BlacklistingOption> {
		return this.get<BlacklistingOption>(url`api/options/server/ts.blacklisting-option`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Fetches the server option schema. */
	public getServerOptionSchema(): Promise<OptionDescriptor[]> {
		return this.get<OptionDescriptor[]>(url`api/options/server/schema`).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Sets a specific server option.
	 *
	 * @param saveIfValidationFails Whether settings should be saved even if validation fails, defaults to false.
	 */
	public setServerOption(
		optionName: string,
		optionValue: OptionValue,
		saveIfValidationFails?: boolean
	): Promise<void> {
		const urlBuilder = url`api/options/server/${optionName}`;
		urlBuilder.append('save-if-validation-fails', saveIfValidationFails);
		return this.put(urlBuilder, optionValue);
	}

	/** Deletes a specific server option. */
	public deleteServerOption(optionName: string): Promise<void> {
		const urlBuilder = url`api/options/server/${optionName}`;
		return this.delete(urlBuilder);
	}

	/** Fetches all set user options for the specified user. */
	public getUserOptions(username: string): Promise<UserOptions> {
		return this.get<UserOptions>(url`api/users/${username}/options`).catch(this.getDefaultErrorHandler());
	}

	/** Fetches the user option schema. */
	public getUserOptionSchemaAsync(): Promise<OptionDescriptor[]> {
		return this.get(url`api/users/options/schema`);
	}

	/** Sets a specific user option. */
	public setUserOptionAsync(
		username: string,
		optionName: string,
		optionValue: UserOptions[keyof UserOptions]
	): Promise<void> {
		const urlBuilder = url`api/users/${username}/options/${optionName}`;
		return this.put(urlBuilder, optionValue);
	}

	/** Sets a specific user option for the current user, which is retrieved from the perspective context. */
	public async setCurrentUserOptionAsync<T extends keyof UserOptions>(
		perspectiveContext: PerspectiveContext,
		optionName: T,
		optionValue: UserOptions[T]
	): Promise<void> {
		await this.setUserOptionAsync(perspectiveContext.userInfo.currentUser.username, optionName, optionValue);
		const cachedPerspectiveContext = ReactUtils.queryClient.getQueryData<PerspectiveContext>([
			'perspective-context'
		])!;
		cachedPerspectiveContext.userInfo.userOptions[optionName] = optionValue;
		ReactUtils.queryClient.setQueryData(['perspective-context'], cachedPerspectiveContext);
	}

	/** Retrieves information about Teamscale and the system it is running on. */
	public getSystemInfo(): Promise<SystemProcessInfo[]> {
		return this.get<SystemProcessInfo[]>(url`api/system-info`).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Retrieves the license information. IMPORTANT: This may return null if no valid license is found.
	 *
	 * @param reload Whether to reload the license file
	 */
	public getLicenseInfo(reload: boolean): Promise<LicenseInfo> {
		const urlBuilder = url`api/license-info`;
		urlBuilder.append('reload', reload);
		return this.get<LicenseInfo>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Retrieves all user groups registered with Teamscale. */
	public getUserGroups(): Promise<UserGroup[]> {
		return this.get(url`api/user-groups`);
	}

	/** Retrieves all names of user groups registered with Teamscale. */
	public getUserGroupNames(): Promise<string[]> {
		return this.get<string[]>(url`api/user-groups/names`).catch(this.getDefaultErrorHandler());
	}

	/** Retrieves a single user group registered with Teamscale. */
	public getUserGroup(groupName: string): Promise<UserGroup> {
		return this.get<UserGroup>(url`api/user-groups/${groupName}`).catch(this.getDefaultErrorHandler());
	}

	/** Creates a user group on the server. */
	public createUserGroup(userGroup: UserGroup): Promise<void> {
		return this.post(url`api/user-groups`, userGroup);
	}

	/**
	 * Updates a user group on the server.
	 *
	 * @param userGroup New value for the user group.
	 * @param previousGroupName Name of the user group before the update.
	 */
	public updateUserGroup(userGroup: UserGroup, previousGroupName: string): Promise<void> {
		return this.put(url`api/user-groups/${previousGroupName}`, userGroup);
	}

	/** Deletes the group with the given name. The membership of all users is automatically adjusted. */
	public deleteUserGroup(groupName: string): Promise<void> {
		return this.delete(url`api/user-groups/${groupName}`);
	}

	/** Returns the global analysis progress as a mapping from project IDs to their analysis progress */
	public getGlobalAnalysisProgress(): Promise<Record<string, AnalysisProgressDescriptor[]>> {
		return this.get<Record<string, AnalysisProgressDescriptor[]>>(url`api/analysis-progress`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Returns the user activity history (i.e. number of active users over time). */
	public getUserActivityHistory(): Promise<UserActivity[]> {
		return this.get<UserActivity[]>(url`api/user-activity-history`).catch(this.getDefaultErrorHandler());
	}

	/** Returns the committer activity history (i.e. number of committers over time). */
	public getCommitterActivityHistory(): Promise<UserActivity[]> {
		return this.get<UserActivity[]>(url`api/committer-activity-history`).catch(this.getDefaultErrorHandler());
	}

	/** Returns the names of all committers of the last quarter. */
	public getCommittersLastQuarter(): Promise<CommitterDisplayName[]> {
		return this.get<CommitterDisplayName[]>(url`api/license/active-committer-names`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Returns the service load */
	public getServiceLoad(): Promise<LoadProfile[]> {
		return this.get<LoadProfile[]>(url`api/service-load`).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns a {@link BranchesInfo} for the given project. The startOffset and limit parameters can be used to achieve
	 * pagination (i.e., get only branches 0-100, 100-200, ...).
	 *
	 * @param project The project
	 * @param startOffset The index of the start of the pagination window (default is 0)
	 * @param limit The size of the pagination window (i.e., the max number of branches returned)
	 * @param onlyLiveBranches Whether to only return currently live branches (default is false)
	 */
	public getBranchesInfoForProject(
		project: string,
		startOffset = 0,
		limit?: number,
		onlyLiveBranches = false
	): Promise<BranchesInfo> {
		return QUERY.getBranchesGetRequest(project, {
			'start-offset': startOffset,
			limit,
			'only-live-branches': onlyLiveBranches
		})
			.fetch()
			.catch(this.getDefaultErrorHandler());
	}

	/** Returns the default branch name of the project. */
	public getDefaultBranchNameForProject(project: string): Promise<string> {
		return QUERY.getDefaultBranchNameGetRequest(project).fetch().catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the branches that are contained in the project as {@link BranchesInfo}.
	 *
	 * @param project The project
	 * @param branches The branches that should be checked. If this is an empty list all branches will be returned.
	 */
	public getFilteredBranchesInfoForProject(project: string, branches: Array<string | null>): Promise<BranchesInfo> {
		const params = { filter: branches.filter(branch => branch != null) as string[] };
		return QUERY.getBranchesGetRequest(project, params).fetch();
	}

	/** Notifies the server that the current user selected the given branch in the branch selector. */
	public async registerBranchSelection(projectId: string, branchName: string): Promise<void> {
		if (StringUtils.isEmptyOrWhitespace(projectId)) {
			return;
		}
		return this.post<void>(url`api/projects/${projectId}/recent-branches/${branchName}`).catch(() => {
			return;
		});
	}

	/**
	 * Reports the javascript error to the corresponding service.
	 *
	 * @param error The error message
	 */
	public reportJavaScriptError(error: string, project: string | null): Promise<void> {
		try {
			const parsedErrorMessage = JSON.parse(error);
			if (parsedErrorMessage['stack'] != null && parsedErrorMessage['message'] != null) {
				error = parsedErrorMessage['message']! + '\n\n' + parsedErrorMessage['stack']!;
			}
		} catch (error) {}

		// Error message is not a JSON object
		return this.post(url`api/logs/javascript-errors/report`, {
			url: window.location.href,
			errorMessage: error || 'No error message given',
			project,
			userAgent: navigator.userAgent
		} as JavaScriptError);
	}

	private static logsUrl(project: string | null, logsType: ELogType): URLBuilder {
		if (StringUtils.isEmptyOrWhitespace(project)) {
			return url`api/logs/${logsType}`;
		} else {
			return url`api/projects/${project}/logs/${logsType}`;
		}
	}

	/**
	 * Retrieves the frequencies of the corresponding log levels.
	 *
	 * @param id Of a log service
	 */
	public getFrequencies(logType: ELogType): Promise<ProjectLogLevelFrequencies[]> {
		return this.get<ProjectLogLevelFrequencies[]>(url`api/logs/${logType}/frequencies`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Retrieves the newest/oldest short log entries sorted by timestamp by the system caller descending.
	 *
	 * @param logServiceId Of a log service least the provided value are returned.
	 * @param logFilteringParameters The parameters used to filter the log entries
	 * @param minLogLevel If this is not null, only log messages of at least the provided value are returned.
	 * @param project If this is not null, only log messages for this project are returned
	 */
	public getShortLogFromService(
		logsType: ELogType,
		logFilteringParameters: LogFilteringParameters,
		minLogLevel: string | null,
		project: string | null
	): Promise<ShortLogResponse> {
		const urlBuilder = this.getLogQuery(
			TeamscaleServiceClient.logsUrl(project, logsType),
			logFilteringParameters,
			minLogLevel
		);
		return this.get<ShortLogResponse>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Retrieves the detailed log for a given timestamp for the corresponding system caller.
	 *
	 * @param id Of a log service
	 */
	public getDetailedLogFromService(
		timestamp: number,
		projectId: string | null,
		logType: ELogType
	): Promise<DetailedWorkerLog | null> {
		const urlBuilder = TeamscaleServiceClient.logsUrl(projectId, logType);
		urlBuilder.appendToPath('details', timestamp.toString());
		return this.get<DetailedWorkerLog | null>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Deletes the selected logs of the given event log type. */
	public deleteLogEntries(
		logFilteringParameters: LogFilteringParameters | null,
		logType: ELogType,
		project: string | null,
		minLogLevel: string | null
	): Promise<string> {
		let urlBuilder = TeamscaleServiceClient.logsUrl(project, logType);
		if (logFilteringParameters != null) {
			urlBuilder = this.getLogQuery(urlBuilder, logFilteringParameters);
		}
		urlBuilder.append('level', minLogLevel ?? 'info');
		return this.delete<string>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Retrieves the project's languages. */
	public getProjectLanguages(projectId: string): Promise<ELanguageEntry[]> {
		return this.get<ELanguageEntry[]>(url`api/projects/${projectId}/languages`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Gets the analysis branch of the provided spec item */
	public getSpecItemBranch(
		projectId: string,
		specItemId: string,
		commit?: UnresolvedCommitDescriptor
	): Promise<string> {
		const urlBuilder = url`api/projects/${projectId}/spec-items/${specItemId}/branch`;
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/** Gets the spec item by spec item ID. */
	public getSpecItem(
		projectId: string,
		specItemId: string,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<ImportedLinksAndTypeResolvedSpecItem> {
		const urlBuilder = url`api/projects/${projectId}/spec-items/${specItemId}`;
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/** Gets the available spec item query descriptors. */
	public getStoredSpecItemQueryDescriptors(projectId: string): Promise<StoredQueryDescriptor[]> {
		return this.get(url`api/projects/${projectId}/spec-items/queries`);
	}

	/** Creates/overrides a spec item query. */
	public createStoredSpecItemQuery(project: string, specItemQueryDescriptor: StoredQueryDescriptor): Promise<void> {
		return this.post(url`api/projects/${project}/spec-items/queries`, specItemQueryDescriptor);
	}

	/** Deletes the given spec item query. */
	public deleteSpecItemQuery(project: string, specItemQueryName: string): Promise<void> {
		return this.delete(url`api/projects/${project}/spec-items/queries/${specItemQueryName}`);
	}

	/** Gets the available test query descriptors. */
	public getTestQueryMetrics(projectId: string): Promise<StoredQueryDescriptor[]> {
		return this.get(url`api/projects/${projectId}/tests/queries`);
	}

	/** Creates/overrides a test query. */
	public createTestQueryMetric(project: string, testQueryDescriptor: StoredQueryDescriptor): Promise<void> {
		return this.post(url`api/projects/${project}/tests/queries`, testQueryDescriptor);
	}

	/** Deletes the given test query. */
	public deleteTestQueryMetric(project: string, testQueryName: string): Promise<void> {
		return this.delete(url`api/projects/${project}/tests/queries/${testQueryName}`);
	}

	/** Gets spec item details of the given spec item IDs. */
	public getSpecItemDetails(
		projectId: string,
		specItemIds: TeamscaleIssueId[]
	): Promise<Array<UserResolvedSpecItem | null>> {
		const urlBuilder = url`api/projects/${projectId}/spec-items/details`;
		urlBuilder.appendMultiple(
			'spec-item-ids',
			specItemIds.map(id => id.internalId)
		);
		return this.get(urlBuilder);
	}

	/** Deletes the given requirement metric. */
	public getCommitTreeNodes(project: string, repositoryId: string | null): Promise<CommitTreeNodeData[]> {
		const urlBuilder = url`api/projects/${project}/debug/commit-tree-nodes`;
		if (repositoryId) {
			urlBuilder.append('repository-id', repositoryId);
		}
		return this.get<CommitTreeNodeData[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Gets the spec item code references by spec item ID. */
	public getSpecItemCodeReferences(
		projectId: string,
		specItemId: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<SpecItemCodeReference[]> {
		const urlBuilder = url`api/projects/${projectId}/spec-items/${specItemId}/code-references`;
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/** Gets the spec item test executions by spec item ID. */
	public getSpecItemTestExecutions(
		projectId: string,
		specItemId: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<TestExecutionWithPartition[]> {
		const urlBuilder = url`api/projects/${projectId}/spec-items/${specItemId}/test-executions`;
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/** Fetches the work item link roles provided by the configured Polarion account. */
	public async fetchPolarionWorkItemLinkRoles(
		connector: ConnectorConfiguration
	): Promise<PolarionWorkItemLinkRolesResult> {
		const accountName = connector.options!['Account']!;
		const urlBuilder = url`api/spec-items/polarion/${accountName}/work-items/link-roles`;
		return this.put<PolarionWorkItemLinkRolesResult>(urlBuilder, connector);
	}

	/** Fetches the work item types provided by the configured Polarion account. */
	public async fetchPolarionWorkItemTypes(connector: ConnectorConfiguration): Promise<PolarionWorkItemTypeResult> {
		const accountName = connector.options!['Account']!;
		const urlBuilder = url`api/spec-items/polarion/${accountName}/work-items/types`;
		return this.put<PolarionWorkItemTypeResult>(urlBuilder, connector);
	}

	/** Fetches the work item types provided by the configured connector. */
	public async fetchWorkItemLinkRoles(connector: ConnectorConfiguration): Promise<GetLinkRolesResponse> {
		const accountName = connector.options!['Account']!;
		const urlBuilder = url`api/spec-items/default/${accountName}/schema/link-roles`;
		return this.put<GetLinkRolesResponse>(urlBuilder, connector);
	}

	/** Retrieves the spec item graph for the given query. */
	public getSpecItemQueryGraph(
		project: string,
		query: string,
		commit: UnresolvedCommitDescriptor
	): Promise<SpecItemGraph> {
		const urlBuilder = url`api/projects/${project}/spec-items/graph`;
		urlBuilder.append('query', query);
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/**
	 * Retrieves the list of signals for a Simulink type dependency.
	 *
	 * @param projectName The project's name
	 * @param dependencySource The dependency's source
	 * @param dependencyTarget The dependency's target
	 */
	public getSimulinkSignalsForDependency(
		projectName: string,
		dependencySource: string,
		dependencyTarget: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<string[]> {
		const urlBuilder = url`api/projects/${projectName}/simulink/dependencies/signals`;
		urlBuilder.append('source', dependencySource);
		urlBuilder.append('target', dependencyTarget);
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/**
	 * Resolves a commit descriptor. Useful if dealing with Commit descriptors that have the previous attribute set.
	 *
	 * @param projectName The project's name
	 * @param commit The commit to resolve
	 */
	public resolveCommitDescriptor(
		projectName: string,
		commit: UnresolvedCommitDescriptor
	): Promise<DataCommitDescriptor> {
		return this.get(url`api/projects/${projectName}/commit-resolver/${commit.toString()}`);
	}

	/** Notifies the server that the current user selected the given project in the project selector. */
	public async registerProjectSelection(projectId: string): Promise<void> {
		if (StringUtils.isEmptyOrWhitespace(projectId)) {
			return;
		}
		return this.post<void>(url`api/projects/${projectId}/visited`).catch(() => {
			return;
		});
	}

	/**
	 * Finds the initial commit which added the file at the given path. Requires a commit from the branch on which the
	 * service should look for the initial commit of the file.
	 */
	public findInitialCommitForPath(
		projectName: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor
	): Promise<DataCommitDescriptor | null> {
		return QUERY.findInitialCommit(projectName, commit, { uniformPath }).fetch();
	}

	/** Performs a query for spec items. */
	public getCodeReferencesForSpecItemQuery(
		project: string,
		query: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<SpecItemCodeMapping> {
		const urlBuilder = url`api/projects/${project}/spec-items/code-references`;
		urlBuilder.append('query', query);
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/**
	 * Appends the parameters for the principal metric index, metric indexes and boundaries to the URL builder.
	 *
	 * @param urlBuilder The urlBuilder to append to.
	 * @param principalMetricIndex The 'selected' metric index with respect to which the metric distribution is
	 *   calculated.
	 * @param metricIndexes The list of metric indexes to calculate the distribution.
	 * @param boundaries The list of boundaries from which consecutive intervals are calculated and used to calculate
	 *   the distribution.
	 */
	private static appendMetricDistributionParameters(
		urlBuilder: URLBuilder,
		principalMetricIndex: number,
		metricIndexes: number[],
		boundaries: number[]
	): void {
		urlBuilder.append('principal-metric-index', principalMetricIndex);
		urlBuilder.appendMultiple('metric-indexes', metricIndexes);
		urlBuilder.appendMultiple('boundaries', boundaries);
	}

	/** Returns a promise for all sap abap connection identifiers of the instance. */
	public getSapConnectionIdentifiers(): Promise<string[]> {
		return this.get<string[]>(url`api/sap-connection-identifiers`).catch(this.getDefaultErrorHandler());
	}

	/** Returns a promise for metadata for the given ABAP file. */
	public getAbapFileMetadata(
		projectId: string,
		filePath: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<AbapFileMetadata> {
		const urlBuilder = url`api/projects/${projectId}/abap-file-meta-data/${filePath}`;
		urlBuilder.append('t', commit);
		return this.get<AbapFileMetadata>(urlBuilder);
	}

	/** Returns a promise for a list of all primary project ids to which the user has access. */
	public getPrimaryProjectIds(): Promise<string[]> {
		return this.get<string[]>(url`api/projects/ids`).catch(this.getDefaultErrorHandler());
	}

	/** Returns a promise for a mapping from primary project ids to the internal and public ids. * */
	public getAllProjectIds(): Promise<Map<string, ProjectIdEntry>> {
		return this.get<Record<string, ProjectIdEntry>>(url`api/project-ids/`)
			.then(result => ObjectUtils.toMap(result))
			.catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the methods that are covered by a test with method wise test coverage data.
	 *
	 * @param firstCommit The first commit, commits before this are not considered
	 * @param lastCommit The last commit to be considered
	 * @param partitions Optional, the partitions to be used
	 */
	public getMethodChangelogForTest(
		project: string,
		testExecutionPath: string,
		firstCommit: UnresolvedCommitDescriptor,
		lastCommit: UnresolvedCommitDescriptor,
		partitions?: string[]
	): Promise<MethodHistoryEntriesWrapper> {
		const urlBuilder = url`api/projects/${project}/tests/${testExecutionPath}/executed-methods-changelog`;
		urlBuilder.append('baseline', firstCommit);
		urlBuilder.append('end', lastCommit);
		partitions?.forEach(partition => urlBuilder.append('partitions', partition));
		return this.get<MethodHistoryEntriesWrapper>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Query the status of the given test minimization job.
	 *
	 * @param projectId The id of the project the job is supposed to be executed for.
	 * @param jobId The id of the job.
	 * @returns The current execution status of the job.
	 */
	public getTestMinimizationJobStatus(projectId: string, jobId: string): Promise<TestMinimizationJobRun | null> {
		const urlBuilder = url`api/projects/${projectId}/minimized-tests/jobs/${jobId}`;
		return this.get<TestMinimizationJobRun>(urlBuilder).catch<TestMinimizationJobRun | null>(
			TeamscaleServiceClient.handle404AsNull
		);
	}

	/**
	 * Stop the given minimization job on the server.
	 *
	 * @param projectId The id of the project the job is to be executed for.
	 * @param jobId The id of the job to stop.
	 */
	public stopAndDeleteMinimizationJob(projectId: string, jobId: string): Promise<void> {
		const urlBuilder = url`api/projects/${projectId}/minimized-tests/jobs/${jobId}`;
		return this.delete<void>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Query the options a given test minimization job was submitted with.
	 *
	 * @param projectId The id of the project the job is supposed to be executed for.
	 * @param jobId The id of the job.
	 * @returns The options the job was started with, for example, query and partitions.
	 */
	public getTestMinimizationJobOptions(projectId: string, jobId: string): Promise<TestMinimizationRequestOptions> {
		const urlBuilder = url`api/projects/${projectId}/minimized-tests/jobs/${jobId}/options`;
		return this.get<TestMinimizationRequestOptions>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Query the results computed for the given test minimization job.
	 *
	 * @param projectId The id of the project the job is supposed to be executed for.
	 * @param jobId The id of the job.
	 * @returns A list of test clusters ranked based on the jobs options.
	 */
	public getTestMinimizationJobResults(projectId: string, jobId: string): Promise<PrioritizableTestCluster[]> {
		const urlBuilder = url`api/projects/${projectId}/minimized-tests/results/${jobId}`;
		return this.get<PrioritizableTestCluster[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Query the methods covered by the result of the given test minimization job.
	 *
	 * @param projectId The id of the project the job is supposed to be executed for.
	 * @param jobId The id of the job.
	 * @returns A list of lists of additionally covered methods (element on position i: additional coverage after
	 *   executing tests 0 to i-1).
	 */
	public getTestMinimizationJobAdditionallyCovered(projectId: string, jobId: string): Promise<string[][]> {
		const urlBuilder = url`api/projects/${projectId}/minimized-tests/results/${jobId}/covered`;
		return this.get<string[][]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Start a test minimization computation asynchronously.
	 *
	 * @param projectId The project to run the job for.
	 * @param commit The commit with the data to optimize.
	 * @param partitions The test partitions to consider.
	 * @param query The query selecting test set of tests to optimize.
	 * @param maxExecTime The max. time budget.
	 * @returns The ID of the minimization job that has been started.
	 */
	public startTestMinimizationJob(
		projectId: string,
		commit: UnresolvedCommitDescriptor,
		partitions: string[],
		query: string,
		tests: string[],
		maxExecTime?: number
	): Promise<string> {
		const urlBuilder = url`api/projects/${projectId}/minimized-tests/jobs/`;
		urlBuilder.appendMultiple('partitions', partitions);
		urlBuilder.append('ensure-processed', false);
		urlBuilder.append('end', commit);
		urlBuilder.append('clustering-regex', '.*');
		urlBuilder.append('max-exec-time', maxExecTime);
		let payload: string[] = [];
		if (tests.length > 0) {
			payload = tests;
		} else {
			urlBuilder.append('query', IssueQueryInputHandler.escapeIssueQuery(query));
		}
		return this.post<string>(urlBuilder, payload).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the URL to download a CSV file containing all methods executed by one or multiple test executions
	 * specified by a test query.
	 *
	 * @param project The project.
	 * @param query The query to match the test executions
	 * @param partitions The partitions that should be taken into account, or undefined to select all partitions
	 * @param endCommit The commit until which test executions should be respected.
	 */
	public getExecutedMethodsByTestCaseCsvUrl(
		project: string,
		query: string,
		partitions?: string[],
		endCommit?: UnresolvedCommitDescriptor | null
	): string {
		const urlBuilder = url`api/projects/${project}/test-query/executed-methods.csv`;
		urlBuilder.append('query', IssueQueryInputHandler.escapeIssueQuery(query));
		urlBuilder.appendMultiple('partition', partitions);
		urlBuilder.append('all-partitions', partitions === undefined);
		urlBuilder.append('t', endCommit);
		return urlBuilder.getURL();
	}

	/**
	 * Get an URL that redirects to the snapshot example.
	 *
	 * @param comparisonId The id of the instance comparison computation
	 * @param exampleType Type of the example (e.g. finding, worker log)
	 * @param exampleId Id of the example (format depends on the example type)
	 * @param remoteExample Whether the example is on the remote instance or on the current instance
	 * @param project The project of the example. May be null for examples without a project (e.g. Maintenance worker
	 *   logs)
	 */
	public getSnapshotExampleRedirectionUrl(
		comparisonId: string,
		exampleType: string,
		exampleId: string,
		remoteExample: boolean,
		project?: string
	): string {
		return url`api/instance-comparison/redirect`
			.append('comparison-id', comparisonId)
			.append('example-type', exampleType)
			.append('example-id', exampleId)
			.append('project', project)
			.append('is-remote-example', remoteExample)
			.getURL();
	}

	/** Returns true if the SAP JCo library has been loaded, otherwise false. */
	public sapJcoLibraryLoaded(): Promise<boolean> {
		return this.get<boolean>(url`api/sap-jco-loaded`).catch(this.getDefaultErrorHandler());
	}

	/** Fetches all event announcements stored in the backend. */
	public async getEventAnnouncements(): Promise<ExtendedEventAnnouncement[]> {
		return this.get(url`api/event-announcement/events`);
	}

	/** Saves the given event announcement. */
	public async storeEventAnnouncement(event: ExtendedEventAnnouncement): Promise<void> {
		return this.put(url`api/event-announcement/events/${event.eventId}`, event);
	}

	/** Fetches an event ID that is guaranteed to be unused so far. */
	public async getUnusedEventId(): Promise<string> {
		return this.get(url`api/event-announcement/unused-id`);
	}

	/** Hides the event announcement with the given ID for the current user. */
	public hideEventAnnouncement(eventId: string): Promise<string> {
		return this.post<string>(url`api/event-announcement/hide`, eventId);
	}

	/** Deletes the event announcement with the given ID for every user. */
	public deleteEventAnnouncement(eventId: string): Promise<void> {
		return this.delete(url`api/event-announcement/delete/${eventId}`);
	}

	/** Triggers the download of the issue list. */
	public downloadIssuesList(
		format: EIssuesExportFormatEntry,
		projectId: string,
		query: string,
		tgaFilter: EIssueTgaFilterOptionEntry,
		coverageSourceParameters: CoverageSourceQueryParameters,
		issueTgaParameters: IssueTgaParameters
	): void {
		const urlBuilder = url`api/projects/${projectId}/issue-query/with-tga/export/${format}`;
		urlBuilder.append('query', query);
		urlBuilder.append('tga-filter', tgaFilter);
		urlBuilder.append('sort-by', 'id');
		urlBuilder.append('sort-order', SortingUtils.ASCENDING_ORDER);
		urlBuilder.append('start', 0);
		urlBuilder.append('max', -1);
		urlBuilder.appendAll(ServiceClient.convertToKebabCaseURLSearchParams(coverageSourceParameters));
		urlBuilder.appendAll(ServiceClient.convertToKebabCaseURLSearchParams(issueTgaParameters));
		UIUtils.downloadFile(urlBuilder.getURL(), 'issues-' + projectId + '.' + format.toLowerCase());
	}

	/** Returns the input for the Teamscale Upload configuration wizard. */
	public getTSUploadConfigurationWizardInput(project: string): Promise<TeamscaleUploadWizardInput> {
		return this.get<TeamscaleUploadWizardInput>(url`api/projects/${project}/ts-upload-wizard`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Retrieve a teamscale-upload invocation for the given configuration.
	 *
	 * @param project The project for which to upload external reports.
	 * @param user The user under which to perform the uploads.
	 * @param partition The partition to upload to.
	 * @param format The format of the external report.
	 */
	public async getTSUploadInvocation(
		project: string,
		user: string,
		partition: string,
		format: string
	): Promise<string> {
		const urlBuilder = url`api/projects/${project}/ts-upload-wizard/ts-upload-invocation`;
		urlBuilder.append('user', user);
		urlBuilder.append('partition', partition);
		urlBuilder.append('format', format);
		return this.get<string>(urlBuilder);
	}

	/** Loads the Swagger API JSON */
	public async getApiSpecification(includeInternalApi: boolean): Promise<Blob> {
		const urlBuilder = url`openapi.json`;
		urlBuilder.append('include-internal', includeInternalApi);
		return this.get<Blob>(urlBuilder);
	}

	/** Retrieves a map to resolve links to CCPs via template strings. */
	public async getPlatformLinks(project: string): Promise<Map<string, ProjectPlatformLinks>> {
		return this.get<Record<string, ProjectPlatformLinks>>(url`api/projects/${project}/linktemplates`).then(result =>
			ObjectUtils.toMap(result)
		);
	}

	/** Returns the widget context, which includes the ids of all user-accessible projects. */
	public async getWidgetContext(): Promise<WidgetContext> {
		return this.get<WidgetContext>(url`api/dashboards/widget-context`);
	}

	/** Stores a manually created pareto list */
	public async storeParetoList(project: string, paretoListDescriptor: ParetoListDescriptor): Promise<void> {
		return this.post(url`api/projects/${project}/pareto-lists`, paretoListDescriptor);
	}

	/** Loads all manually created pareto lists */
	public async loadParetoListsNames(project: string): Promise<string[]> {
		return this.get<string[]>(url`api/projects/${project}/pareto-lists/names`);
	}

	/** Loads all manually created pareto lists */
	public async loadParetoListsEntries(
		project: string,
		paretoListName: string,
		commit?: UnresolvedCommitDescriptor
	): Promise<TestHistoryEntry[]> {
		const urlBuilder = url`api/projects/${project}/pareto-lists/${paretoListName}/test-history`;
		if (commit != null) {
			urlBuilder.append('t', commit);
		}
		return this.get<TestHistoryEntry[]>(urlBuilder);
	}

	/** Deletes a manually created pareto list */
	public async deleteParetoList(project: string, paretoListName: string): Promise<void> {
		return this.delete(url`api/projects/${project}/pareto-lists/${paretoListName}`);
	}

	/** Returns all retrospectives visible to the user. */
	public async getQualityRetrospectives(project: string): Promise<UserResolvedRetrospective[]> {
		return this.get<UserResolvedRetrospective[]>(url`api/projects/${project}/retrospectives`);
	}

	/** Returns all retrospectives visible to the user. */
	public async getQualityRetrospective(project: string, retrospectiveId: string): Promise<UserResolvedRetrospective> {
		return this.get<UserResolvedRetrospective>(url`api/projects/${project}/retrospectives/${retrospectiveId}`);
	}

	/** Stores the given retrospective on the server. */
	public async saveQualityRetrospective(project: string, retrospective: Retrospective): Promise<string> {
		return this.post(url`api/projects/${project}/retrospectives`, retrospective);
	}

	/**
	 * Provides a 'Save as' functionality for quality retrospectives. Can either override an existing report with the
	 * given new configuration, or create a copy with the given id on the server using the given new retrospective
	 * profile and meta info.
	 *
	 * The current user needs to have VIEW rights on the original retrospective, as well as the default project of the
	 * new one.
	 *
	 * @param project The project of the old and new retrospective
	 * @param retrospective The new quality report
	 * @param idOfExistingRetrospective The id of the existing report to be used as a template or to be overridden
	 * @param isEditingExistingRetrospective Specifies whether to create a new retrospective or override the existing
	 *   one.
	 * @returns The id of the newly created retrospective
	 */
	public async saveQualityRetrospectiveAs(
		project: string,
		retrospective: Retrospective,
		idOfExistingRetrospective: string,
		isEditingExistingRetrospective: boolean
	): Promise<string> {
		const urlBuilder = url`api/projects/${project}/retrospectives`;
		urlBuilder.append('from-template', idOfExistingRetrospective);
		urlBuilder.append('overwrite', isEditingExistingRetrospective);
		return this.post(urlBuilder, retrospective);
	}

	/** Updates the notes of the given retrospective. */
	public async updateRetrospectiveNotes(
		project: string,
		retrospectiveId: string,
		updatedNotes: string
	): Promise<UserResolvedRetrospective> {
		return this.put<UserResolvedRetrospective>(
			url`api/projects/${project}/retrospectives/${retrospectiveId}/notes`,
			updatedNotes
		);
	}

	/** Deletes the given retrospective. */
	public async deleteRetrospective(project: string, retrospectiveId: string) {
		return this.delete(url`api/projects/${project}/retrospectives/${retrospectiveId}`);
	}

	/** Cancels the provided task. */
	public async cancelTask(
		project: string,
		workerId: string,
		taskName: string,
		commit?: CommitDescriptor,
		interrupt = false
	): Promise<void> {
		const body: CancelTriggerRequestBody = {
			project,
			commit,
			taskName,
			interrupt
		};
		return this.put(url`api/execution-status/workers/${workerId}/cancel`, body);
	}
}
