import { HttpStatus } from 'api/HttpStatus';
import type { ServiceCallError } from 'api/ServiceCallError';
import { Button, Icon, Message } from 'semantic-ui-react';
import { Zippy } from 'ts/base/components/Zippy';
import { useUserPermissionInfo } from 'ts/base/hooks/PermissionInfoHook';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { TeamscaleSupportDialog } from 'ts/base/scaffolding/TeamscaleSupportDialog';
import { EGlobalPermission } from 'typedefs/EGlobalPermission';
import styles from './ServiceErrorComponent.module.less';

/** The props for ServiceErrorComponent. */
type ErrorProps = {
	error: ServiceCallError;
	resetErrorBoundary: () => void;
};

/**
 * Renders an error to the requested page. It may be possible that more than 1 error could be encountered as a page
 * loads. Therefore, this method considers this scenario too. Though there may be UI elements on the side bar, none are
 * displayed on the main container except error messages.
 *
 * In case a '401 Unauthorized' error happens, a reload of the current page is triggered. This will show the login
 * screen and redirect back to the current page after login.
 */
export function ServiceErrorComponent({ error, resetErrorBoundary }: ErrorProps): JSX.Element | null {
	if (error.statusCode === HttpStatus.UNAUTHORIZED) {
		const perspective = window.location.pathname.split('/').pop() || '';
		window.location.replace(
			'login.html?target=' + encodeURIComponent(perspective + window.location.search + window.location.hash)
		);
		return null;
	}
	if (error.statusCode === HttpStatus.NOT_FOUND) {
		return (
			<BaseErrorTemplate
				message="the page you requested does not exist."
				resetErrorBoundary={resetErrorBoundary}
			/>
		);
	} else if (error.statusCode === HttpStatus.FORBIDDEN) {
		return (
			<BaseErrorTemplate
				message={`you are missing permissions for the requested operation: ${error.message}`}
				resetErrorBoundary={resetErrorBoundary}
			/>
		);
	} else {
		return <RegularServiceError error={error} resetErrorBoundary={resetErrorBoundary} />;
	}
}

/**
 * A component for rendering server-side errors on the UI after with toggling functionality for displaying more details
 * of the error may be prepared. A short summary of it is first shown. This is helpful to avoid 'flooding' the user with
 * technical details. Provision is made on UI for viewing the technical description.
 */
function RegularServiceError({ error, resetErrorBoundary }: ErrorProps): JSX.Element {
	return (
		<div>
			<Message error className={styles.serviceCallError}>
				<Message.Header>&#9785; {error.errorSummary}</Message.Header>
				<TryAgainButton resetErrorBoundary={resetErrorBoundary} />
				<Zippy header="Details">
					The service call failed with an error.
					<br />
					Error code {error.statusCode}: {error.message}
					<br />
					<br />
					<SupportDialogLink />
					<h4 style={{ marginTop: '20px' }}>Technical Description</h4>
					<pre style={{ marginTop: '5px' }}>{error.technicalDetails}</pre>
				</Zippy>
			</Message>
		</div>
	);
}

/** The props for BaseErrorTemplate. */
type BaseErrorTemplateProps = {
	message: string;
	resetErrorBoundary: () => void;
};

function TryAgainButton({ resetErrorBoundary }: { resetErrorBoundary: () => void }) {
	return (
		<Button size="small" icon={<Icon name="redo alternate" />} content="Try again" onClick={resetErrorBoundary} />
	);
}

/** Shows a basic error message prefixed with Sorry, ... with a link to create a support request. */
export function BaseErrorTemplate({ message, resetErrorBoundary }: BaseErrorTemplateProps): JSX.Element {
	return (
		<div>
			<Message error className={styles.baseError}>
				<Message.Header>Sorry, {message}</Message.Header>
				<SupportDialogLink />
				<TryAgainButton resetErrorBoundary={resetErrorBoundary} />
			</Message>
		</div>
	);
}

/** Shows a link to create a support request. Shows nothing when the user does not have the necessary permissions. */
function SupportDialogLink(): JSX.Element | null {
	const permissionInfo = useUserPermissionInfo();
	const client = useTeamscaleServiceClient();
	if (!permissionInfo.hasGlobalPermission(EGlobalPermission.CREATE_SUPPORT_REQUEST)) {
		return null;
	}
	return (
		<h4>
			<a
				className={styles.supportDialogLauncher}
				onClick={(event): void => {
					event.preventDefault();
					new TeamscaleSupportDialog(client).show();
				}}
			>
				Submit a Support Request.
			</a>
		</h4>
	);
}
