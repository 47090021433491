import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';

/**
 * Name of the custom event which we use to inform other useLocalStorage hooks on the same page about changes to the
 * local storage.
 */
const CUSTOM_LOCAL_STORAGE_EVENT_NAME = 'local-storage';

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface WindowEventMap {
		[CUSTOM_LOCAL_STORAGE_EVENT_NAME]: StorageEvent;
	}
}

/**
 * Sync state to local storage so that it persists through a page refresh. Usage is similar to useState except we pass
 * in a local storage key so that we can default to that value on page load instead of the specified initial value.
 *
 * Source: https://usehooks.com/useLocalStorage/
 *
 * @param key The key under which the value is stored in the local storage
 * @param initialValue The value to use as fallback when the local storage does not contain the value
 * @param forcedInitialValue If given the value in the local storage will be ignored in favor of forcedInitialValue.
 *   State updates will still be persisted into the local storage.
 */
export function useLocalStorage<T>(
	key: string,
	initialValue: T,
	forcedInitialValue?: T
): [T, Dispatch<SetStateAction<T>>] {
	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState(() => {
		if (forcedInitialValue) {
			return forcedInitialValue;
		}
		try {
			const item = window.localStorage.getItem(key);
			// Parse stored json or if none return initialValue
			if (item) {
				return JSON.parse(item);
			} else {
				return initialValue;
			}
		} catch (error) {
			console.error(error);
			return initialValue;
		}
	});

	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = useCallback(
		(value: SetStateAction<T>): void => {
			try {
				let valueToStore: T;
				// Allow value to be a function so we have same API as useState
				if (value instanceof Function) {
					valueToStore = value(storedValue);
				} else {
					valueToStore = value;
				}
				const newValue = JSON.stringify(valueToStore);
				window.localStorage.setItem(key, newValue);
				setStoredValue(valueToStore);

				// We dispatch a custom event so every useLocalStorage hook are notified
				window.dispatchEvent(new StorageEvent(CUSTOM_LOCAL_STORAGE_EVENT_NAME, { key, newValue }));
			} catch (error) {
				console.error(error);
			}
		},
		[key, storedValue]
	);

	useEffect(() => {
		const handleStorageChange = (event: StorageEvent) => {
			if (event.key === key) {
				setStoredValue(JSON.parse(event.newValue!));
			}
		};
		window.addEventListener(CUSTOM_LOCAL_STORAGE_EVENT_NAME, handleStorageChange);
		return () => {
			window.removeEventListener(CUSTOM_LOCAL_STORAGE_EVENT_NAME, handleStorageChange);
		};
	}, [key]);

	return [storedValue, setValue];
}
