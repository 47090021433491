/**
 * Teamscale REST API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit the class manually.
 */

import type { QueryOperation } from './Query';
import type { MutationOperation } from './Mutation';
import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { AnalysisProfile } from 'typedefs/AnalysisProfile';
import type { AnalysisProfileUsageInfoWithProjects } from 'typedefs/AnalysisProfileUsageInfoWithProjects';
import type { AnalysisProfileWithProjects } from 'typedefs/AnalysisProfileWithProjects';
import type { AnalysisProgressDescriptor } from 'typedefs/AnalysisProgressDescriptor';
import type { BranchesInfo } from 'typedefs/BranchesInfo';
import type { CodeSearchResultsWrapper } from 'typedefs/CodeSearchResultsWrapper';
import type { CommitAlerts } from 'typedefs/CommitAlerts';
import type { CommitDescriptor } from 'typedefs/CommitDescriptor';
import type { ConnectorConfiguration } from 'typedefs/ConnectorConfiguration';
import type { Credentials } from 'typedefs/Credentials';
import type { DeclarationOfAccessibilityContext } from 'typedefs/DeclarationOfAccessibilityContext';
import type { ECommitAttributeEntry } from 'typedefs/ECommitAttribute';
import type { ECommitTypeEntry } from 'typedefs/ECommitType';
import type { EFindingBlacklistOperationEntry } from 'typedefs/EFindingBlacklistOperation';
import type { EFindingBlacklistTypeEntry } from 'typedefs/EFindingBlacklistType';
import type { ETokenClassEntry } from 'typedefs/ETokenClass';
import type { ETypeEntry } from 'typedefs/EType';
import type { ExternalStorageBackendData } from 'typedefs/ExternalStorageBackendData';
import type { FilteredTreeMapWrapper } from 'typedefs/FilteredTreeMapWrapper';
import type { FindingBlacklistInfo } from 'typedefs/FindingBlacklistInfo';
import type { FindingBlacklistRequestBody } from 'typedefs/FindingBlacklistRequestBody';
import type { FindingChurnCount } from 'typedefs/FindingChurnCount';
import type { GetLinkRolesResponse } from 'typedefs/GetLinkRolesResponse';
import type { LineCoverageInfo } from 'typedefs/LineCoverageInfo';
import type { LoginContext } from 'typedefs/LoginContext';
import type { MethodHistoryEntriesWrapper } from 'typedefs/MethodHistoryEntriesWrapper';
import type { MetricDirectorySchema } from 'typedefs/MetricDirectorySchema';
import type { PartitionInfo } from 'typedefs/PartitionInfo';
import type { ProjectBranchingConfiguration } from 'typedefs/ProjectBranchingConfiguration';
import type { RedundantLiteralInfo } from 'typedefs/RedundantLiteralInfo';
import type { RepositoryChurn } from 'typedefs/RepositoryChurn';
import type { RepositoryLogEntryAggregate } from 'typedefs/RepositoryLogEntryAggregate';
import type { RepositoryLogFileHistoryEntry } from 'typedefs/RepositoryLogFileHistoryEntry';
import type { Stacktrace } from 'typedefs/Stacktrace';
import type { TokenElementChurnInfo } from 'typedefs/TokenElementChurnInfo';
import type { TreeMapNode } from 'typedefs/TreeMapNode';
import type { UserResolvedFindingBlacklistInfo } from 'typedefs/UserResolvedFindingBlacklistInfo';
import type { UserResolvedRepositoryLogEntry } from 'typedefs/UserResolvedRepositoryLogEntry';
import type { UserResolvedTeamscaleIssue } from 'typedefs/UserResolvedTeamscaleIssue';

export type AutocompleteIssueIdQueryParams = {
	'search'?: string
};

export type AutocompleteUserGroupQueryParams = {
	'token'?: string, 
	'regex'?: boolean, 
	'max_matches'?: number
};

export type AutocompleteUserNameQueryParams = {
	'token'?: string, 
	'regex'?: boolean, 
	'max_matches'?: number
};

export type CreateDashboardFromTemplateQueryParams = {
	/**
	 * Name of the template to use as base for the new dashboard.
	 */
	'template': string, 
	/**
	 * Name of the project to use as datasource for the new dashboard.
	 */
	'project': string, 
	/**
	 * Name of the new dashboard.
	 */
	'dashboard-name': string, 
	/**
	 * Path within the project to use as datasource for the new dashboard.
	 */
	'path'?: string, 
	/**
	 * Overwrite dashboard if it already exists.
	 */
	'overwrite'?: boolean
};

export type FindInitialCommitQueryParams = {
	/**
	 * The path of the file
	 */
	'uniformPath'?: string
};

export type FindLogEntriesInRangeQueryParams = {
	/**
	 * Start timestamp to return repository log entries after.
	 */
	'start'?: number, 
	/**
	 * End timestamp to return repository log entries before.
	 */
	'end'?: number, 
	/**
	 * Whether or not commits for the timestamps from the start and/or end commit are included.
	 */
	'include-bounds'?: boolean, 
	/**
	 * The amount of repository logs returned when at least one of the commit parameters is omitted.
	 */
	'entry-count'?: number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon. Only log entries from commits merged into the given branch up to the given point will be considered when the start and end timestamps are evaluated.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Controls whether only repository log entries are returned where the current user was the committer.
	 */
	'privacy-aware'?: boolean, 
	/**
	 * The commit types to show or hide. If omitted, commits for all types are returned.
	 */
	'commit-types'?: Array<ECommitTypeEntry>, 
	/**
	 * The commit attribute to filter by.
	 */
	'commit-attribute'?: ECommitAttributeEntry, 
	/**
	 * Option whether to filter out commits, that belong to other branches.
	 */
	'exclude-other-branches'?: boolean, 
	/**
	 * User names to filter for.
	 */
	'users'?: Array<string>, 
	/**
	 * Group names to filter for.
	 */
	'groups'?: Array<string>, 
	/**
	 * Whether we want to filter for spec items or code.
	 */
	'specItemEntriesOnly'?: boolean, 
	/**
	 * Commit messages to filter for.
	 */
	'commit-messages'?: Array<string>
};

export type FlagFindingsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Request operation to perform (e.g. add or remove blacklisting information).
	 */
	'operation': EFindingBlacklistOperationEntry, 
	/**
	 * The type of flagging
	 */
	'type': EFindingBlacklistTypeEntry
};

export type GetAllPartitionDetailsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetBranchForSpecItemQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetBranchesGetRequestQueryParams = {
	/**
	 * The offset from which to start loading branches.
	 */
	'start-offset'?: number, 
	/**
	 * The number of branches that should be returned.
	 */
	'limit'?: number, 
	/**
	 * Whether only currently live branches should be returned.
	 */
	'only-live-branches'?: boolean, 
	/**
	 * If this is set the service uses the regex filter to return branches matching the filter.
	 */
	'regex-filter'?: string, 
	/**
	 * If this is set the service checks whether the passed branches are contained in the currently live branches of the project and returns the one that are.
	 */
	'filter'?: Array<string>
};

export type GetBranchesPostRequestQueryParams = {
	/**
	 * The offset from which to start loading branches.
	 */
	'start-offset'?: number, 
	/**
	 * The number of branches that should be returned.
	 */
	'limit'?: number, 
	/**
	 * Whether only currently live branches should be returned.
	 */
	'only-live-branches'?: boolean, 
	/**
	 * If this is set the service uses the regex filter to return branches matching the filter.
	 */
	'regex-filter'?: string, 
	/**
	 * If this is set the service checks whether the passed branches are contained in the currently live branches of the project and returns the one that are.
	 */
	'filter'?: Array<string>
};

export type GetChangeTreemapQueryParams = {
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The width for the treemap to layout.
	 */
	'width'?: number, 
	/**
	 * The height for the treemap to layout.
	 */
	'height'?: number, 
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string
};

export type GetCodeSearchMatchResultAndTreemapQueryParams = {
	/**
	 * Uniform path to retrieve files for
	 */
	'uniform-path': string, 
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The index for the metric used to determine the color of the displayed nodes.
	 */
	'color-metric'?: number, 
	'min-value-for-color'?: number, 
	'max-value-for-color'?: number, 
	'color'?: number, 
	/**
	 * Regular expressions the file paths have to match to be displayed.
	 */
	'included-files-regexes'?: Array<string>, 
	/**
	 * Regular expressions the file paths may not match to be displayed.
	 */
	'excluded-files-regexes'?: Array<string>, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether to enable the color-blind mode for the Treemap
	 */
	'color-blind-mode'?: boolean, 
	/**
	 * The width for the treemap to layout.
	 */
	'width'?: number, 
	/**
	 * The height for the treemap to layout.
	 */
	'height'?: number, 
	'search-term': string, 
	'token-classes'?: Array<ETokenClassEntry>, 
	'is-color-gradation-active'?: boolean, 
	/**
	 * Result list size limit (or 0 for no limit)
	 */
	'preview-size'?: number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetCommitAlertsQueryParams = {
	'commit'?: Array<UnresolvedCommitDescriptor>
};

export type GetDeclarationOfAccessibilityQueryParams = {
	/**
	 * Whether a reload should be forced.
	 */
	'force-reload'?: boolean
};

export type GetFindingChurnCountQueryParams = {
	'commit'?: Array<UnresolvedCommitDescriptor>
};

export type GetFlaggedFindingIdsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetFlaggedFindingInfoQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetFlaggedFindingsQueryParams = {
	/**
	 * Key of a precomputed (using the \"merge-requests/parent-info\" endpoint) merge-base info. Should be provided in case the finding delta is requested for a (possible) merge request. 
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Commit denoting the start of the flagged finding interval, or the source commit in case of a (possible) merge. Must be present when \"merge-base-cache-key\" is provided.
	 */
	'from'?: UnresolvedCommitDescriptor, 
	/**
	 * Commit denoting the end of the flagged finding interval, or the target commit in case of a (possible) merge.
	 */
	'to': UnresolvedCommitDescriptor
};

export type GetFlaggedFindingsInfosQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetGlobalBranchesGetRequestQueryParams = {
	/**
	 * A list of project IDs. When not specified it defaults to \"all projects\"
	 */
	'projects'?: Array<string>, 
	/**
	 * The offset from which to start loading branches.
	 */
	'start-offset'?: number, 
	/**
	 * The number of branches that should be returned.
	 */
	'limit'?: number, 
	/**
	 * Whether only currently live branches should be returned.
	 */
	'only-live-branches'?: boolean, 
	/**
	 * If this is set the service uses the regex filter to return branches matching the filter.
	 */
	'regex-filter'?: string, 
	/**
	 * If this is set the service checks whether the passed branches are contained in the currently live branches of the project and returns the one that are.
	 */
	'filter'?: Array<string>
};

export type GetGlobalDefaultBranchGetRequestQueryParams = {
	/**
	 * A list of project IDs. When not specified it defaults to \"all projects\"
	 */
	'projects'?: Array<string>
};

export type GetGlobalRecentBranchesGetRequestQueryParams = {
	/**
	 * A list of project IDs. When not specified it defaults to \"all projects\"
	 */
	'projects'?: Array<string>
};

export type GetIssuesByRepositoryCommitsBody = {
	'commit'?: Array<UnresolvedCommitDescriptor>
};

export type GetLastCommitOfTypeQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	'commit-type'?: ECommitTypeEntry
};

export type GetMergeRequestRepositoryChurnQueryParams = {
	/**
	 * The source commit descriptor.
	 */
	'source': UnresolvedCommitDescriptor, 
	/**
	 * The target commit descriptor.
	 */
	'target': UnresolvedCommitDescriptor, 
	/**
	 * Key of a precomputed merge-base info object.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * The commit types to show or hide. If omitted, commits for all types are returned.
	 */
	'commit-types'?: Array<ECommitTypeEntry>, 
	/**
	 * The commit attribute to filter by.
	 */
	'commit-attribute'?: ECommitAttributeEntry, 
	/**
	 * Option whether to filter out commits, that belong to other branches.
	 */
	'exclude-other-branches'?: boolean, 
	/**
	 * User names to filter for.
	 */
	'users'?: Array<string>, 
	/**
	 * Group names to filter for.
	 */
	'groups'?: Array<string>, 
	/**
	 * Whether we want to filter for spec items or code.
	 */
	'specItemEntriesOnly'?: boolean, 
	/**
	 * Commit messages to filter for.
	 */
	'commit-messages'?: Array<string>
};

export type GetMethodHistoryQueryParams = {
	/**
	 * The character-based offset in file on which the method starts (at the specified time).
	 */
	'start-offset': number, 
	/**
	 * The character-based offset in file on which the method ends (at the specified time).
	 */
	'end-offset': number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The partitions from which coverage uploads should be retrieved. If not set, all partitions in the project are used.
	 */
	'partitions'?: Array<string>, 
	/**
	 * The cross-annotation projects to retrieve execution information from.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * The baseline until to compute detailed history.
	 */
	'baseline-timestamp'?: number, 
	/**
	 * The commit types to show or hide. If omitted, commits for all types are returned.
	 */
	'commit-types'?: Array<ECommitTypeEntry>, 
	/**
	 * The commit attribute to filter by.
	 */
	'commit-attribute'?: ECommitAttributeEntry, 
	/**
	 * Option whether to filter out commits, that belong to other branches.
	 */
	'exclude-other-branches'?: boolean, 
	/**
	 * User names to filter for.
	 */
	'users'?: Array<string>, 
	/**
	 * Group names to filter for.
	 */
	'groups'?: Array<string>, 
	/**
	 * Whether we want to filter for spec items or code.
	 */
	'specItemEntriesOnly'?: boolean, 
	/**
	 * Commit messages to filter for.
	 */
	'commit-messages'?: Array<string>
};

export type GetRedundantLiteralTreemapQueryParams = {
	/**
	 * The sub path
	 */
	'uniform-path'?: string, 
	/**
	 * The minimum size of the literal
	 */
	'min-literal'?: number, 
	/**
	 * The minimum occurrences of the literal
	 */
	'min-occurrences'?: number, 
	/**
	 * Constraint metric to local scope.
	 */
	'local-scope-metric'?: boolean, 
	/**
	 * Regex to include literals.
	 */
	'include-regex'?: string, 
	/**
	 * Regex to exclude literals.
	 */
	'exclude-regex'?: string, 
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The index for the metric used to determine the color of the displayed nodes.
	 */
	'color-metric'?: number, 
	'min-value-for-color'?: number, 
	'max-value-for-color'?: number, 
	'color'?: number, 
	/**
	 * Regular expressions the file paths have to match to be displayed.
	 */
	'included-files-regexes'?: Array<string>, 
	/**
	 * Regular expressions the file paths may not match to be displayed.
	 */
	'excluded-files-regexes'?: Array<string>, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether to enable the color-blind mode for the Treemap
	 */
	'color-blind-mode'?: boolean, 
	/**
	 * The width for the treemap to layout.
	 */
	'width'?: number, 
	/**
	 * The height for the treemap to layout.
	 */
	'height'?: number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If color gradation should be used when displaying treemap
	 */
	'color-gradation'?: boolean
};

export type GetRedundantLiteralsQueryQueryParams = {
	/**
	 * The sub path
	 */
	'uniform-path'?: string, 
	/**
	 * The minimum size of the literal
	 */
	'min-literal'?: number, 
	/**
	 * The minimum occurrences of the literal
	 */
	'min-occurrences'?: number, 
	/**
	 * Constraint metric to local scope.
	 */
	'local-scope-metric'?: boolean, 
	/**
	 * Regex to include literals.
	 */
	'include-regex'?: string, 
	/**
	 * Regex to exclude literals.
	 */
	'exclude-regex'?: string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetRepositoryChurnQueryParams = {
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string, 
	/**
	 * The commit types to show or hide. If omitted, commits for all types are returned.
	 */
	'commit-types'?: Array<ECommitTypeEntry>, 
	/**
	 * The commit attribute to filter by.
	 */
	'commit-attribute'?: ECommitAttributeEntry, 
	/**
	 * Option whether to filter out commits, that belong to other branches.
	 */
	'exclude-other-branches'?: boolean, 
	/**
	 * User names to filter for.
	 */
	'users'?: Array<string>, 
	/**
	 * Group names to filter for.
	 */
	'groups'?: Array<string>, 
	/**
	 * Whether we want to filter for spec items or code.
	 */
	'specItemEntriesOnly'?: boolean, 
	/**
	 * Commit messages to filter for.
	 */
	'commit-messages'?: Array<string>
};

export type GetResourceHistoryQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The commit types to show or hide. If omitted, commits for all types are returned.
	 */
	'commit-types'?: Array<ECommitTypeEntry>, 
	/**
	 * The commit attribute to filter by.
	 */
	'commit-attribute'?: ECommitAttributeEntry, 
	/**
	 * Option whether to filter out commits, that belong to other branches.
	 */
	'exclude-other-branches'?: boolean, 
	/**
	 * User names to filter for.
	 */
	'users'?: Array<string>, 
	/**
	 * Group names to filter for.
	 */
	'groups'?: Array<string>, 
	/**
	 * Whether we want to filter for spec items or code.
	 */
	'specItemEntriesOnly'?: boolean, 
	/**
	 * Commit messages to filter for.
	 */
	'commit-messages'?: Array<string>, 
	/**
	 * This parameter limits the number of returned entries to the newest ones. If all parameters should be returned, either omit the parameter or use 0 instead.
	 */
	'number-of-entries'?: number
};

export type GetSpecItemChurnQueryParams = {
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string
};

export type GetStacktraceQueryParams = {
	/**
	 * This parameter must be set to the partition in which the test was uploaded.
	 */
	'partition': string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTestCoverageQueryParams = {
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider. If this parameter is omitted every partition will be taken.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If true, the coverage is adjusted to match a pretty printed version of the code.
	 */
	'pretty'?: boolean, 
	/**
	 * If true, the coverage will include method-accurate coverage.
	 */
	'include-method-coverage'?: boolean
};

export type GetTestCoveragePostBody = {
	/**
	 * If true, the coverage will include method-accurate coverage.
	 */
	'include-method-coverage'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider. If this parameter is omitted every partition will be taken.
	 */
	'partitions'?: Array<string>, 
	/**
	 * If true, the coverage is adjusted to match a pretty printed version of the code.
	 */
	'pretty'?: boolean, 
	/**
	 *  This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \\\"HEAD\\\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \\\"p1\\\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \\\"master:1601637680000p1\\\"
	 */
	't'?: UnresolvedCommitDescriptor
};

export type ImportAnalysisProfileBody = {
	'analysis-profile': Array<File>
};

export type PostCommitAlertsBody = {
	'commit'?: Array<UnresolvedCommitDescriptor>
};

export type PostFindingChurnCountBody = {
	'commit'?: Array<UnresolvedCommitDescriptor>
};

export type UnmarkToleratedAndFalsePositiveFindingsBeforeCommitQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Whether false positive findings should be unmarked as well. If not specified, only tolerated findings will be unmarked.
	 */
	'unmark-false-positives'?: boolean
};


/** Type definition of the QUERIES interface. */
export type Queries = {

	/**
	 * Get autocompletion suggestions
	 *
	 * Gets autocompletion suggestions based on the input The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	autocompleteIssueId(project: string, queryParams: AutocompleteIssueIdQueryParams): QueryOperation<Array<string>>

	/**
	 * Autocomplete user group name
	 *
	 * null The API requires no permissions
	 */
	autocompleteUserGroup(queryParams: AutocompleteUserGroupQueryParams): QueryOperation<Array<string>>

	/**
	 * Get user autocompletion suggestions
	 *
	 * Gets autocompletion suggestions based on the input The API requires no permissions
	 */
	autocompleteUserName(queryParams: AutocompleteUserNameQueryParams): QueryOperation<Array<string>>

	/**
	 * Create analysis profile
	 *
	 * Creates a new analysis profile. The API requires the user to have Create Analysis Profiles permissions.
	 */
	createAnalysisProfile(body: AnalysisProfile): QueryOperation<void>

	/**
	 * Create dashboard from template
	 *
	 * Creates a dashboard from a template. Returns the qualified name of the created dashboard. The API requires no permissions
	 */
	createDashboardFromTemplate(queryParams: CreateDashboardFromTemplateQueryParams): QueryOperation<string>

	/**
	 * Create external storage backend
	 *
	 * Creates new external storage backend. The API requires the user to have Configure External Storage Backends permissions.
	 */
	createExternalStorageBackend(body: ExternalStorageBackendData): QueryOperation<void>

	/**
	 * Delete analysis profile
	 *
	 * Deletes the analysis profile identified by the given name from the system The API requires the user to have Delete permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName Name of the analysis profile to delete
	 */
	deleteAnalysisProfile(analysisProfileName: string): QueryOperation<void>

	/**
	 * Delete external storage backends
	 *
	 * Deletes the external storage backends identified by the given name from the system The API requires the user to have Delete permissions on the External Storage Backends externalStorageBackendName.
     * @param externalStorageBackendName Name of the external storage backend to delete
	 */
	deleteExternalStorageBackend(externalStorageBackendName: string): QueryOperation<void>

	/**
	 * Delete option
	 *
	 * Deletes the user option identified by the given name The API requires the user to have Edit permissions on the user.
     * @param user 
     * @param optionName 
	 */
	deleteUserOption(user: string, optionName: string): QueryOperation<void>

	/**
	 * Get analysis profile documentation
	 *
	 * Returns documentation of an analysis profile. The documentation is restricted to findings which are enabled in the profile and includes descriptions of the findings. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	downloadAnalysisProfileDocumentation(analysisProfileName: string): QueryOperation<void>

	/**
	 * Edit option
	 *
	 * Edits the user option identified by the given name The API requires the user to have Edit permissions on the user.
     * @param user Name of the user to edit the option for
     * @param optionName Name of the option to edit
	 */
	editUserOption(user: string, optionName: string, body: object): QueryOperation<void>

	/**
	 * Exports the analysis profile
	 *
	 * Retrieves the analysis profile identified by given name. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	exportAnalysisProfile(analysisProfileName: string): QueryOperation<void>

	/**
	 * Check if external storage backends exist
	 *
	 * Checks if the external storage backend identified by the given name exist. No permissions needed, as the service checks whether the backend already exists for every user to prohibit overwriting backends not visible for the current user
     * @param externalStorageBackendName Name of the external storage backend to check
	 */
	externalStorageBackendExists(externalStorageBackendName: string): QueryOperation<ExternalStorageBackendData>

	/**
	 * Returns the initial commit which added the file at the given path. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param unresolvedCommit This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	findInitialCommit(project: string, unresolvedCommit: UnresolvedCommitDescriptor, queryParams: FindInitialCommitQueryParams): QueryOperation<CommitDescriptor | null>

	/**
	 * Get repository log entries
	 *
	 * Retrieves a specific count of repository log entries after a start timestamp or before an end timestamp. If both start and end timestamp are missing, the newest log entries are returned. If both a start and end timestamp are provided all log entries between the two are returned. The resulting log entries include all log entries from merged commits into the current branch. The current branch and branch state at a point in time are determined by the commit parameter. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	findLogEntriesInRange(project: string, queryParams: FindLogEntriesInRangeQueryParams): QueryOperation<Array<UserResolvedRepositoryLogEntry>>

	/**
	 * Flags/unflags findings
	 *
	 * Flags/unflags the given findings with the given flagging type. This service is public API since Teamscale 8.5. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	flagFindings(project: string, queryParams: FlagFindingsQueryParams, body: FindingBlacklistRequestBody): QueryOperation<void>

	/**
	 * Get all external storage backends
	 *
	 * Retrieves all available external storage backends in the system. No permissions needed, as the service will only return backends visible to current user.
	 */
	getAllExternalStorageBackends(): QueryOperation<Array<ExternalStorageBackendData>>

	/**
	 * Get details (name, last updated) for all test execution partitions
	 *
	 * Returns all available test execution partitions and their last updated timestamp; of the project that have been uploaded and processed up to the given commit. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getAllPartitionDetails(project: string, queryParams: GetAllPartitionDetailsQueryParams): QueryOperation<Array<PartitionInfo>>

	/**
	 * Get analysis profile
	 *
	 * Retrieves the analysis profile identified by given name. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	getAnalysisProfile(analysisProfileName: string): QueryOperation<AnalysisProfile>

	/**
	 * Get all analysis profile names
	 *
	 * Retrieves the names of all analysis profiles visible to the current user. No permissions needed, as the service will only return names of analysis profiles visible to current user.
	 */
	getAnalysisProfileNames(): QueryOperation<Array<string>>

	/**
	 * Get analysis profile usage
	 *
	 * Returns a list of AnalysisProfileUsageInfoWithProjects indicating the list of referenced projects for each analysis profile No permissions needed, as the service will only return names of analysis profiles visible to current user.
	 */
	getAnalysisProfileUsageInfo(): QueryOperation<Array<AnalysisProfileUsageInfoWithProjects>>

	/**
	 * Get analysis profile with referencing projects
	 *
	 * Returns an analysis profile annotated with the list of projects using the profile The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	getAnalysisProfileWithProjects(analysisProfileName: string): QueryOperation<AnalysisProfileWithProjects>

	/**
	 * Get spec item branch
	 *
	 * Get the branch where the spec item with the provided id is analysed on The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param id ID of the spec item to fetch the branch for
	 */
	getBranchForSpecItem(project: string, id: string, queryParams: GetBranchForSpecItemQueryParams): QueryOperation<string>

	/**
	 * Get branches
	 *
	 * Returns the specified range of sorted branches (or all if no range specified), including/excluding deleted/anonymous branches or the sorted filtered branches based on the defined branchesFilter/regexFilter as BranchesInfo. This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getBranchesGetRequest(project: string, queryParams: GetBranchesGetRequestQueryParams): QueryOperation<BranchesInfo>

	/**
	 * Get branches
	 *
	 * Returns the specified range of sorted branches (or all if no range specified), including/excluding deleted/anonymous branches or the sorted filtered branches based on the defined branchesFilter/regexFilter as BranchesInfo. This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getBranchesPostRequest(project: string, queryParams: GetBranchesPostRequestQueryParams): QueryOperation<BranchesInfo>

	/**
	 * Get change treemap
	 *
	 * Retrieves a treemap of change frequencies. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getChangeTreemap(project: string, queryParams: GetChangeTreemapQueryParams): QueryOperation<TreeMapNode>

	/**
	 * List the code search match results and returns a treemap.
	 *
	 * Lists all code search matches result including path and line numbers for a given search term and a max result size. Additionally, return a treemap. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getCodeSearchMatchResultAndTreemap(project: string, queryParams: GetCodeSearchMatchResultAndTreemapQueryParams): QueryOperation<CodeSearchResultsWrapper>

	/**
	 * Get commit alerts
	 *
	 * Provides access to the alerts for the given commits. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getCommitAlerts(project: string, queryParams: GetCommitAlertsQueryParams): QueryOperation<Array<CommitAlerts | null>>

	/**
	 * Get the declaration of accessibility
	 *
	 * Returns the declaration of accessibility as found in the configuration directory (file name: 'declaration_of_accessibility.md'). To avoid unnecessary IO lookups, the declaration (or non-existent declaration) is locally cached until a reload is forced. The API requires no login
	 */
	getDeclarationOfAccessibility(queryParams: GetDeclarationOfAccessibilityQueryParams): QueryOperation<string | null>

	/**
	 * null The API requires no login
	 */
	getDeclarationOfAccessibilityContext(): QueryOperation<DeclarationOfAccessibilityContext>

	/**
	 * Get the default branch name
	 *
	 * Get the default branch name This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getDefaultBranchNameGetRequest(project: string): QueryOperation<string>

	/**
	 * This service provides access to the finding churn count. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindingChurnCount(project: string, queryParams: GetFindingChurnCountQueryParams): QueryOperation<Array<FindingChurnCount>>

	/**
	 * Get identifiers of flagged findings
	 *
	 * Returns identifiers of all flagged findings for a given project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFlaggedFindingIds(project: string, queryParams: GetFlaggedFindingIdsQueryParams): QueryOperation<Array<string>>

	/**
	 * Gets flagging information for a finding.
	 *
	 * Returns the flagging information for the finding with given identifier. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param finding Id of the finding the information is requested for.
	 */
	getFlaggedFindingInfo(project: string, finding: string, queryParams: GetFlaggedFindingInfoQueryParams): QueryOperation<UserResolvedFindingBlacklistInfo | null>

	/**
	 * Get flagged findings
	 *
	 * Returns all flagged findings for a given project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFlaggedFindings(project: string, queryParams: GetFlaggedFindingsQueryParams): QueryOperation<Array<FindingBlacklistInfo>>

	/**
	 * Get flagging information for findings.
	 *
	 * Returns flagging information for the given findings. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFlaggedFindingsInfos(project: string, queryParams: GetFlaggedFindingsInfosQueryParams, body: Array<string>): QueryOperation<Array<FindingBlacklistInfo>>

	/**
	 * Get multi project branches
	 *
	 * Gets the branches for multiple projects as BranchesInfo The API requires no permissions
	 */
	getGlobalBranchesGetRequest(queryParams: GetGlobalBranchesGetRequestQueryParams): QueryOperation<BranchesInfo>

	/**
	 * Get multi project default branch
	 *
	 * Gets the default branch of one the specified projects. The API requires no permissions
	 */
	getGlobalDefaultBranchGetRequest(queryParams: GetGlobalDefaultBranchGetRequestQueryParams): QueryOperation<string | null>

	/**
	 * Get multi project recent branches
	 *
	 * Gets the recent branches for multiple projects. The API requires no permissions
	 */
	getGlobalRecentBranchesGetRequest(queryParams: GetGlobalRecentBranchesGetRequestQueryParams): QueryOperation<Array<string>>

	/**
	 * Retrieve commit issues
	 *
	 * Returns the issues associated with the given commits. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getIssuesByRepositoryCommits(project: string, body: GetIssuesByRepositoryCommitsBody): QueryOperation<Array<Array<UserResolvedTeamscaleIssue> | null>>

	/**
	 * Return last change log entry with the given commit type
	 *
	 * Returns the log entry for the last change with the given commit type The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getLastCommitOfType(project: string, queryParams: GetLastCommitOfTypeQueryParams): QueryOperation<RepositoryLogEntryAggregate | null>

	/**
	 * Get link roles
	 *
	 * Returns the link roles for a specific connector. The API requires the user to have View permissions on the External Accounts accountName.
     * @param accountName The ID of the external accounts the user needs access to.
	 */
	getLinkRoles(accountName: string, body: ConnectorConfiguration): QueryOperation<GetLinkRolesResponse>

	/**
	 * Provides context for the login screen
	 *
	 * null The API requires no login
	 */
	getLoginContext(): QueryOperation<LoginContext>

	/**
	 * Get merge request repository churn
	 *
	 * Retrieves the merge request repository churn. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMergeRequestRepositoryChurn(project: string, queryParams: GetMergeRequestRepositoryChurnQueryParams): QueryOperation<RepositoryChurn>

	/**
	 * Get method history
	 *
	 * Returns the method history entries including changes, renamings or movements and points in time where method has been tested. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath Uniform path to retrieve the metric history for.
	 */
	getMethodHistory(project: string, uniformPath: string, queryParams: GetMethodHistoryQueryParams): QueryOperation<MethodHistoryEntriesWrapper>

	/**
	 * Get metric schema
	 *
	 * Returns the metric schema for a path within a project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPathType The path for which the metric schema should be fetched
	 */
	getMetricDirectorySchemaForPath(project: string, uniformPathType: ETypeEntry): QueryOperation<MetricDirectorySchema>

	/**
	 * Get project analysis progress
	 *
	 * Retrieves the project specific analysis progress. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getProjectAnalysisProgress(project: string): QueryOperation<Array<AnalysisProgressDescriptor>>

	/**
	 * Get recent branches
	 *
	 * Returns the recently visited branches. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRecentBranches(project: string): QueryOperation<Array<string>>

	/**
	 * Get redundant literal treemap
	 *
	 * Retrieves a treemap of all redundant literals. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRedundantLiteralTreemap(project: string, queryParams: GetRedundantLiteralTreemapQueryParams): QueryOperation<FilteredTreeMapWrapper>

	/**
	 * Get redundant literals
	 *
	 * Retrieves all redundant literals in the project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRedundantLiteralsQuery(project: string, queryParams: GetRedundantLiteralsQueryQueryParams): QueryOperation<Array<RedundantLiteralInfo>>

	/**
	 * Get repository churn
	 *
	 * Retrieves the repository churn for a time range. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRepositoryChurn(project: string, queryParams: GetRepositoryChurnQueryParams): QueryOperation<RepositoryChurn>

	/**
	 * Get resource history
	 *
	 * Returns repository log file entries for a specific path, i.e. the association between files and repository log entries. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getResourceHistory(project: string, uniformPath: string, queryParams: GetResourceHistoryQueryParams): QueryOperation<Array<RepositoryLogFileHistoryEntry>>

	/**
	 * Gets the spec item churn
	 *
	 * Provides a spec item churn (i.e., the list of changed spec items) for a given time range. This service is public API since Teamscale 8.5. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getSpecItemChurn(project: string, queryParams: GetSpecItemChurnQueryParams): QueryOperation<Array<TokenElementChurnInfo>>

	/**
	 * Get stacktrace of failed test execution
	 *
	 * Retrieves the stacktrace of the failed test execution at a given uniform path. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param test Uniform path to retrieve the stacktrace for
	 */
	getStacktrace(project: string, test: string, queryParams: GetStacktraceQueryParams): QueryOperation<Stacktrace | null>

	/**
	 * Returns test coverage
	 *
	 * Returns the line coverage data for an element. This service is public API since Teamscale 5.3. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getTestCoverage(project: string, uniformPath: string, queryParams: GetTestCoverageQueryParams): QueryOperation<LineCoverageInfo | null>

	/**
	 * Returns test coverage
	 *
	 * Returns the line coverage data for an element. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getTestCoveragePost(project: string, uniformPath: string, body: GetTestCoveragePostBody): QueryOperation<LineCoverageInfo | null>

	/**
	 * Import analysis profile
	 *
	 * Imports an analysis profile. Requires Create Analysis Profiles or the permission to edit analysis profiles when a profile with the same name already exists.
	 */
	importAnalysisProfile(body: ImportAnalysisProfileBody): QueryOperation<void>

	/**
	 * Availability of spec-item analysis
	 *
	 * Determines whether spec-item analysis is enabled for the project The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	isSpecItemAnalysisEnabled(project: string): QueryOperation<boolean>

	/**
	 * Performs the login of a user.
	 *
	 * Authenticates the user based on given credentials and returns a session cookie in case of success. The API requires no login
	 */
	performLogin(body: Credentials): QueryOperation<void>

	/**
	 * Retrieve commit alerts
	 *
	 * Provides access to the alerts for the given commits. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	postCommitAlerts(project: string, body: PostCommitAlertsBody): QueryOperation<Array<CommitAlerts | null>>

	/**
	 * This service provides access to the finding churn count. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	postFindingChurnCount(project: string, body: PostFindingChurnCountBody): QueryOperation<Array<FindingChurnCount>>

	/**
	 * Unmark tolerated/false positive findings before given commit.
	 *
	 * Unmark all tolerated/false positive findings that were marked before the given commit. Also returns the ids of the unmarked findings. The API requires the user to have View Project, Flag Red Findings, Flag Yellow Findings permissions on the project.
     * @param project The project ID.
	 */
	unmarkToleratedAndFalsePositiveFindingsBeforeCommit(project: string, queryParams: UnmarkToleratedAndFalsePositiveFindingsBeforeCommitQueryParams): QueryOperation<Array<string>>

	/**
	 * Update analysis profile
	 *
	 * Updates the analysis profile identified by the given name with the value in the request body. Requires the edit permission on the respective analysis profile.
	 */
	updateAnalysisProfile(body: AnalysisProfile): QueryOperation<void>

	/**
	 * Update external storage backends
	 *
	 * Updates the external storage backend identified by the given name with the value in the request body. The API requires the user to have Edit permissions on the External Storage Backends externalStorageBackendName.
     * @param externalStorageBackendName Name of the external storage backend which should be updated.
	 */
	updateExternalStorageBackend(externalStorageBackendName: string, body: ExternalStorageBackendData): QueryOperation<void>

	/**
	 * Validates a branching configuration
	 *
	 * Validates a given branching configuration. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	validateBranchingConfiguration(body: ProjectBranchingConfiguration): QueryOperation<string | null>

}

/** Type definition of the MUTATIONS interface. */
export type Mutations = {

	/**
	 * Get autocompletion suggestions
	 *
	 * Gets autocompletion suggestions based on the input The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	autocompleteIssueId: MutationOperation<{ pathParams: { project: string }, queryParams: AutocompleteIssueIdQueryParams }, Array<string>>

	/**
	 * Autocomplete user group name
	 *
	 * null The API requires no permissions
	 */
	autocompleteUserGroup: MutationOperation<{ queryParams: AutocompleteUserGroupQueryParams }, Array<string>>

	/**
	 * Get user autocompletion suggestions
	 *
	 * Gets autocompletion suggestions based on the input The API requires no permissions
	 */
	autocompleteUserName: MutationOperation<{ queryParams: AutocompleteUserNameQueryParams }, Array<string>>

	/**
	 * Create analysis profile
	 *
	 * Creates a new analysis profile. The API requires the user to have Create Analysis Profiles permissions.
	 */
	createAnalysisProfile: MutationOperation<{ body: AnalysisProfile }, void>

	/**
	 * Create dashboard from template
	 *
	 * Creates a dashboard from a template. Returns the qualified name of the created dashboard. The API requires no permissions
	 */
	createDashboardFromTemplate: MutationOperation<{ queryParams: CreateDashboardFromTemplateQueryParams }, string>

	/**
	 * Create external storage backend
	 *
	 * Creates new external storage backend. The API requires the user to have Configure External Storage Backends permissions.
	 */
	createExternalStorageBackend: MutationOperation<{ body: ExternalStorageBackendData }, void>

	/**
	 * Delete analysis profile
	 *
	 * Deletes the analysis profile identified by the given name from the system The API requires the user to have Delete permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName Name of the analysis profile to delete
	 */
	deleteAnalysisProfile: MutationOperation<{ pathParams: { analysisProfileName: string } }, void>

	/**
	 * Delete external storage backends
	 *
	 * Deletes the external storage backends identified by the given name from the system The API requires the user to have Delete permissions on the External Storage Backends externalStorageBackendName.
	 * @param externalStorageBackendName Name of the external storage backend to delete
	 */
	deleteExternalStorageBackend: MutationOperation<{ pathParams: { externalStorageBackendName: string } }, void>

	/**
	 * Delete option
	 *
	 * Deletes the user option identified by the given name The API requires the user to have Edit permissions on the user.
	 * @param user 
	 * @param optionName 
	 */
	deleteUserOption: MutationOperation<{ pathParams: { user: string, optionName: string } }, void>

	/**
	 * Get analysis profile documentation
	 *
	 * Returns documentation of an analysis profile. The documentation is restricted to findings which are enabled in the profile and includes descriptions of the findings. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	downloadAnalysisProfileDocumentation: MutationOperation<{ pathParams: { analysisProfileName: string } }, void>

	/**
	 * Edit option
	 *
	 * Edits the user option identified by the given name The API requires the user to have Edit permissions on the user.
	 * @param user Name of the user to edit the option for
	 * @param optionName Name of the option to edit
	 */
	editUserOption: MutationOperation<{ pathParams: { user: string, optionName: string }, body: object }, void>

	/**
	 * Exports the analysis profile
	 *
	 * Retrieves the analysis profile identified by given name. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	exportAnalysisProfile: MutationOperation<{ pathParams: { analysisProfileName: string } }, void>

	/**
	 * Check if external storage backends exist
	 *
	 * Checks if the external storage backend identified by the given name exist. No permissions needed, as the service checks whether the backend already exists for every user to prohibit overwriting backends not visible for the current user
	 * @param externalStorageBackendName Name of the external storage backend to check
	 */
	externalStorageBackendExists: MutationOperation<{ pathParams: { externalStorageBackendName: string } }, ExternalStorageBackendData>

	/**
	 * Returns the initial commit which added the file at the given path. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param unresolvedCommit This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	findInitialCommit: MutationOperation<{ pathParams: { project: string, unresolvedCommit: UnresolvedCommitDescriptor }, queryParams: FindInitialCommitQueryParams }, CommitDescriptor | null>

	/**
	 * Get repository log entries
	 *
	 * Retrieves a specific count of repository log entries after a start timestamp or before an end timestamp. If both start and end timestamp are missing, the newest log entries are returned. If both a start and end timestamp are provided all log entries between the two are returned. The resulting log entries include all log entries from merged commits into the current branch. The current branch and branch state at a point in time are determined by the commit parameter. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	findLogEntriesInRange: MutationOperation<{ pathParams: { project: string }, queryParams: FindLogEntriesInRangeQueryParams }, Array<UserResolvedRepositoryLogEntry>>

	/**
	 * Flags/unflags findings
	 *
	 * Flags/unflags the given findings with the given flagging type. This service is public API since Teamscale 8.5. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	flagFindings: MutationOperation<{ pathParams: { project: string }, queryParams: FlagFindingsQueryParams, body: FindingBlacklistRequestBody }, void>

	/**
	 * Get all external storage backends
	 *
	 * Retrieves all available external storage backends in the system. No permissions needed, as the service will only return backends visible to current user.
	 */
	getAllExternalStorageBackends: MutationOperation<void, Array<ExternalStorageBackendData>>

	/**
	 * Get details (name, last updated) for all test execution partitions
	 *
	 * Returns all available test execution partitions and their last updated timestamp; of the project that have been uploaded and processed up to the given commit. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getAllPartitionDetails: MutationOperation<{ pathParams: { project: string }, queryParams: GetAllPartitionDetailsQueryParams }, Array<PartitionInfo>>

	/**
	 * Get analysis profile
	 *
	 * Retrieves the analysis profile identified by given name. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	getAnalysisProfile: MutationOperation<{ pathParams: { analysisProfileName: string } }, AnalysisProfile>

	/**
	 * Get all analysis profile names
	 *
	 * Retrieves the names of all analysis profiles visible to the current user. No permissions needed, as the service will only return names of analysis profiles visible to current user.
	 */
	getAnalysisProfileNames: MutationOperation<void, Array<string>>

	/**
	 * Get analysis profile usage
	 *
	 * Returns a list of AnalysisProfileUsageInfoWithProjects indicating the list of referenced projects for each analysis profile No permissions needed, as the service will only return names of analysis profiles visible to current user.
	 */
	getAnalysisProfileUsageInfo: MutationOperation<void, Array<AnalysisProfileUsageInfoWithProjects>>

	/**
	 * Get analysis profile with referencing projects
	 *
	 * Returns an analysis profile annotated with the list of projects using the profile The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	getAnalysisProfileWithProjects: MutationOperation<{ pathParams: { analysisProfileName: string } }, AnalysisProfileWithProjects>

	/**
	 * Get spec item branch
	 *
	 * Get the branch where the spec item with the provided id is analysed on The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param id ID of the spec item to fetch the branch for
	 */
	getBranchForSpecItem: MutationOperation<{ pathParams: { project: string, id: string }, queryParams: GetBranchForSpecItemQueryParams }, string>

	/**
	 * Get branches
	 *
	 * Returns the specified range of sorted branches (or all if no range specified), including/excluding deleted/anonymous branches or the sorted filtered branches based on the defined branchesFilter/regexFilter as BranchesInfo. This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getBranchesGetRequest: MutationOperation<{ pathParams: { project: string }, queryParams: GetBranchesGetRequestQueryParams }, BranchesInfo>

	/**
	 * Get branches
	 *
	 * Returns the specified range of sorted branches (or all if no range specified), including/excluding deleted/anonymous branches or the sorted filtered branches based on the defined branchesFilter/regexFilter as BranchesInfo. This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getBranchesPostRequest: MutationOperation<{ pathParams: { project: string }, queryParams: GetBranchesPostRequestQueryParams }, BranchesInfo>

	/**
	 * Get change treemap
	 *
	 * Retrieves a treemap of change frequencies. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getChangeTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetChangeTreemapQueryParams }, TreeMapNode>

	/**
	 * List the code search match results and returns a treemap.
	 *
	 * Lists all code search matches result including path and line numbers for a given search term and a max result size. Additionally, return a treemap. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getCodeSearchMatchResultAndTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetCodeSearchMatchResultAndTreemapQueryParams }, CodeSearchResultsWrapper>

	/**
	 * Get commit alerts
	 *
	 * Provides access to the alerts for the given commits. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getCommitAlerts: MutationOperation<{ pathParams: { project: string }, queryParams: GetCommitAlertsQueryParams }, Array<CommitAlerts | null>>

	/**
	 * Get the declaration of accessibility
	 *
	 * Returns the declaration of accessibility as found in the configuration directory (file name: 'declaration_of_accessibility.md'). To avoid unnecessary IO lookups, the declaration (or non-existent declaration) is locally cached until a reload is forced. The API requires no login
	 */
	getDeclarationOfAccessibility: MutationOperation<{ queryParams: GetDeclarationOfAccessibilityQueryParams }, string | null>

	/**
	 * null The API requires no login
	 */
	getDeclarationOfAccessibilityContext: MutationOperation<void, DeclarationOfAccessibilityContext>

	/**
	 * Get the default branch name
	 *
	 * Get the default branch name This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getDefaultBranchNameGetRequest: MutationOperation<{ pathParams: { project: string } }, string>

	/**
	 * This service provides access to the finding churn count. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindingChurnCount: MutationOperation<{ pathParams: { project: string }, queryParams: GetFindingChurnCountQueryParams }, Array<FindingChurnCount>>

	/**
	 * Get identifiers of flagged findings
	 *
	 * Returns identifiers of all flagged findings for a given project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFlaggedFindingIds: MutationOperation<{ pathParams: { project: string }, queryParams: GetFlaggedFindingIdsQueryParams }, Array<string>>

	/**
	 * Gets flagging information for a finding.
	 *
	 * Returns the flagging information for the finding with given identifier. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param finding Id of the finding the information is requested for.
	 */
	getFlaggedFindingInfo: MutationOperation<{ pathParams: { project: string, finding: string }, queryParams: GetFlaggedFindingInfoQueryParams }, UserResolvedFindingBlacklistInfo | null>

	/**
	 * Get flagged findings
	 *
	 * Returns all flagged findings for a given project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFlaggedFindings: MutationOperation<{ pathParams: { project: string }, queryParams: GetFlaggedFindingsQueryParams }, Array<FindingBlacklistInfo>>

	/**
	 * Get flagging information for findings.
	 *
	 * Returns flagging information for the given findings. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFlaggedFindingsInfos: MutationOperation<{ pathParams: { project: string }, queryParams: GetFlaggedFindingsInfosQueryParams, body: Array<string> }, Array<FindingBlacklistInfo>>

	/**
	 * Get multi project branches
	 *
	 * Gets the branches for multiple projects as BranchesInfo The API requires no permissions
	 */
	getGlobalBranchesGetRequest: MutationOperation<{ queryParams: GetGlobalBranchesGetRequestQueryParams }, BranchesInfo>

	/**
	 * Get multi project default branch
	 *
	 * Gets the default branch of one the specified projects. The API requires no permissions
	 */
	getGlobalDefaultBranchGetRequest: MutationOperation<{ queryParams: GetGlobalDefaultBranchGetRequestQueryParams }, string | null>

	/**
	 * Get multi project recent branches
	 *
	 * Gets the recent branches for multiple projects. The API requires no permissions
	 */
	getGlobalRecentBranchesGetRequest: MutationOperation<{ queryParams: GetGlobalRecentBranchesGetRequestQueryParams }, Array<string>>

	/**
	 * Retrieve commit issues
	 *
	 * Returns the issues associated with the given commits. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getIssuesByRepositoryCommits: MutationOperation<{ pathParams: { project: string }, body: GetIssuesByRepositoryCommitsBody }, Array<Array<UserResolvedTeamscaleIssue> | null>>

	/**
	 * Return last change log entry with the given commit type
	 *
	 * Returns the log entry for the last change with the given commit type The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getLastCommitOfType: MutationOperation<{ pathParams: { project: string }, queryParams: GetLastCommitOfTypeQueryParams }, RepositoryLogEntryAggregate | null>

	/**
	 * Get link roles
	 *
	 * Returns the link roles for a specific connector. The API requires the user to have View permissions on the External Accounts accountName.
	 * @param accountName The ID of the external accounts the user needs access to.
	 */
	getLinkRoles: MutationOperation<{ pathParams: { accountName: string }, body: ConnectorConfiguration }, GetLinkRolesResponse>

	/**
	 * Provides context for the login screen
	 *
	 * null The API requires no login
	 */
	getLoginContext: MutationOperation<void, LoginContext>

	/**
	 * Get merge request repository churn
	 *
	 * Retrieves the merge request repository churn. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMergeRequestRepositoryChurn: MutationOperation<{ pathParams: { project: string }, queryParams: GetMergeRequestRepositoryChurnQueryParams }, RepositoryChurn>

	/**
	 * Get method history
	 *
	 * Returns the method history entries including changes, renamings or movements and points in time where method has been tested. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath Uniform path to retrieve the metric history for.
	 */
	getMethodHistory: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetMethodHistoryQueryParams }, MethodHistoryEntriesWrapper>

	/**
	 * Get metric schema
	 *
	 * Returns the metric schema for a path within a project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPathType The path for which the metric schema should be fetched
	 */
	getMetricDirectorySchemaForPath: MutationOperation<{ pathParams: { project: string, uniformPathType: ETypeEntry } }, MetricDirectorySchema>

	/**
	 * Get project analysis progress
	 *
	 * Retrieves the project specific analysis progress. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getProjectAnalysisProgress: MutationOperation<{ pathParams: { project: string } }, Array<AnalysisProgressDescriptor>>

	/**
	 * Get recent branches
	 *
	 * Returns the recently visited branches. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRecentBranches: MutationOperation<{ pathParams: { project: string } }, Array<string>>

	/**
	 * Get redundant literal treemap
	 *
	 * Retrieves a treemap of all redundant literals. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRedundantLiteralTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetRedundantLiteralTreemapQueryParams }, FilteredTreeMapWrapper>

	/**
	 * Get redundant literals
	 *
	 * Retrieves all redundant literals in the project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRedundantLiteralsQuery: MutationOperation<{ pathParams: { project: string }, queryParams: GetRedundantLiteralsQueryQueryParams }, Array<RedundantLiteralInfo>>

	/**
	 * Get repository churn
	 *
	 * Retrieves the repository churn for a time range. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRepositoryChurn: MutationOperation<{ pathParams: { project: string }, queryParams: GetRepositoryChurnQueryParams }, RepositoryChurn>

	/**
	 * Get resource history
	 *
	 * Returns repository log file entries for a specific path, i.e. the association between files and repository log entries. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getResourceHistory: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetResourceHistoryQueryParams }, Array<RepositoryLogFileHistoryEntry>>

	/**
	 * Gets the spec item churn
	 *
	 * Provides a spec item churn (i.e., the list of changed spec items) for a given time range. This service is public API since Teamscale 8.5. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getSpecItemChurn: MutationOperation<{ pathParams: { project: string }, queryParams: GetSpecItemChurnQueryParams }, Array<TokenElementChurnInfo>>

	/**
	 * Get stacktrace of failed test execution
	 *
	 * Retrieves the stacktrace of the failed test execution at a given uniform path. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param test Uniform path to retrieve the stacktrace for
	 */
	getStacktrace: MutationOperation<{ pathParams: { project: string, test: string }, queryParams: GetStacktraceQueryParams }, Stacktrace | null>

	/**
	 * Returns test coverage
	 *
	 * Returns the line coverage data for an element. This service is public API since Teamscale 5.3. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getTestCoverage: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetTestCoverageQueryParams }, LineCoverageInfo | null>

	/**
	 * Returns test coverage
	 *
	 * Returns the line coverage data for an element. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getTestCoveragePost: MutationOperation<{ pathParams: { project: string, uniformPath: string }, body: GetTestCoveragePostBody }, LineCoverageInfo | null>

	/**
	 * Import analysis profile
	 *
	 * Imports an analysis profile. Requires Create Analysis Profiles or the permission to edit analysis profiles when a profile with the same name already exists.
	 */
	importAnalysisProfile: MutationOperation<{ body: ImportAnalysisProfileBody }, void>

	/**
	 * Availability of spec-item analysis
	 *
	 * Determines whether spec-item analysis is enabled for the project The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	isSpecItemAnalysisEnabled: MutationOperation<{ pathParams: { project: string } }, boolean>

	/**
	 * Performs the login of a user.
	 *
	 * Authenticates the user based on given credentials and returns a session cookie in case of success. The API requires no login
	 */
	performLogin: MutationOperation<{ body: Credentials }, void>

	/**
	 * Retrieve commit alerts
	 *
	 * Provides access to the alerts for the given commits. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	postCommitAlerts: MutationOperation<{ pathParams: { project: string }, body: PostCommitAlertsBody }, Array<CommitAlerts | null>>

	/**
	 * This service provides access to the finding churn count. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	postFindingChurnCount: MutationOperation<{ pathParams: { project: string }, body: PostFindingChurnCountBody }, Array<FindingChurnCount>>

	/**
	 * Unmark tolerated/false positive findings before given commit.
	 *
	 * Unmark all tolerated/false positive findings that were marked before the given commit. Also returns the ids of the unmarked findings. The API requires the user to have View Project, Flag Red Findings, Flag Yellow Findings permissions on the project.
	 * @param project The project ID.
	 */
	unmarkToleratedAndFalsePositiveFindingsBeforeCommit: MutationOperation<{ pathParams: { project: string }, queryParams: UnmarkToleratedAndFalsePositiveFindingsBeforeCommitQueryParams }, Array<string>>

	/**
	 * Update analysis profile
	 *
	 * Updates the analysis profile identified by the given name with the value in the request body. Requires the edit permission on the respective analysis profile.
	 */
	updateAnalysisProfile: MutationOperation<{ body: AnalysisProfile }, void>

	/**
	 * Update external storage backends
	 *
	 * Updates the external storage backend identified by the given name with the value in the request body. The API requires the user to have Edit permissions on the External Storage Backends externalStorageBackendName.
	 * @param externalStorageBackendName Name of the external storage backend which should be updated.
	 */
	updateExternalStorageBackend: MutationOperation<{ pathParams: { externalStorageBackendName: string }, body: ExternalStorageBackendData }, void>

	/**
	 * Validates a branching configuration
	 *
	 * Validates a given branching configuration. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	validateBranchingConfiguration: MutationOperation<{ body: ProjectBranchingConfiguration }, string | null>

}

/** Maps operation IDs to the actual path, method and expected Content-Type. */
export const urlMapping = { 
	autocompleteIssueId: {
		method: 'GET',
		path: '/api/projects/{project}/issues/find'
	},
	autocompleteUserGroup: {
		method: 'GET',
		path: '/api/user-groups/find'
	},
	autocompleteUserName: {
		method: 'GET',
		path: '/api/users/find'
	},
	createAnalysisProfile: {
		method: 'POST',
		path: '/api/analysis-profiles',
		contentType: 'application/json'
	},
	createDashboardFromTemplate: {
		method: 'POST',
		path: '/api/dashboards/from-template'
	},
	createExternalStorageBackend: {
		method: 'POST',
		path: '/api/external-storage-backends',
		contentType: 'application/json'
	},
	deleteAnalysisProfile: {
		method: 'DELETE',
		path: '/api/analysis-profiles/{analysisProfileName}'
	},
	deleteExternalStorageBackend: {
		method: 'DELETE',
		path: '/api/external-storage-backends/{externalStorageBackendName}'
	},
	deleteUserOption: {
		method: 'DELETE',
		path: '/api/users/{user}/options/{optionName}'
	},
	downloadAnalysisProfileDocumentation: {
		method: 'GET',
		path: '/api/analysis-profiles/{analysisProfileName}/documentation'
	},
	editUserOption: {
		method: 'PUT',
		path: '/api/users/{user}/options/{optionName}',
		contentType: 'application/json'
	},
	exportAnalysisProfile: {
		method: 'GET',
		path: '/api/analysis-profiles/{analysisProfileName}/export'
	},
	externalStorageBackendExists: {
		method: 'GET',
		path: '/api/external-storage-backends/{externalStorageBackendName}'
	},
	findInitialCommit: {
		method: 'GET',
		path: '/api/projects/{project}/initial-commit-finder/{unresolvedCommit}'
	},
	findLogEntriesInRange: {
		method: 'GET',
		path: '/api/projects/{project}/repository-log-range'
	},
	flagFindings: {
		method: 'PUT',
		path: '/api/projects/{project}/findings/flagged',
		contentType: 'application/json'
	},
	getAllExternalStorageBackends: {
		method: 'GET',
		path: '/api/external-storage-backends'
	},
	getAllPartitionDetails: {
		method: 'GET',
		path: '/api/projects/{project}/test-executions/partitions/details'
	},
	getAnalysisProfile: {
		method: 'GET',
		path: '/api/analysis-profiles/{analysisProfileName}'
	},
	getAnalysisProfileNames: {
		method: 'GET',
		path: '/api/analysis-profiles'
	},
	getAnalysisProfileUsageInfo: {
		method: 'GET',
		path: '/api/analysis-profiles/with-referenced-projects'
	},
	getAnalysisProfileWithProjects: {
		method: 'GET',
		path: '/api/analysis-profiles/{analysisProfileName}/with-referenced-projects'
	},
	getBranchForSpecItem: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/{id}/branch'
	},
	getBranchesGetRequest: {
		method: 'GET',
		path: '/api/projects/{project}/branches'
	},
	getBranchesPostRequest: {
		method: 'POST',
		path: '/api/projects/{project}/branches'
	},
	getChangeTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/change-treemap'
	},
	getCodeSearchMatchResultAndTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/audit/code-search/match-list-and-treemap'
	},
	getCommitAlerts: {
		method: 'GET',
		path: '/api/projects/{project}/commit-alerts'
	},
	getDeclarationOfAccessibility: {
		method: 'GET',
		path: '/api/accessibility/declaration',
		acceptType: 'text/markdown'
	},
	getDeclarationOfAccessibilityContext: {
		method: 'GET',
		path: '/api/context/accessibility'
	},
	getDefaultBranchNameGetRequest: {
		method: 'GET',
		path: '/api/projects/{project}/default-branch'
	},
	getFindingChurnCount: {
		method: 'GET',
		path: '/api/projects/{project}/finding-churn/count'
	},
	getFlaggedFindingIds: {
		method: 'GET',
		path: '/api/projects/{project}/findings/flagged/ids'
	},
	getFlaggedFindingInfo: {
		method: 'GET',
		path: '/api/projects/{project}/findings/flagged/{finding}'
	},
	getFlaggedFindings: {
		method: 'GET',
		path: '/api/projects/{project}/findings/flagged'
	},
	getFlaggedFindingsInfos: {
		method: 'POST',
		path: '/api/projects/{project}/findings/flagged/with-ids',
		contentType: 'application/json'
	},
	getGlobalBranchesGetRequest: {
		method: 'GET',
		path: '/api/branches'
	},
	getGlobalDefaultBranchGetRequest: {
		method: 'GET',
		path: '/api/branches/default-branch'
	},
	getGlobalRecentBranchesGetRequest: {
		method: 'GET',
		path: '/api/branches/recent-branches'
	},
	getIssuesByRepositoryCommits: {
		method: 'POST',
		path: '/api/projects/{project}/commit-issues',
		contentType: 'application/x-www-form-urlencoded'
	},
	getLastCommitOfType: {
		method: 'GET',
		path: '/api/projects/{project}/repository/last-commit'
	},
	getLinkRoles: {
		method: 'PUT',
		path: '/api/spec-items/default/{accountName}/schema/link-roles',
		contentType: 'application/json'
	},
	getLoginContext: {
		method: 'GET',
		path: '/api/context/login'
	},
	getMergeRequestRepositoryChurn: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests/repository-churn'
	},
	getMethodHistory: {
		method: 'GET',
		path: '/api/projects/{project}/methods/history/{uniformPath}'
	},
	getMetricDirectorySchemaForPath: {
		method: 'GET',
		path: '/api/projects/{project}/metric-schema/{uniformPathType}'
	},
	getProjectAnalysisProgress: {
		method: 'GET',
		path: '/api/projects/{project}/analysis-progress'
	},
	getRecentBranches: {
		method: 'GET',
		path: '/api/projects/{project}/recent-branches'
	},
	getRedundantLiteralTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/redundant-literals/treemap'
	},
	getRedundantLiteralsQuery: {
		method: 'GET',
		path: '/api/projects/{project}/redundant-literals'
	},
	getRepositoryChurn: {
		method: 'GET',
		path: '/api/projects/{project}/repository-churn'
	},
	getResourceHistory: {
		method: 'GET',
		path: '/api/projects/{project}/{uniformPath}/resource-history'
	},
	getSpecItemChurn: {
		method: 'GET',
		path: '/api/projects/{project}/delta/affected-spec-items'
	},
	getStacktrace: {
		method: 'GET',
		path: '/api/projects/{project}/tests/{test}/stacktrace'
	},
	getTestCoverage: {
		method: 'GET',
		path: '/api/projects/{project}/test-coverage/{uniformPath}'
	},
	getTestCoveragePost: {
		method: 'POST',
		path: '/api/projects/{project}/test-coverage/{uniformPath}',
		contentType: 'application/x-www-form-urlencoded'
	},
	importAnalysisProfile: {
		method: 'POST',
		path: '/api/analysis-profiles/import',
		contentType: 'multipart/form-data'
	},
	isSpecItemAnalysisEnabled: {
		method: 'GET',
		path: '/api/projects/{project}/feature/spec-item'
	},
	performLogin: {
		method: 'POST',
		path: '/api/auth/login',
		contentType: 'application/json'
	},
	postCommitAlerts: {
		method: 'POST',
		path: '/api/projects/{project}/commit-alerts',
		contentType: 'application/x-www-form-urlencoded'
	},
	postFindingChurnCount: {
		method: 'POST',
		path: '/api/projects/{project}/finding-churn/count',
		contentType: 'application/x-www-form-urlencoded'
	},
	unmarkToleratedAndFalsePositiveFindingsBeforeCommit: {
		method: 'DELETE',
		path: '/api/projects/{project}/findings/unmark-before-commit'
	},
	updateAnalysisProfile: {
		method: 'PUT',
		path: '/api/analysis-profiles',
		contentType: 'application/json'
	},
	updateExternalStorageBackend: {
		method: 'PUT',
		path: '/api/external-storage-backends/{externalStorageBackendName}',
		contentType: 'application/json'
	},
	validateBranchingConfiguration: {
		method: 'PUT',
		path: '/api/branching-configuration/validation',
		contentType: 'application/json'
	}
} as const;
