import { SafeHtml } from 'ts-closure-library/lib/html/safehtml';
import { linkifyPlainTextAsHtml } from 'ts-closure-library/lib/string/linkify';

/** The message of a commit (repository log entry) in different formats. */
export class FormattedCommitMessage {
	/** Constructor for FormattedCommitMessage. */
	public constructor(
		public readonly messageTitle: string,
		public readonly messageTitleSafeHtml: SafeHtml,
		public readonly messageDetailSafeHtml: SafeHtml | null
	) {}

	/**
	 * Parses the commit message of the entry and returns a new FormattedCommitMessage. NOTE: When possible, use a
	 * {@link CommitRepositoryLogEntry} as wrapper for your log entry instead, which does the parsing automatically.
	 */
	public static fromPlainCommitMessage(commitMessage: string | null | undefined): FormattedCommitMessage {
		if (commitMessage == null) {
			return new FormattedCommitMessage('', SafeHtml.EMPTY, null);
		}
		const messageTitleAndDetailRegex = /^(.+?)\n\n(.+)$/s;
		const match = messageTitleAndDetailRegex.exec(commitMessage);
		if (match !== null) {
			return new FormattedCommitMessage(
				match[1]!,
				linkifyPlainTextAsHtml(match[1]!),
				linkifyPlainTextAsHtml(match[2]!, { preserveNewlines: 'true', preserveSpacesAndTabs: 'true' })
			);
		}
		const trimmedMessage = commitMessage.trim();
		return new FormattedCommitMessage(trimmedMessage, linkifyPlainTextAsHtml(trimmedMessage), null);
	}
}
