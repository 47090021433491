import { useContext } from 'react';
import type { TeamscaleServiceClient } from 'ts/base/client/TeamscaleServiceClient';
import { TeamscaleServiceClientContext } from 'ts/base/context/TeamscaleServiceClientContext';

/**
 * Hook that provides a TeamscaleServiceClient that handles errors with a default ErrorFallback inside the react
 * component.
 *
 * @deprecated Use QUERY or MUTATION instead (see Confluence blog post or 'src/api' folder)
 */
export function useTeamscaleServiceClient(): TeamscaleServiceClient {
	return useContext(TeamscaleServiceClientContext)!;
}
