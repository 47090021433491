import * as TeamscaleCodePerspectiveTemplate from 'soy/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy.generated';
import * as asserts from 'ts-closure-library/lib/asserts/asserts';
import { assertIsHtmlCanvasElement } from 'ts-closure-library/lib/asserts/dom';
import * as dom from 'ts-closure-library/lib/dom/dom';
import { TagName } from 'ts-closure-library/lib/dom/tagname';
import type { BrowserEvent } from 'ts-closure-library/lib/events/browserevent';
import * as events from 'ts-closure-library/lib/events/eventhandler';
import { EventType } from 'ts-closure-library/lib/events/eventtype';
import * as style from 'ts-closure-library/lib/style/style';
import * as soy from 'ts/base/soy/SoyRenderer';

/**
 * Wraps some code into a container that fills either (1) the entire remaining height of the screen, or (2) up to a
 * given height with blank space.
 *
 * To do so it sets the
 *
 * # FLEX_CODE_CONTAINER_WRAPPER_CLASS class on the parent which makes the parent a flex box host
 *
 * With vertical (column) direction. The container itself (#FLEX_CODE_CONTAINER_CLASS) dynamically takes up all the
 * remaining space (also when a resize occurs) and is itself a horizontally oriented (row) flex box layout. Inside the
 * container is a
 *
 * # SCROLL_CONTAINER_CLASS that allows code to scroll in both directions. The row layout allows the code
 *
 * Outline to stay attached to the right of the code's scroll container regardless of its visibility.
 */
export class FlexCodeContainer {
	/** The CSS class of the parent of the flex code container. */
	public static FLEX_CODE_CONTAINER_WRAPPER_CLASS = 'ts-code-container-wrapper';

	/** The CSS class of the flex code container. */
	public static FLEX_CODE_CONTAINER_CLASS = 'ts-flex-code-container';

	/** The CSS class of the scroll code container. */
	public static SCROLL_CONTAINER_CLASS = 'ts-code-scroll-container';

	/** Code container that hosts the scrolling code container and the outline bar. */
	private readonly containerElement: Element;

	/** The scrolling container. */
	private readonly scrollContainer: HTMLElement;

	/** The outline bar. */
	private readonly outlineBar: HTMLCanvasElement;

	/** The outline bar. */
	private readonly codeElement: Element;

	/**
	 * @param contentElement The content that should be wrapped. Must not have been inserted into the DOM yet.
	 * @param maxHeight Optional predefined height. If this is not given, the entire remaining screen is filled.
	 * @param minHeight Optional predefined height. If this is not given, the minimum height is set to zero.
	 */
	public constructor(contentElement: Element, maxHeight?: number | null, minHeight?: string | null) {
		this.containerElement = soy.renderAsElement(TeamscaleCodePerspectiveTemplate.codeContainer, {
			heightLimit: maxHeight,
			minHeight
		});
		this.scrollContainer = asserts.assertElement(
			dom.getElementByClass(FlexCodeContainer.SCROLL_CONTAINER_CLASS, this.containerElement)
		) as HTMLElement;
		dom.setFocusableTabIndex(this.scrollContainer, true);
		this.outlineBar = assertIsHtmlCanvasElement(
			dom.getElementsByTagName(TagName.CANVAS, this.containerElement)[0]!
		);
		events.listen(this.outlineBar, EventType.CLICK, event => this.outlineBarClicked(event));
		this.codeElement = contentElement;
		this.scrollContainer.appendChild(contentElement);
	}

	/**
	 * Handler for the click event on the outline bar to scroll to the corresponding position in the code.
	 *
	 * @param event The ui event
	 */
	public outlineBarClicked(event: BrowserEvent): void {
		const bounds = style.getBounds(this.containerElement);
		const outlineHeight = bounds.height;
		this.scrollToPercentage(event.offsetY / outlineHeight);
	}

	/**
	 * Appends the scrolling container to the given parent element. It also adds the
	 *
	 * # FLEX_CODE_CONTAINER_WRAPPER_CLASS to all parent classes including main to get the desired
	 *
	 * Height wrapping.
	 *
	 * @param parentElement The element to append the container to.
	 * @param dontUseRecursiveParentFlexBox If set to <code>true</code>, this method will _not_ set the {@link
	 *
	 *   # FLEX_CODE_CONTAINER_WRAPPER_CLASS} class recursively to all parents of the given element. This can
	 *
	 *   Be useful for fixed layouts. The default behavior will set the classes.
	 */
	public appendTo(
		parentElement: Element,
		dontUseParentFlexBox?: boolean,
		dontUseRecursiveParentFlexBox?: boolean
	): void {
		parentElement.appendChild(this.containerElement);
		if (!dontUseParentFlexBox) {
			parentElement.classList.toggle(FlexCodeContainer.FLEX_CODE_CONTAINER_WRAPPER_CLASS, true);
		}
		if (!dontUseRecursiveParentFlexBox) {
			while (parentElement.getAttribute('id') !== 'main') {
				parentElement = asserts.assertElement(parentElement.parentElement);
				parentElement.classList.toggle(FlexCodeContainer.FLEX_CODE_CONTAINER_WRAPPER_CLASS, true);
			}
		}
	}

	/**
	 * Scrolls the embedded code container to the given position.
	 *
	 * @param position The position relative to the whole content.
	 */
	public scrollToPercentage(position: number): void {
		const overallHeight = this.getCodeElement().clientHeight;
		const outlineHeight = style.getBounds(this.scrollContainer).height;
		const targetPosition = position * overallHeight;
		this.scrollContainer.scrollTop = Math.max(0, targetPosition - outlineHeight / 2);
	}

	/** Returns the container element representing the root of the code container. */
	public getContainerElement(): Element {
		return this.containerElement;
	}

	/** Returns the scroll container element. */
	public getScrollContainerElement(): HTMLElement {
		return this.scrollContainer;
	}

	/** Returns the code element. */
	public getCodeElement(): Element {
		return this.codeElement;
	}

	/**
	 * Creates a new outline bar element.
	 *
	 * @returns A new outline bar.
	 */
	public getOutlineBar(): HTMLCanvasElement {
		return this.outlineBar;
	}
}
