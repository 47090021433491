import * as UIUtilsTemplate from 'soy/commons/UIUtilsTemplate.soy.generated';
import * as dom from 'ts-closure-library/lib/dom/dom';
import * as soy from 'ts/base/soy/SoyRenderer';
import { tsdom } from 'ts/commons/tsdom';

/**
 * A progress indicator to show for long-running actions. This class is a singleton, since it does not make sense to
 * show more than one progress bar at a time.
 */
export class PerspectiveProgress {
	/** The singleton spinner instance. */
	public static INSTANCE: PerspectiveProgress = new PerspectiveProgress();

	/** A reference counter. If it drops to 0, the spinner is hidden. */
	private userCount = 0;

	/**
	 * Shows the progress bar underneath the navbar (if found). If another progress bar is already shown, does nothing.
	 *
	 * NOTE: #stop() has to be called at least as often as this method or the progress bar will stay on screen.
	 */
	public start(): void {
		const perspectiveSettingsBar = tsdom.findElementByClass('perspective-settings-bar');

		// Some perspective might not use the root layout, so we check if there is
		// actually a settings bar in the page.
		if (this.isVisible() || perspectiveSettingsBar == null) {
			return;
		}
		const progressElement = soy.renderAsElement(UIUtilsTemplate.perspectiveProgress);
		dom.insertChildAt(perspectiveSettingsBar, progressElement, 0);
		this.userCount++;
	}

	/** Removes the progress element (if found), and resets the user count. */
	public dispose(): void {
		const progress = PerspectiveProgress.getProgressElement();
		if (progress != null) {
			tsdom.removeNode(progress);
		}
		this.userCount = 0;
	}

	private static getProgressElement(): Element | null {
		return document.getElementById('perspective-progress');
	}

	/**
	 * Hides the progress bar.
	 *
	 * NOTE: This method may be called more often than #start() without producing any side-effects.
	 */
	public stop(): void {
		if (this.userCount > 0) {
			this.userCount--;
		}
		if (this.userCount === 0) {
			this.dispose();
		}
	}

	/** Returns true, if the spinner is shown. */
	public isVisible(): boolean {
		return PerspectiveProgress.getProgressElement() != null;
	}
}
