/* eslint-disable react-hooks/rules-of-hooks */
import type { Location } from 'history';
// We can disable this rule here as a component is always either in the router context or it is not, so the
// call order of the hooks will stay the same over the lifetime of a component.
import React, { useContext, useLayoutEffect, useReducer, useRef } from 'react';
import { useInRouterContext, useLocation as useReactRouterLocation } from 'react-router-dom';
import {
	EHashReloadBehavior,
	NavigationHashReloadBehaviorContext
} from 'ts/base/context/NavigationHashReloadBehaviorContext';
import { HISTORY } from 'ts/base/routing/PerspectiveHashRouter';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { ObjectUtils } from 'ts/commons/ObjectUtils';

/**
 * Returns the artificial location of the current page. It works both in the top level React tree that has a router
 * context as well as in React trees that are rendered in a subview. When used in the top level tree a change of the
 * location will also trigger a re-render of the component.
 */
export function useLocation(): Location {
	const routerContext = useInRouterContext();
	const hashBehavior = useContext(NavigationHashReloadBehaviorContext);
	const mounted = React.useRef(false);
	if (routerContext && hashBehavior === EHashReloadBehavior.RELOAD_ALL) {
		// The useLocation hook only works in the context of React router which is only available in the top level tree ATM.
		// As we want the component to rerender when the location changes we still want to use useLocation instead of
		// window.location.
		return useReactRouterLocation();
	} else {
		//  For components that are not directly mounted below the top-level router we manually listen for the history events.
		const lastLocation = useRef<Location>(HISTORY.location);
		const [, rerender] = useReducer(state => state + 1, 0);
		useLayoutEffect(() => {
			mounted.current = true;
			const unlisten = HISTORY.listen(({ location: newLocation, action }) => {
				const isSilentUrlChange =
					newLocation.state === NavigationHash.DO_NOT_RELOAD_VIEW_STATE && action === 'REPLACE';
				const locationHasChanged = !ObjectUtils.deepEqual(lastLocation.current, newLocation);
				const shouldIgnoreHashChange =
					(hashBehavior === EHashReloadBehavior.RELOAD_IGNORE_SILENTLY_APPLIED_CHANGES &&
						isSilentUrlChange) ||
					(hashBehavior === EHashReloadBehavior.RELOAD_ALL && !isSilentUrlChange);
				if (mounted.current && locationHasChanged && !shouldIgnoreHashChange) {
					lastLocation.current = newLocation;
					rerender();
				}
			});
			return () => {
				mounted.current = false;
				unlisten();
			};
		}, [hashBehavior]);
		return lastLocation.current;
	}
}
