import type { ServiceCallError } from 'api/ServiceCallError';
import type { ErrorHandler } from 'ts/base/client/ServiceClient';
import { PerspectiveProgress } from 'ts/commons/PerspectiveProgress';
import { ToastNotification } from 'ts/commons/ToastNotification';

/** This component handles error management functions for UI pages during communication between UI and Teamscale backend. */
export class ErrorManager {
	/**
	 * Collects errors encountered while UI page is loading. This is necessary to keep multiple errors so that when the
	 * page is fully loaded, they can be reported to the user.
	 */
	private readonly errors: ServiceCallError[] = [];

	/** The maximal number of errors to show to the user. We also use this as a counter by decrementing to 0. */
	private maxErrorsShown = 5;

	/**
	 * Stores the number of times the back-end server tried to be reached but could not. This could result from
	 * internet/connection down/lost, or the server has died. Once the stored value reaches maxTimesServerUnreachable,
	 * perspectives/views that utilize periodic service calls can stop it.
	 */
	private numberOfTimesServerUnreachable = 0;

	/** Whether the warning about unreachable Teamscale server is currently shown to the user. */
	private unreachableServerWarningShown = false;

	/** The maximum number of times to allow trying to make service calls when the server cannot be reached. */
	private maxTimesServerUnreachable = 1;

	/** @param errorHandler A function which clients can use to plug into the error handling method of this manager. */
	public constructor(private readonly errorHandler: ErrorHandler) {}

	/** Handler in case the server is unreachable. */
	public unreachableServerErrorHandler(): void {
		if (!this.unreachableServerWarningShown) {
			this.unreachableServerWarningShown = true;
			ToastNotification.showUnreachableServerWarning();
			setTimeout(() => {
				this.unreachableServerWarningShown = false;
				ToastNotification.clearAll();
			}, 50000);
		}
	}

	/** Indicates whether newly occurred errors should be shown (because we have space left to show them). */
	private isSpaceLeftToShowNewErrors(): boolean {
		return this.maxErrorsShown > 0;
	}

	/**
	 * Keeps stock of the errors encountered so UI perspectives and views can display them. Also allows them to
	 * contribute to error handling activity.
	 *
	 * @deprecated This method can cause a white page with just the error message, which is bad user experience.
	 *   Consider showing a targeted error message instead.
	 */
	public handleError(error: ServiceCallError): void {
		PerspectiveProgress.INSTANCE.stop();
		if (this.isSpaceLeftToShowNewErrors() || this.errors.length === 0) {
			// When views or perspectives don't provide a next call error handler, we need keep a
			// limit to how many errors TeamscalePerspectiveBase.js shows on the UI.
			this.maxErrorsShown -= 1;
			this.errors.push(error);
		}
		if (error.statusCode === 0) {
			// Status 0 means Teamscale server is unreachable from
			// an unknown error probably a timeout.
			this.numberOfTimesServerUnreachable += 1;
			if (this.isServerUnreachable()) {
				this.unreachableServerErrorHandler();
			}
		}
		this.errorHandler(error);
	}

	/**
	 * Returns true if a Teamscale server is unreachable after maxTimesServerUnreachable previous tries to communicate
	 * failed. Unreachability might mean internet connection /VPN down or server process died. Callers use this to stop
	 * making further calls and avoid flooding the UI with errors.
	 */
	private isServerUnreachable(): boolean {
		return this.numberOfTimesServerUnreachable >= this.maxTimesServerUnreachable;
	}

	/**
	 * Sets the error handler for unreachable Teamscale server.
	 *
	 * @param handler Error handling function for unreachable server.
	 */
	public setUnreachableServerErrorHandler(handler: () => void): void {
		this.unreachableServerErrorHandler = handler;
	}

	/**
	 * Sets maximum number of times to allow trying to make service calls when the server cannot be reached.
	 *
	 * @param maxTimes Desired number of times to allow trying to make service calls when the server cannot be reached.
	 */
	public setMaxTimesServerUnreachable(maxTimes: number): void {
		this.maxTimesServerUnreachable = maxTimes;
	}
}
