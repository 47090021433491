import { HttpStatus } from 'api/HttpStatus';
import { ServiceCallError } from 'api/ServiceCallError';
import * as PerspectiveBaseTemplate from 'soy/base/scaffolding/PerspectiveBaseTemplate.soy.generated';
import * as dom from 'ts-closure-library/lib/dom/dom';
import * as events from 'ts-closure-library/lib/events/eventhandler';
import { EventType } from 'ts-closure-library/lib/events/eventtype';
import type { Storage } from 'ts-closure-library/lib/storage/storage';
import * as style from 'ts-closure-library/lib/style/style';
import { AnimatedZippy } from 'ts-closure-library/lib/ui/animatedzippy';
import type { ZippyEvent } from 'ts-closure-library/lib/ui/zippy';
import { Events, Zippy as GoogZippy } from 'ts-closure-library/lib/ui/zippy';
import type { Callback } from 'ts/base/Callback';
import * as soy from 'ts/base/soy/SoyRenderer';
import { UIUtils } from './UIUtils';

/** The HTML elements belonging to a Zippy. */
type ZippyElements = { zippyElement: Element; zippyParentElement: Element; expandIcon: Element; expandButton: Element };

/** Class that contains zippy element construction */
export class Zippy {
	/** Class attribute that zippy element gets after it is completely expanded */
	private static readonly EXPANDED_STAGE = 'ts-zippy-expanded';

	/** Class attribute that zippy element gets during its animation */
	private static readonly ANIMATING_STAGE = 'ts-zippy-animating';

	/** Class attribute that zippy element gets after it is completely collapsed */
	private static readonly COLLAPSED_STAGE = 'ts-zippy-collapsed';

	/** Class attribute that expand icon gets after the user clicked to expand the zippy element */
	private static readonly EXPAND_UP_ICON = 'grey angle up icon';

	/** Class attribute that expand icon gets after the user clicked to collapsed the zippy element */
	private static readonly EXPAND_DOWN_ICON = 'grey angle down icon';

	/** CSS overflow attributes that zippy element gets during its animation */
	private static readonly OVERFLOW_ANIMATION = { overflowX: 'auto', overflowY: 'hidden' };

	/** CSS overflow attribute that zippy element gets after its completely expanded/collapsed */
	private static readonly OVERFLOW_NO_ANIMATION = { overflow: '' };

	/** The animation duration for expanding/collapsing the element, in milliseconds. */
	private static readonly TOGGLE_EXPANSION_ANIMATION_DURATION = 200;

	/**
	 * A static method for rendering server-side errors on the UI after which toggling functionality for displaying more
	 * details of the error may be prepared. A short summary of it is first shown. This is helpful to avoid 'flooding'
	 * the user with technical details. Provision is made on UI for viewing the technical description.
	 *
	 * @param status A HTTP Status integer code.
	 * @param statusText Some status text.
	 * @param technicalDescription Some text providing stack-trace information.
	 * @param callback A callback function to be executed just before preparing the error details toggler.
	 *   Function(renderedElement).
	 *
	 *   In React use ts/base/components/Zippy
	 */
	public static renderErrorAndPrepareToggler(error: Error, callback: Callback<Element>): void {
		let status = 0;
		let technicalDescription = '';
		if (error instanceof ServiceCallError) {
			status = error.statusCode;
			technicalDescription = error.technicalDetails;
		}
		const errorObj = {
			status,
			statusText: error.message,
			technicalErrorDescription: technicalDescription,
			technicalErrorSummary: undefined as undefined | string,
			timeMilliSecs: undefined as undefined | number
		};
		const addTechnicalDetails = status !== HttpStatus.NOT_FOUND && status !== HttpStatus.FORBIDDEN;
		if (addTechnicalDetails && error instanceof ServiceCallError) {
			errorObj.technicalErrorSummary = error.errorSummary;

			// We need the time to identify each error element for toggling feature
			errorObj.timeMilliSecs = new Date().getTime();
		}
		const renderedElement = soy.renderAsElement(PerspectiveBaseTemplate.error, errorObj);
		callback(renderedElement);
		if (addTechnicalDetails) {
			const toggler = dom.getElementByClass('error-toggler', renderedElement)!;
			const icon = dom.getFirstElementChild(toggler);
			const errorBody = dom.getNextElementSibling(toggler);
			if (document.getElementById(toggler.id) != null) {
				// This condition is not satisfied if the callback did not attach the renderedElement on the page
				// (e.g., on service errors during preloadContent).
				Zippy.hookZippy(errorBody!.id, toggler.id, icon!.id, '', false);
			}
		}
	}

	/**
	 * Hooks and creates zippy animations
	 *
	 * @param zippyElementId The CSS ID of zippy element that is being expanded/collapsed
	 * @param expandElementId The CSS ID of element that will be listened for click/toggle events.
	 * @param expandIconId The CSS ID of icon that must be clicked to expand/collapse the zippy element
	 * @param storageId The ID under which to store the zippy's state (needs to be Teamscale globally unique).
	 * @param expandedDefault Specifies the initial expanded state of the content, if no entry in the localstore is
	 *   found. Otherwise the value from the localstore is found.
	 * @param callback Callback which will be called after the handler is done. Parameter for the callback is whether
	 *   the content is visible now.
	 * @param noAnimation Optional parameter to determine if animated zippy should be used. By default & if this
	 *   parameter is omitted, animation is used.
	 * @returns Widget contains expandable/collapsible container.
	 */
	public static hookZippy(
		zippyElementId: string,
		expandElementId: string,
		expandIconId: string,
		storageId: string,
		expandedDefault: boolean,
		callback?: Callback<boolean>,
		noAnimation?: boolean
	): GoogZippy | AnimatedZippy {
		const localStorage = UIUtils.getLocalStorage();
		let isExpanded = Zippy.isExpanded(localStorage, storageId, expandedDefault);
		const zippy = Zippy.getZippy(zippyElementId, isExpanded, noAnimation);
		const uiElements = Zippy.getUiElements(zippyElementId, expandElementId, expandIconId);
		Zippy.setZippyStages(uiElements, isExpanded, false, callback);
		events.listen(
			uiElements.expandButton,
			EventType.CLICK,
			UIUtils.preventDefaultEventAction(() => {
				if (noAnimation === undefined && (zippy as AnimatedZippy).isBusy()) {
					// Wait till animation is over
					return;
				}
				isExpanded = zippy.isExpanded();
				zippy.setExpanded(!isExpanded);
				localStorage.set(storageId, !isExpanded);
				Zippy.setZippyStages(uiElements, !isExpanded, true, callback);
			})
		);
		events.listen<unknown, ZippyEvent>(zippy, Events.TOGGLE, e => {
			Zippy.setZippyStages(uiElements, e.expanded, false, callback);
		});
		return zippy;
	}

	/**
	 * Gets UI Elements ID's as a parameter and returns actual UI Elements
	 *
	 * @param zippyElementId The CSS ID of zippy element that is being expanded/collapsed
	 * @param expandElementId The CSS ID of element that will be listened for click/toggle events.
	 * @param expandIconId The CSS ID of icon that must be clicked to expand/collapse the zippy element
	 * @returns Contains all UI elements that will be using for changing their attributes
	 */
	private static getUiElements(zippyElementId: string, expandElementId: string, expandIconId: string): ZippyElements {
		return {
			zippyElement: document.getElementById(zippyElementId)!,
			zippyParentElement: document.getElementById(zippyElementId)!.parentElement!,
			expandButton: document.getElementById(expandElementId)!,
			expandIcon: document.getElementById(expandIconId)!
		};
	}

	/**
	 * Returns zippy widget that is used for listening click and toggle events
	 *
	 * @param zippyElementId The CSS ID of zippy element that is being expanded/collapsed
	 * @param isExpanded Indicates whether the zippy element is completely expanded/collapsed
	 * @param noAnimation Optional parameter to determine if animated zippy should be used. By default & if this
	 *   parameter is omitted, animation is used.
	 * @returns Widget contains expandable/collapsible container.
	 */
	private static getZippy(
		zippyElementId: string,
		isExpanded: boolean,
		noAnimation?: boolean
	): GoogZippy | AnimatedZippy {
		const zippyElement = document.getElementById(zippyElementId);
		if (noAnimation !== undefined) {
			return new GoogZippy(null, zippyElement, isExpanded);
		}
		const animatedZippy = new AnimatedZippy(null, zippyElement, isExpanded);
		animatedZippy.animationDuration = Zippy.TOGGLE_EXPANSION_ANIMATION_DURATION;
		return animatedZippy;
	}

	/**
	 * Returns the zippy element's status of expanded/collapsed
	 *
	 * @param localStorage The local storage
	 * @param storageId The ID which to store the zippy's state (needs to be Teamscale globally unique).
	 * @param expandedDefault Optional parameter to determine if animated zippy should be used. By default & if this
	 *   parameter is omitted, animation is used.
	 */
	private static isExpanded(localStorage: Storage, storageId: string, expandedDefault?: boolean): boolean {
		let isExpanded = localStorage.get(storageId);
		if (isExpanded === undefined) {
			isExpanded = expandedDefault;
		}
		return isExpanded;
	}

	/**
	 * Sets the attributes of both hidden element and expand icon. They are set based on the expanding and animating
	 * stage of the hidden element
	 *
	 * @param uiElements Contains all UI elements that will be using for changing their attributes
	 * @param isExpanded Indicates whether the zippy element is completely expanded/collapsed
	 * @param isAnimating Indicates whether the zippy element is being animating
	 * @param callback Callback which will be called after the handler is done. Parameter for the callback is whether
	 *   the content is visible now.
	 */
	private static setZippyStages(
		uiElements: ZippyElements,
		isExpanded: boolean,
		isAnimating: boolean,
		callback?: Callback<boolean>
	): void {
		callback?.(isExpanded);
		uiElements.zippyElement.classList.remove(Zippy.ANIMATING_STAGE);
		style.setStyle(uiElements.zippyParentElement, Zippy.OVERFLOW_NO_ANIMATION);
		if (isExpanded) {
			uiElements.zippyElement.classList.add(Zippy.EXPANDED_STAGE);
			uiElements.expandIcon.className = Zippy.EXPAND_UP_ICON;
		} else {
			uiElements.zippyElement.classList.add(Zippy.COLLAPSED_STAGE);
			uiElements.expandIcon.className = Zippy.EXPAND_DOWN_ICON;
		}
		if (isAnimating) {
			uiElements.zippyElement.classList.remove(Zippy.EXPANDED_STAGE);
			uiElements.zippyElement.classList.remove(Zippy.COLLAPSED_STAGE);
			uiElements.zippyElement.classList.add(Zippy.ANIMATING_STAGE);
			style.setStyle(uiElements.zippyParentElement, Zippy.OVERFLOW_ANIMATION);
			if (isExpanded) {
				uiElements.expandIcon.className = Zippy.EXPAND_UP_ICON;
			} else {
				uiElements.expandIcon.className = Zippy.EXPAND_DOWN_ICON;
			}
		}
	}
}
