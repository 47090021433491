import clsx from 'clsx';
import type { ReactNode } from 'react';
import { useRef, useState } from 'react';
import type { Callback } from 'ts/base/Callback';
import styles from './Zippy.module.css';

/** Props for Zippy components. */
export type ZippyProps = Omit<JSX.IntrinsicElements['div'], 'style'> & {
	/** The header of the Zippy */
	header: ReactNode;
	/** Optional buttons for the Zippy header. Clicking on these buttons doesn't toggle expanding the Zippy. */
	buttons?: ReactNode;
	/** The body of the Zippy that can be expanded and collapsed. */
	children: ReactNode;
	forceExpanded?: boolean;
	/** Whether the zippy should initially be in expanded state. */
	defaultExpanded?: boolean;
	/**
	 * The key under which the current Zippy toggle position is persisted in the local storage. If none is given nothing
	 * is persisted.
	 */
	storageKey?: string;

	compact?: boolean;
};

/** A collapse/expandable component that provides a header and the collapse body. */
export function Zippy({
	compact = false,
	storageKey,
	children,
	header,
	buttons,
	forceExpanded,
	defaultExpanded = false,
	className,
	...sectionProps
}: ZippyProps): JSX.Element {
	const contentElement = useRef(null);
	const [isExpanded, setExpanded] = useOptionallyPersistedState(storageKey, defaultExpanded, forceExpanded);

	const onToggle = () => setExpanded(!isExpanded);
	return (
		<>
			<div
				tabIndex={0}
				role="button"
				onClick={onToggle}
				onKeyDown={e => {
					if (e.code === 'Enter' || e.code === 'Space') {
						onToggle();
					}
				}}
				className={clsx(styles.collapsible, { [styles.expanded!]: isExpanded, [styles.compact!]: compact })}
			>
				{header}
				{buttons != null ? (
					<div onClick={e => e.stopPropagation()} className={styles.zippyButtons}>
						{buttons}
					</div>
				) : null}
			</div>
			<div
				{...sectionProps}
				className={clsx(className, styles.zippyContent)}
				style={{ maxHeight: isExpanded ? 'inherit' : '' }}
				ref={contentElement}
			>
				{children}
			</div>
		</>
	);
}

/** Uses local state if storageKey is undefined and local storage otherwise. */
function useOptionallyPersistedState<T>(
	storageKey: string | undefined,
	initialValue: T,
	forcedInitialValue?: T
): [T, Callback<T>] {
	const [isExpanded, setExpanded] = useState(() => {
		if (forcedInitialValue !== undefined) {
			return forcedInitialValue;
		}
		if (storageKey !== undefined) {
			try {
				const item = window.localStorage.getItem(storageKey);
				// Parse stored json or if none return initialValue
				if (item) {
					return JSON.parse(item);
				} else {
					return initialValue;
				}
			} catch (error) {
				console.error(error);
				return initialValue;
			}
		}
		return initialValue;
	});
	const setExpandedStore = (state: T) => {
		if (storageKey !== undefined) {
			window.localStorage.setItem(storageKey, JSON.stringify(state));
		}
		setExpanded(state);
	};
	return [isExpanded, setExpandedStore];
}
