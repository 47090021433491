// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/perspectives/user/TeamscaleUserPerspectiveTemplate.soy

/**
 * @fileoverview Templates in namespace ts.perspectives.user.TeamscaleUserPerspectiveTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';
import * as $soy$ts$commons$LinkTemplate from './../../commons/LinkTemplate.soy.generated.js';
import * as $soy$ts$commons$UIUtilsTemplate from './../../commons/UIUtilsTemplate.soy.generated.js';


/**
 * @param {!$avatarSource.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $avatarSource = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $avatarSource$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.username, opt_data.gravatarHash, opt_data.size);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} username
 * @param {?=} gravatarHash
 * @param {null|string|undefined=} size
 * @return {string}
 * @suppress {checkTypes}
 */
const $avatarSource$ = function($$areYouAnInternalCaller, $ijData, username, gravatarHash, size) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.user.TeamscaleUserPerspectiveTemplate.avatarSource']) {
    return soy.$$stubsMap['ts.perspectives.user.TeamscaleUserPerspectiveTemplate.avatarSource']({username: username, gravatarHash: gravatarHash, size: size}, $ijData);
  }
  soy.assertParamType(size == null || typeof size === 'string', 'size', size, '@param', 'null|string|undefined');
  let $output = '';
  if (gravatarHash) {
    let translatedSize__soy18784 = '' + $avatarSizeTranslation$(soy.$$internalCallMarkerDoNotUse, $ijData, size);
    $output += 'https://secure.gravatar.com/avatar/' + soy.$$escapeUri(gravatarHash) + '?size=' + soy.$$escapeUri(translatedSize__soy18784);
  } else {
    $output += 'api/avatars/' + (username ? soy.$$escapeUri(username) : 'unknown') + '/image?size=' + soy.$$escapeUri(size);
  }
  return $output;
};
export { $avatarSource as avatarSource };
export { $avatarSource$ as avatarSource$ };
/**
 * @typedef {{
 *  username: ?,
 *  gravatarHash?: (?|undefined),
 *  size?: (null|string|undefined),
 * }}
 */
$avatarSource.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $avatarSource.soyTemplateName = 'ts.perspectives.user.TeamscaleUserPerspectiveTemplate.avatarSource';
}


/**
 * @param {?$avatarSizeTranslation.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $avatarSizeTranslation = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $avatarSizeTranslation$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.sizeName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {null|string|undefined=} sizeName
 * @return {string}
 * @suppress {checkTypes}
 */
const $avatarSizeTranslation$ = function($$areYouAnInternalCaller, $ijData, sizeName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.user.TeamscaleUserPerspectiveTemplate.avatarSizeTranslation']) {
    return soy.$$stubsMap['ts.perspectives.user.TeamscaleUserPerspectiveTemplate.avatarSizeTranslation']({sizeName: sizeName}, $ijData);
  }
  soy.assertParamType(sizeName == null || typeof sizeName === 'string', 'sizeName', sizeName, '@param', 'null|string|undefined');
  let $output = '';
  const $tmp = sizeName;
  switch (google.isObject($tmp) ? $tmp.toString() : $tmp) {
    case 'tiny':
      $output += '50';
      break;
    case 'small':
      $output += '100';
      break;
    case 'medium':
      $output += '200';
      break;
    case 'large':
      $output += '300';
      break;
    default:
      $output += '200';
  }
  return $output;
};
export { $avatarSizeTranslation as avatarSizeTranslation };
export { $avatarSizeTranslation$ as avatarSizeTranslation$ };
/**
 * @typedef {{
 *  sizeName?: (null|string|undefined),
 * }}
 */
$avatarSizeTranslation.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $avatarSizeTranslation.soyTemplateName = 'ts.perspectives.user.TeamscaleUserPerspectiveTemplate.avatarSizeTranslation';
}


/**
 * @param {?$avatarForUsername.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $avatarForUsername = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $avatarForUsername$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.username, opt_data.user, opt_data.size);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {null|string|undefined=} username
 * @param {?=} user
 * @param {null|string|undefined=} size
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $avatarForUsername$ = function($$areYouAnInternalCaller, $ijData, username, user, size) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.user.TeamscaleUserPerspectiveTemplate.avatarForUsername']) {
    return soy.$$stubsMap['ts.perspectives.user.TeamscaleUserPerspectiveTemplate.avatarForUsername']({username: username, user: user, size: size}, $ijData);
  }
  soy.assertParamType(username == null || typeof username === 'string', 'username', username, '@param', 'null|string|undefined');
  soy.assertParamType(size == null || typeof size === 'string', 'size', size, '@param', 'null|string|undefined');
  let $output = '';
  const theUsername__soy18817 = user ? user.username : username;
  let src__soy18818 = '' + $avatarSource$(soy.$$internalCallMarkerDoNotUse, $ijData, theUsername__soy18817, soy.$$coerceToBoolean(user) && soy.$$coerceToBoolean(user[/** @type {?} */ ('useGravatar')]) ? user[/** @type {?} */ ('gravatarHash')] : null, size);
  $output += $soy$ts$commons$UIUtilsTemplate.wrapInLink$(soy.$$internalCallMarkerDoNotUse, $ijData, soy.VERY_UNSAFE.$$ordainSanitizedHtmlForInternalBlocks('<img title="' + (user ? soy.$$escapeHtmlAttribute(user[/** @type {?} */ ('firstName')]) + ' ' + soy.$$escapeHtmlAttribute(user[/** @type {?} */ ('lastName')]) : soy.$$escapeHtmlAttribute(username)) + '" src="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeMediaUri(src__soy18818)) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.user.TeamscaleUserPerspectiveTemplate.avatarForUsername ts/perspectives/user/TeamscaleUserPerspectiveTemplate.soy:91"' : '') + '>'), username != null && user != null, soy.VERY_UNSAFE.$$ordainSanitizedAttributesForInternalBlocks('href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.showUser$(soy.$$internalCallMarkerDoNotUse, $ijData, theUsername__soy18817))) + '"'));
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $avatarForUsername as avatarForUsername };
export { $avatarForUsername$ as avatarForUsername$ };
/**
 * @typedef {{
 *  username?: (null|string|undefined),
 *  user?: (?|undefined),
 *  size?: (null|string|undefined),
 * }}
 */
$avatarForUsername.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $avatarForUsername.soyTemplateName = 'ts.perspectives.user.TeamscaleUserPerspectiveTemplate.avatarForUsername';
}


/**
 * @param {!$roundAvatar.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $roundAvatar = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $roundAvatar$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.username, opt_data.user, opt_data.size, opt_data.hideUserName, opt_data.prefixForTooltip, opt_data.dontLinkToUser);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} username
 * @param {?=} user
 * @param {null|string|undefined=} size
 * @param {boolean|null|undefined=} hideUserName
 * @param {null|string|undefined=} prefixForTooltip
 * @param {boolean|null|undefined=} dontLinkToUser
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $roundAvatar$ = function($$areYouAnInternalCaller, $ijData, username, user, size, hideUserName, prefixForTooltip, dontLinkToUser) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.user.TeamscaleUserPerspectiveTemplate.roundAvatar']) {
    return soy.$$stubsMap['ts.perspectives.user.TeamscaleUserPerspectiveTemplate.roundAvatar']({username: username, user: user, size: size, hideUserName: hideUserName, prefixForTooltip: prefixForTooltip, dontLinkToUser: dontLinkToUser}, $ijData);
  }
  soy.assertParamType(typeof username === 'string', 'username', username, '@param', 'string');
  soy.assertParamType(size == null || typeof size === 'string', 'size', size, '@param', 'null|string|undefined');
  soy.assertParamType(hideUserName == null || typeof hideUserName === 'boolean', 'hideUserName', hideUserName, '@param', 'boolean|null|undefined');
  soy.assertParamType(prefixForTooltip == null || typeof prefixForTooltip === 'string', 'prefixForTooltip', prefixForTooltip, '@param', 'null|string|undefined');
  soy.assertParamType(dontLinkToUser == null || typeof dontLinkToUser === 'boolean', 'dontLinkToUser', dontLinkToUser, '@param', 'boolean|null|undefined');
  let $output = '';
  const canLinkToUser__soy18849 = soy.$$coerceToBoolean(username) && soy.$$coerceToBoolean(user) && !soy.$$equals(dontLinkToUser, true);
  let $tmp;
  if (soy.$$coerceToBoolean(user) && (!soy.$$equals(user.firstName, '') || !soy.$$equals(user.lastName, ''))) {
    $tmp = soy.$$escapeHtml(user.firstName) + ' ' + soy.$$escapeHtml(user.lastName);
  } else if (soy.$$coerceToBoolean(username) && username != '') {
    $tmp = ' ' + soy.$$escapeHtml(username);
  } else {
    $tmp = ' <i' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.user.TeamscaleUserPerspectiveTemplate.roundAvatar ts/perspectives/user/TeamscaleUserPerspectiveTemplate.soy:123"' : '') + '>Unknown user</i>';
  }
  let visibleUserNameHtml__soy18850 = '' + $tmp;
  const visibleUserNameHtml__wrapped18850 = soy.VERY_UNSAFE.$$ordainSanitizedHtmlForInternalBlocks(visibleUserNameHtml__soy18850);
  let $tmp$$1;
  if (user) {
    $tmp$$1 = '' + user.firstName + ' ' + '' + user.lastName;
  } else if (soy.$$coerceToBoolean(username) && username != '') {
    $tmp$$1 = ' ' + '' + username;
  } else {
    $tmp$$1 = ' Unknown user';
  }
  let visibleUserNameString__soy18866 = '' + $tmp$$1;
  $output += (canLinkToUser__soy18849 ? '<a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.showUser$(soy.$$internalCallMarkerDoNotUse, $ijData, user.username))) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.user.TeamscaleUserPerspectiveTemplate.roundAvatar ts/perspectives/user/TeamscaleUserPerspectiveTemplate.soy:133"' : '') + '>' : '') + '<img class="ui avatar image ' + (size ? soy.$$escapeHtmlAttribute(size) : '') + '"' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, (prefixForTooltip ? '' + prefixForTooltip : '') + ' ' + '' + visibleUserNameString__soy18866)) + ' src="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeMediaUri($avatarSource$(soy.$$internalCallMarkerDoNotUse, $ijData, user ? user.username : username, soy.$$coerceToBoolean(user) && soy.$$coerceToBoolean(user.useGravatar) ? user.gravatarHash : null, size != null ? size : 'tiny'))) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.user.TeamscaleUserPerspectiveTemplate.roundAvatar ts/perspectives/user/TeamscaleUserPerspectiveTemplate.soy:137"' : '') + '>' + (!hideUserName ? '<span' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.user.TeamscaleUserPerspectiveTemplate.roundAvatar ts/perspectives/user/TeamscaleUserPerspectiveTemplate.soy:148"' : '') + '> ' + visibleUserNameHtml__wrapped18850 + '</span>' : '') + (canLinkToUser__soy18849 ? '</a>' : '');
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $roundAvatar as roundAvatar };
export { $roundAvatar$ as roundAvatar$ };
/**
 * @typedef {{
 *  username: string,
 *  user?: (?|undefined),
 *  size?: (null|string|undefined),
 *  hideUserName?: (boolean|null|undefined),
 *  prefixForTooltip?: (null|string|undefined),
 *  dontLinkToUser?: (boolean|null|undefined),
 * }}
 */
$roundAvatar.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $roundAvatar.soyTemplateName = 'ts.perspectives.user.TeamscaleUserPerspectiveTemplate.roundAvatar';
}


/**
 * @param {!$title.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $title = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $title$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.text);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} text
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $title$ = function($$areYouAnInternalCaller, $ijData, text) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.user.TeamscaleUserPerspectiveTemplate.title']) {
    return soy.$$stubsMap['ts.perspectives.user.TeamscaleUserPerspectiveTemplate.title']({text: text}, $ijData);
  }
  soy.assertParamType(typeof text === 'string', 'text', text, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml($soy$ts$commons$UIUtilsTemplate.alerts(null, $ijData) + '<div class="page-header ts-headline"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.user.TeamscaleUserPerspectiveTemplate.title ts/perspectives/user/TeamscaleUserPerspectiveTemplate.soy:162"' : '') + '><h1>' + soy.$$escapeHtml(text) + '</h1></div>');
};
export { $title as title };
export { $title$ as title$ };
/**
 * @typedef {{
 *  text: string,
 * }}
 */
$title.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $title.soyTemplateName = 'ts.perspectives.user.TeamscaleUserPerspectiveTemplate.title';
}


/**
 * @param {!$updateAvatar.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $updateAvatar = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.user.TeamscaleUserPerspectiveTemplate.updateAvatar']) {
    return soy.$$stubsMap['ts.perspectives.user.TeamscaleUserPerspectiveTemplate.updateAvatar'](opt_data, $ijData);
  }
  /** @type {?} */
  const username = opt_data.username;
  /** @type {?} */
  const csrfToken = opt_data.csrfToken;
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui container centered" style="max-width: 800px !important;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.user.TeamscaleUserPerspectiveTemplate.updateAvatar ts/perspectives/user/TeamscaleUserPerspectiveTemplate.soy:179"' : '') + '><div class="page-header ts-headline"><h1>Upload a New Avatar</h1></div>' + $soy$ts$commons$UIUtilsTemplate.alerts(null, $ijData) + '<form class="ui form avatar-update-form" method="POST" action="api/avatars/' + soy.$$escapeHtmlAttribute($soy$ts$commons$UIUtilsTemplate.legacyEscapeText$(soy.$$internalCallMarkerDoNotUse, $ijData, username)) + '/image?redirectToAdminPage=false" enctype="multipart/form-data"><div class="field">' + $soy$ts$commons$UIUtilsTemplate.csrfTokenInput(/** @type {?} */ (opt_data), $ijData) + $soy$ts$commons$UIUtilsTemplate.fileUpload$(soy.$$internalCallMarkerDoNotUse, $ijData, 'avatar', 'Select an Image', undefined, 'avatar-file-input', 'image/*') + '</div><div class="field"><input type="submit" class="ui button primary upload-avatar" value="Upload"></div></form></div>');
};
export { $updateAvatar as updateAvatar };
/**
 * @typedef {{
 *  username: ?,
 *  csrfToken: ?,
 * }}
 */
$updateAvatar.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $updateAvatar.soyTemplateName = 'ts.perspectives.user.TeamscaleUserPerspectiveTemplate.updateAvatar';
}


/**
 * @param {?$accessKey.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $accessKey = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $accessKey$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.accessKey);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {null|string|undefined=} accessKey
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $accessKey$ = function($$areYouAnInternalCaller, $ijData, accessKey) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.user.TeamscaleUserPerspectiveTemplate.accessKey']) {
    return soy.$$stubsMap['ts.perspectives.user.TeamscaleUserPerspectiveTemplate.accessKey']({accessKey: accessKey}, $ijData);
  }
  soy.assertParamType(accessKey == null || typeof accessKey === 'string', 'accessKey', accessKey, '@param', 'null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui container narrow"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.user.TeamscaleUserPerspectiveTemplate.accessKey ts/perspectives/user/TeamscaleUserPerspectiveTemplate.soy:214"' : '') + '>' + $title$(soy.$$internalCallMarkerDoNotUse, $ijData, 'Access Key') + '<p class="lead">The IDE access key can be used instead of your actual password when integrating Teamscale with external tools, such as your IDE. At any time you can revoke your access key or generate a new one.</p><p class="lead">Your current key is shown below:</p><div class="access-key-container">' + $accessKeySnippet$(soy.$$internalCallMarkerDoNotUse, $ijData, accessKey) + '</div><div style="text-align: right"><div class="ui icon button primary" id="generate-button">' + (accessKey ? '<i class="repeat icon"> </i> Regenerate' : 'Generate') + ' Access Key</div>' + (accessKey ? ' <div class="ui icon button" id="revoke-button"><i class="trash icon"></i> Revoke Access Key</div>' : '') + '</div></div>');
};
export { $accessKey as accessKey };
export { $accessKey$ as accessKey$ };
/**
 * @typedef {{
 *  accessKey?: (null|string|undefined),
 * }}
 */
$accessKey.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $accessKey.soyTemplateName = 'ts.perspectives.user.TeamscaleUserPerspectiveTemplate.accessKey';
}


/**
 * @param {?$accessKeySnippet.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $accessKeySnippet = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $accessKeySnippet$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.accessKey);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {null|string|undefined=} accessKey
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $accessKeySnippet$ = function($$areYouAnInternalCaller, $ijData, accessKey) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.user.TeamscaleUserPerspectiveTemplate.accessKeySnippet']) {
    return soy.$$stubsMap['ts.perspectives.user.TeamscaleUserPerspectiveTemplate.accessKeySnippet']({accessKey: accessKey}, $ijData);
  }
  soy.assertParamType(accessKey == null || typeof accessKey === 'string', 'accessKey', accessKey, '@param', 'null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="access-key"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.user.TeamscaleUserPerspectiveTemplate.accessKeySnippet ts/perspectives/user/TeamscaleUserPerspectiveTemplate.soy:246"' : '') + '>' + (accessKey ? '<input id="input-access-key" type="text" spellcheck=false readonly value="' + soy.$$escapeHtmlAttribute(accessKey) + '"><button class="small basic ui icon button"' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'Copy to clipboard')) + ' id="button-copy-key" data-clipboard-target="#input-access-key"><i class="icon copy"></i></button>' : 'No access key set.') + '</div>');
};
export { $accessKeySnippet as accessKeySnippet };
export { $accessKeySnippet$ as accessKeySnippet$ };
/**
 * @typedef {{
 *  accessKey?: (null|string|undefined),
 * }}
 */
$accessKeySnippet.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $accessKeySnippet.soyTemplateName = 'ts.perspectives.user.TeamscaleUserPerspectiveTemplate.accessKeySnippet';
}
