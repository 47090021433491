import * as array from 'ts-closure-library/lib/array/array';
import * as dom from 'ts-closure-library/lib/dom/dom';
import { TableSorter } from 'ts-closure-library/lib/ui/tablesorter';
import { DateUtils } from 'ts/commons/DateUtils';
import { tsdom } from 'ts/commons/tsdom';
import { UIUtils } from './UIUtils';

/** A sortable entry. */
type SortableEntry = [string, number, HTMLTableRowElement];

/**
 * A smart table sorter that remembers its previous sorting for the given table using the HTML 5 local storage. Make
 * sure that the table has a unique id as it will be used as a key to store the sort order.
 *
 * For technical reasons, {@link ts.commons.SmartTableSorter#sort} is an almost verbatim copy of {@link TableSorter#sort}
 * (Apache 2.0 licensed). See {@link ts.commons.SmartTableSorter#sort} for the exact extent of and reason behind the
 * copy.
 */
export class SmartTableSorter extends TableSorter {
	/** Unique ID used for this table sorter. Will be used to identify the correct storage value */
	public id: string;

	/** @param table The table to sort */
	public constructor(table: Element) {
		super();
		if (!table.id) {
			console.error('Must not use SmartTableSorter for table without id');
		}
		this.id = 'table-' + table.id;
		table.classList.toggle('sortable', true);
	}

	public override sort(column: number, reverse: boolean | undefined): boolean {
		UIUtils.getLocalStorage().set(this.id, column + ':' + reverse);

		// BEGIN COPIED CODE:
		// Below is an almost verbatim copy of the TableSorter#sort method (Apache 2.0 licensed).
		// The following changes have been made:
		// - This code below calls this.getSortValue(cell) rather than sorting the cell's textual content
		// - The code below has been reformatted to following our coding guidelines.
		const sortFunction = this.getSortFunction(column);
		if (sortFunction === TableSorter.noSort) {
			return false;
		}

		// Remove old header classes.
		this.updateHeaderClass(false);
		// @ts-ignore is private
		this.reversed_ = !!reverse;
		let multiplier: number;
		// @ts-ignore is private
		if (this.reversed_) {
			multiplier = -1;
		} else {
			multiplier = 1;
		}

		function compareFunction(a: SortableEntry, b: SortableEntry): number {
			return multiplier * sortFunction(a[0]!, b[0]!) || a[1]! - b[1]!;
		}

		// Sort all tBodies
		const table = this.getElement() as HTMLTableElement;
		for (const tableBody of table.tBodies) {
			this.sortTableBodyRows(table, tableBody, column, compareFunction);
		}
		// @ts-ignore
		this.header_ = table.tHead!.rows[this.sortableHeaderRowIndex_]!.cells[column]!;

		// Update the header class.
		this.updateHeaderClass(true);
		return true;
	}

	// END COPIED CODE
	/** @returns The current sort header element of the table. */
	public getHeader(): HTMLTableCellElement | null {
		// @ts-ignore is private
		return this.header_;
	}

	/** @returns True if the last sort was reversed otherwise false */
	public isReversed(): boolean {
		// @ts-ignore is private
		return this.reversed_;
	}

	/**
	 * Sort the rows of a table body.
	 *
	 * @param table The concerned table element
	 * @param tableBody A table body HTML element
	 * @param column The column to sort by
	 * @param compareFunction Callback function that compares any 2 values.
	 */
	public sortTableBodyRows(
		table: HTMLTableElement,
		tableBody: HTMLTableSectionElement,
		column: number,
		compareFunction: (p1: SortableEntry, p2: SortableEntry) => number
	): void {
		// Collect all of the rows into an array.
		const values: SortableEntry[] = Array.from(tableBody.rows).map((row, rowIndex): SortableEntry => {
			return [this.getSortValue(row.cells[column]!), rowIndex, row];
		});
		array.sort(values, compareFunction);

		// Remove the tBody temporarily since this speeds up the sort on some browsers.
		const nextSibling = tableBody.nextSibling;
		table.removeChild(tableBody);

		// Sort the rows, using the resulting array.
		values.forEach(row => tableBody.appendChild(row[2]!));

		// Reinstate the tBody.
		table.insertBefore(tableBody, nextSibling);
	}

	/**
	 * Remove or add class information into the head column of a table by which it's rows are sorted.
	 *
	 * @param addClass True to add class info, false to remove it.
	 */
	private updateHeaderClass(addClass: boolean): void {
		const header = this.getHeader();
		if (header == null) {
			return;
		}
		let className = 'ascending';
		if (this.isReversed()) {
			className = 'descending';
		}
		header.classList.toggle(className, addClass);
		header.classList.toggle('sorted', addClass);
	}

	/**
	 * Gets a sort value for a table cell. If the data-sort-value attribute exists on any child element of the cell,
	 * then its value is taken. If the cell holds a timestamp, its numerical value is taken. Otherwise, the cell's
	 * textual content is used.
	 *
	 * @param cell DOM node to get the sort value from.
	 * @returns The sort value
	 */
	public getSortValue(cell: Element | null): string {
		if (cell == null) {
			return '';
		}
		// Check if any of the children has a sortValue
		for (const child of dom.getChildren(cell)) {
			const sortValue = (child as HTMLElement).dataset['sortValue'];
			if (sortValue != null) {
				return sortValue;
			}
		}
		const cellTextContent = dom.getTextContent(cell);
		// Check if the cell value should be interpreted as timestamp
		if (cell.className.includes('timestamp')) {
			return `${DateUtils.parseTimestampFromDate(cellTextContent)}`;
		}
		return cellTextContent;
	}

	/**
	 * Sorts the table by using its stored column index. If there is no stored index but the optional parameter
	 * defaultSortColumnIndex is defined, is used as initial sorting index.
	 *
	 * @param element The table element
	 * @param defaultSortColumnIndex The optional column index to sort the table.
	 * @param defaultReverse The optional sort order of the table (true=descending, false=ascending).
	 * @param ignorePreviousSortColumn Whether the stored column should always be ignored.
	 */
	public override decorate(
		element: HTMLTableElement,
		defaultSortColumnIndex: number | null = null,
		defaultReverse = false,
		ignorePreviousSortColumn = false
	): void {
		super.decorate(element);
		const storageValue = UIUtils.getLocalStorage().get(this.id);
		if (typeof storageValue === 'string' && !ignorePreviousSortColumn) {
			const values = String(storageValue).split(':');
			const reverse = values[1] === 'true';
			const column = parseInt(values[0]!, 10);
			if (element.rows.length > 0 && column < element.rows[0]!.cells.length) {
				this.sort(column, reverse);
			}
		} else if (defaultSortColumnIndex != null) {
			this.sort(defaultSortColumnIndex, defaultReverse);
		}
	}

	/**
	 * Hook sorting functionality into all tables in the given element. Make sure that the tables have a unique id as it
	 * will be used to store the sort order in the local browser storage.
	 *
	 * @param element The element in which the tables should be searched for
	 * @param className The class name of the table that should be used. If none is given all 'ui sortable table' are
	 *   considered.
	 * @param sortFunctions An optional dictionary to specify sort functions for certain columns. If a column has no
	 *   corresponding sort function or if no dictionary is provided, {@link UIUtils.smartSort} will be used.
	 */
	public static hookTableSorting(
		element: Element,
		className?: string,
		sortFunctions?: Record<number, (element1: string | number, element2: string | number) => number>
	): void {
		let tables;
		if (className) {
			tables = tsdom.getElementsByClass(className, element);
		} else {
			tables = element.querySelectorAll('.ui.sortable.table');
		}
		tables.forEach(table => {
			const sorter = new SmartTableSorter(table);
			if (sortFunctions != null) {
				for (const [column, sortFunction] of Object.entries(sortFunctions)) {
					sorter.setSortFunction(Number(column), sortFunction);
				}
			}
			sorter.setDefaultSortFunction(UIUtils.smartSort);
			sorter.decorate(table as HTMLTableElement);
		});
	}
}
