/** Generated from EBasicPermissionScope.java. Do not modify this file! */

import type { EGlobalPermissionEntry } from 'typedefs/EGlobalPermission';

export type EBasicPermissionScopeEntry = 'USERS' | 'GROUPS' | 'ANALYSIS_PROFILES' | 'METRIC_THRESHOLD_CONFIGURATIONS' | 'EXTERNAL_TOOL_ACCOUNTS' | 'EXTERNAL_STORAGE_BACKENDS' | 'REPORTS';

export class EBasicPermissionScope {
	static readonly USERS = new EBasicPermissionScope(0, 'USERS', "User", "CREATE_USERS");
	static readonly GROUPS = new EBasicPermissionScope(1, 'GROUPS', "Group", "CREATE_GROUPS");
	static readonly ANALYSIS_PROFILES = new EBasicPermissionScope(2, 'ANALYSIS_PROFILES', "Analysis Profile", "CREATE_ANALYSIS_PROFILES");
	static readonly METRIC_THRESHOLD_CONFIGURATIONS = new EBasicPermissionScope(3, 'METRIC_THRESHOLD_CONFIGURATIONS', "Metric Threshold Configuration", "CREATE_METRIC_THRESHOLD_CONFIGURATIONS");
	static readonly EXTERNAL_TOOL_ACCOUNTS = new EBasicPermissionScope(4, 'EXTERNAL_TOOL_ACCOUNTS', "External Accounts", "CREATE_EXTERNAL_TOOL_ACCOUNTS");
	static readonly EXTERNAL_STORAGE_BACKENDS = new EBasicPermissionScope(5, 'EXTERNAL_STORAGE_BACKENDS', "External Storage Backends", "CREATE_EXTERNAL_STORAGE_BACKENDS");
	static readonly REPORTS = new EBasicPermissionScope(6, 'REPORTS', "Quality Report", "CREATE_QUALITY_REPORTS");
	static readonly values = [
		EBasicPermissionScope.USERS,
		EBasicPermissionScope.GROUPS,
		EBasicPermissionScope.ANALYSIS_PROFILES,
		EBasicPermissionScope.METRIC_THRESHOLD_CONFIGURATIONS,
		EBasicPermissionScope.EXTERNAL_TOOL_ACCOUNTS,
		EBasicPermissionScope.EXTERNAL_STORAGE_BACKENDS,
		EBasicPermissionScope.REPORTS
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EBasicPermissionScopeEntry,
		public readonly readableName: string,
		public readonly createPermission: EGlobalPermissionEntry
	){
	}

	toString() {
		return this.name;
	}
}

