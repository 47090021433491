import type { MetricTrendChartData } from 'ts/commons/charts/MetricTrendChartData';
import { StringUtils } from 'ts/commons/StringUtils';
import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { CommitDescriptor } from 'typedefs/CommitDescriptor';
import { EAggregation } from 'typedefs/EAggregation';
import { EMetricValueType } from 'typedefs/EMetricValueType';
import { ETestGapState } from 'typedefs/ETestGapState';
import type { MetricDirectorySchemaEntry } from 'typedefs/MetricDirectorySchemaEntry';
import type { MetricTrendEntry } from 'typedefs/MetricTrendEntry';
import { DateUtils } from './DateUtils';
import { TimeFormatter } from './time/TimeFormatter';
import type { TypedPointInTime } from './time/TypedPointInTime';

/** A trend info. */
type TrendInfo = {
	metricsSchema: {
		entries: MetricDirectorySchemaEntry[];
	};
	metricIndexes: number[];
	trendData: MetricTrendChartData[];
};

type TestGapStates =
	| typeof ETestGapState.TEST_GAP_TREE_MAP_STATES
	| typeof ETestGapState.EXECUTION_TREE_MAP_STATES
	| typeof ETestGapState.CHURN_TEST_GAP_TREE_MAP_STATES;

/** Baseline description texts. */
export type BaselineDescription = {
	baselineShortText: string;
	endShortText: string;
	baselineDescription: string;
	endDescription: string;
};

/** Utility functions for test gap analysis. */
export class TestGapUtils {
	/** Creates a key for method locations. Used for mapping OverlayData. */
	public static createKeyFromLocation(uniformPath: string, region: { start: number; end: number }): string {
		return uniformPath + '#' + region.start + '-' + region.end;
	}
	/**
	 * @param trendData Data to render.
	 * @param executionOnly Whether to only display execution information.
	 * @param isPercentTrend Whether this is a Test Gap percent trend.
	 * @param isChurn Whether we want to view only the churn (i.e. code changes regardless of coverage).
	 */
	public static createTrendInfoObject(
		trendData: MetricTrendEntry[],
		executionOnly: boolean,
		isPercentTrend: boolean,
		isChurn?: boolean | null
	): TrendInfo {
		let states: TestGapStates = ETestGapState.TEST_GAP_TREE_MAP_STATES;
		if (isPercentTrend) {
			return TestGapUtils.trendInfoIfIsPercentTrend(trendData, executionOnly);
		}
		if (executionOnly) {
			states = ETestGapState.EXECUTION_TREE_MAP_STATES;
		} else if (isChurn != null && isChurn) {
			states = ETestGapState.CHURN_TEST_GAP_TREE_MAP_STATES;
		}
		return {
			trendData: TestGapUtils.createStackedTrendData(trendData, states),
			metricsSchema: { entries: [] },
			metricIndexes: []
		};
	}

	/**
	 * Creates the trendInfo if percentTrend is true.
	 *
	 * @param trendData Data to render.
	 * @param executionOnly Whether to only display execution information.
	 */
	public static trendInfoIfIsPercentTrend(trendData: MetricTrendEntry[], executionOnly: boolean): TrendInfo {
		let percentedTrendData = TestGapUtils.createPercentTrendData(trendData);
		let metricIndexesArray = [0];
		if (executionOnly) {
			percentedTrendData = TestGapUtils.createPercentedTrendDataStated(
				trendData,
				ETestGapState.EXECUTION_TREE_MAP_STATES
			);
			metricIndexesArray = [0, 0];
		}
		return {
			trendData: percentedTrendData,
			metricsSchema: {
				entries: [
					{
						name: '',
						description: '',
						aggregation: EAggregation.SUM.name,
						valueType: EMetricValueType.NUMERIC.name,
						properties: ['RATIO_METRIC']
					}
				]
			},
			metricIndexes: metricIndexesArray
		};
	}

	/**
	 * Creates the trend data to pass to the chart for a stacked Test Gap or execution trend.
	 *
	 * @param trendData
	 * @param states The states in the stacked trend.
	 */
	public static createStackedTrendData(trendData: MetricTrendEntry[], states: TestGapStates): MetricTrendChartData[] {
		return states.map((state, stateIndex) => {
			const series: Array<[number, number]> = trendData.map(data => {
				const timestamp = data.timestamp;
				const value = Number(data.value[stateIndex]!);
				return [timestamp, value];
			});
			return {
				data: series,
				label: states[stateIndex]!.shortDescription
			};
		});
	}

	/** Creates the trend data to pass to the chart for a simple Test Gap percent trend. */
	public static createPercentTrendData(trendData: MetricTrendEntry[]): MetricTrendChartData[] {
		const series: Array<[number, number]> = trendData.map(data => {
			const timestamp = data.timestamp;
			const added = Number(
				data.value[ETestGapState.TEST_GAP_TREE_MAP_STATES.indexOf(ETestGapState.UNTESTED_ADDITION)]!
			);
			const modified = Number(
				data.value[ETestGapState.TEST_GAP_TREE_MAP_STATES.indexOf(ETestGapState.UNTESTED_CHANGE)]!
			);
			const tested = Number(
				data.value[ETestGapState.TEST_GAP_TREE_MAP_STATES.indexOf(ETestGapState.TESTED_CHURN)]!
			);
			const changed = added + modified;
			const total = added + modified + tested;
			let value = 0;
			if (total > 0) {
				value = changed / total;
			}
			return [timestamp, value];
		});
		return [{ data: series, label: 'Test Gap' }];
	}

	/**
	 * Generates an information object about the baseline and the end date used in the tree map.
	 *
	 * @param baseline The active baseline value
	 * @param endDate The widget's end date (if set)
	 * @param baselineParameterValue Parameter value for the baseline
	 * @param endDateParameterValue Parameter value for the end date
	 * @param issueId The selected issue (optional).
	 * @returns {{
	 * 	baselineShortText: string;
	 * 	endShortText: string | undefined;
	 * 	baselineDescription: string;
	 * 	endDescription: string | undefined;
	 * }}
	 *   The timespan information.
	 */
	public static createBaselineEndDateInfoWithParameterValues(
		baseline: number | null,
		endDate: CommitDescriptor | UnresolvedCommitDescriptor,
		baselineParameterValue: TypedPointInTime,
		endDateParameterValue: TypedPointInTime,
		issueId?: string | null | number
	): BaselineDescription {
		const baselineShortText = DateUtils.formatTimestamp(baseline);
		let baselineDescription = TimeFormatter.simple(baselineParameterValue);
		const endShortText = DateUtils.formatTimestamp(endDate.timestamp);
		let endDescription = TimeFormatter.simple(endDateParameterValue);
		if (issueId != null && !(typeof issueId === 'string' && StringUtils.isEmptyOrWhitespace(issueId))) {
			baselineDescription = 'First commit to issue ' + issueId;
			endDescription = 'Last commit to issue ' + issueId;
		}
		return {
			baselineShortText,
			endShortText,
			baselineDescription,
			endDescription
		};
	}

	/**
	 * Creates the trend data to pass to the chart for a Test Gap execution percent trend.
	 *
	 * @param trendData
	 * @param states The states in the stacked trend.
	 */
	public static createPercentedTrendDataStated(
		trendData: MetricTrendEntry[],
		states: TestGapStates
	): MetricTrendChartData[] {
		return states.map((state, stateIndex) => {
			const series: Array<[number, number]> = trendData.map(data => {
				const timestamp = data.timestamp;
				let total = 0;
				for (let i = 0; i < data.value.length; i++) {
					total += Number(data.value[i]!);
				}
				let value = 0;
				if (total !== 0) {
					value = Number(data.value[stateIndex]!) / total;
				}
				return [timestamp, value];
			});
			return {
				data: series,
				label: states[stateIndex]!.shortDescription
			};
		});
	}
}
