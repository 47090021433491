/** Generated from EBlacklistingOption.java. Do not modify this file! */


export type EBlacklistingOptionEntry = 'EXCLUDED' | 'ALL' | 'ONLY_BLACKLISTED' | 'ONLY_TOLERATED' | 'ONLY_FALSE_POSITIVES';

export class EBlacklistingOption {
	static readonly EXCLUDED = new EBlacklistingOption(0, 'EXCLUDED');
	static readonly ALL = new EBlacklistingOption(1, 'ALL');
	static readonly ONLY_BLACKLISTED = new EBlacklistingOption(2, 'ONLY_BLACKLISTED');
	static readonly ONLY_TOLERATED = new EBlacklistingOption(3, 'ONLY_TOLERATED');
	static readonly ONLY_FALSE_POSITIVES = new EBlacklistingOption(4, 'ONLY_FALSE_POSITIVES');
	static readonly values = [
		EBlacklistingOption.EXCLUDED,
		EBlacklistingOption.ALL,
		EBlacklistingOption.ONLY_BLACKLISTED,
		EBlacklistingOption.ONLY_TOLERATED,
		EBlacklistingOption.ONLY_FALSE_POSITIVES
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EBlacklistingOptionEntry,

	){
	}

	toString() {
		return this.name;
	}
}

