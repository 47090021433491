/** Generated from EMimeType.java. Do not modify this file! */


export type EMimeTypeEntry = 'PLAIN' | 'HTML' | 'XML' | 'JAVASCRIPT' | 'CSS' | 'CSV' | 'JSON' | 'MULTIPART_FORM_DATA' | 'URL_ENCODED_FORM_DATA' | 'ICON' | 'GIF' | 'JPEG' | 'PNG' | 'SVG' | 'FONT' | 'FONT_TTF' | 'FONT_WOFF' | 'FONT_WOFF_2' | 'MP3' | 'ZIP' | 'OCTET_STREAM' | 'SOAP' | 'PDF' | 'MD';

export class EMimeType {
	static readonly PLAIN = new EMimeType(0, 'PLAIN', "text/plain", []);
	static readonly HTML = new EMimeType(1, 'HTML', "text/html", ["application/xhtml+xml"]);
	static readonly XML = new EMimeType(2, 'XML', "text/xml", ["application/xml"]);
	static readonly JAVASCRIPT = new EMimeType(3, 'JAVASCRIPT', "text/javascript", ["application/x-javascript"]);
	static readonly CSS = new EMimeType(4, 'CSS', "text/css", []);
	static readonly CSV = new EMimeType(5, 'CSV', "text/csv", ["application/vnd.ms-excel"]);
	static readonly JSON = new EMimeType(6, 'JSON', "application/json", []);
	static readonly MULTIPART_FORM_DATA = new EMimeType(7, 'MULTIPART_FORM_DATA', "multipart/form-data", []);
	static readonly URL_ENCODED_FORM_DATA = new EMimeType(8, 'URL_ENCODED_FORM_DATA', "application/x-www-form-urlencoded", []);
	static readonly ICON = new EMimeType(9, 'ICON', "image/x-icon", []);
	static readonly GIF = new EMimeType(10, 'GIF', "image/gif", []);
	static readonly JPEG = new EMimeType(11, 'JPEG', "image/jpeg", []);
	static readonly PNG = new EMimeType(12, 'PNG', "image/png", ["image/x-png"]);
	static readonly SVG = new EMimeType(13, 'SVG', "image/svg+xml", []);
	static readonly FONT = new EMimeType(14, 'FONT', "application/x-font", []);
	static readonly FONT_TTF = new EMimeType(15, 'FONT_TTF', "font/ttf", []);
	static readonly FONT_WOFF = new EMimeType(16, 'FONT_WOFF', "font/woff", []);
	static readonly FONT_WOFF_2 = new EMimeType(17, 'FONT_WOFF_2', "font/woff2", []);
	static readonly MP3 = new EMimeType(18, 'MP3', "audio/mpeg3", []);
	static readonly ZIP = new EMimeType(19, 'ZIP', "application/zip", []);
	static readonly OCTET_STREAM = new EMimeType(20, 'OCTET_STREAM', "application/octet-stream", []);
	static readonly SOAP = new EMimeType(21, 'SOAP', "application/soap+xml", []);
	static readonly PDF = new EMimeType(22, 'PDF', "application/pdf", []);
	static readonly MD = new EMimeType(23, 'MD', "text/markdown", []);
	static readonly values = [
		EMimeType.PLAIN,
		EMimeType.HTML,
		EMimeType.XML,
		EMimeType.JAVASCRIPT,
		EMimeType.CSS,
		EMimeType.CSV,
		EMimeType.JSON,
		EMimeType.MULTIPART_FORM_DATA,
		EMimeType.URL_ENCODED_FORM_DATA,
		EMimeType.ICON,
		EMimeType.GIF,
		EMimeType.JPEG,
		EMimeType.PNG,
		EMimeType.SVG,
		EMimeType.FONT,
		EMimeType.FONT_TTF,
		EMimeType.FONT_WOFF,
		EMimeType.FONT_WOFF_2,
		EMimeType.MP3,
		EMimeType.ZIP,
		EMimeType.OCTET_STREAM,
		EMimeType.SOAP,
		EMimeType.PDF,
		EMimeType.MD
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EMimeTypeEntry,
		public readonly type: string,
		public readonly additionalTypes: Array<string>
	){
	}

	toString() {
		return this.name;
	}
}

