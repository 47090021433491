/** Generated from EProjectScheduleCommand.java. Do not modify this file! */


export type EProjectScheduleCommandEntry = 'PAUSE' | 'UNPAUSE';

export class EProjectScheduleCommand {
	static readonly PAUSE = new EProjectScheduleCommand(0, 'PAUSE');
	static readonly UNPAUSE = new EProjectScheduleCommand(1, 'UNPAUSE');
	static readonly values = [
		EProjectScheduleCommand.PAUSE,
		EProjectScheduleCommand.UNPAUSE
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EProjectScheduleCommandEntry,

	){
	}

	toString() {
		return this.name;
	}
}

