import * as asserts from 'ts-closure-library/lib/asserts/asserts';
import * as strings from 'ts-closure-library/lib/string/string';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import type { ReportSlideBase } from 'typedefs/ReportSlideBase';

export type ExtendedReportSlide = ExtendTypeWith<
	ReportSlideBase,
	{
		/**
		 * A unique identifier for the slide. Not final or private so we can copy slides and manipulate the id
		 * afterwards.
		 */
		id: string;
	}
>;

/**
 * Asserts that the given slide is an {@link ExtendedReportSlide} by checking the presence of the {@link #id} field.
 *
 * @returns The input slide itself
 */
export function assertExtendedSlide(slide: ReportSlideBase): ExtendedReportSlide {
	const extSlide = slide as ExtendedReportSlide;
	asserts.assertString(extSlide.id, 'Expected slide to have id');
	return extSlide;
}

export function wrapReportSlide(slide: ReportSlideBase | ExtendedReportSlide): ExtendedReportSlide {
	return Object.assign(slide, { id: slide.id || strings.createUniqueString() });
}

export function wrapAllReportSlides(slides: ReportSlideBase[]): ExtendedReportSlide[] {
	return slides.map(slide => wrapReportSlide(slide));
}
