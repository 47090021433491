import { EMimeType } from 'typedefs/EMimeType';
import { HttpStatus, isSuccess } from './HttpStatus';
import { ServiceCallError } from './ServiceCallError';

/** Request options that can be set for all types of requests (all methods). */
export type BasicRequestConfig = {
	/** The accepted response content type. By default, this is json, but can be set to e.g. csv if needed. */
	acceptType: string;

	/**
	 * Setting this automatically wraps/unwraps the response depending on the value. E.g. 'blob' wraps the response in a
	 * Blob object. For more information see
	 * https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/responseType
	 */
	responseType: XMLHttpRequestResponseType;

	/** An abort signal that can be used to abort the request. */
	signal: AbortSignal;
};

/** Callback that reports the progress of an ongoing upload. */
export type UploadProgress = (event: ProgressEvent) => void;

/** Request options that are valid for POST and PUT requests only. */
export type RequestWithBodyConfig = BasicRequestConfig & {
	/**
	 * The type of the content in this request (in PUT or POST). By default, this is inferred from the given payload
	 * (see RequestPayload). contentType should be specified if the default behavior does not match with what should
	 * happen. Note that this only changes the Content-Type header and does not influence the default json
	 * serialization.
	 */
	contentType: string;

	/** Is called with the progress of the upload. */
	uploadProgressCallback: UploadProgress | null;
};

/** Request options for usage withing this class */
type InternalRequestWithBodyConfig = RequestWithBodyConfig & {
	/**
	 * The original HTTP method in case it was rewritten.
	 *
	 * @see ServiceClientImplementation#shortenLongUrl
	 */
	originalMethod?: Method;
};

/**
 * The request payload aka request body (in PUT or POST requests). Can either be
 *
 * - URLSearchParams which is serialized as application/x-www-form-urlencoded
 * - FormData object which is automatically serialized and transferred as multipart/form-data
 * - All other kinds of objects and strings are serialized to json and sent as application/json
 */
export type RequestPayload = unknown;

/** Profiler info which is returned in the headers by Teamscale if enabled. */
export type ProfilerInfo = {
	/** Number of storage calls needed for the request. */
	storageCalls: string | null;
	/** Start time of the request. */
	startTimeMillis: string | null;
	/** The number of milliseconds spent in storage calls. */
	storageTimeMillis: string | null;
	/** The overall runtime of the service call on the server side. */
	overallTimeMillis: string | null;
	/** The url of the service call this info belongs to. */
	url: string;
};

/** Profiler hook callback. */
type ProfilerHook = (profilerInfo: ProfilerInfo) => void;

/** The valid HTTP methods. */
export type Method = 'HEAD' | 'GET' | 'POST' | 'PUT' | 'DELETE';

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		/** The number of open XHR requests. Is undefined when no XHR call was made. */
		openRequests?: number;
	}
}

/**
 * The service client provides methods for accessing the service interface in a convenient and consistent way. Note that
 * all methods are executed asynchronously and thus require a callback function.
 */
export class ServiceClientImplementation {
	/**
	 * This is the longest URL length that is expected to work in all browsers, load balancers etc. Source:
	 * https://stackoverflow.com/a/417184/4158397
	 */
	protected static readonly MAX_SAFE_URL_LENGTH = 2000;

	/**
	 * A function that is called with profiling information for each returned call. For the format of the profiling
	 * object see the implementation of the reportProfilingInformation() method.
	 *
	 * This is static so all created clients can be profiled.
	 */
	private static profilerHook: ProfilerHook | null = null;

	/**
	 * Sets a function to be called with profiling information on each returned remote call. Set to null to disable
	 * profiling.
	 */
	public static setProfilerHook(profilerHook: ProfilerHook | null): void {
		ServiceClientImplementation.profilerHook = profilerHook;
	}

	/**
	 * Status code set by XMLHttpRequest to signal that the request has not been sent yet or that performing the request
	 * failed.See https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/status
	 */
	private static readonly XHR_STATUS_UNSENT_OR_ERROR = 0;

	/** Handles server calls that didn't succeed (based on the Http status code). */
	private static buildServiceCallError(xhr: XMLHttpRequest, isTimeout = false): ServiceCallError {
		const technicalDetails = ServiceClientImplementation.extractTechnicalDetails(xhr, isTimeout);
		if (xhr.status <= ServiceClientImplementation.XHR_STATUS_UNSENT_OR_ERROR || isTimeout) {
			let message;
			if (isTimeout) {
				message = 'Request timed out!';
			} else {
				message = 'Connection failed!';
			}
			return new ServiceCallError(
				ServiceClientImplementation.XHR_STATUS_UNSENT_OR_ERROR,
				message,
				technicalDetails
			);
		}
		const message = ServiceClientImplementation.extractEssentialMessage(xhr);
		return new ServiceCallError(xhr.status, message, technicalDetails, ServiceClientImplementation.readResult(xhr));
	}

	/** Builds some metadata in human readable form that will be shown in the details error message in the UI. */
	private static extractTechnicalDetails(xhr: XMLHttpRequest, isTimeout: boolean): string {
		let technicalDetails = 'Originating page: ' + window.location + '\n';
		if (xhr.status <= 0 || isTimeout) {
			technicalDetails += 'Requested URI: ' + xhr.responseURL + '\n';
			technicalDetails += 'XHR Status: ' + xhr.status + '\n';
		} else {
			technicalDetails += xhr.responseText;
		}
		return technicalDetails;
	}

	/** Attempts to extract the essential message from the server response. */
	private static extractEssentialMessage(xhr: XMLHttpRequest): string {
		let message = xhr.statusText + '\nUnknown details; Please contact your administrator to check the service log.';
		const messageLine = xhr.responseText.split('\n').find(line => line.startsWith('Message:'));
		if (messageLine) {
			message = messageLine.substring(messageLine.indexOf(':') + 1);
		}
		return message;
	}

	/**
	 * Reads the result form the xhr result and either converts it from json into an object or returns the plain string.
	 * "No content" and "Created" responses are returned as null.
	 */
	private static readResult(xhr: XMLHttpRequest): unknown {
		// Responses with "204 No content" or "201 Created" should be returned as a
		// null response that can be handled with a simple null check
		if (xhr.status === HttpStatus.NO_CONTENT || xhr.status === HttpStatus.CREATED) {
			return null;
		}
		const contentType = xhr.getResponseHeader('Content-Type');
		const result = xhr.responseText;
		if (contentType?.startsWith(EMimeType.JSON.type)) {
			return ServiceClientImplementation.parseJsonRobust(result);
		}
		return result;
	}

	/**
	 * A more robust version of JSON.parse handles undefined properly. Parsing can fail in which case an error is
	 * thrown.
	 *
	 * @param json The JSON to parse.
	 * @returns Null if json is not a string.
	 */
	private static parseJsonRobust(json: string | null | undefined): object | null | number | string | boolean {
		if (json == null || json === '') {
			return json ?? null;
		}

		// JSON.parse fails if the json doesn't represent a valid JSON.
		return JSON.parse(json) as object | number | string | boolean | null;
	}

	/** Reports profiling information to the profiling hook. */
	private static reportProfilingInformation(xhr: XMLHttpRequest): void {
		if (ServiceClientImplementation.profilerHook === null) {
			return;
		}
		const info: ProfilerInfo = {
			url: xhr.responseURL,
			startTimeMillis: xhr.getResponseHeader('x-conqat-service-profiling-start-time'),
			overallTimeMillis: xhr.getResponseHeader('x-conqat-service-profiling-overall-time'),
			storageTimeMillis: xhr.getResponseHeader('x-conqat-service-profiling-storage-time'),
			storageCalls: xhr.getResponseHeader('x-conqat-service-profiling-storage-calls')
		};
		ServiceClientImplementation.profilerHook(info);
	}

	/**
	 * Performs a service call to the given URL and takes care of deserializing the result or wrapping an error in a
	 * ServiceCallError. We do not use fetch here as it does not support reporting the upload progress yet, which we
	 * need e.g. for the backup import.
	 */
	public static call<T>(
		method: Method,
		url: string,
		config: Partial<RequestWithBodyConfig> = {},
		body?: XMLHttpRequestBodyInit
	): Promise<T> {
		if (window.openRequests === undefined) {
			window.openRequests = 0;
		}
		window.openRequests++;
		// Safe for later use as the original value is still needed
		const originalMethod = method;
		[method, url, config.contentType, body] = this.shortenLongUrl(method, url, config.contentType, body);

		const xhr = this.createXhr(method, url, { originalMethod, ...config });
		const promise = new Promise<T>((resolve, reject) => {
			xhr.onload = (): void => {
				ServiceClientImplementation.reportProfilingInformation(xhr);
				if (isSuccess(xhr.status)) {
					if (xhr.responseType === '' || xhr.responseType === 'text') {
						resolve(ServiceClientImplementation.readResult(xhr) as T);
					} else {
						resolve(xhr.response);
					}
				} else {
					reject(ServiceClientImplementation.buildServiceCallError(xhr));
				}
			};
			config.signal?.addEventListener('abort', () => {
				xhr.abort();
				reject();
			});
			xhr.onerror = (): void => reject(ServiceClientImplementation.buildServiceCallError(xhr));
			xhr.ontimeout = (): void => reject(ServiceClientImplementation.buildServiceCallError(xhr, true));
			xhr.send(body);
		});
		return promise.finally(() => window.openRequests!--);
	}

	/** Rewrites requests with too long urls to POST to the same endpoint with query parameters in the body. */
	private static shortenLongUrl(
		method: Method,
		initialUrl: string,
		initialContentType?: string,
		payload?: XMLHttpRequestBodyInit
	): [Method, string, string | undefined, XMLHttpRequestBodyInit | undefined] {
		if (
			(method === 'GET' || method === 'HEAD') &&
			initialUrl.length > ServiceClientImplementation.MAX_SAFE_URL_LENGTH
		) {
			const queryStart = initialUrl.indexOf('?');
			method = 'POST';
			payload = new URLSearchParams(initialUrl.substring(queryStart));
			initialContentType = EMimeType.URL_ENCODED_FORM_DATA.type;
			initialUrl = initialUrl.substring(0, queryStart);
		}
		return [method, initialUrl, initialContentType, payload];
	}

	private static createXhr(
		method: Method,
		url: string,
		config: Partial<InternalRequestWithBodyConfig>
	): XMLHttpRequest {
		const xhr = new XMLHttpRequest();
		if (config.uploadProgressCallback) {
			xhr.upload.onprogress = config.uploadProgressCallback;
		}
		xhr.open(method, url);
		if (config.responseType) {
			xhr.responseType = config.responseType;
		}
		const headers = ServiceClientImplementation.buildHeaders(method, config);
		Object.keys(headers).forEach(key => {
			xhr.setRequestHeader(key, headers[key]!);
		});
		return xhr;
	}

	/** Returns the headers to be used in the remote call. */
	private static buildHeaders(
		method: Method,
		config: Partial<InternalRequestWithBodyConfig>
	): Record<string, string> {
		const result: Record<string, string> = {};
		result['Accept'] = config.acceptType ?? EMimeType.JSON.type;
		if (config.contentType) {
			result['Content-Type'] = config.contentType;
		}
		if (ServiceClientImplementation.profilerHook !== null) {
			result['x-conqat-service-profiling-enabled'] = 'true';
		}
		if (config.originalMethod !== undefined && config.originalMethod !== method) {
			result['X-Original-Method'] = config.originalMethod;
		}
		if (method !== 'GET' && method !== 'HEAD') {
			result['X-Requested-By'] = ServiceClientImplementation.getCsrfToken();
		}
		return result;
	}

	/**
	 * Returns our csrf token, which is identical to the Teamscale session cookies. However since the csrf token and the
	 * session are conceptually different things its named csrf token from here on. The token might also be changed to
	 * csrfToken=HMAC(sessionId+timestamp) later on. We return all cookies here that start with teamscale-session, since
	 * we don't know which of them is the correct one for the current instance. We need to avoid sending all cookies as
	 * there might be leftovers from other services e.g. when running on localhost:8080 which could result in a "431
	 * Request Header Fields Too Large" (TS-29398).
	 */
	public static getCsrfToken(): string {
		return document.cookie
			.split('; ')
			.filter(cookie => cookie.startsWith('teamscale-session'))
			.join('; ');
	}
}
