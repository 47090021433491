// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/commons/PlaceholdersTemplate.soy

/**
 * @fileoverview Templates in namespace ts.commons.PlaceholdersTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';


/**
 * @param {!$placeholders.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $placeholders = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $placeholders$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.sectionIdsWithPlaceholderCounts, opt_data.useImagePlaceholders);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!Array<?>} sectionIdsWithPlaceholderCounts
 * @param {boolean|null|undefined=} useImagePlaceholders
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $placeholders$ = function($$areYouAnInternalCaller, $ijData, sectionIdsWithPlaceholderCounts, useImagePlaceholders) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.PlaceholdersTemplate.placeholders']) {
    return soy.$$stubsMap['ts.commons.PlaceholdersTemplate.placeholders']({sectionIdsWithPlaceholderCounts: sectionIdsWithPlaceholderCounts, useImagePlaceholders: useImagePlaceholders}, $ijData);
  }
  soy.assertParamType(Array.isArray(sectionIdsWithPlaceholderCounts), 'sectionIdsWithPlaceholderCounts', sectionIdsWithPlaceholderCounts, '@param', '!Array<?>');
  soy.assertParamType(useImagePlaceholders == null || typeof useImagePlaceholders === 'boolean', 'useImagePlaceholders', useImagePlaceholders, '@param', 'boolean|null|undefined');
  let $output = '';
  const sectionIdWithElementCount40775List = sectionIdsWithPlaceholderCounts;
  const sectionIdWithElementCount40775ListLen = sectionIdWithElementCount40775List.length;
  for (let sectionIdWithElementCount40775Index = 0; sectionIdWithElementCount40775Index < sectionIdWithElementCount40775ListLen; sectionIdWithElementCount40775Index++) {
    const sectionIdWithElementCount40775Data = sectionIdWithElementCount40775List[sectionIdWithElementCount40775Index];
    $output += '<div id="' + soy.$$escapeHtmlAttribute(sectionIdWithElementCount40775Data[/** @type {?} */ (0)]) + '" class="ui placeholder"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.PlaceholdersTemplate.placeholders ts/commons/PlaceholdersTemplate.soy:19"' : '') + '>';
    if (useImagePlaceholders) {
      const i40789_RangeEnd = sectionIdWithElementCount40775Data[/** @type {?} */ (1)];
      const i40789ListLen = Math.max(0, Math.ceil((i40789_RangeEnd - 0) / 1));
      for (let i40789Index = 0; i40789Index < i40789ListLen; i40789Index++) {
        const i40789Data = 0 + i40789Index * 1;
        $output += '<div class="image"></div>';
      }
    } else {
      $output += '<div class="paragraph">';
      const i40806_RangeEnd = sectionIdWithElementCount40775Data[/** @type {?} */ (1)];
      const i40806ListLen = Math.max(0, Math.ceil((i40806_RangeEnd - 0) / 1));
      for (let i40806Index = 0; i40806Index < i40806ListLen; i40806Index++) {
        const i40806Data = 0 + i40806Index * 1;
        $output += '<div class="line"></div>';
      }
      $output += '</div>';
    }
    $output += '</div>';
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $placeholders as placeholders };
export { $placeholders$ as placeholders$ };
/**
 * @typedef {{
 *  sectionIdsWithPlaceholderCounts: !Array<?>,
 *  useImagePlaceholders?: (boolean|null|undefined),
 * }}
 */
$placeholders.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $placeholders.soyTemplateName = 'ts.commons.PlaceholdersTemplate.placeholders';
}
