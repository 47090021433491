import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';

/** The URLBuilder type. We don't export URLBuilderImpl to enforce creation of URLs via the url tag function. */
export type URLBuilder = InstanceType<typeof URLBuilderImpl>;

/**
 * Constructs the URLBuilder for a HTTP call. This is a tag function
 * (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals).
 *
 * This can be used as in front of a template literal without parenthesis. JS will call the method with a list of n+1
 * static string fragments (pathFragments) and n values of the evaluated interpolation expressions.
 *
 * E.g. url`api/projects/${project}/service/${uniformPath}` is behaves like the following method call:
 * url(['api/projects/', '/service/', ''], [project, uniformPath])
 *
 * All interpolation expressions will get URL encoded.
 */
export function url(pathFragments: TemplateStringsArray, ...pathParameters: string[]): URLBuilder {
	let url = pathFragments[0]!;
	pathParameters.forEach((pathParameter, i) => {
		url += encodeURIComponent(pathParameter);
		url += pathFragments[i + 1]!;
	});
	return new URLBuilderImpl(url);
}

/**
 * Allows to construct service URL from parameter values. This class should NOT be exported to ensure all access' go
 * through `url` and are therefore properly escaped.
 */
class URLBuilderImpl {
	/** Stores the query parameters that are appended to the url. */
	private readonly queryParameters: URLSearchParams;

	/** @param url The URL without the query parameters. */
	public constructor(private url: string, queryParameters?: URLSearchParams) {
		this.queryParameters = new URLSearchParams(queryParameters);
	}

	/**
	 * Adds one or multiple path segments to the url. The path segments are URL encoded.
	 *
	 * @param pathElements Are encoded and then added to the URL separated by a '/'
	 */
	public appendToPath(...pathElements: string[]): void {
		if (!this.url.endsWith('/')) {
			this.url += '/';
		}
		this.url += pathElements.map(encodeURIComponent).join('/');
	}

	/**
	 * Appends the given parameter with the given value. If the value is undefined it is simply ignored. The supplied
	 * values are URL-encoded by this method. An array parameter will be added as a single comma separated list. If you
	 * prefer multiple parameters in this case, use appendMultiple().
	 *
	 * @param parameter The parameter name
	 * @param value The parameter value
	 */
	public append(
		parameter: string,
		value: UnresolvedCommitDescriptor | null | string | number | boolean | undefined
	): URLBuilder {
		if (value == null) {
			return this;
		}
		this.queryParameters.append(parameter, value.toString());
		return this;
	}

	/**
	 * Appends the given multiple parameter with the given values. If the value is undefined it is simply ignored. The
	 * supplied values are URL-encoded by this method.
	 *
	 * @param parameter The parameter name
	 * @param values The parameter values as an array
	 */
	public appendMultiple(parameter: string, values: Array<string | number | boolean> | null | undefined): URLBuilder {
		if (values == null) {
			return this;
		}
		for (let i = 0; i < values.length; i++) {
			this.append(parameter, values[i]);
		}
		return this;
	}

	/** Adds all parameters from the given URLSearchParams to the URL. */
	public appendAll(parameters: URLSearchParams): void {
		parameters.forEach((value, key) => this.queryParameters.append(key, value));
	}

	/**
	 * Returns the constructed URL as a string
	 *
	 * @returns The URL.
	 */
	public getURL(): string {
		let query = this.queryParameters.toString();
		if (query.length > 0) {
			query = '?' + query;
		}
		return this.url + query;
	}

	/**
	 * Constructs a copy of this URLBuilder optionally appending the #additionalQueryParameters to the new URLBuilder
	 * instance.
	 */
	public clone(additionalQueryParameters?: URLSearchParams): URLBuilder {
		const newUrlBuilder = new URLBuilderImpl(this.url, this.queryParameters);
		if (additionalQueryParameters !== undefined) {
			newUrlBuilder.appendAll(additionalQueryParameters);
		}
		return newUrlBuilder;
	}
}
